import React, { useEffect, useRef } from 'react';
import './style.scss';
import { ReactSVG } from 'react-svg'
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import add from 'src/Assets/Icon/add-2.svg';
import {
  toggleFinishTrialModal,
  finishTrialAction,
  getCurrentPlanAction,
} from 'src/Redux/Actions';
import { showSpinner, hideSpinner, showToast } from 'src/Util/Helper';

export default function FinishTrialModal() {
  const dispatch = useDispatch();
  const LoadingRef = useRef(false);

  const {
    isFinishTrialModal
  } = useSelector((state) => state.Modal);

  const {
    finishTrialStatus, finishTrialLoading, finishTrialSuccess,
  } = useSelector((state) => state.MPlan);

  //get cancel subscription res
  useEffect(() => {
    if (LoadingRef.current && !finishTrialLoading) {
      hideSpinner();
      if (finishTrialSuccess) {
        showToast("success", 3000, finishTrialStatus);
        dispatch(getCurrentPlanAction());
      } else {
        showToast("error", 3000, finishTrialStatus)
      }
      closeModal();
    }
    LoadingRef.current = finishTrialLoading;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishTrialLoading, finishTrialSuccess, finishTrialStatus, dispatch]);

  const closeModal = () => {
    dispatch(toggleFinishTrialModal(false));
  };

  const onUpgrade = () => {
    showSpinner()
    dispatch(finishTrialAction());
  }

  return (
    <Modal show={isFinishTrialModal} className="finish-trial-modal-component">
      <div className="finish-trial-content shadow-object v-r">
        <div className="close-btn">
          <ReactSVG src={add} className='icon-close' onClick={closeModal} />
        </div>
        <div className='description'>
          Great job on practicing 100 free questions! Don't stop now, activate the full plan to practice 400+  common questions and get 4 hrs of live review per month.
        </div>
        <div className='upgrade-btn btn-2' onClick={onUpgrade}>Activate Plan Now</div>
      </div>
    </Modal>
  );
}
