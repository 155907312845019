import React, { useEffect } from "react";
import './style.scss';
import UserStatComponent from "../UserStat";
import ConceptComponent from "../ConceptComponent";
import ConceptIntroComponent from "../ConceptIntroComponent";
import FlashcardComponent from "../FlashcardComponent";
import FlashcardComplete from "../FlashcardComplete";
import FlashcardQuiz from "../FlashcardQuiz";
import { useDispatch, useSelector } from "react-redux";
import {
    SET_ACTIVE_CONCEPT,
    START_FLASHCARD_QUIZ,
    FLASHCARDS_NEXT_STAGE
} from 'src/Redux/Type';
import {
    getFlashcardsAction,
    getFlashcardQuestionsAction
} from 'src/Redux/Actions';
import _ from 'lodash';
import ScoreComponent from "../ScoreComponent";
import { hideSpinner, showSpinner } from "src/Util/Helper";
import { FLASHCARD_NUM } from "../Info";
import { saveFlashcardStageAction, submitQuizAnswersAction } from "src/Redux/Actions";

export default function QuizHomeComponent() {
    const dispatch = useDispatch();

    const {
        active_flashcard,
        isQuizModalOpen,
        flashcard_concept,
        loading_flashcard_questions,
        getFlashcards_success,
        getFcQuestions_success,
        flashcards,
        flashcard_questions,
        showing_flashcards,
        taking_quiz,
        showing_score,
        quiz_flashcards,
        quiz_completed,
        quiz_answers,
        submit_quiz_success,
        submit_quiz_loading
    } = useSelector(state => state.Mims)

    const concept = (active_flashcard.concepts)[flashcard_concept]

    useEffect(() => {
        if(quiz_completed) {
            const formdata = new FormData()
            formdata.append('ac_practice_id', active_flashcard.id)
            formdata.append('answers', JSON.stringify(quiz_answers))
            showSpinner()
            dispatch(submitQuizAnswersAction(formdata))
            saveFlashcardStage()
        }
    }, [quiz_completed, dispatch])

    const saveFlashcardStage = () => {
        if(concept && concept.id) {
            const formdata = new FormData()
            formdata.append('concept_id', concept.id)
            formdata.append('stage', concept.stage)
            dispatch(saveFlashcardStageAction(formdata))
        }
    }

    useEffect(() => {
        if(!submit_quiz_loading && submit_quiz_success) {
            hideSpinner()
        }
    }, [submit_quiz_loading, submit_quiz_success])

    useEffect(() => {
        if(isQuizModalOpen) {
            var concept = Object.keys(active_flashcard.concepts).find(key => {
                let keyConcept = active_flashcard.concepts[key]
                return keyConcept.stage * FLASHCARD_NUM < keyConcept.total
            })
            if(!_.isEmpty(concept)) {
                dispatch({type: SET_ACTIVE_CONCEPT, data: { concept }})
            } else {
                concept = Object.keys(active_flashcard.concepts)[0]
                dispatch({type: SET_ACTIVE_CONCEPT, data: { concept }})
            }
        }
    }, [active_flashcard.id, isQuizModalOpen])

    const onStartFlashcards = () => {
        if (concept && concept.id) {
            showSpinner()
            dispatch(getFlashcardsAction(concept.id))
        }
    }

    const onBoost = () => dispatch({type: FLASHCARDS_NEXT_STAGE, data: { isContinue: false }})

    const onContinue = () => dispatch({type: FLASHCARDS_NEXT_STAGE, data: { isContinue: true }})

    useEffect(() => {
        if(getFlashcards_success && !getFcQuestions_success) {
            console.log('=== flashcards ===', flashcards)
            if (concept && concept.id) dispatch(getFlashcardQuestionsAction(concept.id))
        } else if (getFcQuestions_success && !loading_flashcard_questions) {
            hideSpinner()
            console.log('=== questions ===', flashcard_questions)
        }
    }, [loading_flashcard_questions, getFlashcards_success, getFcQuestions_success, concept])

    const getFlashcardStudied = () => {
        if (quiz_flashcards.length > 0) return quiz_flashcards.length 
        if (concept.stage * FLASHCARD_NUM < concept.total) return concept.stage * FLASHCARD_NUM
        return concept.total
    }

    const onStartQuiz = () => {
        if(_.isEmpty(flashcard_questions)) {
            onStartFlashcards()
        } else {
            dispatch({type: START_FLASHCARD_QUIZ})
        }
    }

    const showIntro = () => !taking_quiz && !showing_flashcards && !showing_score && concept && concept.stage === 0

    const showCompleted = () => !taking_quiz && !showing_flashcards && !showing_score && concept && concept.stage > 0

    const showScore = () => !taking_quiz && !showing_flashcards && showing_score

    const showQuiz = () => taking_quiz && !showing_flashcards && !showing_score 

    const showFlashcards = () => !taking_quiz && showing_flashcards && !showing_score && !_.isEmpty(quiz_flashcards)

    return (
        <div className="quiz-section-wrapper">
            <div className="row">
                <div className="col-lg-4 left-section">
                    <UserStatComponent />
                    <ConceptComponent onRetake={() => onStartQuiz()}/>
                </div>
                <div className="col-lg-8 right-section">
                    { showIntro() && <ConceptIntroComponent concept={flashcard_concept} onStart={() => onStartFlashcards()}/>}
                    { showFlashcards() && <FlashcardComponent cards={quiz_flashcards}/> }
                    { showCompleted() && <FlashcardComplete onStart={() => onStartQuiz()} concept={flashcard_concept} flashcardNum={getFlashcardStudied()}/>}
                    { showScore() && <ScoreComponent onBoost={() => onBoost()} onContinue={() => onContinue()}/> }
                    { showQuiz() && <FlashcardQuiz concept={concept}/> }
                </div>
            </div>
        </div>
    )
}