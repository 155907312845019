import React, { useEffect } from 'react';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  updateToken,
  toggleMims,
} from 'src/Redux/Actions';

export default function AuthCheck() {
  const dispatch = useDispatch();
  let history = useHistory();

  const {
    token
  } = useSelector((state) => state.Auth);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paramsToken = params.get('token');
    // window.localStorage.setItem('token', paramsToken);
    const mims = params.get('mims');
    const isMims = !!(mims === 'true')

    if (paramsToken) {
      dispatch(updateToken(paramsToken));
      dispatch(toggleMims(isMims));
      if (isMims) {
        history.push('/make-it-make-sense/dashboard');
      } else {
        history.push('/tutors');
      };
    } else {
      if (token) {
        history.push('/tutors');
      }
    }

  }, [dispatch, history, token]);

  return (
    <div className='auth-check-component'>

    </div>
  );
}