import {
  GET_QUESTIONS, GET_QUESTIONS_SUCCESS, GET_QUESTIONS_FAIL,
  GET_TEST_ID, GET_TEST_ID_SUCCESS, GET_TEST_ID_FAIL,
  GET_SCORES, GET_SCORES_SUCCESS, GET_SCORES_FAIL,
  GET_AVERAGE_TIME, GET_AVERAGE_TIME_SUCCESS, GET_AVERAGE_TIME_FAIL,
  SUBMIT_ANSWER, SUBMIT_ANSWER_SUCCESS, SUBMIT_ANSWER_FAIL,
} from '../Type';

import {
  getQuestions,
  getTestId,
  getScores,
  getAverageTime,
  submitAnswer,
} from "src/Api";

export const getQuestionsAction = (topic_id) => async (dispatch) => {
  dispatch({ type: GET_QUESTIONS });
  const res = await getQuestions(topic_id);
  if (res && res.success && res.data) {
    dispatch({ type: GET_QUESTIONS_SUCCESS, payload: res.data });
  } else {
    dispatch({ type: GET_QUESTIONS_FAIL, payload: res.data });
  }
};

export const getTestIdAction = (payload) => async (dispatch) => {
  dispatch({ type: GET_TEST_ID });
  const res = await getTestId(payload);
  if (res && res.data) {
    dispatch({ type: GET_TEST_ID_SUCCESS, payload: res.data });
  } else {
    dispatch({ type: GET_TEST_ID_FAIL, payload: res.data });
  }
};

export const getScoresAction = (test_id) => async (dispatch) => {
  dispatch({ type: GET_SCORES });
  const res = await getScores(test_id);
  if (res && res.data) {
    dispatch({ type: GET_SCORES_SUCCESS, payload: res.data });
  } else {
    dispatch({ type: GET_SCORES_FAIL, payload: res.data });
  }
};

export const getAverageTimeAction = (test_id) => async (dispatch) => {
  dispatch({ type: GET_AVERAGE_TIME });
  const res = await getAverageTime(test_id);
  if (res && res.data && res.success) {
    dispatch({ type: GET_AVERAGE_TIME_SUCCESS, payload: res.data });
  } else {
    dispatch({ type: GET_AVERAGE_TIME_FAIL, payload: res.data });
  }
};

export const submitAnswerAction = (payload) => async (dispatch) => {
  dispatch({ type: SUBMIT_ANSWER });
  const res = await submitAnswer(payload);
  if (res && res.data) {
    dispatch({ type: SUBMIT_ANSWER_SUCCESS, payload: res.data });
  } else {
    dispatch({ type: SUBMIT_ANSWER_FAIL, payload: res.data });
  }
};
