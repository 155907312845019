import React from 'react';
import './RatePlanPage.scss';
import { InputBox, Toggle, HourSlider, HourlyRateSlider, CardInputBox } from '../../Components';
import card from '../../Assets/Icon/credit-card.svg';
import { ReactSVG } from 'react-svg';
import left from '../../Assets/Icon/arrow-left.svg';
import checkmark from "../../Assets/Icon/checkmark.png";
import numeral from 'numeral';
import moment from 'moment';
import _ from 'lodash'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from "react-toastify";
import {
  getMypofileInfoAction,
  getPlanAction,
  getBraintreekeyAction,
  profileUpdateAction,
  getCardInfoAction,
  removeCardAction,
  setDefaultCardAction,
  sendNoneToServerAction
} from "../../Redux/Actions";
import { braintainService } from '../../services/paymentServices';
import { showSpinner, hideSpinner } from 'src/Util/Helper';

const PaymentPlan = [
  { des: "Suspend and cancel your account at any time — no contracts   " },
  { des: "Recurring weekly payment" },
];

class RatePlanPage extends React.Component {
  state = {
    activeColumn: 0,
    selectedPlan: 0,
    depth: 0,

    card_numbers: '',
    selectedCardIndex: '',

    card_number: '',
    month: '',
    year: '',
    cvv2: '',
    coupon_code: '',
    referal_code: '',

    check_sign: false,
    min_hour: 1,
    max_hour: 25,
    min_hourlyRate: 27.5,
    max_hourlyRate: 75,
    plan_tutoring_hour: 1,
    plan_rate_per_hour: 25,
    planName: '',
    isPayClick: false,
    plan_charge_date: '',
    plan_remaining_hour: '',
    card_update: false,
    old_cardInfo: null
  };


  componentDidMount() {
    showSpinner();

    this.getCarInfo();

    const { myProfileInfo } = this.props;
    if (_.isEmpty(myProfileInfo)) {
      this.props.getMypofileInfoAction().then(() => {
        this.updatePlanState();
      });
    } else {
      this.updatePlanState();
    };
    this.props.getBraintreekeyAction();

    this.setState({
      depth: this.props.depth
    });
  };

  updatePlanState = () => {
    const { myProfileInfo } = this.props;
    if (myProfileInfo) {
      this.setState({
        plan_tutoring_hour: Number(myProfileInfo.plan_tutoring_hour),
        plan_rate_per_hour: Number(myProfileInfo.plan_rate_per_hour),
        planName: myProfileInfo.plan_frequency ? myProfileInfo.plan_frequency : "Weekly",
        plan_charge_date: myProfileInfo.plan_charge_date,
        plan_remaining_hour: myProfileInfo.plan_remaining_hour
      });
    };
    this.props.getPlanAction().then(() => {
      hideSpinner()
      var planName = myProfileInfo.plan_frequency ? myProfileInfo.plan_frequency : "Weekly"
      const { plans } = this.props;
      if (_.isEmpty(plans)) {
        this.setState({
          min_hour: _.find(plans, { name: planName }).min_hour,
          max_hour: _.find(plans, { name: planName }).max_hour,
          min_hourlyRate: _.find(plans, { name: planName }).min_rate,
          max_hourlyRate: _.find(plans, { name: planName }).max_rate,
        });
      }
    });
  }

  static getDerivedStateFromProps(props, state) {
    return {
      depth: props.depth
    };
  };

  getCarInfo = () => {
    this.props.getCardInfoAction().then(() => {
      const { card_numbers } = this.props;
      if (card_numbers) {
        this.setState({ card_numbers: card_numbers })
      };
    });
  };

  overButton = (num) => {
    this.setState({ activeColumn: num })
  };

  leaveButton = (num) => {
    if (num === this.state.activeColumn) {
      this.setState({ activeColumn: 0 })
    };
  };

  selectPlan = (num) => {
    // this.setState({ selectedPlan: num})
    this.props.updateTitle('push', 'Make Payment');
  };

  updateValue = (field, value) => {
    this.setState({ [field]: value });
  };

  goBack = () => {
    this.props.updateTitle('pop');
  };

  changePricingPlan = (value) => {
    this.setState({
      min_hour: value.min_hour,
      max_hour: value.max_hour,
      min_hourlyRate: value.min_rate,
      max_hourlyRate: value.max_rate,
      plan_tutoring_hour: value.min_hour,
      plan_rate_per_hour: value.min_rate,
      planName: value.name
    });
  };

  selectHour = (value) => {
    this.setState({ plan_tutoring_hour: value });
  };

  selectHourlyRate = (value) => {
    this.setState({ plan_rate_per_hour: value });
  };

  makePayment = () => {
    showSpinner()
    const { card_number, month, year, cvv2, card_update } = this.state;
    if (card_update) {
      const { braintreeKey } = this.props;
      var cardDetail = {
        card_number,
        month,
        year,
        cvv2,
        zip_code: '10001'
      };
      braintainService(braintreeKey, cardDetail).then((res) => {
        this.sendNonce(res);
      })
        .catch(err => {
          hideSpinner()
          toast("Invalid Card Info")
        });
    } else {
      this.profileUpdate();
    };

  };

  sendNonce = (nonce) => {
    const { old_cardInfo } = this.state;
    const formData = new FormData()
    formData.append('nonce', nonce);

    if (old_cardInfo) {
      formData.append('todo', 'update');
      formData.append('payment_method_token', old_cardInfo.payment_method_token);
    } else {
      formData.append('todo', 'new');
    };

    this.props.sendNoneToServerAction(formData).then(() => {
      const { nonceUpdate } = this.props;
      if (nonceUpdate) {
        toast(nonceUpdate);
        this.profileUpdate();
      } else {
        toast("card update error!");
        hideSpinner();
      };
    });
  };

  profileUpdate = () => {
    const formData = new FormData()
    formData.append('plan_frequency', this.state.planName);
    formData.append('plan_tutoring_hour', this.state.plan_tutoring_hour);
    formData.append('plan_rate_per_hour', this.state.plan_rate_per_hour);
    formData.append('plan_status', 'active');

    this.props.profileUpdateAction(formData).then(() => {
      hideSpinner();
      if (this.props.profileUpdateStatus) {
        toast("Successfully Updating!")
        this.getCarInfo();
        this.setState({
          card_number: '',
          month: '',
          year: '',
          cvv2: '',
          card_update: false
        });
      } else {
        toast(this.props.profileUpdateErr)
      };
    });
  };

  editCard = (item) => {
    this.setState({ card_update: true });
    this.setState({ old_cardInfo: item });
  };

  addNewCard = () => {
    this.setState({ card_update: true });
    this.setState({ old_cardInfo: null });
  };

  removeCard = (item) => {
    showSpinner()
    const formData = new FormData();
    formData.append('todo', 'remove');
    formData.append('payment_method_token', item.payment_method_token);

    this.props.removeCardAction(formData).then(() => {
      hideSpinner()

      const { removeCardStatus, removeCardError } = this.props;
      if (removeCardStatus) {
        this.getCarInfo();
        toast(removeCardStatus);
      } else {
        toast(removeCardError);
      };
    });
  };

  setAsDefaultCard = (item) => {
    showSpinner()
    const formData = new FormData();
    formData.append('todo', 'set_default');
    formData.append('payment_method_token', item.payment_method_token);

    this.props.setDefaultCardAction(formData).then(() => {
      hideSpinner()

      const { defaultCardStatus, defaultCardError } = this.props;
      if (defaultCardStatus) {
        this.getCarInfo();
        toast(defaultCardStatus);
      } else {
        toast(defaultCardError)
      };
    });
  };

  updateValue = (field, value) => {
    this.setState({ [field]: value });
  };

  render() {
    const { plans, myProfileInfo } = this.props;
    const { plan_charge_date, plan_remaining_hour, min_hour, max_hour, min_hourlyRate, max_hourlyRate, planName, plan_tutoring_hour, plan_rate_per_hour, depth, card_numbers, card_number, month, year, cvv2, coupon_code, referal_code, check_sign, isPayClick, selectedCardIndex, card_update, old_cardInfo } = this.state;
    return (
      <div className="rateplan-page v-r">
        {depth === 0 && <div className="container plan-area">
          <h1>Your Rate Plan</h1>
          <p className="page-description-txt">To update your plan, set payment frequency, select tutoring hours needed, and choose your hourly rate.</p>
          <div className="row mt-5" id="section-1-1">
            <div className="col-lg-12 remaining">
              <h2>{`Available tutoring hour(s) ${plan_charge_date ? `for billing period ending ${moment(plan_charge_date).local().format('MMM DD, YYYY')}` : "is"} : `}</h2>
              <p className="remaining-hour">{plan_remaining_hour}</p>
            </div>
            <div className="col-lg-12">
              <h2>Select your payment frequency:</h2>
            </div>
            {plans
              ? plans.map((item, index) => (
                <div
                  className="col-lg-3 col-md-3 col-sm-6 col-6 grade-level-col"
                  key={index}
                  style={{ position: "relative" }}
                  onClick={(e) =>
                    this.changePricingPlan(item)
                  }
                >
                  <div
                    className={`grade-level center-item ${planName === item.name
                      ? "active"
                      : ""
                      }`}
                  >
                    {item.name}
                  </div>
                  <div className="under-dot grade-level-bg" />
                </div>
              ))
              : null}
          </div>

          <div className="row" id="section-1-1" ref="section12">
            <div className="col-lg-12">
              <h2>Select how many tutoring hours you need: {" "} {this.state.planName}</h2>
              <div className="slider-bar">
                <div className="slider-des-body text-center">
                  {/* {this.state.plan_tutoring_hour}Hrs */}
                  {plan_tutoring_hour}{plan_tutoring_hour === 1 ? " hour" : " hours"}
                </div>
                <HourSlider
                  hourValue={plan_tutoring_hour}
                  selectHour={(e) => this.selectHour(e)}
                  min={min_hour}
                  max={max_hour}
                />
              </div>
            </div>
          </div>

          <div className="row" id="section-1-1" ref="section12">
            <div className="col-lg-12">
              <h2>Choose your price (hourly rate):</h2>
              <div className="col-lg-12 price-note">
                At GradeGetter, We let our customers choose what they can pay for online and one-on-one tutoring.
                Paying a little more helps to offset the cost for families who might not be able to afford tutoring regularly.
                With this approach, we are able to make private tutoring more accessible and affordable for everyone.
              </div>
              <div className="slider-bar">
                <div className="slider-des-body text-center">
                  ${plan_rate_per_hour}/hour
                </div>
                <HourlyRateSlider
                  hourlyValue={plan_rate_per_hour}
                  selectHourlyRate={(e) => this.selectHourlyRate(e)}
                  min={min_hourlyRate}
                  max={max_hourlyRate}
                />
              </div>
            </div>
          </div>

          {/* <div className="row" id="section-1-2" ref="section12">
                        <div className="col-lg-12">
                            <div className="free-lesson">
                                <div className="free-lesson-header">
                                    <p className="text-center">One FREE lesson</p>
                                    <h1 className="lesson-title text-center">
                                        $0.00 due today
                                    </h1>
                                </div>
                                <div className="free-lesson-body">
                                    {FreeLesson.map((item, index) => {
                                        return (
                                        <div className="body-item" key={index}>
                                            <div>
                                            <img src={checkmark} alt="checkmark" />
                                            </div>
                                            <div className="explain">{item.des}</div>
                                        </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="under-dot additional-bg" />
                        </div>
                    </div> */}

          <div className="row" id="section-1-2" ref="section13">
            <div className="col-lg-12">
              <div className="free-lesson">
                <div className="free-lesson-header">
                  <p className="text-center">{planName} Payment Plan</p>
                  <h1 className="lesson-title text-center">
                    ${numeral(plan_tutoring_hour * plan_rate_per_hour).format('0,0.00')}/
                    {planName === 'Weekly' ? 'Week' :
                      planName === 'Biweekly' ? 'Biweek' :
                        planName === 'Monthly' ? 'Month' : 'One Time'
                    }
                  </h1>
                </div>
                <div className="free-lesson-body">
                  <div className="body-item">
                    <div>
                      <img src={checkmark} alt="checkmark" />
                    </div>
                    <div className="explain">
                      {" "}
                      ${plan_rate_per_hour}/hour
                    </div>
                  </div>
                  <div className="body-item">
                    <div>
                      <img src={checkmark} alt="checkmark" />
                    </div>
                    <div className="explain">
                      {" "}
                      {plan_tutoring_hour} &nbsp; tutoring {plan_tutoring_hour === 1 ? " hour" : " hours"} &nbsp;
                      {planName == 'Weekly' ? 'weekly' : planName == 'Biweekly' ? 'biweekly' : 'monthly'}
                    </div>
                  </div>
                  {PaymentPlan.map((item, index) => {
                    return (
                      <div className="body-item" key={index}>
                        <div>
                          <img src={checkmark} alt="checkmark" />
                        </div>
                        <div className="explain">{item.des}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="under-dot additional-bg2" />
            </div>
          </div>

          <div className="select-plan-btn" style={{ paddingRight: '15px', marginVertical: 30 }}>
            <button className={`btn-2 btn-2-outline select-btn`} onClick={e => this.selectPlan()} >Update Plan</button>
          </div>
        </div>}
        {depth === 1 && <div className="container payment-area">
          <h1 className="show-web">Make Payment</h1>
          <ReactSVG src={left} className="svg-icon back-icon show-web" onClick={e => this.goBack()} />
          <div className="main-content">
            <h2>Card Information</h2>
            {card_numbers && card_numbers.map((data, index) => (
              <div className="edit-card" key={index}>
                <div className="card-num-date">
                  <p className="card-brand">{data.masked_card_number}</p>
                  <p className="ml-5">{`Expires: ${data.exp_month}/${data.exp_year}`}</p>
                </div>
                <div className="edit-card-row">
                  <div className="edit-item-btn" onClick={e => this.editCard(data)}>Edit</div>
                  <div className="edit-item-btn" onClick={e => this.removeCard(data, index)}>Remove</div>
                  {!data.is_default && <div className="edit-item-btn" onClick={e => this.setAsDefaultCard(data, index)}>Set as default</div>}
                </div>
              </div>
            ))}

            <div className="add-new-card" onClick={e => this.addNewCard()}>Add New Card</div>

            {card_update &&
              <div className="mt-5">
                <div className="row">
                  <div className="col-lg-12 form-group">
                    <label>{`Card Number(${old_cardInfo ? 'Edit' : 'New'})`}</label>
                    {/* <InputBox icon={card} value={card_number} placeholder="Card Number" onChange={val => this.updateValue('card_number', val)}/> */}
                    <CardInputBox
                      icon={card}
                      type="number"
                      value={card_number}
                      onChange={val => this.updateValue('card_number', val)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 form-group">
                    <label>Month</label>
                    <InputBox value={month} placeholder="Month" onChange={val => this.updateValue('month', val)} />
                  </div>
                  <div className="col-lg-4 form-group">
                    <label>Year</label>
                    <InputBox value={year} placeholder="Year" onChange={val => this.updateValue('year', val)} />
                  </div>
                  <div className="col-lg-4 form-group">
                    <label>CVV2</label>
                    <InputBox value={cvv2} placeholder="CVV2" onChange={val => this.updateValue('cvv2', val)} />
                  </div>
                </div>
              </div>
            }

            <div className="row">
              <div className="col-lg-12 form-group toggle-area">
                <Toggle value={check_sign} className="custom-toggle" onChange={val => this.updateValue('check_sign', val)} />
                <p>On the GradeGetter platform, your subscription includes a set number of tutoring minutes for a specified period of time. The tutoring minutes do not roll over from one period to another, and we do not issue refunds without reason. Cancel at any time. You are billed either weekly, biweekly, monthly, or one time depending on your plan. Additional tutoring sessions not covered by your plan will be billed individually. Your membership will be renewed automatically at the beginning of each cycle until you cancel. Tutor satisfaction guaranteed: If you do not like your tutor that you are assigned, we assign you a new tutor at no additional cost.</p>
              </div>
            </div>
            <div className="row">
              {check_sign && card_numbers && month && year && cvv2 || !card_update && check_sign && !_.isEmpty(card_numbers) ?
                <div className="col-lg-12 form-group">
                  <button className="btn-2 primary-btn" onClick={e => this.makePayment()}>Make Payment</button>
                </div>
                :
                <div className="col-lg-12 form-group">
                  <button className="btn-2" >Make Payment</button>
                </div>
              }
            </div>
            {/* <div className="row">
                <div className="col-lg-12 form-group policy">
                    <label>Cancellation Policy</label>
                    <p>On the GradeGetter platform, your subscription includes a set number of tutoring minutes for a specified period of time. The tutoring minutes do not roll over from one period to another, and we do not issue refunds without reason. Cancel at any time. You are billed either weekly, biweekly, monthly, or one time depending on your plan. Additional tutoring sessions not covered by your plan will be billed individually. Your membership will be renewed automatically at the beginning of each cycle until you cancel. Tutor satisfaction guaranteed: If you do not like your tutor that you are assigned, we assign you a new tutor at no additional cost.</p>
                </div>
            </div> */}
          </div>
        </div>}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  myProfileInfo: state.Core.myProfileInfo,
  plans: state.Core.plans,
  braintreeKey: state.Core.braintreeKey,
  profileUpdateStatus: state.Core.profileUpdateStatus,
  profileUpdateErr: state.Core.profileUpdateErr,
  card_numbers: state.Core.card_numbers,

  removeCardStatus: state.Payment.removeCardStatus,
  removeCardError: state.Payment.removeCardError,
  
  defaultCardStatus: state.Payment.defaultCardStatus,
  defaultCardError: state.Payment.defaultCardError,
  nonceUpdate: state.Auth.nonceUpdate,
});

export default connect(mapStateToProps, {
  getMypofileInfoAction,
  getPlanAction,
  getBraintreekeyAction,
  profileUpdateAction,
  getCardInfoAction,
  removeCardAction,
  setDefaultCardAction,
  sendNoneToServerAction
})(withRouter(RatePlanPage));