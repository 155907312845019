import React from "react";
import './style.scss';
import TimeImage from 'src/Assets/MIMS/Planner/time management.png';

export default function StartComponent({onStart}) {
    
    return (
        <div  className="start-component">
            <div className="start-wrapper">
                <div className="row no-gutters">
                    <div className="col-lg-6 left-section">
                        <div className="title">Perfect Score <br></br> Planner</div>
                        <div className="primary-btn" onClick={onStart}>START TO PLAN</div>
                    </div>
                    <div className="col-lg-6 right-section">
                        <img src={TimeImage}/>
                    </div>
                </div>
            </div>
        </div>
    )
}