import React, { useState, useEffect, useRef } from 'react';
import './style.scss';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import BackIcon from "src/Assets/Icon/back-icon.svg";
import {
  Toggle,
  CardList,
  AddPayment
} from 'src/Components';
import {
  getBraintreekeyAction,
  sendNoneToServerAction,
  updateMPurchaseAction,
  frogUpHubSpotAction,
} from 'src/Redux/Actions';
import { braintainService } from 'src/services/paymentServices';
import { showSpinner, hideSpinner, showToast } from 'src/Util/Helper';

export default function Payment({
  topic_id,
  plan_id,
  onBack
}) {

  const dispatch = useDispatch();
  const NonceLoadingRef = useRef(false);
  const PurchaseLoadingRef = useRef(false);

  const {
    braintreeKey, card_numbers, myProfileInfo
  } = useSelector((state) => state.Core);

  const {
    nonceUpdate, nonceUpdateErr, nonceUpdateStatus_loading, nonceUpdateStatus_success,
  } = useSelector((state) => state.Auth);

  const {
    mPurchaseStatus, mPurchase_success, mPurchase_loading,
  } = useSelector((state) => state.MPayment);

  const {
    mPlans,
  } = useSelector((state) => state.MPlan);

  const {
    topicList
  } = useSelector(
    state => state.Topic,
  );

  const [cardInfo, setCardInfo] = useState({
    cardNumber: '',
    month: '',
    year: '',
    cvv2: '',
    couponCode: '',
    referralCode: '',
  });
  const [isPolicy, setIsPolicy] = useState(false);
  const [isCardUpdate, setIsCardUpdate] = useState('');
  const [oldCardInfo, setOldCardInfo] = useState({});

  //Call Braintree Key
  useEffect(() => {
    if (_.isEmpty(braintreeKey)) {
      dispatch(getBraintreekeyAction());
    }
  }, [braintreeKey, dispatch]);

  //Update Nonce Res
  useEffect(() => {
    if (NonceLoadingRef.current && !nonceUpdateStatus_loading) {
      if (nonceUpdateStatus_success) {
        showToast("success", 3000, nonceUpdate)
        purchase();
      } else {
        hideSpinner();
        showToast("error", 3000, nonceUpdateErr)
      }
    }
    NonceLoadingRef.current = nonceUpdateStatus_loading;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nonceUpdateStatus_loading, nonceUpdateStatus_success, nonceUpdate, nonceUpdateErr]);


  // Update Nonce end

  //Purchase res
  useEffect(() => {
    if (PurchaseLoadingRef.current && !mPurchase_loading) {
      hideSpinner();
      if (mPurchase_success) {
        callFrogHubSpot();
        showToast("success", 3000, mPurchaseStatus)
      } else {
        showToast("error", 3000, mPurchaseStatus)
      }
    }
    PurchaseLoadingRef.current = mPurchase_loading;
  }, [mPurchase_loading, mPurchase_success, mPurchaseStatus]);

  const purchase = () => {
    const payload = {
      plan_id: Number(plan_id),
      topic_id: Number(topic_id)
    }
    dispatch(updateMPurchaseAction(payload));
  };
  // Purchase end

  const callFrogHubSpot = () => {
    const planName = mPlans.find(item => item.id === Number(plan_id))?.name || '';
    const topicName = topicList.find(item => item.id === Number(topic_id))?.name || '';
    const payload = {
      first_name: myProfileInfo.first_name,
      last_name: myProfileInfo.last_name,
      email: myProfileInfo.email,
      product: topicName,
      plan: planName
    };
    dispatch(frogUpHubSpotAction(payload));
  }

  const onChangeCardInfo = (field, value) => {
    setCardInfo({
      ...cardInfo,
      [field]: value,
    });
  };

  const onCheckCouponCode = () => {

  };

  const onChangePolicyCheck = () => setIsPolicy(!isPolicy);

  const onMakePayment = () => {
    showSpinner()
    if (isCardUpdate) {
      var cardDetail = {
        card_number: cardInfo.cardNumber,
        month: cardInfo.month,
        year: cardInfo.year,
        cvv2: cardInfo.cvv2,
        zip_code: '10001'
      };
      braintainService(braintreeKey, cardDetail).then((res) => {
        updateNonce(res);
      })
        .catch(err => {
          hideSpinner();
          showToast("error", 3000, "Invalid Card Info")
        });
    } else {
      purchase();
    }
  }

  const updateNonce = (nonce) => {
    const formData = new FormData()
    formData.append('nonce', nonce);
    if (oldCardInfo) {
      formData.append('todo', 'update');
      formData.append('payment_method_token', oldCardInfo.payment_method_token);
    } else {
      formData.append('todo', 'new');
    }
    dispatch(sendNoneToServerAction(formData));
  };

  const getDisableBtn = () => {
    if (isCardUpdate) {
      if (cardInfo.cardNumber && cardInfo.month && cardInfo.year && cardInfo.cvv2 && isPolicy) {
        return false;
      }
    } else {
      if (!_.isEmpty(card_numbers)) {
        return false
      }
    }
    return true;
  };

  const editCard = (info) => {
    setIsCardUpdate('Edit');
    setOldCardInfo(info);
  };

  const addNewCard = () => {
    setIsCardUpdate('New');
    setOldCardInfo(null);
  }

  return (
    <div className="m-payment-component">
      <div className='back-content' onClick={onBack}>
        <img src={BackIcon} alt='back-icon' className='back-icon' />
      </div>
      <h2 className='text-center'>Make a Payment</h2>
      <div className="m-payment-content">
        <CardList
          editCard={editCard}
        />

        <div className="add-new-card" onClick={addNewCard}>Add New Card</div>

        <AddPayment
          isCardUpdate={isCardUpdate}
          cardInfo={cardInfo}
          onChangeCardInfo={onChangeCardInfo}
        />

        <div className="row">
          <div className="col-lg-12 form-group">
            <button
              disabled={getDisableBtn()}
              className={`btn-2 ${getDisableBtn() ? "" : "primary-btn"}`}
              onClick={onMakePayment}>
              Make Payment
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 form-group toggle-area">
            <Toggle
              value={isPolicy}
              className="custom-toggle"
              onChange={onChangePolicyCheck}
            />
            <p>
              You can cancel and receive a complete refund within 24 hours if you believe the value provided does not meet your expectations. We assure you a full refund if the request is made within 30 days of your purchase. We commit to processing refunds within 24 hours following your request. For any inquiries or requests, contact us at
              <a
                className='ml-1 mr-1'
                href={`mailto:info@gradegetter.com`}>
                {`info@gradegetter.com`}
              </a>
              or visit our office at 1751 River Run Suite 200, Fort Worth, TX 76107.
            </p>
          </div>
        </div>

        {/* <div className="row">
          <div className="col-lg-12">
            <div className='cancellation-policy-title'>Cancellation Policy</div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
          </div>
        </div> */}
      </div>
    </div>
  );
}
