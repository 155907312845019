import { get, post, remove } from "./Api";

export const getAssessments = () => {
    return get('/atassessments');
}

export const getAssessmentData = (id) => {
    return get(`/attests?at_assessment_id=${id}`)
}

export const getAssessmentQuestions = (id) => {
    return get(`/atquestions?at_assessment_id=${id}&show_solution=${true}`)
}

export const submitAssessment = (data) => {
    return post('/attests', data)
}

export const getAssessmentHistory = (id) => {
    return get(`/attests/${id}/history`)
}

export const getPracticeFlashcards = () => {
    return get(`/acpracticeflashcards`)
}

export const getPracticeFlashcardData = (id) => {
    return get(`/acpracticeflashcards/${id}`)
}

export const getFlashcards = (id) => {
    return get(`/acflashcards?ac_concept_id=${id}`)
}

export const getFlashcardQuestions = (id) => {
    return get(`/acfcquestions?ac_concept_id=${id}&show_solution=${true}`)
}

export const submitQuizAnswers = (data) => {
    return post('/acflashcardquiz', data)
}

export const saveFlashcardStage = (data) => {
    return post('/acflashcardquiz/save', data)
}

export const getExams = () => {
    return get('/exams')
}

export const getPlanner = (examId) => {
    return get(`/planner?exam_id=${examId}`)
}

export const createPlanner = (data) => {
    return post('/planner', data)
}

export const deletePlanner = (exam_id) => {
    return post(`/planner/delete?exam_id=${exam_id}`)
}