import React from 'react';
import './style.scss';

export default function ReviewUser({
  className = '', info, selectedId, onClick,
}) {

  return (
    <div
      className={`review-user-item ${className} ${selectedId === info.id ? 'selected' : ''}`}
      onClick={() => onClick(info)}>
      <div className='user-item-content'>
        <img src={`${process.env.REACT_APP_IMAGE_DOMAIN}${info.topic.picture}`} alt="logo" className='logo' />
        <p>{info.review ? info.review : 'No Review'}</p>
      </div>
    </div>
  );
}