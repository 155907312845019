import arrowLeft from './Assets/Icon/arrow-left.png';
import arrowRight from './Assets/Icon/arrow-right.png';

export const StarSVGViewBox = "0 0 16 15";
export const StarSVGIconPath = "M7.90577 0.587492L9.82622 5.13287L14.7428 5.55524C15.0838 5.58469 15.2225 6.01017 14.9637 6.23414L11.2344 9.46512L12.3519 14.2717C12.4294 14.6057 12.0675 14.8684 11.7746 14.6909L7.54927 12.1427L3.32397 14.6909C3.03025 14.8676 2.6691 14.6049 2.7466 14.2717L3.86415 9.46512L0.134072 6.23337C-0.124778 6.00939 0.0131721 5.58392 0.354947 5.55447L5.27155 5.13209L7.192 0.587492C7.3253 0.271292 7.77247 0.271292 7.90577 0.587492Z";

export const CarouselLeftButton = `
    <div style='width:50px;height:50px;border-radius:50%;display:flex;align-items:center;justify-content:center;background:white;box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);'>
        <img src='${arrowLeft}' alt='nav-arrow' />
    </div>
`;
export const CarouselRightButton = `
    <div style='width:50px;height:50px;border-radius:50%;display:flex;align-items:center;justify-content:center;background:white;box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);'>
        <img src='${arrowRight}' alt='nav-arrow' />
    </div>
`;

export const PlansInfo = [
    {
        id: "1",
        access: "Total LifeTime Access",
        price: "$399",
        permissions: [
            "Lifetime access to SAT, ACT, ASVAB, TEAS,HESI, ISEE, PSAT, GRE, IRQT, SSAT, PSAT, AFOQT, GMAT",
            "Unlimited access to live classes when available",
        ]
    },
    {
        id: "2",
        access: "Specific LifeTime Access",
        price: "$149.99",
        permissions: [
            `LLifetime access to 500+ <ProductName> practice questions`,
            "Access to 2 live classes",
        ]
    },
    {
        id: "3",
        access: "Monthly Access",
        price: "$49.99/month",
        permissions: [
            "Monthly access to 500+ <ProductName> practice questions",
            "Can attend one live class"
        ]
    },
    {
        id: "4",
        access: "FREE Access",
        price: "$0.00",
        permissions: [
            "Access to 5 additional practice questions",
        ]
    }
]

export const colorRatio = ({color1 = '24C1A5', color2 = 'F92672', ratio}) => {
    var hex = function(x) {
        x = x.toString(16);
        return (x.length == 1) ? '0' + x : x;
    };
  
    var r = Math.ceil(parseInt(color1.substring(0,2), 16) * ratio + parseInt(color2.substring(0,2), 16) * (1-ratio));
    var g = Math.ceil(parseInt(color1.substring(2,4), 16) * ratio + parseInt(color2.substring(2,4), 16) * (1-ratio));
    var b = Math.ceil(parseInt(color1.substring(4,6), 16) * ratio + parseInt(color2.substring(4,6), 16) * (1-ratio));
  
    var colorValue = hex(r) + hex(g) + hex(b);
    return colorValue
}

export const convertSecondsToMS = (seconds) => {
    // const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    // const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

    const formattedString = `${formattedMinutes}:${formattedSeconds}`;
    return formattedString.includes('NaN') ? '00:00' : formattedString
}

export const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

export const WeekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

export const monthShortNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const ordinal_suffix_of = (i) => {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
}


// flashcards test data
export const Flaschards = [
    {
        "id": 1,
        "name": "Flashcard 1",
        "question": "<p>Third flash content 1</p>",
        "answer": "<p>third flash answer</p>",
        "concept_id": 1,
        "icon": "",
        "status": "active",
        "questions_count": 0
    },
    {
        "id": 2,
        "name": "Flashcard 2",
        "question": "<p>Third flash content 2</p>",
        "answer": "<p>third flash answer</p>",
        "concept_id": 1,
        "icon": "",
        "status": "active",
        "questions_count": 0
    },
    {
        "id": 3,
        "name": "Flashcard 3",
        "question": "<p>Third flash content 3</p>",
        "answer": "<p>third flash answer</p>",
        "concept_id": 2,
        "icon": "",
        "status": "active",
        "questions_count": 0
    },
    {
        "id": 4,
        "name": "Flashcard 4",
        "question": "<p>Third flash content 4</p>",
        "answer": "<p>third flash answer</p>",
        "concept_id": 2,
        "icon": "",
        "status": "active",
        "questions_count": 0
    },
    {
        "id": 5,
        "name": "Flashcard 5",
        "question": "<p>Third flash content 5</p>",
        "answer": "<p>third flash answer</p>",
        "concept_id": 3,
        "icon": "",
        "status": "active",
        "questions_count": 0
    },
    {
        "id": 6,
        "name": "Flashcard 6",
        "question": "<p>Third flash content 6</p>",
        "answer": "<p>third flash answer</p>",
        "concept_id": 3,
        "icon": "",
        "status": "active",
        "questions_count": 0
    },
]

export const FlaschardQuestions = [
    {
        "id": 1,
        "name": "Name 1",
        "picture": "",
        "description": "<p>Question cnotent edited 1</p>",
        "ac_flashcard_id": 1,
        "options": [
            "Option 1",
            "Option 2",
            "option 3",
            "Option 4",
            "Option 5"
        ],
        "solution": "Option 5",
        "timer": 90,
        "question_date": "12/04/2022",
        "rules": "ruels",
        "notes": "<p>these are notes</p>"
    },
    {
        "id": 2,
        "name": "Name 2",
        "picture": "",
        "description": "<p>Question cnotent edited 2</p>",
        "ac_flashcard_id": 1,
        "options": [
            "Option 1",
            "Option 2",
            "option 3",
            "Option 4",
            "Option 5"
        ],
        "solution": "Option 5",
        "timer": 90,
        "question_date": "12/04/2022",
        "rules": "ruels",
        "notes": "<p>these are notes</p>"
    },
    {
        "id": 3,
        "name": "Name 3",
        "picture": "",
        "description": "<p>Question cnotent edited 3</p>",
        "ac_flashcard_id": 2,
        "options": [
            "Option 1",
            "Option 2",
            "option 3",
            "Option 4",
            "Option 5"
        ],
        "solution": "Option 5",
        "timer": 90,
        "question_date": "12/04/2022",
        "rules": "ruels",
        "notes": "<p>these are notes</p>"
    },
    {
        "id": 4,
        "name": "Name 4",
        "picture": "",
        "description": "<p>Question cnotent edited 3</p>",
        "ac_flashcard_id": 2,
        "options": [
            "Option 1",
            "Option 2",
            "option 3",
            "Option 4",
            "Option 5"
        ],
        "solution": "Option 5",
        "timer": 90,
        "question_date": "12/04/2022",
        "rules": "ruels",
        "notes": "<p>these are notes</p>"
    },
    {
        "id": 5,
        "name": "Name 5",
        "picture": "",
        "description": "<p>Question cnotent edited 3</p>",
        "ac_flashcard_id": 2,
        "options": [
            "Option 1",
            "Option 2",
            "option 3",
            "Option 4",
            "Option 5"
        ],
        "solution": "Option 5",
        "timer": 90,
        "question_date": "12/04/2022",
        "rules": "ruels",
        "notes": "<p>these are notes</p>"
    },
    {
        "id": 6,
        "name": "Name 6",
        "picture": "",
        "description": "<p>Question cnotent edited 3</p>",
        "ac_flashcard_id": 3,
        "options": [
            "Option 1",
            "Option 2",
            "option 3",
            "Option 4",
            "Option 5"
        ],
        "solution": "Option 5",
        "timer": 90,
        "question_date": "12/04/2022",
        "rules": "ruels",
        "notes": "<p>these are notes</p>"
    },
    {
        "id": 7,
        "name": "Name 7",
        "picture": "",
        "description": "<p>Question cnotent edited 3</p>",
        "ac_flashcard_id": 3,
        "options": [
            "Option 1",
            "Option 2",
            "option 3",
            "Option 4",
            "Option 5"
        ],
        "solution": "Option 5",
        "timer": 90,
        "question_date": "12/04/2022",
        "rules": "ruels",
        "notes": "<p>these are notes</p>"
    },
    {
        "id": 8,
        "name": "Name 8",
        "picture": "",
        "description": "<p>Question cnotent edited 3</p>",
        "ac_flashcard_id": 4,
        "options": [
            "Option 1",
            "Option 2",
            "option 3",
            "Option 4",
            "Option 5"
        ],
        "solution": "Option 5",
        "timer": 90,
        "question_date": "12/04/2022",
        "rules": "ruels",
        "notes": "<p>these are notes</p>"
    },
    {
        "id": 9,
        "name": "Name 9",
        "picture": "",
        "description": "<p>Question cnotent edited 3</p>",
        "ac_flashcard_id": 4,
        "options": [
            "Option 1",
            "Option 2",
            "option 3",
            "Option 4",
            "Option 5"
        ],
        "solution": "Option 5",
        "timer": 90,
        "question_date": "12/04/2022",
        "rules": "ruels",
        "notes": "<p>these are notes</p>"
    },
]
