import React, { useState } from "react";
import './style.scss';
import ReactSlider from 'react-slider';
import PlannerProgressIndicator from "../../PlannerProgress";

export default function TimeSplitterComponent({exam, onValue}) {
    const [value, setValue] = useState(50)

    const getPercentage = (state) => {
        if (state.index === 0) return value
        return 100 - value 
    }

    const trimTopic = (text) => {
        let edited = text.toUpperCase().replaceAll('MAKE', '')
        return (edited.replaceAll('SENSE', '').trim())
    }

    const updateSliderPosition = (value) => {
        if (value < 15) {
            setValue(15)
        } else if (value > 85) {
            setValue(85)
        } else setValue(value)
    }

    const onNext = () => {
        let result = exam.topics.map((item, index) => {
            let range = index === 0 ? value : 100 - value
            return { "mims_topic_id": item.id, "percentage": range}
        })
        onValue(result)
    }

    return (
        <div className="time-splitter-1 card">
            <div className="info-content">
                <div className="step-info">step 3 of 5</div>
                <h3 className="plan-title">Balancing Your Prep: Prioritize to Maximize:</h3>
                <p className="plan-content">Distribute your study hours across various test prep sections. A greater percentage for a section signifies an increased focus on its study materials.</p>
                <div className="slider-wrapper">
                    <div className="label-wrapper">
                        {
                            exam.topics.map(item => (
                                <div key={item.id}>{trimTopic(item.name)}</div>
                            ))
                        }
                    </div>
                    <ReactSlider
                        className="horizontal-slider"
                        trackClassName="customSlider-track"
                        withTracks={true}
                        value={value}
                        // onBeforeChange={(value, index) => console.log(`onBeforeChange: ${JSON.stringify({ value, index })}`)}
                        onChange={(value, index) => updateSliderPosition(value) }
                        onAfterChange={(value, index) => updateSliderPosition(value) }
                        renderTrack={(props, state) => <div {...props}>{getPercentage(state)}%</div>}
                        renderThumb={(props, state) => <div {...props}></div>}
                    />
                </div>
            </div>
            <div className={`primary-btn`} onClick={() => onNext()}>NEXT</div>
            <PlannerProgressIndicator stage={3}/>
        </div>
    )
}