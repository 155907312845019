import gera from '../../Assets/Tutor/Gera.png';
import sandy from '../../Assets/Tutor/Sandy.png';
import valerie from '../../Assets/Tutor/Valerie.png';
import peggy from '../../Assets/Tutor/Peggy.png';

export const TutorList = [
    { id: 1, name: 'Gera O.', photo: gera, review: null, message: null },
    { id: 2, name: 'Sandy Tiwari', photo: sandy, review: null, message: null },
    { id: 3, name: 'Roland Omene', photo: null, review: null, message: null },
    { 
        id: 4, name: 'Valerie N', photo: valerie, 
        review: {
            text: 'Valerie N did a great job tutoring me. He listened to my problems and questions carefully and answered them thoroughly. He was easy to work with and excited to help me with my goals. He was very knowledgeable and quick to help me find solutions.',
            date: '2020-04-21 12:32:00',
            knowledge: 3,
            presentation: 2,
        }, 
        message: {
            content: `
                Hi Alex Tyshchenko,<br /><br />
                Thanks for taking the time to leave me a review. I know you have choices when it comes to your tutoring needs and thanks again for choosing to work with me.<br /><br />
                Most families outside the Grade Getter community have yet to know the amazing work me and other Frog Tutors do in the Fort Worth, TX area. Can you please take a minute of your time to copy and paste your recent review below or a new one on any of our review pages listed below; I will get a bonus of $10 for any positive reviews I get on yelp. <br /><br />
                Your recent review:<br />
                “Wonderful Tutor!”<br /><br />
                Please click links below to leave your reviews: <br />
                <a href="https://www.facebook.com/">Facebook</a> <br />
                Or <br /><br />
                <a href="https://www.yelp.com/">Yelp</a><br />
                <a href="https://www.bbb.org//">BBB (Better Business Bureau)</a><br />
                <a href="https://www.google.com/">Google local</a><br /><br /><br /><br />
                Thank you for sharing.<br /><br />    
                Sincerely,<br />
                Gera O
            `,
            date: '2020-02-14 12:32:00'
        }
    },
    { id: 5, name: 'Peggy Morales', photo: peggy, review: null, message: null },
];