import React from 'react';
import './Header.scss';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { ReactSVG } from 'react-svg'
import hamburger from '../../Assets/Icon/hamburger.svg';
import left from '../../Assets/Icon/arrow-left.svg';
import down from '../../Assets/Icon/arrow-down.svg';
import envelope from '../../Assets/Icon/envelope.svg';
import giftbox from '../../Assets/Icon/giftbox.svg';
import {
  getPendingLessonAction,
  toggleJoinLesson,
  toggleMobileSidebar,
  toggleCreditModal,
  signOutAction
} from '../../Redux/Actions';

class Header extends React.Component {
  timer = null;

  state = {
    titleArray: [],
    depth: 0,
    showAccountMenu: false,
    blured: false,
  }

  componentDidMount() {
    this.setState({
      titleArray: this.props.titleArray,
      depth: this.props.depth
    })
  }

  componentDidUpdate() {
    if (this.refs.accountMenu) this.refs.accountMenu.focus();
  }

  openSidebar = () => {
    this.props.toggleMobileSidebar(true);
  }

  static getDerivedStateFromProps(props, state) {
    return {
      titleArray: props.titleArray,
      depth: props.depth
    }
  }

  goBack = () => {
    if (this.props.match.params && Object.keys(this.props.match.params).length > 0) {
      const prevURL = "/" + this.props.match.url.split("/")[1];
      this.props.history.replace(prevURL);
    }
    this.props.updateTitle('pop');
  }

  toggleAccountMenu = () => {
    if (this.state.blured) {
      this.setState({ blured: false });
      return;
    }
    this.setState({ showAccountMenu: true });
  }

  blurMenu = () => {
    this.setState({ showAccountMenu: false, blured: true });
    setTimeout(() => {
      this.setState({ blured: false })
    }, 300);
  }



  navigateAccountMenu = (menu) => {
    this.setState({ showAccountMenu: false });
    if (menu !== 'name') {
      this.props.history.push('/profile', { menu });
    }
    if (menu === 'logout') {
      this.props.signOutAction();
      setTimeout(() => {
        window.location.href = `${process.env.REACT_APP_PUBLIC_DOMAIN}?signout=signout`;
      }, 300);
    }
  }

  message = () => {
    this.props.history.push('/message');
  }

  render() {
    const { titleArray, depth, showAccountMenu, } = this.state;
    const { isOpen, profileInfo, isMims, } = this.props;
    return (
      <div className={`header-component v-r ${!isOpen ? 'expanded' : ''}`}>
        <div className="timer show-mobile">9:41</div>
        <div className="header-wrapper v-c">
          <div className="title-area v-c">
            {depth > 0 ? <ReactSVG src={left} className="inactive-icon show-mobile-flex svg-icon" onClick={e => this.goBack()} />
              : <ReactSVG src={hamburger} className="inactive-icon show-mobile-flex svg-icon" onClick={e => this.openSidebar()} />}
            <div className="title show-web">{titleArray[0]}</div>
            <div className="title show-mobile">{titleArray[depth]}</div>
          </div>
          <div className="action-area v-c">
            <div className="get-30 v-c show-web-flex" onClick={e => this.props.history.push('/referral')}>
              <p>GET $100</p>
              <ReactSVG src={giftbox} className="svg-icon" />
            </div>
            <NavLink to="/request-tutor" className="btn-2 secondary-btn show-web-flex v-c">Request new tutor</NavLink>
            {!isMims && <NavLink to="/schedule-lesson" className="btn-2 primary-btn show-web-flex v-c">Schedule a lesson</NavLink>}
            <div className="notify-area active" onClick={() => this.message()}>
              <ReactSVG src={envelope} className="inactive-icon" />
              <div className="new-notify" />
            </div>
            <div className="avatar-area v-c" onClick={e => this.toggleAccountMenu()}>
              <div className="avatar v-c h-c">
                {profileInfo.picture ? <img src={`${process.env.REACT_APP_IMAGE_DOMAIN}${profileInfo.picture}`} style={{ width: '100%', height: '100%', borderRadius: '50%' }} alt="avatar" />
                  : <span>{profileInfo.first_name ? profileInfo.first_name.charAt(0) : 'A'}{profileInfo.last_name ? profileInfo.last_name.charAt(0) : 'L'}</span>}
              </div>
              <ReactSVG src={down} className="inactive-icon" />
              {showAccountMenu && <div className="account-menu v-r" tabIndex="0" ref="accountMenu" onBlur={e => this.blurMenu()}>
                <p onMouseDown={e => this.navigateAccountMenu('name')}>Hi {profileInfo.first_name ? profileInfo.first_name : "Roland"}</p>
                <p onMouseDown={e => this.navigateAccountMenu('PROFILE')}>Profile</p>
                <p onMouseDown={e => this.navigateAccountMenu('STUDENT INFO')}>Student Info</p>
                <p onMouseDown={e => this.navigateAccountMenu('PASSWORD')}>Password</p>
                <p onMouseDown={e => this.navigateAccountMenu('FAQ')}>FAQ</p>
                <p onMouseDown={e => this.navigateAccountMenu('logout')}>Log Out</p>
              </div>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isOpen: state.Sidebar.isOpen,
  pendingLesson: state.Core.pendingLesson,
  isMims: state.Mims.isMims,
});

export default connect(mapStateToProps, {
  getPendingLessonAction,
  toggleJoinLesson,
  toggleMobileSidebar,
  toggleCreditModal,
  signOutAction,
})(withRouter(Header));
