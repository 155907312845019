import React from 'react';
import './MyTutorsPage.scss';
import search from '../../Assets/Icon/search.svg';
import cancel from '../../Assets/Icon/cancel.svg';
import { InputBox, ClientFeedback, Avatar, ConfirmModal } from '../../Components';
import { TutorList } from './TutorList';
import { ReactSVG } from 'react-svg';
import bank from '../../Assets/Icon/bank-building.svg';
import email from '../../Assets/Icon/email.svg';
import place from '../../Assets/Icon/place.svg';
import telephone from '../../Assets/Icon/telephone.svg';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    getMytutorsAction,
    getMytutorDetailAction,
    deleteTutorAction,
    toggleReviewModal,
    getMypofileInfoAction
} from "../../Redux/Actions";
import { toast } from 'react-toastify';
import { showSpinner, hideSpinner } from 'src/Util/Helper';
class MyTutorsPage extends React.Component {
    state = {
        Tutors: TutorList,
        searchText: '',
        depth: 0,
        selectedTutor: {},

        menu: 'ABOUT ME',
        show_review: 3,
        show_blog: 4,
        myTutorDetail: '',
        loading: false,
        isOpenModal: false
    }

    static getDerivedStateFromProps(props, state) {
        return {
            depth: props.depth
        }
    }

    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        const token = params.get('token');
        if (token || window.sessionStorage.getItem("token")) {

            if (token) {
                sessionStorage.setItem("token", token);
                this.props.getMypofileInfoAction().then(() => {
                    const { myProfileInfo } = this.props
                    if (myProfileInfo.connection_need_review) {
                        this.props.toggleReviewModal(true, {
                            connection_id: myProfileInfo.connection_need_review.id,
                            picture: myProfileInfo.connection_need_review.tutor.picture,
                            first_name: myProfileInfo.connection_need_review.tutor.name ? myProfileInfo.connection_need_review.tutor.name.split(' ')[0] : '',
                        });
                    }
                })
            }
            this.props.getMytutorsAction().then(() => {
                if (this.props.mytutors) {
                    if (this.props.match.params.id) {
                        let index = this.props.mytutors.findIndex(temp => temp.id === parseInt(this.props.match.params.id, 10));
                        this.selectTutor(this.props.mytutors[index]);
                    } else {
                        if (this.props.mytutors.length > 0) {
                            // this.selectTutor(this.props.mytutors[0]);
                        }
                    }
                }
            })
        } else {
            window.location.href = process.env.REACT_APP_PUBLIC_DOMAIN
        }
    }

    selectTutor = async (tutor) => {
        let temp = tutor || {};
        this.setState({
            selectedTutor: temp,
        });
        await this.props.formatTitle();
        this.props.updateTitle('push', temp.name, 2);
        this.getMytutorDetail(tutor.id)
    }

    getMytutorDetail = (tutor_id) => {
        this.props.getMytutorDetailAction(tutor_id).then(() => {
            this.setState({ myTutorDetail: this.props.myTutorDetail });
            hideSpinner();
        })
    }

    deleteTutor = (e, item, index) => {
        this.setState({ isOpenModal: true })
        e.stopPropagation();
        this.setState({ delectE: e });
        this.setState({ delectItem: item });
        this.setState({ delectIndex: index });
    }

    deleteConfirm = (deleteStatus) => {
        this.setState({ isOpenModal: false });
        this.setState({ loading: true })
        const { delectE, delectItem, delectIndex } = this.state
        if (deleteStatus) {
            this.props.loader(true)
            delectE.stopPropagation();
            this.props.deleteTutorAction(delectItem.id).then(() => {
                this.props.loader(false)
                const { deleteTutorStatus, deleteTutorErr } = this.props
                if (deleteTutorStatus) {
                    this.props.mytutors.splice(delectIndex, 1);
                    this.setState({ loading: false })
                    if (delectItem.id === this.state.selectedTutor.id) {
                        this.setState({
                            selectedTutor: {},
                        });
                        this.props.formatTitle();
                    }
                } else {
                    toast(deleteTutorErr)
                }
            });
        }
    }

    bookTutor = (tutor_id) => {
        this.props.history.push(`/schedule-lesson/${tutor_id}`);
    }

    renderTutorInfo = () => {
        const { selectedTutor } = this.state;
        return (<React.Fragment>
            <div className="tutor-photo v-r v-c">
                <Avatar className="avatar" info={selectedTutor} />
                <h1>{selectedTutor.name}</h1>
                <p>{selectedTutor.profile_title}</p>
            </div>
            <div className="tutor-info v-r">
                <div className="sub-info">
                    <ReactSVG src={bank} className="svg-icon" />
                    <p>{selectedTutor.school}</p>
                </div>
                <div className="sub-info">
                    <ReactSVG src={telephone} className="svg-icon" />
                    <p>{selectedTutor.phone}</p>
                </div>
                <div className="sub-info">
                    <ReactSVG src={email} className="svg-icon" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                    <p>{selectedTutor.email}</p>
                </div>
                <div className="sub-info">
                    <ReactSVG src={place} className="svg-icon" />
                    <p>{selectedTutor.location}</p>
                </div>
                <button className="btn-2 primary-btn" onClick={e => this.bookTutor(selectedTutor.id)}>Book Me Now</button>
            </div>
        </React.Fragment>)
    }

    render() {
        const { searchText, depth, selectedTutor, Tutors, menu, show_review, show_blog, myTutorDetail } = this.state;

        const { mytutors } = this.props;
        if (mytutors) {
            var list = mytutors.filter(tutor => tutor.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1);
        }
        return (
            <div className="mytutors-page">
                <div className={`tutor-list v-r ${depth === 0 ? 'activate' : ''}`}>
                    <InputBox className="search-contact" value={searchText} icon={search} placeholder="Search..." onChange={val => this.setState({ searchText: val })} />
                    <div className="tutors v-r scroll-area">
                        {list && list.map((item, index) => <div className={`tutor-container ${selectedTutor.id === item.id ? 'selected' : ''}`} key={index} onClick={e => this.selectTutor(item)}>
                            <div className="tutor v-c">
                                <Avatar className="avatar" info={item} />
                                <div className="info v-r">
                                    <h4>{item.name}</h4>
                                    <p>{item.school}</p>
                                </div>
                                {/* <ReactSVG src={cancel} className="svg-icon" onClick={e => this.deleteTutor(e, item, index)} /> */}
                            </div>
                        </div>)}
                    </div>
                </div>
                <div className={`content-area ${depth >= 1 ? 'activate' : ''}`}>
                    {selectedTutor.id > 0 && <div className="tutor-profile show-web-flex web">
                        {this.renderTutorInfo()}
                    </div>}
                    {selectedTutor.id > 0 && <div className="tutor-content v-r">
                        <div className="menu-header v-c">
                            <div className="menu-container v-c">
                                {/* {Menus.map((item, index) => <p className={`head-menu ${menu === item ? 'active' : ''}`} key={index} onClick={e => this.setState({menu: item})}>
                                    {item}
                                </p>)} */}
                                <p className={`head-menu ${menu === "ABOUT ME" ? 'active' : ''}`} onClick={e => this.setState({ menu: "ABOUT ME" })}>
                                    ABOUT ME
                                </p>
                                {(myTutorDetail && myTutorDetail.skills.length > 0) &&
                                    (<p className={`head-menu ${menu === "MY SKILLS" ? 'active' : ''}`} onClick={e => this.setState({ menu: "MY SKILLS" })}>
                                        MY SKILLS
                                    </p>)
                                }
                                {(myTutorDetail && myTutorDetail.qualifications.length > 0) &&
                                    <p className={`head-menu ${menu === "QUALIFICATION" ? 'active' : ''}`} onClick={e => this.setState({ menu: "QUALIFICATION" })}>
                                        QUALIFICATION
                                    </p>
                                }
                                {(myTutorDetail && myTutorDetail.reviews.length > 0) &&
                                    <p className={`head-menu ${menu === "REVIEWS" ? 'active' : ''}`} onClick={e => this.setState({ menu: "REVIEWS" })}>
                                        REVIEWS
                                    </p>
                                }
                                {(myTutorDetail && myTutorDetail.blogs.length > 0) &&
                                    <p className={`head-menu ${menu === "BLOG" ? 'active' : ''}`} onClick={e => this.setState({ menu: "BLOG" })}>
                                        BLOG
                                    </p>
                                }
                            </div>
                        </div>
                        <div className="tutor-profile show-mobile-flex mobile">
                            {this.renderTutorInfo()}
                        </div>
                        <div className={`detail-info v-r ${(menu === 'BLOG' || menu === 'REVIEWS') ? 'no-margin' : ''}`}>
                            {menu === 'ABOUT ME' && <div id="section-skills" className="v-r">
                                {/* {Abouts.map((item, index) => <div className="option-group v-r" key={index}>
                                    <h2 className="option-title">{item.subject}</h2>
                                    <p>{item.content}</p>
                                </div>)} */}
                                <div className="option-group v-r">
                                    <h2 className="option-title">Fun facts</h2>
                                    <p>{selectedTutor.fun_facts}</p>
                                </div>
                                <div className="option-group v-r">
                                    <h2 className="option-title">About me</h2>
                                    <p>{selectedTutor.about_me}</p>
                                </div>
                                <div className="option-group v-r">
                                    <h2 className="option-title">Tutoring experiences</h2>
                                    <p>{selectedTutor.experience}</p>
                                </div>
                                <div className="option-group v-r">
                                    <h2 className="option-title">Tutoring approach</h2>
                                    <p>{selectedTutor.approach}</p>
                                </div>
                            </div>}
                            {menu === 'MY SKILLS' && <div id="section-skills" className="v-r">
                                {myTutorDetail.skills && myTutorDetail.skills.map((item, index) => <div className="option-group v-r" key={index}>
                                    <h2 className="option-title">{item.school}</h2>
                                    <div className="skill-set">
                                        {item.subjects.map((subject, ind) => <div className="subject" key={ind}>{subject}</div>)}
                                    </div>
                                </div>)}
                            </div>}
                            {menu === 'QUALIFICATION' && <div id="section-skills" className="v-r">
                                {myTutorDetail.qualifications && myTutorDetail.qualifications.map((item, index) => <div className="option-group v-r" key={index}>
                                    <h2 className="option-title">{item.subject}</h2>
                                    <p>{item.content}</p>
                                </div>)}
                            </div>}
                            {menu === 'REVIEWS' && <div id="section-skills" className="v-r">
                                {myTutorDetail.reviews && myTutorDetail.reviews.map((item, index) => { return (index < show_review && <ClientFeedback key={index} item={item} />) })}
                                {/* {(Reviews.length - show_review > 0) && <button className="btn-2 btn-2-outline load-more-btn" onClick={e => this.setState({show_review: show_review + 3})}>Load More</button>} */}
                            </div>}
                            {menu === 'BLOG' && <div id="section-skills" className="v-r">
                                {myTutorDetail.blogs && myTutorDetail.blogs.map((item, index) => {
                                    return (index < show_blog && <div className="blog" key={index}>
                                        <img src={item.picture} alt="blog" />
                                        <div className="blog-detail v-r">
                                            <h2>{item.title}</h2>
                                            <p>{item.content}</p>
                                            <div className="read-more">Read More</div>
                                        </div>
                                    </div>)
                                })}
                                {/* {(Blogs.length - show_blog > 0) && <button className="btn-2 btn-2-outline load-more-btn" onClick={e => this.setState({show_blog: show_blog + 4})}>Load More</button>} */}
                            </div>}
                        </div>
                    </div>}
                </div>
                <ConfirmModal
                    isOpenModal={this.state.isOpenModal}
                    deleteConfirm={(e) => this.deleteConfirm(e)}
                    cancelTitle="Delete this tutor?"
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    mytutors: state.Core.mytutors,
    myTutorDetail: state.Core.myTutorDetail,
    deleteTutorStatus: state.Auth.deleteTutorStatus,
    deleteTutorErr: state.Auth.deleteTutorErr,
    myProfileInfo: state.Core.myProfileInfo,
});

export default connect(mapStateToProps, {
    getMytutorsAction,
    getMytutorDetailAction,
    deleteTutorAction,
    toggleReviewModal,
    getMypofileInfoAction
})(withRouter(MyTutorsPage));