import React, {useState} from "react";
import './style.scss';
import ThemeToggle from "../../ThemeToggle";
import PrevCardImg from 'src/Assets/MIMS/Quiz/PrevCard.png';
import NextCardImg from 'src/Assets/MIMS/Quiz/NextCard.png';
import { useDispatch, useSelector } from "react-redux";
import { FLASHCARD_NUM } from "../Info";
import {
    FLASHCARD_ROUND_COMPLETED
} from 'src/Redux/Type';
// import Image from 'src/Assets/MIMS/Planner/time management 2.png';

export default function FlashcardComponent({cards}) {
    const dispatch = useDispatch()
    const [activeQuestion, setActiveQuestion] = useState(cards[0].id);
    const [lightTheme, setLightTheme] = useState(false);
    const [cardCount, setCardCount] = useState(1);

    const {
        flashcard_concept
    } = useSelector(state => state.Mims)

    const getCurrentIndex = () => {
        const currentIndex = cards.findIndex(item => {
            return item.id === activeQuestion;
        })
        return currentIndex;
    }

    const flipCard = () => {
        const flashcard = document.getElementById('QuestionFlashCard')
        if (flashcard) {
            if (flashcard.classList.contains('flipped')) {
                flashcard.classList.remove('flipped')
            } else {
                flashcard.classList.add('flipped')
            }
       }
    }

    const resetCard = () => {
        const flashcard = document.getElementById('QuestionFlashCard')
       if (flashcard && flashcard.classList.contains('flipped')) {
            flashcard.classList.remove('flipped')
        } 
    }

    const onNextQuestion = () => {
        const currentIndex = getCurrentIndex()
        if (cardCount === FLASHCARD_NUM || currentIndex === cards.length - 1) {
            dispatch({type: FLASHCARD_ROUND_COMPLETED})
        } else if (currentIndex !== -1 && currentIndex < cards.length - 1) {
            const nextQuestion = cards[currentIndex + 1]
            setActiveQuestion(nextQuestion.id)
            setCardCount(cardCount + 1)
            resetCard()
        }
    }

    const onPrevQuestion = () => {
        const currentIndex = getCurrentIndex()
        if (currentIndex !== -1 && currentIndex > 0) {
            const prevQuestion = cards[currentIndex - 1];
            setActiveQuestion(prevQuestion.id)
            if (cardCount > 0) setCardCount(cardCount - 1)
            resetCard()
        }
    }

    const onThemeChange = (value) => {
        const flashcard = document.getElementById('QuestionFlashCard');
        if (flashcard) {
            if (value) {
                flashcard.classList.add('light')
            } else {
                flashcard.classList.remove('light')
            }
        }
        setLightTheme(value)
    }

    function QuestionContent({card}) {
        return (
            <div className="question-content">
                <div className="front">
                    <h1 dangerouslySetInnerHTML={{ __html: card.question }}></h1>
                </div>
                <div className="back">
                    <h1 className="title" dangerouslySetInnerHTML={{ __html: card.question }}></h1>
                    <p className="mt-1" dangerouslySetInnerHTML={{ __html: card.answer}}></p>
                </div>
            </div>
        )
    } 

    return (
        <div className="flashcard-component card">
             <h1>{flashcard_concept} <span>Flashcards</span></h1>
            <div className="section-wrapper">
                <div className="question-card-wrapper" id="QuestionFlashCard">
                    {
                        cards.map(card => (
                            <div className={card.id === activeQuestion ? 'appear' : 'd-none' } key={card.id}>
                                <QuestionContent card={card} />
                            </div>
                        ))
                    }
                    { activeQuestion !== cards[0].id && <img className="prev" src={PrevCardImg} onClick={() => onPrevQuestion()}></img>}
                    <img className="next" src={NextCardImg} onClick={() => onNextQuestion()}></img>
                </div>
                <div className="toggle-wrapper">
                    <ThemeToggle disable={false} onChange={(value) => onThemeChange(value)} value={ lightTheme } />
                </div>
                <div className="btn-wrapper">
                    <div className='btn-3 flip-card-btn' onClick={() => flipCard()}>FLIP CARD</div>
                </div>
                <div className="items-shown">
                    <span>{getCurrentIndex() + 1}</span> of {cards.length}
                </div>
            </div>
        </div>
    )
}