import React from "react";
import './style.scss';
import { Modal } from "react-bootstrap";
import IconSave from 'src/Assets/MIMS/IconArchive.png';
import IconExit from 'src/Assets/MIMS/IconExit.png';

export default function ExitAssessmentModal({name, show, onExit, onResume}) {

    return (
       <Modal show={show} className="exit-assessment-modal">
            <Modal.Body>
                <h4 className="text-center">End Your <br></br>{name}</h4>
                <div className="action-content">
                    {/* <div className="outline-btn" onClick={onExit}>
                        <img className="" src={IconSave}/>
                        <div>SAVE & EXIT</div>
                    </div> */}
                    <div className="outline-btn" onClick={onExit}>
                        <img className="" src={IconExit}/>
                        <div>EXIT</div>
                    </div>
                    <div onClick={onResume}>RESUME</div>
                </div>
            </Modal.Body>
       </Modal>
    )
}