import React, { useEffect } from 'react';
import './style.scss';
import { useSelector, useDispatch } from 'react-redux';
import { SocialSharing } from 'src/Components';
import {
  getCurrentPlanAction,
} from 'src/Redux/Actions';

export default function MReferralLinkPage() {
  const dispatch = useDispatch();

  const {
    myProfileInfo
  } = useSelector((state) => state.Core);

  const {
    mCurrentPlan
  } = useSelector((state) => state.MPlan);

  const ShareURL = `https://makeitmakesense.gradegetter.com/make-it-make-sense/topic_id/${mCurrentPlan?.interested_topic_id}?referrer_code=${myProfileInfo?.id}`

  //call the current plan
  useEffect(() => {
    dispatch(getCurrentPlanAction());
  }, [dispatch]);

  const onCopyLink = async () => {
    await navigator.clipboard.writeText(ShareURL);
  };

  return (
    <div className='M-referral-link-page'>
      <div className='link-container'>
        <h2>Refer Your Friends And Make $$</h2>
        <div className='description'>You get 40% referral fees on what they spend on make it make sense services</div>
        <div className='link-content'>
          {ShareURL}
        </div>
        <div className='copy-link-btn center' onClick={onCopyLink}>Copy link</div>
        <div className='social-share-title'>Share on social media</div>
        <div className='social-share-description'>You’ll see a preview before you post</div>
        <SocialSharing
          ShareURL={ShareURL}
          className={'share-icons'}
        />
      </div>
    </div>
  );
}
