import {
  GET_REMOVE_CARD,  GET_REMOVE_CARD_FAIL, GET_REMOVE_CARD_SUCCESS,
  GET_SET_DEFAULT_CARD, GET_SET_DEFAULT_CARD_FAIL, GET_SET_DEFAULT_CARD_SUCCESS,
} from "../Type";

import {
  removeCard,
  setDefaultCard,
} from "src/Api";

export const removeCardAction = (payload) => async (dispatch) => {
  dispatch({ type: GET_REMOVE_CARD });
  const res = await removeCard(payload);
  if (res && res.data && !res.data.error) {
    dispatch({ type: GET_REMOVE_CARD_SUCCESS, payload: res.data.message });
  } else {
    dispatch({ type: GET_REMOVE_CARD_FAIL, payload: res.data.message });
  }
};

export const setDefaultCardAction = (payload) => async (dispatch) => {
  dispatch({ type: GET_SET_DEFAULT_CARD });
  const res = await setDefaultCard(payload);
  if (res && res.data && !res.data.error) {
    dispatch({ type: GET_SET_DEFAULT_CARD_SUCCESS, payload: res.data.message });
  } else {
    dispatch({ type: GET_SET_DEFAULT_CARD_FAIL, payload: res.data.message });
  }
};