import React from 'react';
import './App.scss';
import {
  Sidebar,
  MobileSidebar,
  VideoModal, MuseVideoModal,
  JoinLesson,
  CreditModal,
  ReviewModal,
  UpgradePlanModal,
  // Loader, 
  QuestionAnswerModal, NoteModal, GraphModal, FinishTrialModal,
  AuthCheck,
} from './Components';
import {
  MyTutorsPage, RatePlanPage, ReviewTutorsPage, CancelSubscriptionPage, CreditCardPage,
  ReceiptPage, ScheduleLessonPage, RequestTutorPage, SettingsPage, MessagePage, CompletedLessonPage,
  PendingLessonPage, OnlineDemoPage, SmileAndLearnPage, ReferralPage,
  MDashboardPage, MPlansPage, MReviewsPage, MReferralLinkPage,
} from './Pages';
import Page from './Pages/Page';
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import BeatLoader from 'react-spinners/BeatLoader'
import { initAppAction, 
  getTopicListAction, 
  toggleMims, 
  getAssessmentListAction, 
  getPracticeFlashcardsAction,
  getExamsAction
 } from "./Redux/Actions";
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { EventEmitter } from './Util/events';

const pages = [
  { url: '/', title: 'My Tutors', page: props => <MyTutorsPage {...props} /> },
  { url: '/tutors', title: 'My Tutors', page: props => <MyTutorsPage {...props} /> },
  { url: '/tutors/:id', title: 'My Tutors', page: props => <MyTutorsPage {...props} /> },
  { url: '/your-plan', title: 'Your Plan', page: props => <RatePlanPage {...props} /> },
  { url: '/review-tutors', title: 'Review Tutors', page: props => <ReviewTutorsPage {...props} /> },
  { url: '/cancel-subscriptions', title: 'Cancel Subscriptions', page: props => <CancelSubscriptionPage {...props} /> },
  { url: '/credit-card', title: 'Credit Card', page: props => <CreditCardPage {...props} /> },
  { url: '/receipt', title: 'Receipt', page: props => <ReceiptPage {...props} /> },
  { url: '/receipt/:id', title: 'Receipt', page: props => <ReceiptPage {...props} /> },
  { url: '/schedule-lesson', title: 'Schedule a lesson', page: props => <ScheduleLessonPage {...props} /> },
  { url: '/schedule-lesson/:tutor_id', title: 'Schedule a lesson', page: props => <ScheduleLessonPage {...props} /> },
  { url: '/request-tutor', title: 'Request new tutor', page: props => <RequestTutorPage {...props} /> },
  { url: '/profile', title: 'My Settings', page: props => <SettingsPage {...props} /> },
  { url: '/message', title: 'Message', page: props => <MessagePage {...props} /> },
  { url: '/completed-lesson', title: 'Completed Lessons', page: props => <CompletedLessonPage {...props} /> },
  { url: '/completed-lesson/:id', title: 'Completed Lessons', page: props => <CompletedLessonPage {...props} /> },
  { url: '/pending-lesson', title: 'Pending Lessons', page: props => <PendingLessonPage {...props} /> },
  { url: '/pending-lesson/:id', title: 'Pending Lessons', page: props => <PendingLessonPage {...props} /> },
  { url: '/online-demo', title: 'Online Demo', page: props => <OnlineDemoPage {...props} /> },
  { url: '/smile-and-learn', title: 'Smile And Learn', page: props => <SmileAndLearnPage {...props} /> },
  { url: '/referral', title: 'Referral', page: props => <ReferralPage {...props} /> },

  //Make It Make Sense Pages
  { url: '/make-it-make-sense/dashboard', title: 'Dashboard', page: props => <MDashboardPage {...props} /> },
  { url: '/make-it-make-sense/plans', title: 'Plans', page: props => <MPlansPage {...props} /> },
  { url: '/make-it-make-sense/reviews', title: 'Reviews', page: props => <MReviewsPage {...props} /> },
  { url: '/make-it-make-sense/referral-link', title: 'Referral Link', page: props => <MReferralLinkPage {...props} /> },

];

class App extends React.Component {
  state = {
    isBlur: false,
    isSidebarOpen: true,
    isSidebarPrevOpen: true,
    isResize: false,
    loading: false,
    isLoggedIn: false,
  }

  static getDerivedStateFromProps(props, state) {
    return {
      isBlur: props.isMobileSidebarOpen || props.isModalOpen,
      isSidebarOpen: props.isSidebarOpen,
      isSidebarPrevOpen: state.isSidebarOpen,
      isResize: props.isJoinLessonOpen
    }
  }

  componentDidMount() {
    const isMims = !!(window.location.pathname?.split('/')[1] === 'make-it-make-sense')
    this.props.toggleMims(isMims);
    this.props.initAppAction();
    this.props.getTopicListAction();
    this.props.getAssessmentListAction();
    this.props.getPracticeFlashcardsAction()
    this.props.getExamsAction()
  }

  loading = (value) => {
    this.setState({ loading: value })
  }

  render() {
    const { isBlur, isSidebarOpen, isSidebarPrevOpen, isResize, loading } = this.state;
    EventEmitter.subscribe('isLoading', (event) => this.setState({ loading: event }));

    if (isBlur) {
      if (document.body) {
        const body = document.body
        body.classList.add('modal-open');
      }
    } else {
      if (document.body) {
        const body = document.body
        body.classList.remove('modal-open');
      }
    }
    const token = sessionStorage.getItem("token");

    return (
      <BrowserRouter>
        <div className="App">
          {/* <Loader loading={this.state.loading} /> */}
          <JoinLesson />
          <div id="main" className={`${isBlur ? 'blur' : ''} ${isResize ? 'resize' : ''}`}>
            <MobileSidebar />
            <div className={`content-wrapper ${isSidebarOpen ? isSidebarPrevOpen ? '' : 'open' : 'closed'}`}>
              <Switch>
                <Route exact path="/auth" component={AuthCheck} />
                {pages.map((item, index) =>
                  <Route exact path={item.url} key={index}
                    render={() => token
                      ? <Page
                        sendLoading={(v) => this.loading(v)}
                        title={item.title}
                        page={item.page}
                      />
                      : <Redirect to="/auth" />}
                  />)}
              </Switch>
            </div>
            <Sidebar />
            <VideoModal />
            <MuseVideoModal />
            <CreditModal />
            <ReviewModal />
            <UpgradePlanModal />
            <QuestionAnswerModal />
            <NoteModal />
            <GraphModal />
            <FinishTrialModal />
          </div>
        </div>
        <NotificationContainer />
        <div className={loading ? "overlay-loader" : "d-none"}>
          <BeatLoader
            size={30}
            color={"#1BD0A5"}
            loading={loading}
          />
        </div>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.Auth.isLoggedIn,
    isMobileSidebarOpen: state.Sidebar.isMobileOpen,
    isSidebarOpen: state.Sidebar.isOpen,
    isModalOpen: state.Modal.isVideoModalOpen || state.Modal.isCreditModalOpen || state.Modal.isReviewModalOpen,
    isJoinLessonOpen: state.Lesson.isOpen,
  }
}

export default connect(mapStateToProps, {
  initAppAction,
  getTopicListAction,
  toggleMims,
  getAssessmentListAction,
  getPracticeFlashcardsAction,
  getExamsAction
})(App);
