import React from 'react';
import './style.scss';

export default class CustomToggle extends React.Component {
  state = {
    value: this.props.value,
  }

  onChange = () => {
    if (this.props.disable) return;

    this.props.onChange(!this.state.value);
    this.setState({
      value: !this.state.value,
    })
  }

  render() {
    const {
      className,
      disable,
      isOnValue,
      isOffValue
    } = this.props;

    const { value } = this.state;

    return (
      <div className={`custom-toggle-component ${className || ''} ${disable? 'disable': ''} ${value? '': 'off'}`} onClick={this.onChange}>
        <div className='handle'></div>
        <div className='theme'>{ value ? isOnValue : isOffValue}</div>
      </div>
    )
  };
};