import React from 'react';
import './SearchBox.scss';
import { ReactSVG } from 'react-svg'

export default class SearchBox extends React.Component {
    state = {
        showMenu: false,
        value: '',
        className: '',
        icon: '',
        placeholder: '',
        // options: [],
    }

    componentDidMount() {
        this.setState({
            value: this.props.value || '',
            className: this.props.className || '',
            icon: this.props.icon || '',
            placeholder: this.props.placeholder || '',
            // options: this.props.options || []
        })
    }

    showMenu = (event, flag) => {
        event.stopPropagation();
        this.setState({
            showMenu: flag
        })
    }

    selectItem = (item) => {
        this.setState({
            value: item.name,
            showMenu: false
        })
        this.props.onChange(item);
    }

    changeText = (e) => {
        this.setState({
            value: e.currentTarget.value
        })
    }

    render() {
        const {options} = this.props;
        const { className, icon, placeholder, showMenu, value } = this.state;
        const filteredOptions = options.filter(item => item.name.toLowerCase().includes(value.toLowerCase())) || [];
        return (
            <div className={`searchbox-component ${className}`}>
                <div className="searchbox-container">
                    {icon && <ReactSVG src={icon} className="svg-icon start-icon"/>}
                    <input type="text" className="search-text" onFocus={e => this.showMenu(e, true)} onBlur={e => this.showMenu(e, false)} placeholder={placeholder} value={value} onChange={e => this.changeText(e)}/>
                </div>
                {showMenu && <div className="options-area"><div>
                    {filteredOptions.map((item, index) => <div className="option" key={index} onMouseDown={e => this.selectItem(item)}>
                        {item.name}
                    </div>)}
                </div></div>}
            </div> 
        );
    }
}
