import React from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import Timer from 'react-timer-wrapper';
import Timecode from 'react-timecode';

export default function CustomCircleTimer({
    keyId, 
    initialRemainingTime = 0,
    getAnswerTime,
    duration = 30,
    colorsTime = [30, 20, 10, 0],
    activeCountUp = false,
    onTimeUpUpdate,
    isCountDownTime = false,
    isStatic = false,
    size = 150
}) {

    const formatTime = (remainingTime) => {
        const timeValue = isStatic ? duration : remainingTime;
        const minutes = Math.floor(timeValue / 60)
        const seconds = timeValue % 60
        return `${padTime(minutes)}:${padTime(seconds)}`
    }

    const padTime = (value) => value < 10 ? `0${value}` : value

    return (
        <div className='circle-timer-wrapper'>
            <CountdownCircleTimer
                key={keyId}
                isPlaying={isCountDownTime}
                duration={duration}
                colors={['#23c1a5', '#ffdf6e', '#f08c15', '#F92672']}
                colorsTime={colorsTime}
                size={size}
                trailStrokeWidth={14}
                strokeWidth={14}
                initialRemainingTime={initialRemainingTime}
                trailColor={'#E9F6FD'}
                onUpdate={(remainingTime) => getAnswerTime(duration - remainingTime)}
            >
                {({ remainingTime }) =>
                <div className='time'>
                    {!activeCountUp ? formatTime(remainingTime)
                    :
                    <div className='v-c count-up'>
                        <div> - </div>
                        <Timer active={activeCountUp} duration={1000000000000} onTimeUpdate={(e) => onTimeUpUpdate(e.time)}>
                            <Timecode />
                        </Timer>
                    </div>
                    }
                </div>
                }
        </CountdownCircleTimer>
    </div>
    )
}