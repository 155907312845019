import React from "react";
import './style.scss';
import TimeImage from 'src/Assets/MIMS/Planner/time management 2.png';

export default function ProcessCompleteComponent() {

    return (
        <div className="process-complete">
           <div className="title">Wait a minute<br></br> we collect your Plan</div>
            <img src={TimeImage}/>
        </div>
    )
}