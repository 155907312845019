import React, { useState } from "react";
import './style.scss';
import Lock from 'src/Assets/MIMS/IconLock.png';
import Countdown from "react-countdown";
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash';
import {
    RETAKE_ASSESSMENT,
    SHOW_ASSESSMENT_SOLUTION
} from 'src/Redux/Type';

export default function AssessmentComplete({onStart}) {
    const dispatch = useDispatch();
    const [retake, setRetake] = useState(false);
    const {
        assessment,
        assessment_questions,
        assessment_on_hold,
        assessment_result
    } = useSelector(state => state.Mims)
    const {
        myProfileInfo
    } = useSelector(state => state.Core)

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            setRetake(true)
          return <div></div>;
        } else {
          // Render a countdown
          return <span>{days > 0 ? `${days}:` : ''}{hours > 0 ? `${zeroPad(hours)}${hours}:` : ''}{`${zeroPad(minutes)}${minutes}:`}{zeroPad(seconds)}{seconds}</span>;
        }
    };
      
    const zeroPad = (time) => time < 10 ? '0' : ''

    const onRetakeTest = () =>  dispatch({type: RETAKE_ASSESSMENT});

    const onSolution = () => dispatch({type: SHOW_ASSESSMENT_SOLUTION});

    const canRetake = () => {
        if (retake) return true
        return !_.isEmpty(assessment_result) ? assessment_result.wait_time === 0 : assessment.wait_time === 0
    }

    const getWaitTime = () => !_.isEmpty(assessment_result) ? assessment_result.wait_time * 1000 : assessment.wait_time * 1000

    function CompletionComponent() {
        return (
            <div className="score-section">
                <div className="assessment-complete card">
                    {
                        !assessment_on_hold && (
                            <>
                                <h1>Congratulations 🎉 <br></br>
                                { myProfileInfo.first_name }
                                </h1>
                                <p>
                                    You've successfully completed the assessment! 🌟 Your hard work has paid off, and we're proud of your efforts. 🏆 Take a moment to acknowledge your achievements, and know that you're one step closer to reaching your academic goals! 🚀
                                </p>
                            </>
                        )
                    }
                    <div className={`btn-row row row-md ${assessment_on_hold ? 'mt-0' : ''}`}>
                        <div className="col-lg-6">
                            <div className={`btn-c primary-btn ${ _.isEmpty(assessment_questions) ? 'disabled' : ''}`} onClick={() => onSolution()}>ASSESSMENT SOLUTION</div>
                        </div>
                        <div className="col-lg-6">
                            <div className={`btn-c retake-btn ${canRetake() ? '' : 'disabled'}`} onClick={() => onRetakeTest()}>
                                <div className="content">
                                    { canRetake() ? <div></div> : <img src={Lock}/> }
                                    <div>RETAKE TEST</div>
                                </div>
                            </div>
                            <div className={`retake-count-down ${canRetake() ? 'd-none': ''}`}>
                                <Countdown
                                    date={Date.now() + getWaitTime() }
                                    zeroPadTime={2}
                                    renderer={renderer}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    // function PoorScore() {
        
    //     return (
    //         <div className="score-section">
    //             <div className="poor-score-sc card">
    //                 <h1>Score Below 90%<br></br>
    //                 { assessment_result && assessment_result.user.first_name}
    //                 </h1>
    //                 <p>
    //                     Great effort on the quiz! 🌟 To reinforce your learning, we have a second quiz lined up just for you! 📚 Click the button below to boost your understanding and score even higher this time! 💪 You've got this! 👏🏼 Good luck! 🍀
    //                 </p>
    //                 <div className="d-flex justify-content-center btn-row">
    //                     <div className="btn-c primary-btn fit-cotent">GO TO NEXT ASSESSMENT</div>
    //                 </div>
    //             </div>
    //             <div className="btn-card card">
    //                 <div className="row row-md">
    //                     <div className="col-lg-6">
    //                         <div className="btn-c primary-btn green" onClick={() => onSolution()}>ASSESSMENT SOLUTION</div>
    //                     </div>
    //                     <div className="col-lg-6">
    //                         <div className={`btn-c retake-btn ${canRetake() ? '' : 'disabled'}`} onClick={() => onRetakeTest()}>
    //                             <div className="content">
    //                                 { canRetake() ? <div></div> : <img src={Lock}/> }
    //                                 <div>RETAKE TEST</div>
    //                             </div>
    //                         </div>
    //                         <div className={`retake-count-down ${canRetake() ? 'd-none': ''}`}>
    //                             <Countdown
    //                                 date={Date.now() + assessment.wait_time}
    //                                 zeroPadTime={2}
    //                                 renderer={renderer}
    //                             />
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }

    return (
       assessment && <CompletionComponent />
    )
}