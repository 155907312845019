import React from "react";
import './style.scss';
import CustomCircleTimer from "../CustomCircleTimer";
import { useSelector } from "react-redux";

export default function AttentionComponent({onStart, assessment }) {

    const {
        myProfileInfo
    } = useSelector(state => state.Core)

    const getAnswerTime = (time) => {

    }

    const questionTime = () => {
        const questionTime = assessment.test_time / assessment.questions_count
        return questionTime.toFixed(0)
    }

    return (
        <div className="attention card">
            <h1>Attention, {myProfileInfo.first_name}! 📣</h1>
            <p>⏰ Your timed assessment test is about to begin! Be ready to give it your best shot as the timer won't pause once you start.
                📝 Treat this like a real-life exam – find a quiet space, gather your materials, and avoid interruptions.
                🍀Stay focused, and good luck! </p>
            <div className="d-flex justify-content-center">
                <div className="primary-btn" onClick={onStart}>START TEST</div>
            </div>
            <div className="timer-wrapper">
                <div className="item">
                    <CustomCircleTimer 
                        keyId={11}
                        duration={questionTime()}
                        colorsTime={[]}
                        getAnswerTime={(time) => getAnswerTime(time)}
                        isStatic={true}
                        />
                    <div className="tag">Question Time</div>
                </div>
                <div className="item">
                    <CustomCircleTimer 
                        keyId={12}
                        duration={assessment.test_time}
                        colorsTime={[]}
                        getAnswerTime={(time) => getAnswerTime(time)}
                        isStatic={true}
                    />
                    <div className="tag">All Time</div>
                </div>
            </div>
        </div>
    )
} 