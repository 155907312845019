import {
  GET_REMOVE_CARD, GET_REMOVE_CARD_FAIL, GET_REMOVE_CARD_SUCCESS,
  GET_SET_DEFAULT_CARD, GET_SET_DEFAULT_CARD_FAIL, GET_SET_DEFAULT_CARD_SUCCESS,

} from "../Type";

const INITIAL = {
  removeCardStatus: null,
  removeCardError: '',
  removeCardLoading: false,
  removeCardSuccess: false,

  defaultCardStatus: null,
  defaultCardError: '',
  defaultCardLoading: false,
  defaultCardSuccess: false,
};

export default (state = INITIAL, action) => {
  switch (action.type) {
    //REMOVE CARD
    case GET_REMOVE_CARD:
      return {
        ...state,
        removeCardStatus: null,
        removeCardLoading: true,
        removeCardSuccess: false,
      };
    case GET_REMOVE_CARD_SUCCESS:
      return {
        ...state,
        removeCardStatus: action.payload,
        removeCardLoading: false,
        removeCardSuccess: true,
      };
    case GET_REMOVE_CARD_FAIL:
      return {
        ...state,
        removeCardStatus: null,
        removeCardError: action.payload,
        removeCardLoading: false,
        removeCardSuccess: false,
      };


    //SET DEFAULT CARD
    case GET_SET_DEFAULT_CARD:
      return {
        ...state,
        defaultCardStatus: null,
        defaultCardLoading: true,
        defaultCardSuccess: false,
      };
    case GET_SET_DEFAULT_CARD_SUCCESS:
      return {
        ...state,
        defaultCardStatus: action.payload,
        defaultCardLoading: false,
        defaultCardSuccess: true,
      };
    case GET_SET_DEFAULT_CARD_FAIL:
      return {
        ...state, defaultCardStatus: null,
        defaultCardError: action.payload,
        defaultCardLoading: false,
        defaultCardSuccess: false,
      };


    default:
      return state;
  }
};
