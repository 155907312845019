import React, { useEffect, useState } from "react";
import './style.scss';
import InfoIcon from 'src/Assets/MIMS/info-circle.png';
import QuizCircleProgress from "../../QuizCircleProgress";
import { useSelector } from "react-redux";
import _ from 'lodash';
import { convertSecondsToMS } from "src/Constant";

export default function UserStatComponent({completed, assessment, onPrint }) {
    const failColor = '#F92672';
    const passColor = '#24C1A5';
    const [text, setText] = useState(`0/0`)
    const [fail, setFail] = useState(0)
    const [passRatio, setPassRatio] = useState(0)
    const [percentage, setPercentage] = useState(0)
    const [asmtTime, setAsmtTime] = useState("00:00")

    const {
        assessment_result,
        total_time
    } = useSelector(state => state.Mims)
    const {
        myProfileInfo
    } = useSelector(state => state.Core)

    useEffect(() => { 
        if (completed && !_.isEmpty(assessment_result)) {
            computeUserStat(assessment_result)
            setAsmtTime(convertSecondsToMS(total_time))
        } else if (!_.isEmpty(assessment.question_summary)) {
            computeUserStat(assessment)
            setAsmtTime(convertSecondsToMS(assessment.time_taken))
        }
    }, [completed])

    const computeUserStat = (asmt) => {
        const summary = asmt.question_summary
        setFail(valueOn10(summary.correct + summary.wrong, summary.total))
        setPassRatio((summary.correct / summary.total))
        setText(`${summary.correct}/${summary.total}`)
        setPercentage(calculatePercentage(summary))
    }

    const valueOn10 = (value, max) => (value / max) * 10

    const calculatePercentage = (summary) => {
        let percentage = (summary.correct / summary.total) * 100
        return percentage.toFixed(1)
    }
 
    return (
        <div className="person-section card">
            <div className="user-stat-section">
                <div className="leading-sec">
                {myProfileInfo.picture ? <div className="avatar"><img src={`${process.env.REACT_APP_IMAGE_DOMAIN}${myProfileInfo.picture}`} alt="avatar" /></div>
                    : <div className="avatar-alt"><span>{myProfileInfo.first_name ? myProfileInfo.first_name.charAt(0) : 'A'}{myProfileInfo.last_name ? myProfileInfo.last_name.charAt(0) : 'L'}</span></div>}
                    <h6>{myProfileInfo.first_name} {myProfileInfo.last_name}</h6>
                    {/* <div>Graduate CS Student at UTA</div> */}
                </div>
                <div className="trailing-sec">
                    <div className="top-section">
                        <div className="score stat">
                            <div className="stat-label">SCORE</div>
                            <div className="stat-text-large">{percentage}%</div>
                            <img src={InfoIcon} className="info-icon"/>
                        </div>
                        <div className="time stat">
                            <div className="stat-label">TIME</div>
                            <div className="stat-text-large">{asmtTime}</div>
                            <img src={InfoIcon} className="info-icon"/>
                        </div>
                    </div>
                    <div className="progress-show">
                        <div className="progress-stack">
                            <QuizCircleProgress progress={fail} strokeColor={failColor} ratio={1} text={text}/>
                            <QuizCircleProgress progress={10} strokeColor={passColor} ratio={passRatio} text={text}/>
                        </div>
                        <div className="stat-desc">
                            <div className="info-item passed">
                                <div className="dot"></div>
                                <div className="text">Correct</div>
                            </div>
                            <div className="info-item failed">
                                <div className="dot"></div>
                                <div className="text">InCorrect</div>
                            </div>
                            <div className="info-item">
                                <div className="dot"></div>
                                <div className="text">Skipped</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           {/* {
            assessment_result && (
                <div className="btn-wrapper" onClick={onPrint}>
                    <div className="btn-c primary-btn">DOWNLOAD RESULT</div>
                </div>
            )
           } */}
        </div>
    )
}