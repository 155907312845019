import React, { useState, useEffect, useRef } from 'react';
import './style.scss';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  getCurrentPlanAction,
  getMPlanAction,
  cancelPlanAction,
} from 'src/Redux/Actions';
import { hideSpinner, showSpinner, showToast } from 'src/Util/Helper';
import Payment from './Payment';
import SelectPlan from './SelectPlan';

export default function PlanPage() {
  const dispatch = useDispatch();
  const LoadingRef = useRef(false);
  const CancelLoadingRef = useRef(false);

  const location = useLocation();
  const {
    mPlans,
    mCurrentPlan, mCurrentPlanRes, getMCurrentPlanLoading, getMCurrentPlanSuccess,
    cancelPlanStatus, cancelPlanLoading, cancelPlanSuccess,
  } = useSelector((state) => state.MPlan);

  const [paymentStep, setPaymentStep] = useState(1);
  const [planId, setPlanId] = useState("");
  const [topicInfo, setTopicInfo] = useState({});

  //call the current plan
  useEffect(() => {
    if (!_.isEmpty(location?.topicInfo)) {
      setTopicInfo(location?.topicInfo)
      dispatch(getMPlanAction({ topic_id: location.topicInfo.id }));
    }
    dispatch(getCurrentPlanAction());
  }, [topicInfo, dispatch, location]);

  //get current plan res
  useEffect(() => {
    if (LoadingRef.current && !getMCurrentPlanLoading) {
      if (getMCurrentPlanSuccess) {
        
      } else {
        // showToast("error", 3000, mCurrentPlanRes)
      }
    }
    LoadingRef.current = getMCurrentPlanLoading;
  }, [getMCurrentPlanLoading, getMCurrentPlanSuccess, mCurrentPlan, mCurrentPlanRes]);

  //get cancel subscription res
  useEffect(() => {
    if (CancelLoadingRef.current && !cancelPlanLoading) {
      hideSpinner();
      if (cancelPlanSuccess) {
        showToast("success", 3000, cancelPlanStatus)
        dispatch(getCurrentPlanAction());
      } else {
        showToast("error", 3000, cancelPlanStatus)
      }
    }
    CancelLoadingRef.current = cancelPlanLoading;
  }, [cancelPlanLoading, cancelPlanSuccess, cancelPlanStatus, dispatch, location]);

  const onCancelSubscription = () => {
    showSpinner();
    dispatch(cancelPlanAction({ perform: 'cancel' }));
  };

  const onNext = (plan_id) => {
    setPaymentStep(2)
    setPlanId(plan_id)
  };

  const onBack = () => {
    setPaymentStep(1)
  };

  return (
    <div className="m-plan-page">
      {paymentStep === 1 ? (
        <SelectPlan
          mPlans={mPlans}
          mCurrentPlan={mCurrentPlan}
          onCancelSubscription={onCancelSubscription}
          onNext={onNext}
        />
      ) : (
        <Payment
          topic_id={topicInfo.id}
          plan_id={planId}
          onBack={onBack}
        />
      )}
    </div>
  );
}
