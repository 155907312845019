import React, { useState } from "react";
import './style.scss';
import PlannerProgressIndicator from "../../PlannerProgress";

export default function WorkTimeComponent({onWorkTime}) {
    const [selectedSlot, setSelectedSlot] = useState(0)
    const [activeSlot, setActiveSlot] = useState(undefined)
    const [confirmed, setConfirmed] = useState(false)

    const TimeSlots = [
        { value: 1.5, text: "1:30 h/d"}, 
        { value: 2.5, text: "2:30 h/d"}, 
        { value: 2, text: "2:00 h/d"}, 
        { value: 3.5, text: "3:30 h/d"}, 
        { value: 3, text: "3:00 h/d"}, 
        { value: 4.5, text: "4:30 h/d"}, 
        { value: 4, text: "4:00 h/d"}];

    const isSelected = (item) => selectedSlot === item.value;

    const isActiveSlot = (index) => activeSlot === index

    const onSlotClicked = (index) => {
        setActiveSlot(index)
        setConfirmed(false)
    }

    const onConfirm = (item) => {
        setSelectedSlot(item.value)
        setConfirmed(true)
    }

    function SlotSelected({item}) {
        return (
            <div className="slot-selected">
                <div className="slot-active">{item.text}</div>
                { !confirmed && <div className="confirm" onClick={() => onConfirm(item)}>Confirm</div>}
            </div>
        )
    }

    return (
        <div className="work-time card">
            <div className="info-content">
            <div className="step-info">step 5 of 5</div>
                <h3 className="plan-title">Study Duration: Set Your Commitment: A Minimum of 1.5hrs Per Day</h3>
                <p className="plan-content">Select how many hours you intend to study on each of your chosen days.</p>
            </div>
            <div className="hourly-schedule">
                <div className="item-section">
                   {
                    TimeSlots.map((item, index) => (
                        <div className={`option-wrapper`} key={index}>
                            { isActiveSlot(index) ? <SlotSelected item={item}/> : <div className={`slot-item ${isSelected(item) ? 'selected' : ''}`} onClick={() => onSlotClicked(index)}>{item.text}</div> }
                        </div>
                    ))
                   }
                </div>
            </div>
            <div className={`primary-btn ${selectedSlot === 0 ? 'disabled' : ''}`} onClick={() => onWorkTime(selectedSlot)}>MAKE A PLAN NOW</div>
                <PlannerProgressIndicator stage={5} />
        </div>
    )
}