import React, { useEffect, useState, useRef } from 'react';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ReviewContent, ReviewUserList } from 'src/Components';
import { showSpinner, hideSpinner, showToast } from 'src/Util/Helper';
import {
  getMReviewsAction,
  submitMReviewAction,
} from 'src/Redux/Actions';

export default function MReviewsPage({
  updateTitle, depth
}) {
  const dispatch = useDispatch();
  const GetLoadingRef = useRef(false);
  const SubmitLoadingRef = useRef(false);

  const {
    mReviews, getMReviews_success, getMReviews_loading,
    submitMReviewStatus, submitMReview_success, submitMReviews_loading,
  } = useSelector((state) => state.MReviews);

  const [searchText, setSearchText] = useState('');
  const [selectedId, setSelectedId] = useState('1');
  const [selectedUserInfo, setSelectedUserInfo] = useState();
  const [filterUsers, setFilterUsers] = useState([]);
  const [isEditReview, setIsEditReview] = useState(false);
  const [reviewInfo, setReviewInfo] = useState({
    rating: 0,
    review: "",
  });

  //Call get reviews
  useEffect(() => {
    dispatch(getMReviewsAction());
  }, [dispatch]);

  //Res-get reviews
  useEffect(() => {
    if (GetLoadingRef.current && !getMReviews_loading) {
      if (getMReviews_success) {
        setFilterUsers(mReviews)
      } else {
        hideSpinner();
      }
    }
    GetLoadingRef.current = getMReviews_loading;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMReviews_loading, getMReviews_success, mReviews,]);

  //Res-submit review
  useEffect(() => {
    if (SubmitLoadingRef.current && !submitMReviews_loading) {
      hideSpinner();
      if (submitMReview_success) {
        showToast('success', 3000, submitMReviewStatus)
        dispatch(getMReviewsAction());
      } else {
        showToast('error', 3000, submitMReviewStatus)
      }
    }
    SubmitLoadingRef.current = submitMReviews_loading;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitMReviews_loading, submitMReview_success, submitMReviewStatus,]);


  const onChangeSearchText = (searchText) => {
    setSearchText(searchText);
    var _filterUsers = mReviews.filter(item => item.topic.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1);
    setFilterUsers(_filterUsers)
  };

  const onClickUser = (item) => {
    updateTitle("push", item.topic.name)
    setSelectedId(item.id);
    setSelectedUserInfo(item);
    setReviewInfo(item);
    setIsEditReview(false);
  };

  const onChangeReview = (field, value) => {
    setReviewInfo({
      ...reviewInfo,
      [field]: value,
    });
  };

  const onEdit = () => {
    setIsEditReview(true);
  };

  const onSaveReview = () => {
    const payload = {
      test_id: selectedId,
      reviewInfo: {
        rating: reviewInfo.rating,
        review_title: "title",
        review: reviewInfo.review,
      }
    }
    showSpinner();
    dispatch(submitMReviewAction(payload));
  };

  return (
    <div className='m-reviews-page'>
      <div className={`review-user-list ${depth === 0 ? 'activate' : ''}`}>
        <ReviewUserList
          searchText={searchText}
          selectedId={selectedId}
          onChangeSearchText={onChangeSearchText}
          listInfo={filterUsers}
          onClick={onClickUser}
        />
      </div>
      <div className={`review-content ${depth === 1 ? 'activate' : ''}`}>
        <ReviewContent
          userInfo={selectedUserInfo}
          isEditReview={isEditReview}
          reviewInfo={reviewInfo}
          onEdit={onEdit}
          onChangeReview={onChangeReview}
          onSaveReview={onSaveReview}
        />
      </div>
    </div>
  );
}
