import React from 'react';
import './style.scss';
import _ from 'lodash';
import { ReactSVG } from "react-svg";
import WarningIcon from "src/Assets/MIMS/warning-icon.svg";
import LockedIcon from "src/Assets/MIMS/lock.svg";

export default function AnswerStatus({
  className = '', title, value, status, concept, onConcept
}) {

  return (
    <div className={`answer-status-component ${className}`}>
      <div className='status-header v-c'>
        <div className='title mr-1'>{title}</div>
        <div className='concept-container'>
          <ReactSVG src={WarningIcon} />
          {!_.isEmpty(concept) && (
            <>
              <div className='arrow' />
              <div className='concept-content'>
                {concept.map((item, index) => (
                  <div className='concept-item' key={index} onClick={() => onConcept(item)}>
                    <div className='concept-title' >{item.name}</div>
                    <div className='concept-value' >{item.question_ids.length}</div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>

      </div>
      <div className='status-body'>
        {title === 'STATUS' ? (
          <div className={`lock-content v-c ${status ? 'active-content' : ''}`}>
            {!status ? <ReactSVG src={LockedIcon} className='mr-1' /> : null}
            <div className={`locked`}>
              {status ? "Active" : "Locked"}
            </div>
          </div>
        ) : (
          <div className='value'>{value}</div>
        )}
      </div>
    </div >
  );
}