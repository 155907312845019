import React from "react";
import 'react-circular-progressbar/dist/styles.css';
import { CircularProgressbar } from "react-circular-progressbar";

export default function QuizCircleProgress({
    progress, 
    strokeColor, 
    ratio, 
    text,
    width = 145,
    height = 145
}) {
    const normalColor = '#E7EBEE';
    
    return (
        <div style={{ width: width, height: height }} className="progress-item">
            <CircularProgressbar 
                value={progress} 
                text={text} 
                strokeWidth={20}
                maxValue={10}
                circleRatio={ratio}
                styles={{
                    // Customize the root svg element
                    root: {},
                        // Customize the path, i.e. the "completed progress"
                    path: {
                        // Path color
                        stroke: strokeColor,
                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                        strokeLinecap: 'butt',
                        // Customize transition animation
                        transition: 'stroke-dashoffset 0.5s ease 0s',
                        // Rotate the path
                        transform: `rotate(0.25turn)`,
                        transformOrigin: 'center center',
                    },
                    // Customize the circle behind the path, i.e. the "total progress"
                    trail: {
                        // Trail color
                        stroke: normalColor,
                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                        strokeLinecap: 'butt',
                        // Rotate the trail
                        transform: 'rotate(0.25turn)',
                        transformOrigin: 'center center',
                    },
                    // Customize the text
                    text: {
                        // Text color
                        fill: '#252766',
                        fontWeight: 'bold',
                        // Text size
                        fontSize: '14px',
                    },
                    // Customize background - only used when the `background` prop is true
                    background: {
                        fill: '#3e98c7',
                    },
                }}
                />;
        </div>
    )
}