import {
  AUTH_UPDATE_TOKEN,
  GET_AUTH_SIGN_OUT,
  GET_AUTH_SIGN_OUT_SUCCESS,
  GET_AUTH_SIGN_OUT_FAIL,
  GET_REQUEST_TUTOR,
  GET_REQUEST_TUTOR_SUCCESS,
  GET_REQUEST_TUTOR_FAIL,
  GET_SCHEDULE_LESSON,
  GET_SCHEDULE_LESSON_SUCCESS,
  GET_SCHEDULE_LESSON_FAIL,
  GET_DELETE_TUTOR,
  GET_DELETE_TUTOR_SUCCESS,
  GET_DELETE_TUTOR_FAIL,
  GET_REVIEW_TUTOR,
  GET_REVIEW_TUTOR_SUCCESS,
  GET_REVIEW_TUTOR_FAIL,
  GET_CANCEL_LESSON,
  GET_CANCEL_LESSON_SUCCESS,
  GET_CANCEL_LESSON_FAIL,
  GET_CANCEL_SUBSCRIPTION,
  GET_CANCEL_SUBSCRIPTION_SUCCESS,
  GET_CANCEL_SUBSCRIPTION_FAIL,
  GET_AVAILABILITY,
  GET_AVAILABILITY_FAIL,
  GET_AVAILABILITY_SUCCESS,
  GET_CREATE_MESSAGE,
  GET_CREATE_MESSAGE_FAIL,
  GET_CREATE_MESSAGE_SUCCESS,
  GET_SEND_NONCE_TO_SERVER,
  GET_SEND_NONCE_TO_SERVER_SUCCESS,
  GET_SEND_NONCE_TO_SERVER_FAIL,
} from "../Type";

import {
  signOut,
  requestTutor,
  scheduleLesson,
  deleteTutor,
  reviewTutor,
  cancelLesson,
  cancelSubscription,
  getAvailability,
  createMessage,
  sendNoneToServer
} from "../../Api/Auth";

export const updateToken = (token) => {
  return {
    type: AUTH_UPDATE_TOKEN,
    data: { token }
  }
}

export const signOutAction = () => async (dispatch) => {
  dispatch({ type: GET_AUTH_SIGN_OUT });
  const res = await signOut();
  if (res && res.data) {
    dispatch({ type: GET_AUTH_SIGN_OUT_SUCCESS, payload: res.data });
  } else {
    dispatch({ type: GET_AUTH_SIGN_OUT_FAIL, payload: null });
  }
};

export const requestTutorAction = (payload) => async (dispatch) => {
  dispatch({ type: GET_REQUEST_TUTOR });
  const res = await requestTutor(payload);
  if (res && res.data) {
    dispatch({ type: GET_REQUEST_TUTOR_SUCCESS, payload: res.data });
  } else {
    dispatch({ type: GET_REQUEST_TUTOR_FAIL, payload: res.data });
  }
};

export const scheduleLessonAction = (payload) => async (dispatch) => {
  dispatch({ type: GET_SCHEDULE_LESSON });
  const res = await scheduleLesson(payload);
  if (res && res.data && res.data.is_success) {
    dispatch({ type: GET_SCHEDULE_LESSON_SUCCESS, payload: res.data });
  } else {
    dispatch({ type: GET_SCHEDULE_LESSON_FAIL, payload: res.data });
  }
};

export const deleteTutorAction = (payload) => async (dispatch) => {
  dispatch({ type: GET_DELETE_TUTOR });
  const res = await deleteTutor(payload);
  if (res && res.data && res.data.is_success) {
    dispatch({ type: GET_DELETE_TUTOR_SUCCESS, payload: res.data });
  } else {
    dispatch({ type: GET_DELETE_TUTOR_FAIL, payload: res.data });
  }
};

export const reviewTutorAction = (id, payload) => async (dispatch) => {
  dispatch({ type: GET_REVIEW_TUTOR });
  const res = await reviewTutor(id, payload);
  if (res && res.data) {
    dispatch({ type: GET_REVIEW_TUTOR_SUCCESS, payload: res.data });
  } else {
    dispatch({ type: GET_REVIEW_TUTOR_FAIL, payload: res.data });
  }
};

export const cancelLessonActin = (data, id) => async (dispatch) => {
  dispatch({ type: GET_CANCEL_LESSON });
  const res = await cancelLesson(data, id);
  if (res && res.data) {
    dispatch({ type: GET_CANCEL_LESSON_SUCCESS, payload: res.data });
  } else {
    dispatch({ type: GET_CANCEL_LESSON_FAIL, payload: res.data });
  }
};

export const cancelSubscriptionAction = (data) => async (dispatch) => {
  dispatch({ type: GET_CANCEL_SUBSCRIPTION });
  const res = await cancelSubscription(data);
  if (res && res.data) {
    dispatch({ type: GET_CANCEL_SUBSCRIPTION_SUCCESS, payload: res.data });
  } else {
    dispatch({ type: GET_CANCEL_SUBSCRIPTION_FAIL, payload: res.data });
  }
};

export const getAvailabilityAction = (payload) => async (dispatch) => {
  dispatch({ type: GET_AVAILABILITY });
  const res = await getAvailability(payload);
  if (res && res.data) {
    dispatch({ type: GET_AVAILABILITY_SUCCESS, payload: res.data });
  } else {
    dispatch({ type: GET_AVAILABILITY_FAIL, payload: res.data });
  }
};

export const createMessageAction = (payload) => async (dispatch) => {
  dispatch({ type: GET_CREATE_MESSAGE });
  const res = await createMessage(payload);
  if (res && res.data && res.data.data) {
    dispatch({ type: GET_CREATE_MESSAGE_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_CREATE_MESSAGE_FAIL, payload: res.data });
  }
};

export const sendNoneToServerAction = (payload) => async (dispatch) => {
  dispatch({ type: GET_SEND_NONCE_TO_SERVER });
  const res = await sendNoneToServer(payload);
  if (res && res.data && !res.data.error) {
    dispatch({ type: GET_SEND_NONCE_TO_SERVER_SUCCESS, payload: res.data });
  } else {
    dispatch({ type: GET_SEND_NONCE_TO_SERVER_FAIL, payload: res.data });
  }
};

