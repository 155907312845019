import React from 'react';
import './ReviewModal.scss';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import add from '../../Assets/Icon/add.svg';
import { ReactSVG } from 'react-svg'
import { StarSVGViewBox, StarSVGIconPath } from '../../Constant';
import StarRatings from 'react-star-ratings';
import {
    toggleReviewModal,
    reviewTutorAction,
} from "../../Redux/Actions";
class ReviewModal extends React.Component {
    state = {
        isOpen: false,
        knowledge: 0,
        presentation: 0,
        reviewText: '',
        connection_id: '',
        picture: '',
        first_name: ''
    }

    static getDerivedStateFromProps(props, state) {
        return {
            isOpen: props.isOpen,
            connection_id: props.isOpen !== state.isOpen ? props.info.connection_id : state.connection_id,
            picture: props.isOpen !== state.isOpen && props.info.picture?  process.env.REACT_APP_IMAGE_DOMAIN + props.info.picture : state.picture,
            first_name: props.isOpen !== state.isOpen ? props.info.first_name : state.first_name,
        }
    }

    closeModal = () => {
        this.props.toggleReviewModal(false);
    }

    onHide = () => {
    }

    sendReview = () => {
        const { knowledge, presentation, reviewText, connection_id } = this.state;
        this.props.toggleReviewModal(false);
        const formData = new FormData()
        formData.append('rating1', knowledge);
        formData.append('rating2', presentation);
        formData.append('description', reviewText);
        formData.append('_method', 'PUT');
        this.props.reviewTutorAction(connection_id, formData).then(()=>{
            if(this.props.reviewTutorStatus){
                // console.log("reviewTutorStatus:", this.props.reviewTutorStatus)
            }
        })
    }

    render() {
        const { isOpen, knowledge, presentation, reviewText, connection_id, picture, first_name } = this.state;
        return (
            <Modal show={isOpen} onHide={this.onHide} className="review-modal-component">
                <div className="review-content">
                    <div className="close-btn"> <ReactSVG src={ add } className='icon-close' onClick={ () => this.closeModal() }/> </div>
                    <div className="img-area">
                        <div className="bg" />
                            {picture && <img className="picture" src={picture} alt="avatar" />}
                        </div>
                    <div className="content-area v-r">
                        <h1>Leave {first_name} A Review</h1>
                        <p>{`We are constantly striving to improve and we'd love to hear from you regarding  your tutoring experience with ${first_name}.`}</p>
                        <div className="show-mobile-flex v-r v-c star-area">
                            <p>Knowledge of content</p>
                            <StarRatings
                                rating={knowledge}
                                starDimension="32px"
                                starSpacing="4.5px"
                                starRatedColor="#23A4EF"
                                starHoverColor="#23A4EF"
                                starEmptyColor="#D3EDFC"
                                svgIconViewBox={StarSVGViewBox}
                                svgIconPath={StarSVGIconPath}
                                changeRating={(val, name) => this.setState({knowledge: val})}
                                name="knowledge"
                            />
                            <p>Presentation of content</p>
                            <StarRatings
                                rating={presentation}
                                starDimension="32px"
                                starSpacing="4.5px"
                                starRatedColor="#23A4EF"
                                starHoverColor="#23A4EF"
                                starEmptyColor="#D3EDFC"
                                svgIconViewBox={StarSVGViewBox}
                                svgIconPath={StarSVGIconPath}
                                changeRating={(val) => this.setState({presentation: val})}
                            />
                        </div>
                        <div className="show-web v-r v-c star-area mt-3">
                            <p>Knowledge of content</p>
                            <StarRatings
                                rating={knowledge}
                                starDimension="25px"
                                starSpacing="4.5px"
                                starRatedColor="#23A4EF"
                                starHoverColor="#23A4EF"
                                starEmptyColor="#D3EDFC"
                                svgIconViewBox={StarSVGViewBox}
                                svgIconPath={StarSVGIconPath}
                                changeRating={(val, name) => this.setState({knowledge: val})}
                                name="knowledge"
                            />
                            <p>Presentation of content</p>
                            <StarRatings
                                rating={presentation}
                                starDimension="25px"
                                starSpacing="4.5px"
                                starRatedColor="#23A4EF"
                                starHoverColor="#23A4EF"
                                starEmptyColor="#D3EDFC"
                                svgIconViewBox={StarSVGViewBox}
                                svgIconPath={StarSVGIconPath}
                                changeRating={(val) => this.setState({presentation: val})}
                            />
                        </div>
                        <h2>Your Comment</h2>
                        <textarea placeholder="" resize="none" value={reviewText} onChange={e => this.setState({reviewText: e.target.value})}/>
                        {reviewText? 
                            <button className="btn-2 primary-btn" onClick={e => this.sendReview()}>Save Changes</button>
                            :
                            <button className="btn-2 ">Save Changes</button>
                        }
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    isOpen: state.Modal.isReviewModalOpen,
    reviewTutorStatus: state.Auth.reviewTutorStatus,
    info: state.Modal.info,

});
  
export default connect(mapStateToProps, { 
    toggleReviewModal,
    reviewTutorAction
 })(withRouter(ReviewModal));
