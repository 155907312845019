import React from "react";
import './style.scss';
import { monthShortNames, ordinal_suffix_of } from 'src/Constant';

export default function AssessmentHistory({history}) {

    const colors = ['#24C1A5', '#F3CD69', '#F92672'];

    const formatDate = (value) => {
        const date = new Date(value)
        return `${date.getDate()} ${monthShortNames[date.getMonth()]} ${date.getFullYear()}`
    } 

    const getSize = (index) => {
        if (index === 0) return 100
        let value = history.length - index
        return (100 * value) / history.length
    }

    const getColor = (item) => {
        if (item.status === 'Passed') return colors[0]
        if (item.questions_correct_count >= (item.questions_count) / 2) {
            return colors[1];
        } else return colors[2];
    }

    return (
        <div className="history-card-wrapper">
            <div className="assessment-history card">
                <h5>Assessment History</h5>
                <div className="history-content">
                    {
                        history.map((item, index) => (
                            <div className="history" key={index}>
                                <div className="leading">
                                    <div className="circle-wrapper">
                                        <div className={`circle`} style={{
                                            width: `${getSize(index)}%`,
                                            height: `${getSize(index)}%`,
                                            backgroundColor: `${getColor(item)}`
                                        }}>
                                            { index > 0 && <div className="inner"></div>}
                                        </div>
                                    </div>
                                    <div className="times">{ordinal_suffix_of(index + 1)}</div>
                                </div>
                                <div className="row-item"><span>{formatDate(item.date.date)}</span></div>
                                <div className="row-item"><span>{item.status}</span></div>
                                <div className="row-item bold"><span>{item.score}</span></div>
                                <div className="row-item"><span><span className="bold">{item.questions_correct_count}</span>/{item.questions_count}</span></div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}