import {
  TOGGLE_MIMS,
  GET_ASSESSMENT_LIST,
  GET_ASSESSMENT_LIST_FAIL,
  GET_ASSESSMENT_LIST_SUCCESS,
  SET_ACTIVE_ASSESSMENT,
  GET_ASSESSMENT,
  GET_ASSESSMENT_FAIL,
  GET_ASSESSMENT_SUCCESS,
  TOGGLE_ASSESSMENT_MODAL,
  TOGGLE_QUIZ_MODAL,
  GET_ASSESSMENT_QUESTIONS,
  GET_ASSESSMENT_QUESTIONS_FAIL,
  GET_ASSESSMENT_QUESTIONS_SUCCESS,
  SUBMIT_ASSESSMENT,
  SUBMIT_ASSESSMENT_FAIL,
  SUBMIT_ASSESSMENT_SUCCESS,
  TOGGLE_ASMT_EXIT_MODAL,
  GET_ASSESSMENT_HISTORY,
  GET_ASSESSMENT_HISTORY_FAIL,
  GET_ASSESSMENT_HISTORY_SUCCESS,
  TOGGLE_PLANNER_MODAL,
  GET_PRACTICE_FLASHCARDS,
  GET_PRACTICE_FLASHCARDS_SUCCESS,
  GET_PRACTICE_FLASHCARDS_FAIL,
  SET_ACTIVE_FLASHCARD,
  GET_FLASHCARDS,
  GET_FLASHCARDS_SUCCESS,
  GET_FLASHCARDS_FAIL,
  GET_FLASHCARD_QUESTIONS,
  GET_FLASHCARD_QUESTIONS_SUCCESS,
  GET_FLASHCARD_QUESTIONS_FAIL,
  SUBMIT_QUIZ_ANSWERS,
  SUBMIT_QUIZ_ANSWERS_FAIL,
  SUBMIT_QUIZ_ANSWERS_SUCCESS,
  SAVE_FLASHCARD_STAGE,
  SAVE_FLASHCARD_STAGE_FAIL,
  SAVE_FLASHCARD_STAGE_SUCCESS,
  TOGGLE_QUIZ_EXIT_MODAL,
  GET_PRACTICE_FLASHCARD,
  GET_PRACTICE_FLASHCARD_SUCCESS,
  GET_PRACTICE_FLASHCARD_FAIL,
  GET_EXAMS,
  GET_EXAMS_FAIL,
  GET_EXAMS_SUCCESS,
  GET_PLANNER,
  GET_PLANNER_SUCCESS,
  GET_PLANNER_FAIL,
  SET_ACTIVE_EXAM,
  TOGGLE_PLANNER_EXIT_MODAL,
  SUBMIT_PLANNER,
  SUBMIT_PLANNER_ERROR,
  SUBMIT_PLANNER_SUCCESS,
  TOGGLE_CONFIRM_NEW_PLANNER,
  DELETE_PLANNER,
  DELETE_PLANNER_FAIL,
  DELETE_PLANNER_SUCCESS,
  TOGGLE_PLANNER_PROMPT
} from "../Type";

import {
  getAssessmentData,
  getAssessments,
  getAssessmentQuestions,
  submitAssessment,
  getAssessmentHistory,
  getPracticeFlashcards,
  getFlashcards,
  getFlashcardQuestions,
  submitQuizAnswers,
  saveFlashcardStage,
  getPracticeFlashcardData,
  getExams,
  getPlanner,
  createPlanner,
  deletePlanner
} from 'src/Api';

export const toggleMims = (isMims) => {
  return {
    type: TOGGLE_MIMS,
    data: { isMims }
  }
}

export const getAssessmentListAction = () => async (dispatch) => {
  dispatch({type: GET_ASSESSMENT_LIST})
  const res = await getAssessments();
  if (res && res.data) {
    dispatch({ type: GET_ASSESSMENT_LIST_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_ASSESSMENT_LIST_FAIL, payload: res.data });
  }
}

export const getAssessmentAction = (id) => async (dispatch) => {
  dispatch({type: GET_ASSESSMENT})
  const res = await getAssessmentData(id)
  if (res && res.data) {
    dispatch({ type: GET_ASSESSMENT_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_ASSESSMENT_FAIL, payload: res.data });
  }
}

export const getAssessmentQuestionsAction = (id) => async (dispatch) => {
  dispatch({type: GET_ASSESSMENT_QUESTIONS})
  const res = await getAssessmentQuestions(id)
  if (res && res.data) {
    dispatch({ type: GET_ASSESSMENT_QUESTIONS_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_ASSESSMENT_QUESTIONS_FAIL, payload: res.data });
  }
}

export const submitAssessmentAction = (data) => async (dispatch) => {
  dispatch({type: SUBMIT_ASSESSMENT})
  const res = await submitAssessment(data);
  if (res && res.data) {
    dispatch({ type: SUBMIT_ASSESSMENT_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: SUBMIT_ASSESSMENT_FAIL, payload: res.data });
  }
}

export const getAssessmentHistoryAction = (id) => async (dispatch) => {
  dispatch({type: GET_ASSESSMENT_HISTORY})
  const res = await getAssessmentHistory(id);
  if (res && res.data) {
    dispatch({ type: GET_ASSESSMENT_HISTORY_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_ASSESSMENT_HISTORY_FAIL, payload: res.data });
  }
}

export const toggleAssessmentModal = (value) => async (dispatch) => {
  dispatch({type: TOGGLE_ASSESSMENT_MODAL, data: { show: value }})
}

export const toggleAssessmentExitModal = (isOpen) => {
  return {
    type: TOGGLE_ASMT_EXIT_MODAL,
    data: { isOpen }
  }
}

export const setActiveAssessmentAction = (assessment) => {
  return {
    type: SET_ACTIVE_ASSESSMENT,
    data: { assessment }
  }
}

/* Quiz and Flashcards */

export const toggleQuizModal = (value) => async (dispatch) => {
  dispatch({type: TOGGLE_QUIZ_MODAL, data: { show: value }})
}

export const toggleQuizExitModal = (isOpen) => {
  return {
    type: TOGGLE_QUIZ_EXIT_MODAL,
    data: { isOpen }
  }
}

export const getPracticeFlashcardsAction = () => async (dispatch) => {
  dispatch({type: GET_PRACTICE_FLASHCARDS})
  const res = await getPracticeFlashcards()
  if (res && res.data) {
    dispatch({ type: GET_PRACTICE_FLASHCARDS_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_PRACTICE_FLASHCARDS_FAIL, payload: res.data });
  }
}

export const getPracticeFlashcardDataAction = (id) => async (dispatch) => {
  dispatch({type: GET_PRACTICE_FLASHCARD})
  const res = await getPracticeFlashcardData(id)
  if (res && res.data) {
    dispatch({ type: GET_PRACTICE_FLASHCARD_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_PRACTICE_FLASHCARD_FAIL, payload: res.data });
  }
}

export const getFlashcardsAction = (id) => async (dispatch) => {
  dispatch({type: GET_FLASHCARDS})
  const res = await getFlashcards(id)
  if (res && res.data) {
    dispatch({ type: GET_FLASHCARDS_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_FLASHCARDS_FAIL, payload: res.data });
  }
}

export const getFlashcardQuestionsAction = (id) => async (dispatch) => {
  dispatch({type: GET_FLASHCARD_QUESTIONS})
  const res = await getFlashcardQuestions(id)
  if (res && res.data) {
    dispatch({ type: GET_FLASHCARD_QUESTIONS_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_FLASHCARD_QUESTIONS_FAIL, payload: res.data });
  }
}

export const submitQuizAnswersAction = (data) => async (dispatch) => {
  dispatch({type: SUBMIT_QUIZ_ANSWERS})
  const res = await submitQuizAnswers(data)
  if (res && res.data) {
    console.log("submit quiz", res.data);
    dispatch({ type: SUBMIT_QUIZ_ANSWERS_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: SUBMIT_QUIZ_ANSWERS_FAIL, payload: res.data });
  }
}

export const saveFlashcardStageAction = (data) => async (dispatch) => {
  dispatch({type: SAVE_FLASHCARD_STAGE})
  const res = await saveFlashcardStage(data)
  if (res && res.data) {
    dispatch({ type: SAVE_FLASHCARD_STAGE_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: SAVE_FLASHCARD_STAGE_FAIL, payload: res.data });
  }
}

export const setActiveFlashcard = (flashcard) => {
  return {
    type: SET_ACTIVE_FLASHCARD,
    data: { flashcard }
  }
}

/* Planner */

export const togglePlannerModalAction = (show) => {
  return {
    type: TOGGLE_PLANNER_MODAL,
    data: { show }
  }
}

export const togglePlannerExitModal = (show) => {
  return {
    type: TOGGLE_PLANNER_EXIT_MODAL,
    data: { show }
  }
}

export const toggleConfirmNewPlannerModal = (show) => {
  return {
    type: TOGGLE_CONFIRM_NEW_PLANNER,
    data: { show }
  }
}

export const setActiveExam = (exam) => {
  return {
    type: SET_ACTIVE_EXAM,
    data: { exam }
  }
}

export const getExamsAction = () => async (dispatch) => {
  dispatch({type: GET_EXAMS})
  const res = await getExams()
  if (res && res.data) {
    dispatch({ type: GET_EXAMS_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_EXAMS_FAIL, payload: res.data });
  }
}

export const getPlannerAction = (examId) => async (dispatch) => {
  dispatch({type: GET_PLANNER})
  const res = await getPlanner(examId)
  if (res && res.data) {
    dispatch({ type: GET_PLANNER_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_PLANNER_FAIL, payload: res.data });
  }
}

export const createPlannerAction = (data) => async (dispatch) => {
  dispatch({type: SUBMIT_PLANNER})
  const res = await createPlanner(data)
  if (res && res.data) {
    dispatch({ type: SUBMIT_PLANNER_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: SUBMIT_PLANNER_ERROR, payload: res.data });
  }
}

export const deletePlannerAction = (examId) => async (dispatch) => {
  dispatch({type: DELETE_PLANNER})
  const res = await deletePlanner(examId)
  if (res && res.data) {
    dispatch({ type: DELETE_PLANNER_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: DELETE_PLANNER_FAIL, payload: res.data });
  }
}

export const togglePlannerPrompt = (isOpen) => {
  return {
    type: TOGGLE_PLANNER_PROMPT,
    data: { isOpen }
  }
}