import {
  GET_M_PLAN, GET_M_PLAN_SUCCESS, GET_M_PLAN_FAIL,
  GET_M_CURRENT_PLAN, GET_M_CURRENT_PLAN_SUCCESS, GET_M_CURRENT_PLAN_FAIL,
  CANCEL_PLAN, CANCEL_PLAN_SUCCESS, CANCEL_PLAN_FAIL,
  FINISH_TRIAL, FINISH_TRIAL_SUCCESS, FINISH_TRIAL_FAIL,
  
} from '../Type';

import {
  getMPlan,
  getCurrentPlan,
  cancelPlan,
  finishTrial,
} from "../../Api";

export const getMPlanAction = (payload) => async (dispatch) => {
  dispatch({ type: GET_M_PLAN });
  const res = await getMPlan(payload.topic_id);
  if (res && res.data && res.data.data) {
    dispatch({ type: GET_M_PLAN_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_M_PLAN_FAIL, payload: res.data });
  }
};

export const getCurrentPlanAction = () => async (dispatch) => {
  dispatch({ type: GET_M_CURRENT_PLAN });
  const res = await getCurrentPlan();
  if (res && res.success && res.data.data) {
    dispatch({ type: GET_M_CURRENT_PLAN_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_M_CURRENT_PLAN_FAIL, payload: res.data.data });
  }
};

export const cancelPlanAction = (payload) => async (dispatch) => {
  dispatch({ type: CANCEL_PLAN });
  const res = await cancelPlan(payload);
  if (res && res.data && res.data.data.error === false) {
    dispatch({ type: CANCEL_PLAN_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: CANCEL_PLAN_FAIL, payload: res.data.data });
  }
};

export const finishTrialAction = () => async (dispatch) => {
  dispatch({ type: FINISH_TRIAL });
  const res = await finishTrial();
  if (res && res.data && res.data.data.error === false) {
    dispatch({ type: FINISH_TRIAL_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: FINISH_TRIAL_FAIL, payload: res.data.data });
  }
};

