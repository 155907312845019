import React from 'react';
import './style.scss';
// import { useDispatch } from 'react-redux';
// import { ReactSVG } from "react-svg";
import { Score } from 'src/Components';
// import LockIcon from "src/Assets/MIMS/lock-icon.svg";

export default function Scores({
  className = '',
  questionLength,
  scores
}) {

  const {
    score,
    correct,
    pending_question,
    wrong,
  } = scores;

  // const dispatch = useDispatch();

  // const onUpgrade = () => { }

  return (
    <div className={`scores-component ${className}`}>
      <div className='score-content shadow-object'>
        <Score
          title="Score"
          max={questionLength}
          value={score}
        />
        <Score
          title="Pending Questions"
          max={questionLength}
          value={pending_question}
          barColor={"#FFDF6E"}
        />
        <Score
          title="Correct"
          max={questionLength}
          value={correct}
          barColor={"#8ED37D"}
        />
        <Score
          title="Wrong"
          max={questionLength}
          value={wrong}
        />
        {/* <Score
          title="Saved"
          value={0}
        /> */}
      </div>
      {/* <div className='plan-status v-c space-between'>
        <div className='lock-container v-c'>
          <div className='lock-content center'>
            <ReactSVG src={LockIcon} />
          </div>
          <div>
            <div className='plan-status-title'>PLAN STATUS</div>
            <div className='lock-title'>LOCKED</div>
          </div>
        </div>
        <div className='btn-2 upgrade-btn' onClick={onUpgrade}>UPGRADE</div>
      </div> */}
    </div>
  );
}
