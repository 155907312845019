import React, { useEffect, useRef, useState } from "react";
import './style.scss';
import UserStatComponent from "../UserStat";
import ConceptTestTracking from "../ConceptTestTracking";
import FeedbackAnalysis from "../FeedbackAnalysis";
import AttentionComponent from "../Attention";
import AssessmentComplete from "../AssessmentComplete";
import { useDispatch, useSelector } from "react-redux";
import {
    getAssessmentHistoryAction
} from 'src/Redux/Actions'
import {
    START_ASSESSMENT
} from 'src/Redux/Type';
import { hideSpinner } from "src/Util/Helper";
import PDFComponent from "../PDFComponent";
import { useReactToPrint } from "react-to-print";
import AssessmentHistory from "../AssessmentHistory";
import _ from 'lodash';

export default function IntroComponent({
    assessment
}) {
    const dispatch = useDispatch();
    const componentRef = useRef();

    const { 
        assessment_is_active,
        assessment_completed,
        submit_assmt_loading,
        assessment_result,
        total_time,
        assessment_history,
        assessment_on_hold,
        getQuestions_success,
        loading_questions,
    } = useSelector(state => state.Mims)

    const onStart = () => dispatch({ type: START_ASSESSMENT })

    useEffect(() => {
        if(!loading_questions) {
            hideSpinner()
            if(getQuestions_success) {
                console.log('=== questions loaded ===')
            }
        }
    }, [loading_questions, getQuestions_success])

    useEffect(() => {
        if(!submit_assmt_loading) {
            hideSpinner()
        }
    }, [submit_assmt_loading])


    useEffect(() => {
        if(getQuestions_success && !_.isEmpty(assessment.question_summary)) {
            dispatch(getAssessmentHistoryAction(assessment.id))
        }
    }, [getQuestions_success])

    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    })

    const showAttention = () => !assessment_on_hold && !(assessment_is_active || assessment_completed) && _.isEmpty(assessment.question_summary)

    const showCompleted = () => (assessment_on_hold || assessment_completed || !_.isEmpty(assessment.question_summary))

    const showHistory = () => !_.isEmpty(assessment_history) && !_.isEmpty(assessment.question_summary)

    return (
        <>
            <div className="section-wrapper" >
                <div className="row">
                    <div className="col-lg-4 left-section">
                        {
                            assessment && (
                            <>
                                <UserStatComponent completed={assessment_completed} assessment={assessment} onPrint={() => handlePrint()}/>
                                <ConceptTestTracking assessment={assessment}/>
                                <FeedbackAnalysis assessment={assessment}/>
                            </>)
                        }
                    </div>
                    <div className="col-lg-8 right-section">
                        { showAttention() && <AttentionComponent onStart={() => onStart()} assessment={assessment} />}
                        { showCompleted() && <AssessmentComplete /> }
                        { showHistory() && <AssessmentHistory history={assessment_history}/>}
                    </div>
                </div>
            </div>
            { assessment_result && <PDFComponent time={total_time} result={assessment_result} compRef={componentRef}/> }
        </>
    )
}