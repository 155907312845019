import React, { useState } from "react";
import './style.scss';
import ReactSlider from 'react-slider';
import PlannerProgressIndicator from "../../PlannerProgress";

export default function DoubleTimeSplitterComponent({exam, onValue}) {
    const [value, setValue] = useState([33, 66])

    const getPercentage = (state) => {
        if (state.index === 0) return value[0]
        if (state.index === 1) return value[1] - value[0]
        return 100 - value[1]
    }

    const updateSliderPosition = (value) => setValue(value)

    const trimTopic = (text) => {
        let edited = text.toUpperCase().replaceAll('MAKE', '')
        return (edited.replaceAll('SENSE', '').trim())
    }

    const onNext = () => {
        const result = exam.topics.map((item, index) => {
            let range = getTopicRange(index)
            return { "mims_topic_id": item.id, "percentage": range}
        })
        onValue(result)
    }

    const getTopicRange = (index) => {
        if (index === 0) return value[0]
        if (index === 1) return value[1] - value[0]
        return 100 - value[1]
    }

    return (
        <div className="time-splitter-2 card">
            <div className="info-content">
                <div className="step-info">step 3 of 5</div>
                <h3 className="plan-title">Balancing Your Prep: Prioritize to Maximize:</h3>
                <p className="plan-content">Distribute your study hours across various test prep sections. A greater percentage for a section signifies an increased focus on its study materials.</p>
                <div className="slider-wrapper">
                    <div className="label-wrapper">
                        {
                            exam.topics.map(item => (
                                <div key={item.id}>{trimTopic(item.name)}</div>
                            ))
                        }
                    </div>
                    <ReactSlider
                        value={value}
                        className="horizontal-slider"
                        trackClassName="customSlider-track"
                        pearling
                        withTracks
                        // onBeforeChange={(value, index) =>
                        //     console.log(`onBeforeChange: ${JSON.stringify({ value, index })}`)
                        // }
                        onChange={(value, index) => updateSliderPosition(value)}
                        onAfterChange={(value, index) => updateSliderPosition(value)}
                        renderTrack={(props, state) => <div {...props}>{getPercentage(state)}%</div>}
                        renderThumb={(props, state) => <div {...props}></div>}
                    />
                </div>
            </div>
            <div className={`primary-btn`} onClick={() => onNext()}>NEXT</div>
            <PlannerProgressIndicator stage={3}/>
        </div>
    )
}