import React, { useEffect } from "react";
import './style.scss';
import { Modal } from "react-bootstrap";
import HeadLogo from 'src/Assets/MIMS/MIM-Logo.png';
import QuizHomeComponent from "./QuizHomeComponent";
import { useDispatch, useSelector } from "react-redux";
import ExitQuizModal from "./ExitQuizModal";
import { getPracticeFlashcardDataAction, toggleQuizExitModal, toggleQuizModal } from "src/Redux/Actions";
import { hideSpinner, showSpinner } from "src/Util/Helper";

export default function QuizComponent({showModal, onHide}) {

    const dispatch = useDispatch()

    const {
        flashcard_concept,
        isQuizExitModalOpen,
        isQuizModalOpen,
        active_flashcard,
        loadingPracticeFlashcard
    } = useSelector(state => state.Mims)

    useEffect(() => {
        if(isQuizModalOpen && active_flashcard && active_flashcard.id) {
            showSpinner()
            dispatch(getPracticeFlashcardDataAction(active_flashcard.id))
        }
    }, [dispatch, isQuizModalOpen])

    useEffect(() => {
        if (!loadingPracticeFlashcard) {
            hideSpinner()
        }
    }, [loadingPracticeFlashcard])

    const onExit = () => {
        dispatch(toggleQuizExitModal(false))
        dispatch(toggleQuizModal(false))
    }

    return (
       <>
         <Modal className="quiz-modal" show={showModal} onHide={onHide}>
            <Modal.Header className="pc-header">
                <div className="logo-wrapper">
                    <img src={HeadLogo}/>
                </div>
                <div className="header-content">
                    <h4>🎉 Flashcard Mastery! 🌟</h4>
                    <div className="exit-btn" onClick={onHide}>
                        <div>Exit</div>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Header className="mobile-header">
                <h4>🎉 Flashcard Mastery! 🌟</h4>
                <div className="exit-btn" onClick={onHide}>
                    <div>Exit</div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <QuizHomeComponent />
            </Modal.Body>
        </Modal>
        <ExitQuizModal 
            name={`${flashcard_concept} Flashcard Test`}
            show={isQuizExitModalOpen}
            onExit={() => onExit()}
            onResume={() => dispatch(toggleQuizExitModal(false))}
        />
       </>
    )
}