import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Header } from '../Components';
import { getMypofileInfoAction } from '../Redux/Actions';
import _ from 'lodash'

class Page extends React.Component {
    state = {
        titleList: [],
        depth: 0,
        profileInfo: '',
        user: ''
    };

    componentDidMount() {
        const { myProfileInfo } = this.props;
        if (_.isEmpty(myProfileInfo)) {
            this.props.getMypofileInfoAction().then(() => {
                this.updateProfileInfo();
            });
        } else {
            this.updateProfileInfo();
        };

        let temp = this.state.titleList;
        temp.push(this.props.title);
        this.setState({
            titleList: temp,
            depth: 0
        });
    };

    updateProfileInfo = () => {
        const { myProfileInfo } = this.props;
        this.setState({ profileInfo: myProfileInfo });
        this.intercomSetting(myProfileInfo);
    };

    updateTitle = (flag, newTitle = '', lengthLimit = 0) => {
        let { titleList, depth } = this.state;
        if (flag === 'push') {
            if (lengthLimit > 1 && titleList.length === lengthLimit) {
                titleList[depth] = newTitle;
            } else {
                depth++;
                titleList.push(newTitle);
            }
        } else {
            depth--;
            titleList.pop()
        }
        this.setState({
            titleList: titleList,
            depth: depth
        })
    };

    loader = (value) => {
        this.props.sendLoading(value)
    };

    formatTitle = () => {
        let temp = [];
        temp.push(this.props.title);
        this.setState({
            titleList: temp,
            depth: 0
        })
    };

    headerProfileInfo = (payload) => {
        this.setState({ profileInfo: payload });
        this.intercomSetting(payload);
    }

    intercomSetting = (data) => {
        window.intercomSettings = {
            app_id: 'mx9avtti',
            user_id: data.id,
            profile_pic: data.picture ? process.env.REACT_APP_IMAGE_DOMAIN + data.picture : '', // url to profile picture
            first_name: data.first_name,
            last_name: data.last_name,
            phone: data.phone,
            email: data.email,
            // city: data.region_id, // user region
            account_type: 'student',
            subscription_status: data.plan_status, // plan_status field from GET /me
            signup_date: data.created_at.date, // created_at field
            plan_frequency: data.plan_frequency, // from GET /me
            plan_rate_per_hour: data.plan_rate_per_hour, // from GET /me
        };
    };

    render() {
        const { titleList, depth, profileInfo } = this.state;
        const { page } = this.props;
        return (
            <React.Fragment >
                {/* <Intercom appID="abc12345" { ...this.state.user } /> */}
                <Header titleArray={titleList} depth={depth} profileInfo={profileInfo} updateTitle={(f, t) => this.updateTitle(f, t)}></Header>
                <div className="content">
                    {page({ updateTitle: (f, n, t) => this.updateTitle(f, n, t), loader: (v) => this.loader(v), depth: depth, headerProfileInfo: (v) => this.headerProfileInfo(v), formatTitle: () => this.formatTitle() })}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    myProfileInfo: state.Core.myProfileInfo,
});

export default connect(mapStateToProps, {
    getMypofileInfoAction,
})(withRouter(Page));
