import React, { useEffect, useRef } from 'react';
import './style.scss';
import { ReactSVG } from 'react-svg'
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import add from 'src/Assets/Icon/add.svg';
import { ConceptsCharts, Scores } from 'src/Components';
import {
  toggleGraphModal,
  getTestIdAction,
  getScoresAction,
  getAverageTimeAction,
} from 'src/Redux/Actions';
import { showSpinner, hideSpinner } from 'src/Util/Helper';

export default function GraphModal() {
  const dispatch = useDispatch();
  const getTestIdRef = useRef(false);
  const getLoadingRef = useRef(false);

  const {
    isGraphModalOpen, topic_id,
  } = useSelector((state) => state.Modal);

  //Get Test ID
  useEffect(() => {
    if (topic_id) {
      showSpinner();
      dispatch(getTestIdAction({ topic_id }))
    }
  }, [dispatch, isGraphModalOpen, topic_id]);

  const {
    testId, get_testId_success, get_testId_loading,
    averageTime, get_average_time_success, get_average_time_loading,
    questions, scores
  } = useSelector(
    state => state.QA,
  );

  //Get Test id res
  useEffect(() => {
    if (getTestIdRef.current && !get_testId_loading) {
      if (get_testId_success) {
        onGetAnswerScours();
      } else {
        hideSpinner();
      }
    }
    getTestIdRef.current = get_testId_loading;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_testId_loading, get_testId_success, testId]);

  const onGetAnswerScours = () => {
    dispatch(getScoresAction(testId));
    dispatch(getAverageTimeAction(testId));
  }

  //Get Average time res
  useEffect(() => {
    if (getLoadingRef.current && !get_average_time_loading) {
      hideSpinner();
      if (get_average_time_success) {
        // console.log("===average Time res===", averageTime)
      }
    }
    getLoadingRef.current = get_average_time_loading;
  }, [get_average_time_loading, get_average_time_success, averageTime]);

  const closeModal = () => {
    dispatch(toggleGraphModal(false));
  };

  return (
    <Modal show={isGraphModalOpen} className="graph-modal-component">
      <div className="graph-modal-content v-r">
        <div className="close-btn">
          <ReactSVG src={add} className='icon-close' onClick={closeModal} />
        </div>
        <div className='graph-main-content'>
          <ConceptsCharts
            averageTime={averageTime}
          />
          {
            questions && (
              <Scores
                questionLength={questions.length}
                scores={scores}
              />
            )
          }
        </div>
      </div>
    </Modal>
  );
}
