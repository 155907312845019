import React, { useEffect, useRef } from 'react';
import './style.scss';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCardInfoAction,
  removeCardAction,
  setDefaultCardAction,
} from 'src/Redux/Actions';
import { showSpinner, hideSpinner, showToast } from 'src/Util/Helper';

export default function CardList({
  className = '',
  editCard,
}) {

  const dispatch = useDispatch();
  const RemoveCardLoadingRef = useRef(false);
  const SetDefaultCardLoadingRef = useRef(false);

  const {
    card_numbers,
  } = useSelector((state) => state.Core);

  const {
    removeCardStatus, removeCardError, removeCardSuccess, removeCardLoading,
    defaultCardStatus, defaultCardError, defaultCardSuccess, defaultCardLoading,
  } = useSelector((state) => state.Payment);

  //Call get card list info
  useEffect(() => {
    dispatch(getCardInfoAction());
  }, [dispatch]);

  const removeCard = (item) => {
    const formData = new FormData();
    formData.append('todo', 'remove');
    formData.append('payment_method_token', item.payment_method_token);

    showSpinner()
    dispatch(removeCardAction(formData));
  };

  //get remove card res
  useEffect(() => {
    if (RemoveCardLoadingRef.current && !removeCardLoading) {
      hideSpinner();
      if (removeCardSuccess) {
        showToast("success", 3000, removeCardStatus)
        dispatch(getCardInfoAction());
      } else {
        showToast("error", 3000, removeCardError)
      }
    }
    RemoveCardLoadingRef.current = removeCardLoading;
  }, [removeCardLoading, removeCardSuccess, removeCardStatus, removeCardError, dispatch]);

  const setAsDefaultCard = (item) => {
    const formData = new FormData();
    formData.append('todo', 'set_default');
    formData.append('payment_method_token', item.payment_method_token);

    showSpinner()
    dispatch(setDefaultCardAction(formData))
  };

  //get set default card res
  useEffect(() => {
    if (SetDefaultCardLoadingRef.current && !defaultCardLoading) {
      hideSpinner();
      if (defaultCardSuccess) {
        showToast("success", 3000, defaultCardStatus)
        dispatch(getCardInfoAction());
      } else {
        showToast("error", 3000, defaultCardError)
      }
    }
    SetDefaultCardLoadingRef.current = defaultCardLoading;
  }, [defaultCardLoading, defaultCardSuccess, defaultCardStatus, defaultCardError, dispatch]);

  return (
    <div className={`card-list-component ${className}`} >
      {!_.isEmpty(card_numbers) && (
        <>
          <h3>Card Information</h3>
          {card_numbers.map((data, index) => (
            <div className="edit-card" key={index}>
              <div className="card-num-date">
                <p className="card-brand">{data.masked_card_number}</p>
                <p className="exp ml-5">{`Expires: ${data.exp_month}/${data.exp_year}`}</p>
              </div>
              <div className="edit-card-row">
                <div className="edit-item-btn" onClick={e => editCard(data)}>Edit</div>
                <div className="edit-item-btn" onClick={e => removeCard(data, index)}>Remove</div>
                {!data.is_default && (
                  <div className="edit-item-btn" onClick={e => setAsDefaultCard(data, index)}>Set as default</div>
                )}
              </div>
            </div>
          ))}
        </>
      )}
    </div >
  );
}
