import React from 'react';
import './style.scss';
import WaringIcon from 'src/Assets/MIMS/warning.svg';

export default function QuestionTags({
  className = '',
  questionDate,
  concept,
  name,
  rules
}) {

  return (
    <div className={`question-tags-component ${className}`}>
      <div className='tag-item item-1'>{questionDate}</div>
      <div className='tag-item item-2'>{concept}</div>
      <div className='tag-item item-4'>{name}</div>
      <div className='tag-item item-3'>{rules}</div>
      <div className='test-rules'>
        <img src={WaringIcon} alt='waring-icon' />
        <div className='rules'>Test Rules</div>
      </div>
    </div>
  );
}