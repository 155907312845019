import React from 'react';
import './SelectDateTime.scss';
import { ReactSVG } from 'react-svg'
import Calendar from 'react-calendar';
import moment from 'moment-timezone';
import left from '../../Assets/Icon/left.svg';
import right from '../../Assets/Icon/right.svg';
import TimeZone from '../TimeZone';

const timeList = [
    { time24: '00:00:00', value: 0 },
    { time24: '00:30:00', value: 0 },
    { time24: '01:00:00', value: 1 },
    { time24: '01:30:00', value: 1 },
    { time24: '02:00:00', value: 2 },
    { time24: '02:30:00', value: 2 },
    { time24: '03:00:00', value: 3 },
    { time24: '03:30:00', value: 3 },
    { time24: '04:00:00', value: 4 },
    { time24: '04:30:00', value: 4 },
    { time24: '05:00:00', value: 5 },
    { time24: '05:30:00', value: 5 },
    { time24: '06:00:00', value: 6 },
    { time24: '06:30:00', value: 6 },
    { time24: '07:00:00', value: 7 },
    { time24: '07:30:00', value: 7 },
    { time24: '08:00:00', value: 8 },
    { time24: '08:30:00', value: 8 },
    { time24: '09:00:00', value: 9 },
    { time24: '09:30:00', value: 9 },
    { time24: '10:00:00', value: 10 },
    { time24: '10:30:00', value: 10 },
    { time24: '11:00:00', value: 11 },
    { time24: '11:30:00', value: 11 },
    { time24: '12:00:00', value: 12 },
    { time24: '12:30:00', value: 12 },
    { time24: '13:00:00', value: 13 },
    { time24: '13:30:00', value: 13 },
    { time24: '14:00:00', value: 14 },
    { time24: '14:30:00', value: 14 },
    { time24: '15:00:00', value: 15 },
    { time24: '15:30:00', value: 15 },
    { time24: '16:00:00', value: 16 },
    { time24: '16:30:00', value: 16 },
    { time24: '17:00:00', value: 17 },
    { time24: '17:30:00', value: 17 },
    { time24: '18:00:00', value: 18 },
    { time24: '18:30:00', value: 18 },
    { time24: '19:00:00', value: 19 },
    { time24: '19:30:00', value: 19 },
    { time24: '20:00:00', value: 20 },
    { time24: '20:30:00', value: 20 },
    { time24: '21:00:00', value: 21 },
    { time24: '21:30:00', value: 21 },
    { time24: '22:00:00', value: 22 },
    { time24: '22:30:00', value: 22 },
    { time24: '23:00:00', value: 23 },
    { time24: '23:30:00', value: 23 },

];

export default class SelectDateTime extends React.Component {
    state = {
        select_date: '',
        selected_time: '',
        current_timezone: moment.tz.guess(true)
    }

    selectDate = (date) => {
        if (Math.floor((new Date() - new Date(date))) / 1000 <= 24 * 3600) {
            this.setState({
                select_date: date,
                selected_time: '',
            })
        } else {
            this.setState({
                select_date: '',
                selected_time: '',
            })
        }
    }

    selectTimezone = (val) => {
        this.setState({ current_timezone: val })
        var current_timezone_time = moment.tz(val).format("HH:mm")
    }

    confirm = () => {
        var dateTime = `${moment(this.state.select_date).format('YYYY-MM-DD')} ${this.state.selected_time}`
        this.props.selectDate(dateTime);
    }



    render() {
        const { select_date, selected_time, current_timezone } = this.state;
        // const { value } = this.state;
        return (
            <div className="select-date-time-container v-c">
                <div className="date-content">
                    <div className="date-select-area v-r">
                        <Calendar
                            className="calendar"
                            onChange={this.selectDate}
                            value={select_date || new Date()}
                            calendarType="US"
                            prevLabel={<ReactSVG src={left} className="svg-icon" />}
                            nextLabel={<ReactSVG src={right} className="svg-icon" />}
                        />
                        <TimeZone value={current_timezone} onChange={val => this.selectTimezone(val)} />
                    </div>
                    {select_date.toString().length > 0 && <div className="time-select v-r">
                        <div className="time-header v-c v-r">
                            <h1>{moment(select_date).format('dddd, MMMM DD, YYYY')}</h1>
                            <p>Please select start time</p>
                        </div>
                        <div className="time-list v-r">
                            {timeList.map((item, index) =>
                                <div className={`time-container v-c ${selected_time === item.time24 ? 'active' : ''}`} key={index}>
                                    <div className="time-item sub-item v-c h-c" onClick={e => this.setState({ selected_time: item.time24 })}>{moment(item.time24, ["HH:mm"]).format("hh:mm A")}</div>
                                    <div className="confirm-item sub-item v-c h-c" onClick={e => this.confirm()}>Confirm</div>
                                </div>
                            )}
                        </div>
                    </div>}
                </div>
            </div>
        );
    }
}
