import React from 'react';
import './style.scss';
import CanvasJSReact from '@canvasjs/react-charts';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function ConceptsCharts({
  className = '',
  averageTime
}) {

  const options = {
    // title: {
    //   text: "Average time"
    // },
    data: [
      {
        // Change type to "doughnut", "line", "splineArea", etc.
        type: "column",
        dataPoints: averageTime,
        // dataPoints: [
        //   { label: "Algebra", y: 3.25 },
        //   { label: "Algebra", y: 15 },
        //   { label: "Algebra", y: 25 },
        //   { label: "Algebra", y: 30 },
        //   { label: "Algebra", y: 28 }
        // ]
      }
    ]
  }

  return (
    <div className={`concepts-charts-component ${className}`}>
      <div className='chart-title'>Average time</div>
      <CanvasJSChart options={options} />
    </div>
  );
}
