import React, {useState, useMemo, useEffect } from "react";
import './style.scss';
import RadioWrong from 'src/Assets/MIMS/RadioWrong.png';
import RadioCorrect from 'src/Assets/MIMS/RadioCorrect.png';
import VideoItem from "src/Components/MIMS/VideoItem";
import VideoThumb from 'src/Assets/MIMS/VideoThumb.png';
import PlayIcon from 'src/Assets/MIMS/VideoPlay.png';
import CustomToggle from "src/Components/MIMS/CustomToggle";
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash';
import {
    SET_SOLUTION_QUESTION
} from 'src/Redux/Type';
import {
    toggleMuseVideoModal
} from 'src/Redux/Actions';

export function QuestionSolution({onIndexChanged}) {
    const dispatch = useDispatch();
    const [showVideoExp, setShowVideoExp] = useState(true)
   
    const {
        assessment_questions,
        current_question,
        answered_questions,
        assessment_result
    } = useSelector(state => state.Mims)

    const {
        myProfileInfo
    } = useSelector(state => state.Core)

    const currentIndex = useMemo(() => {
        if (_.isEmpty(assessment_questions)) return -1;
        const index = _.findIndex(assessment_questions, function (item) {
            return item.id === current_question.id
        })
        console.log(`current question index: ${index}`)
        return index;
    }, [current_question.id])

    useEffect(() => {
        onIndexChanged(currentIndex)
    }, [currentIndex])

    const isLastQuestion = () => {
        return currentIndex === assessment_questions.length - 1
    }

    const onNext = () => {
        const next_question = isLastQuestion() ? null : assessment_questions[currentIndex + 1];
        dispatch({ 
            type: SET_SOLUTION_QUESTION, 
            data: { question: next_question, last: isLastQuestion() }
        })
    }

    const onBack = () => {
        if (currentIndex === 0) return;
        dispatch({ 
            type: SET_SOLUTION_QUESTION, 
            data: { question: assessment_questions[currentIndex - 1]}
        })
    }

    const onSkip = () => {
        if (isLastQuestion()) return;
        dispatch({ 
            type: SET_SOLUTION_QUESTION, 
            data: { question: assessment_questions[currentIndex + 1]}
        })
    }

    const isSolution = (item) => item === current_question.solution

    const isChoice = (item) => {
        // if user hasn't submited yet, use user answer from questions 
        if(_.isEmpty(assessment_result)) {
            return item === current_question.user_answer;
        } else {
            // if user has just submitted assessments, get results from answer list
            const index = _.findIndex(answered_questions, function(answer) {
                return (answer.question_id === current_question.id) && item === answer.option
            })
            return index !== -1
        }   
    }

    const canToggleExpMode = () => !_.isEmpty(current_question.explanation_text) && !_.isEmpty(current_question.muse_video_preview)
    
    function ExplanationComoponent() {
        if(canToggleExpMode()) {
            return (showVideoExp ? VideoComponent : TextComponent)
        } else if(_.isEmpty(current_question.muse_video_preview)) {
            return (TextComponent)
        } else {
            return (VideoComponent)
        }
    }

    const onSeeVideo = () => {
        dispatch(toggleMuseVideoModal(true, { videoId: current_question.muse_video }));
    }

    const VideoComponent = <VideoItem thumbImg={current_question.muse_video_preview} onClick={onSeeVideo} className='mt-3'/> 

    const TextComponent = <div dangerouslySetInnerHTML={{ __html: current_question.explanation_text }} style={{ paddingTop: '15px'}}></div>

    function Avatar() {
        return (
            myProfileInfo.picture ? <div className="avatar-wrapper"><img src={`${process.env.REACT_APP_IMAGE_DOMAIN}${myProfileInfo.picture}`} alt="avatar" /></div>
                    : <div className="avatar-alt"><span>{myProfileInfo.first_name ? myProfileInfo.first_name.charAt(0) : 'A'}{myProfileInfo.last_name ? myProfileInfo.last_name.charAt(0) : 'L'}</span></div>
        )
    }

    return (
        <div className="question-solution card">
            <div className="anwer-section">
                <h4 className="label">Right Answer</h4>
                {
                   current_question && current_question.options.map((item, index) => (
                        <div className={`answer-wrapper ${isSolution(item) ? 'correct': ''}`} key={index}>
                            <div className='answer-item'>
                                <div className="choice-status">
                                    { isSolution(item) ? (<img src={RadioCorrect} className="icon"/>) : (<img src={RadioWrong} className="icon"/>)}
                                    <div className="text">{item}</div>
                                </div>
                                {
                                    isChoice(item) && (
                                        <div className="choice-selected">
                                            <div className="text">Your choice</div>
                                            <Avatar />
                                        </div>
                                    )
                                }
                            </div>
                            <div className={`${isSolution(item) ? '' : 'd-none'}`}>
                                <ExplanationComoponent />
                                {
                                    canToggleExpMode() ? 
                                    <div className="toggle-wrapper">
                                        <CustomToggle 
                                            value={showVideoExp} 
                                            onChange={(value) => setShowVideoExp(value)} 
                                            className={`${ isSolution(item) ? 'correct' : '' }`}
                                            isOnValue="Text"
                                            isOffValue="Video"
                                        />
                                </div> : <></>
                                }
                            </div>
                        </div>
                    ))
                }
            </div> 
            <div className="btn-section">
                <div className={`btn-custom btn-light ${currentIndex === 0 ? 'disabled' : ''}`} onClick={() => onBack()}>BACK</div>
                <div className="pagination-show"><span>{currentIndex + 1}</span> of {assessment_questions.length}</div>
                <div className="btn-custom skip-btn" onClick={() => onSkip()}>SKIP</div>
                {/* Note: Next button color varies based on question index and time */}
                <div className={`btn-custom primary-btn ${ isLastQuestion() ? 'last': ''}`} onClick={() => onNext()}>
                    { isLastQuestion() ? 'FINISH' : 'NEXT'}
                    </div>
            </div>
        </div>
    )
}