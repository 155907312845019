import React from 'react';
import './style.scss';
import _ from 'lodash';
import moment from 'moment';
import { ReactSVG } from "react-svg";
import StarRatings from 'react-star-ratings';
import { StarSVGViewBox, StarSVGIconPath } from '../../../Constant';
import SendIcon from 'src/Assets/Icon/send.svg';

export default function ReviewContent({
  className = '',
  userInfo,
  isEditReview,
  reviewInfo,
  onChangeReview,
  onEdit,
  onSaveReview
}) {

  return (
    <div className={`review-content-component scroll-area v-r activate ${className}`} >
      {!_.isEmpty(userInfo) &&
        <>
          <p className="tutor-name show-web">{userInfo.topic.name}</p>
          {(!userInfo.review || isEditReview) &&
            <div className="compose-review v-r">
              <div className="star-area v-c h-c v-r">
                <h4>{isEditReview ? 'Publish a new' : 'Edit'} review about <span>{userInfo.topic.name}</span></h4>
                <p>Rating</p>
                <StarRatings
                  rating={reviewInfo.rating}
                  starDimension="32px"
                  starSpacing="4.5px"
                  starRatedColor="#23A4EF"
                  starHoverColor="#23A4EF"
                  starEmptyColor="#D3EDFC"
                  svgIconViewBox={StarSVGViewBox}
                  svgIconPath={StarSVGIconPath}
                  changeRating={val => onChangeReview('rating', val)}
                  name="rating"
                />
              </div>
              <div className="review-input v-c">
                <textarea
                  className="review-text"
                  rows={1}
                  placeholder={`Type your review about ${userInfo.topic.name}`}
                  value={reviewInfo.review}
                  onChange={e => onChangeReview('review', e.target.value)}
                />
                <ReactSVG src={SendIcon} className="svg-icon" onClick={onSaveReview} />
              </div>
            </div>}
          {(userInfo.review && !isEditReview) && <div className="view-review v-r">
            <div className="review-message">
              <div className="v-c h-c initial">
                <img
                  src={`${process.env.REACT_APP_IMAGE_DOMAIN}${userInfo.user.picture}`}
                  alt='user-img'
                  className='user-img'
                />
              </div>
              <div className="review-info v-r">
                <div className="explain">
                  You submitted a review about <span>{userInfo.topic.name}</span> {moment(userInfo.created_at.date).format("hh:mm A")}
                </div>
                <div className="my-review">
                  <div className="feedback-area">
                    <div className="feedback v-c">
                      <label>Rating: </label>
                      <StarRatings
                        rating={userInfo.rating}
                        starDimension="15px"
                        starSpacing="2px"
                        starRatedColor="#23A4EF"
                        starEmptyColor="#D3EDFC"
                        svgIconViewBox={StarSVGViewBox}
                        svgIconPath={StarSVGIconPath}
                      />
                    </div>
                  </div>
                  <p>{userInfo.review}</p>
                </div>
                <div className="v-c btn-area">
                  <button className="btn-2 btn-2-outline edit-btn" onClick={onEdit}>Edit</button>
                </div>
              </div>
            </div>
          </div>}
        </>
      }
    </div >
  );
}