import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import BeatLoader from 'react-spinners/BeatLoader'

export default class Loader extends React.Component {
  render() {
      const {loading} = this.props
    return (
        <LoadingOverlay            
            active={loading}
            style={{width: 500, height: 500, alignItems: 'center', justifyContent: 'center'}}
            spinner={<BeatLoader size={30} color={"#FFF"}/>}
        />        
    );
  }
}