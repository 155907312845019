import React from 'react';
import './style.scss';
import { ReactSVG } from 'react-svg'
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import add from 'src/Assets/Icon/add-2.svg';
import { toggleUpgradePlanModal, toggleQuestionModal } from 'src/Redux/Actions';

export default function UpgradePlanModal() {
  const dispatch = useDispatch();
  let history = useHistory();

  const {
    isUpgradePlanModal, upgradeModalInfo
  } = useSelector((state) => state.Modal);

  const closeModal = () => {
    dispatch(toggleUpgradePlanModal(false));
  };

  const onUpgrade = () => {
    closeModal()
    dispatch(toggleQuestionModal(false));
    history.push({
      pathname : "/make-it-make-sense/plans", 
      topicInfo: upgradeModalInfo
    });
  }

  return (
    <Modal show={isUpgradePlanModal} className="upgrade-plan-modal-component">
      <div className="upgrade-plan-content shadow-object v-r">
        <div className="close-btn">
          <ReactSVG src={add} className='icon-close' onClick={closeModal} />
        </div>
        <div className='description'>Apologies for interrupting your prep session; please upgrade your plan to continue practicing</div>
        <div className='upgrade-btn btn-2' onClick={onUpgrade}>Upgrade Now</div>
      </div>
    </Modal>
  );
}
