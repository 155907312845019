import { get, post, remove } from "./Api";

export const getMytutors = () => {
  return get("/connections/mytutors");
};

export const getPendingLesson = (timezone) => {  
  return get(`/transactions?status=pending&timezone=${timezone}`);
};

export const getCompletedLesson = (timezone) => {
  return get(`/transactions?status=finished&timezone=${timezone}`);
};

export const getMypofileInfo = () => {
  return get("/me");
};

export const profileUpdate = (data) => {
  return post("/me", data);
};

export const getSchools = () => {
  return get("/schools");
};

export const getMytutorDetail = (tutor_id) => {
  return get("/connections/mytutordetail?tutor_id=" + tutor_id);
};

export const getReceipt = () => {
  return get("/payments?payment_type=1");
};

export const getLevels = () => {
  return get("/levels");
};

export const getSubjects = (level_id = 1) => {
  return get(`/subjects?level_id=${level_id}`);
};

export const getMyReviews = () => {
  return get("/connections/myreviews");
};

export const getPlans = () => {
  return get("/plans");
};

export const getBraintreekey = () => {
  return get(`/payments/braintreekey`);
};

export const getMessage = (id) => {
  return get(`/messages?user_id=${id}`);
};

export const getMessageUserList = () => {
  return get("/messages");
};

export const getCardInfo = () => {
  return get("/payments/braintreecard");
};

export const getSmileandlearnSubscription = () => {
  return get("/smileandlearns");
};

export const startSmileandlearnSubscription = (data) => {
  return post("/smileandlearns", data);
};

export const cancelSmileandlearnSubscription = () => {
    return remove(`/smileandlearns/1`);
};

export const getRecommendation = () => {
  return get("/recommendations");
};

