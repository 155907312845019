import { post, remove, put} from "./Api";

export const signOut = () => {
    return post("/logout");
};

export const requestTutor = (data) => {
    return post("/requests", data);
};

export const scheduleLesson = (data) => {
    return post("/transactions", data);
};

export const deleteTutor = (id) => {
    return remove(`/connections/${id}`);
};

export const reviewTutor = (tutor_id, data) => {
    return post(`/connections/${tutor_id}`, data);
};

export const cancelLesson = (data, id) => {
    return post(`/transactions/${id}`, data);
};

export const cancelSubscription = (data) => {
    return post("/cancel_reason", data);
};

export const getAvailability = (data) => {
    return post("/transactions/availability", data);
};

export const createMessage = (data) => {
    return post("/messages", data);
};

export const sendNoneToServer = (data) => {
    return post("/payments/braintreecard", data);
};
