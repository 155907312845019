
export const RightMessages = [
    "Great job! 🎉 You nailed it! 💯",
    "Perfect! 🌟 You're on fire! 🔥",
    "Excellent! 🙌 You're a pro at this!",
    "Well done! 👏 You're crushing it! 💪",
    "Awesome! 🥳 Keep up the fantastic work! 🚀",
    "Outstanding! 🌈 You've got the hang of it! 🌟",
    "Superb! 🎊 You're acing this quiz! 🌟",
    "Terrific! 🎉 You're a quick learner! 🚀",
    "Impressive! 🙌 You've got the right answers! 💯",
    "Amazing! 🎉 Keep going, you're doing great! 👍🏼"
]

export const WrongMessages = [
    "Don't worry, you've got this! 🌟 Keep trying!",
    "It's okay, mistakes happen! 🤗 Learning is a journey!",
    "No problem! 😊 You'll get it next time!",
    "Almost there! 👍 Keep going, you're doing great!",
    "Not quite, but you're getting closer! 🎯 Keep pushing!",
    "You're on the right track! 🌈 Keep going!",
    "Almost! Don't give up! 💪 You can do it!",
    "Keep trying! 🚀 Every attempt is progress!",
    "Not the right answer, but you're learning! 🌟",
    "Don't be discouraged! 😃 Learning is all about growth! 🌱"
]

export const FLASHCARD_NUM = 10
