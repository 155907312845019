import coin from '../../Assets/Icon/coin.svg';
import cup from '../../Assets/Icon/cup.svg';
import ping from '../../Assets/Icon/ping.svg';
import profile from '../../Assets/Icon/profile.svg';
import envelope from '../../Assets/Icon/envelope.svg';
import menu from '../../Assets/Icon/menu.svg';
import idea from '../../Assets/Icon/idea.svg';

export const Menus = [
    {
        id: 1, name: 'My Tutors', link: '/', opened: false, children: [], icon: profile, subItemActive: false,
    },
    {
        id: 2, name: 'My Lessons', link: '', opened: false, icon: menu, subItemActive: false,
        children: [
            { name: 'Pending', link: '/pending-lesson' },
            { name: 'Completed', link: '/completed-lesson' },
        ],
    },
    {
        id: 3, name: 'Your Plan', link: '/your-plan', opened: false, children: [], icon: cup, subItemActive: false,
    },
    {
        id: 4, name: 'Message', link: '/message', opened: false, children: [], icon: envelope, subItemActive: false,
    },
    {
        id: 5, name: 'Review Tutors', link: '/review-tutors', opened: false, children: [], icon: ping, subItemActive: false,
    },
    {
        id: 6, name: 'Payment', link: '', opened: false, icon: coin, subItemActive: false,
        children: [
            { name: 'Receipt', link: '/receipt' },
            { name: 'Cancel Subscriptions', link: '/cancel-subscriptions' },
            { name: 'Credit Card', link: '/credit-card' },
        ],
    },
    {
        id: 7, name: 'Online Demo', link: '/online-demo', opened: false, children: [], icon: idea, subItemActive: false,
    },
    {
        id: 8, name: 'Referral', link: '/referral', opened: false, children: [], icon: profile, subItemActive: false,
    },
];

export const MIMSMenus = [
    {
        id: 1, name: 'Dashboard', link: '/make-it-make-sense/dashboard', opened: false, children: [], icon: menu, subItemActive: false,
    },
    {
        id: 2, name: 'Plans', link: '/make-it-make-sense/plans', opened: false, children: [], icon: cup, subItemActive: false,
    },
    {
        id: 3, name: 'Reviews', link: '/make-it-make-sense/reviews', opened: false, children: [], icon: ping, subItemActive: false,
    },
    {
        id: 4, name: 'Referral Link', link: '/make-it-make-sense/referral-link', opened: false, children: [], icon: profile, subItemActive: false,
    },
]