// import { createStore, applyMiddleware } from 'redux';
// import createSagaMiddleware from 'redux-saga';
// import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';

// import rootReducer from './Reducers';
// import sagas from './Sagas';

// const persistConfig = {
//   key: 'root',
//   storage,
//   blacklist: ['Lesson', 'Modal', 'Sidebar', 'Instructor', 'Payment', 'Review', 'Chat', 'Plan']
// }

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// export default function configureStore(initialState = {}) {
//   const sagaMiddleware = createSagaMiddleware({});

//   const store = createStore(
//     persistedReducer,
//     initialState,
//     applyMiddleware(sagaMiddleware),
//   );

//   // Extensions
//   store.runSaga = sagaMiddleware.run(sagas);
//   store.injectedReducers = {}; // Reducer registry
//   store.injectedSagas = {}; // Saga registry

//   let persistor = persistStore(store);

//   return { store, persistor }
// }


import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

import rootReducer from "./Reducers";

export const store = createStore(rootReducer, applyMiddleware(thunk));
store.subscribe(() => console.log(store.getState()));
