import React from "react";
import './style.scss';
import ConceptDisabledIcon from 'src/Assets/MIMS/Concept-Disabled.png';
// import RadioGreenMinus from 'src/Assets/MIMS/Concept/RadioGreenMinus.png';
// import RadioGreenCheck from 'src/Assets/MIMS/Concept/RadioGreenCheck.png';
// import RadioRed from 'src/Assets/MIMS/Concept/RadioRed.png';
import _ from 'lodash';
import { useSelector } from "react-redux";

export default function ConceptTestTracking({assessment}) {

    const {
        assessment_result      
    } = useSelector(state => state.Mims)

    const showActiveConcept = () => (!_.isEmpty(assessment_result) || !_.isEmpty(assessment.question_summary))

    function ConceptDisabledComponent() {
        return (
            <div className="concept-wrapper">
                {
                    Object.keys(assessment.concepts).map((item, index) => (
                        <div className="item inactive" key={index}>
                            <img src={ConceptDisabledIcon} className="icon"/>
                            <div className="content">
                                <div className="label-wrapper">
                                    <div className="label">{item}</div>
                                    <div className="number"><span style={{
                                        fontWeight: '600', 
                                        color: '#252766',
                                        opacity: '0.5'
                                        }}>{assessment.concepts[item].correct} </span>/ {assessment.concepts[item].total}</div>
                                </div>
                                <div className="bar"></div>
                            </div>
                        </div>
                    ))
                }
            </div>
        )
    }

    function ConceptActiveComponent() {
        const anlysisList = [...assessment.feedback_analysis.sort((a, b) => a.max < b.max ? 1 : -1)]
        let greenMark = anlysisList[0].min;
        let lightMark = anlysisList[1].min;
        let yellowMark = anlysisList[2].min;
        let orangeMark = anlysisList[3].min;
        const concepts = !_.isEmpty(assessment_result) ? assessment_result.concepts : assessment.concepts
       
        function ConceptIcon({item}) {
            let percentCorrect = calculatePercentage(item)
            if (percentCorrect >= greenMark) {
                return (<div className="icon-box" style={{backgroundColor: '#24C1A5'}}>A</div>)
            } else if (percentCorrect >= lightMark) {
                return (<div className="icon-box" style={{backgroundColor: '#8ed37d'}}>B</div>)
            } else if (percentCorrect >= yellowMark) {
                return (<div className="icon-box" style={{backgroundColor: '#ffdf6e'}}>C</div>)
            } else if (percentCorrect >= orangeMark) {
                return (<div className="icon-box" style={{backgroundColor: '#f08c18'}}>D</div>)
            } else if (item.pending === item.total) {
                return (<img src={ConceptDisabledIcon} style={{ marginRight: '15px'}}/>)
            } else {
                return(<div className="icon-box" style={{backgroundColor: '#F92672'}}>F</div>)
            }
        }

        const className = (item) => {
            let percentCorrect = calculatePercentage(item)
            if (percentCorrect >= greenMark) {
                return 'passed';
            } else if (percentCorrect >= lightMark) {
                return 'light';
            } else if (percentCorrect >= yellowMark) {
                return 'yellow';
            } else if (percentCorrect >= orangeMark) {
                return 'orange';
            } else if (item.pending === item.total) {
                return 'pending';
            } else {
                return 'failed';
            }
        }

        const calculatePercentage = (item) => {
            return (item.correct / item.total) * 100
        }

        const progressBarPercentage = (item) => {
            if (item.correct !== 0) {
                return (item.correct / item.total) * 100
            } else if (item.pending === item.total) {
                return 0
            } else {
                return 100
            }
        }

        return (
            <div className="concept-wrapper">
                {
                    Object.keys(concepts).map((item, index) => (
                        <div className="item active" key={index}>
                             <ConceptIcon item={concepts[item]}/>
                             <div className="content">
                                <div className="label-wrapper">
                                    <div className="label">{item}</div>
                                    <div className="number"><span style={{
                                        fontWeight: '600', 
                                        color: '#252766',
                                        opacity: '0.5'
                                        }}>{concepts[item].correct} </span>/ {concepts[item].total}</div>
                                </div>
                                <div className="bar">
                                    <div className={`overlay-progress ${className(concepts[item])}`} style={{ 
                                        width: `${progressBarPercentage(concepts[item])}%`,
                                        height: '100%'
                                        }}></div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        )
    }

    return (
        <div className="concept-test-tracking card">
            <h4>Concept Performance Metrics</h4>
            <div className="concept-sec">
                { !showActiveConcept() && <ConceptDisabledComponent /> }
                { showActiveConcept() && <ConceptActiveComponent /> }
            </div>
            {/* <div className="d-flex justify-content-center">
                <div className="loadmore">LOAD MORE</div>
            </div> */}
        </div>
    )
}