import {
  GET_TOPIC_LIST, GET_TOPIC_LIST_SUCCESS, GET_TOPIC_LIST_FAIL,
  SET_CURRENT_TOPIC,
} from '../Type';

import {
  getTopicList,
} from "src/Api";

export const getTopicListAction = () => async (dispatch) => {
  dispatch({ type: GET_TOPIC_LIST });
  const res = await getTopicList();
  if (res && res.data) {
    dispatch({ type: GET_TOPIC_LIST_SUCCESS, payload: res.data });
  } else {
    dispatch({ type: GET_TOPIC_LIST_FAIL, payload: res.data });
  }
};

export const setCurrentTopic = (currentTopic) => {
  return {
    type: SET_CURRENT_TOPIC,
    data: { currentTopic }
  }
}