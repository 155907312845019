import React, { useEffect, useMemo, useState } from "react";
import './style.scss';
import WeekMore from 'src/Assets/MIMS/Planner/button.png';
// import RadioGreenMinus from 'src/Assets/MIMS/Concept/RadioGreenMinus.png';
import RadioGreenCheck from 'src/Assets/MIMS/Concept/RadioGreenCheck.png';
import RadioRed from 'src/Assets/MIMS/Concept/RadioRed.png';
import ConceptLockedIcon from 'src/Assets/MIMS/Quiz/ConceptLocked.png';
import _ from 'lodash';
import { RightMessages, WrongMessages } from "../../Quiz/Info";
import { useDispatch, useSelector } from "react-redux";
import { 
    getQuestionsAction, 
    getTestIdAction, 
    setActiveAssessmentAction, 
    setActiveFlashcard, 
    toggleAssessmentModal, 
    toggleQuestionModal, 
    toggleQuizModal } from "src/Redux/Actions";
import { SET_ACTIVE_CONCEPT, SET_PLANNER_ACTIVE_DAY } from "src/Redux/Type";

export default function PlannerActivity({planner}) {
    const { topicList } = useSelector(state => state.Topic)
    const dispatch = useDispatch()
    const [dayProgress, setDayProgress] = useState(0)
    const {
        active_day
    } = useSelector(state => state.Mims)

    const week_data = Object.values(planner.week_summary)[0];
    const week_name = Object.keys(planner.week_summary)[0]

    useEffect(() => {
        setDayProgress(calculateDayProgress())
    }, [active_day])

    const isActiveDay = (day) => day.toLowerCase() === active_day.toLowerCase()

    const getPossitiveMessage = () => RightMessages[Math.floor(Math.random() * RightMessages.length)]
    const getNegativeMessage = () => WrongMessages[Math.floor(Math.random() * WrongMessages.length)]

    const computePercentage = (value, total) => (value / total) * 100

    const getActiveIndex = () => {
        const activeIndex = Object.keys(week_data).findIndex(item => {
            return isActiveDay(item)
        })
        return activeIndex
    }

    const trimTopic = (text) => {
        let edited = text.toUpperCase().replaceAll('MAKE', '')
        return (edited.replaceAll('SENSE', '').trim())
    }

    const onChangeActiveDay = (day) => dispatch({type: SET_PLANNER_ACTIVE_DAY, data: { day }})

    const todayAssignment = Object.values(week_data)[getActiveIndex()]

    const getWeekDisplayStatus = (index) => {
        let current_week = planner.current_week
        if (index < current_week) {
            return 'passed';
        } else if (index === current_week) {
            return 'active';
        } else {
            return '';
        }
    }

    const calculateDayProgress = () => {
        const assessments = []
        const flashcards = []
        Object.keys(todayAssignment).forEach(item => {
            let data = todayAssignment[item]
            assessments.push(...data.assessments)
            flashcards.push(...data.flashcards)
        })
        const passed_assessments = assessments.filter(item => {
            let summary = item.question_summary;
            return !_.isEmpty(summary) && summary.correct >= (summary.total / 2)
        })
        const passed_flashcards = flashcards.filter(item => {
            let summary = item.summary;
            return !_.isEmpty(summary) && summary.correct >= (summary.total / 2)
        })
        let totalPassed = passed_assessments.length + passed_flashcards.length
        let totalAssignment = assessments.length + flashcards.length
        return computePercentage(totalPassed, totalAssignment)
    }

    function WeekDayStat({day, index}) {
        const getStatus = () => {
            if (index === getActiveIndex()) {
                return 'passed'
            } else return ''
        }

        return (
            <div className={`day-item-stat ${getStatus()}`} onClick={() => onChangeActiveDay(day)}>
                <div className="day-info">
                    { isActiveDay(day) ? <img src={RadioGreenCheck} className="active-icon"/> : <img src={ConceptLockedIcon} />}
                    <span>{day}</span>
                </div>
               {
                    index === getActiveIndex() && (
                        <>
                             <div className="progress-stat">
                                <div className="motiv-message">{getPossitiveMessage()}</div>
                                <div className="score">{dayProgress.toFixed(0)}%</div>
                            </div>
                            <div className="progress-visual">
                                <div className="bar">
                                    <div className={`overlay-progress ${getStatus()}`} style={{ width: `${dayProgress}%` }}></div>
                                </div>
                            </div>
                        </>
                    )
               }
            </div>
        )
    }

    function AssessmentComponent({assessment}) {
        const testTime = assessment.test_time ? (assessment.test_time / 60).toFixed(0) : '30'
        const summary = assessment.question_summary;
        const asmtHasSummary = () => !_.isEmpty(summary)
        const asmtPercentage = asmtHasSummary() ? computePercentage(summary.correct, summary.total) : 0

        const onAssessmentStart = () => {
            dispatch(setActiveAssessmentAction(assessment))
            dispatch(toggleAssessmentModal(true))
        } 

        const getAsmtIcon = () =>  {
            if (!asmtHasSummary()) {
                return ConceptLockedIcon
            } else if (asmtPercentage >= 50) {
                return RadioGreenCheck
            } else return RadioRed
        }

        const getAsmtStatus = () => {
            if (!asmtHasSummary()) {
                return ''
            } else if (asmtPercentage >= 50) {
                return 'passed'
            } else return 'failed'
        }

        const getAsmtMessage = () => getAsmtStatus() === 'passed' ? getPossitiveMessage() : getNegativeMessage()

        return (
            <div className="assign-item-wrapper">
                <div className={`assign-item ${getAsmtStatus()}`}>
                    <div className="info-row">
                        <img src={getAsmtIcon()}/>
                        <div className="homework-info">
                            <div className="name">{assessment.name}</div>
                            <div className="details">
                                <div><span>{assessment.questions_count}</span> Questions | <span>{testTime}</span> minutes</div>
                            </div>
                        </div>
                    </div>
                    <div className="primary-btn" onClick={() => onAssessmentStart()}>{asmtHasSummary() ? 'Re: Start' : 'Start'}</div>
                </div>
                { asmtHasSummary() && <div className="motiv-message">{getAsmtMessage()}</div>}
            </div>
        )
    }

    function FlashcardComponent({flashcard}) {
        const summary = flashcard.summary;
        const cardHasSummary = () => !_.isEmpty(summary)
        const cardPercentage = cardHasSummary() ? computePercentage(summary.correct, summary.total) : 0

        const onFlashcardStart = () => {
            dispatch(setActiveFlashcard(flashcard))
            dispatch({type: SET_ACTIVE_CONCEPT, data: { concept: flashcard.concept }})
            dispatch(toggleQuizModal(true))
        }

        const getCardIcon = () =>  {
            if (!cardHasSummary()) {
                return ConceptLockedIcon
            } else if (cardPercentage >= 50) {
                return RadioGreenCheck
            } else return RadioRed
        }

        const getCardStatus = () => {
            if (!cardHasSummary()) {
                return ''
            } else if (cardPercentage >= 50) {
                return 'passed'
            } else return 'failed'
        }

        const getCardMessage = () => getCardStatus() === 'passed' ? getPossitiveMessage() : getNegativeMessage()

        const getFlashcardSubMessage = () => !_.isEmpty(flashcard.concept) ? `Practice your <span>${flashcard.concept}</span> Flashcards` : `<span>${flashcard.flashcards_count}</span> Flashcards`

        return (
            <div className="assign-item-wrapper">
                <div className={`assign-item ${getCardStatus()}`}>
                    <div className="info-row">
                        { <img src={getCardIcon()} />}
                        <div className="homework-info">
                            <div className="name">{flashcard.name}</div>
                            <div className="details">
                                <div dangerouslySetInnerHTML={{ __html: getFlashcardSubMessage()}}></div>
                            </div>
                        </div>
                    </div>
                    <div className="primary-btn" onClick={() => onFlashcardStart()}>{cardHasSummary() ? 'Re: Start' : 'Start'}</div>
                </div>
                { cardHasSummary() && <div className="motiv-message">{getCardMessage()}</div>}
            </div>
        )
    }

    const showTopic = (item) => !_.isEmpty(todayAssignment[item].assessments) || !_.isEmpty(todayAssignment[item].flashcards) || !_.isEmpty(todayAssignment[item].mims_questions)

    function MimsQuestionBank({mims}) {

        const {
            id,
            question_answered,
            questions_count,
            have_access,
            correct
          } = mims;

          const mixQuestions = (wrong, pending, correct) => {
            var questions = [];
            let extraCount = wrong.length === 0 ? 15 : wrong.length <= 5 ? 15 - wrong.length : 10
            const extraQuestions = pending.length > 0 ? pending.slice(0, extraCount) : correct.slice(0, extraCount)
            questions = wrong.slice(0, 5).concat(extraQuestions)
            return questions;
        }

        const shuffle = (array) => {
            let currentIndex = array.length,  randomIndex;
            while (currentIndex > 0) {
              randomIndex = Math.floor(Math.random() * currentIndex);
              currentIndex--;
              [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
            }
            return array;
          }

        const test_concept = useMemo(() => {
            const concept_name = mims.concept
            let topic = topicList.find(item => {
                return item.id == mims.id
            })
            if (_.isEmpty(topic)) return null
            const { concept_pending, concept_wrong, concept_correct } = topic
            var wrong_questions = [];
            var pending_questions = [];
            var correct_questions = [];
            concept_wrong.forEach(item => {
                wrong_questions.push(...item.question_ids)
            })
            concept_pending.forEach(item => {
                pending_questions.push(...item.question_ids)

            })
            concept_correct.forEach(item => {
                correct_questions.push(...item.question_ids)
            })
            wrong_questions = shuffle(wrong_questions)
            pending_questions = shuffle(pending_questions)
            correct_questions = shuffle(correct_questions)
            return { name: concept_name, question_ids: mixQuestions(wrong_questions, pending_questions, correct_questions ) }

        }, [mims.id])

        const concept = {...test_concept, question_ids: test_concept.question_ids.slice(0, 15) }

        const onStartQuestions = () => {
            if (Number(questions_count) > 0) {
              dispatch(toggleQuestionModal(true, { topic_id: id, have_access, concept, question_answered }));
              dispatch(getQuestionsAction(id));
              dispatch(getTestIdAction({ topic_id: id }))
            };
          }

          const getMimStatus = () => {
            if (_.isEmpty(question_answered)) return ''
            return (question_answered.length / 2) <= correct ? 'passed' : 'failed';
          }

          const getMimsMessage = () => getMimStatus() === 'passed' ? getPossitiveMessage() : getNegativeMessage()

          const getMimsPracticeMessage = () => !_.isEmpty(test_concept) ? `Test yourself with these <span>${concept.question_ids.length}</span> Questions` : ''

        return (
            <div className="assign-item-wrapper">
                <div className={`assign-item ${getMimStatus()}`}>
                    <div className="info-row mims">
                        { !_.isEmpty(mims.picture) ? <img src={`${process.env.REACT_APP_IMAGE_DOMAIN}${mims.picture}`} /> : <img src={ConceptLockedIcon}/>}
                        <div className="homework-info">
                            <div className="name">{mims.name}</div>
                            <div className="details">
                                <div dangerouslySetInnerHTML={{ __html: getMimsPracticeMessage() }}></div>
                            </div>
                        </div>
                    </div>
                    <div className="primary-btn" onClick={() => onStartQuestions()}>Start</div>
                </div>
                { !_.isEmpty(question_answered) && <div className="motiv-message">{getMimsMessage()}</div>}
            </div>
        )
    }


    return (
        <div className="planner-activity">
            <div className="content">
                <div className="week-display">
                    {
                       <>
                          {
                             Array(planner.week_count)
                             .fill(true)
                             .map((item, index) => (
                                <div className={`week-card ${getWeekDisplayStatus(index + 1)}`} key={index}>W {index + 1}</div>
                             ))
                          }  
                          {/* <img src={WeekMore} className="more"/> */}
                       </>
                    }
                </div>
                <div className="activities-content">
                    <div className="row">
                        <div className="col-lg-4 left-section">
                            <div className="weekdays card">
                                <h3 className="title">{week_name}</h3>
                                {
                                    Object.keys(week_data).map((item, index) => (
                                        <WeekDayStat day={item} key={index} index={index}/>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="col-lg-8 right-section">
                            <div className="days-homework card">
                               <h3 className="title">Assignment for the day</h3>
                               <div className="exam-sec mb-3">
                                    {
                                        Object.keys(todayAssignment).map((item, index) => (
                                           <>
                                             { showTopic(item) && <div className="subject" key={index}>{trimTopic(item)}</div>}
                                             {
                                                todayAssignment[item].assessments.map((asmt, index2) => (
                                                    <AssessmentComponent 
                                                        assessment={asmt} 
                                                        key={index2 + 0.1}
                                                        />
                                                ))
                                             }
                                             {
                                                todayAssignment[item].flashcards.map((card, index3) => (
                                                    <FlashcardComponent 
                                                        flashcard={card}
                                                        key={index3 + 0.3}
                                                        active={index3 === 0}
                                                    />
                                                ))
                                             }
                                             { todayAssignment[item].mims_questions.map((mims, index) => (
                                                <MimsQuestionBank mims={mims} key={index}/>
                                             ))}
                                           </>
                                        ))
                                    }
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}