import React from 'react';
import './ReviewTutorsPage.scss';
import moment from 'moment';
import StarRatings from 'react-star-ratings';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from "react-toastify";
import autosize from 'autosize';
import { ReactSVG } from 'react-svg';
import { InputBox, Avatar } from 'src/Components';
import { TutorList } from './TutorList';
import search from 'src/Assets/Icon/search.svg';
import send from 'src/Assets/Icon/send.svg';
import { StarSVGViewBox, StarSVGIconPath } from '../../Constant';
import {
  getMyReviewsAction,
  reviewTutorAction,
} from "../../Redux/Actions";
import { showSpinner, hideSpinner } from 'src/Util/Helper';

function innerFunc(item) {
  return { __html: item.content }
}
const tutorResponse = {
  content: `
        Hi Alex Tyshchenko,<br /><br />
        Thanks for taking the time to leave me a review. I know you have choices when it comes to your tutoring needs and thanks again for choosing to work with me.<br /><br />
        Most families outside the Grade Getter community have yet to know the amazing work me and other Frog Tutors do in the Fort Worth, TX area. Can you please take a minute of your time to copy and paste your recent review below or a new one on any of our review pages listed below; I will get a bonus of $10 for any positive reviews I get on yelp. <br /><br />
        Your recent review:<br />
        “Wonderful Tutor!”<br /><br />
        Please click links below to leave your reviews: <br />
        <a href="https://www.facebook.com/">Facebook</a> <br />
        Or <br /><br />
        <a href="https://www.yelp.com/">Yelp</a><br />
        <a href="https://www.bbb.org//">BBB (Better Business Bureau)</a><br />
        <a href="https://www.google.com/">Google local</a><br /><br /><br /><br />
        Thank you for sharing.<br /><br />    
        Sincerely,<br />
        Gera O
    `,
}
class ReviewTutorsPage extends React.Component {
  state = {
    Tutors: TutorList,
    searchText: '',
    depth: 0,
    selectedTutor: {},
    reviewText: '',
    editReview: false,

    knowledge: 0,
    presentation: 0,
    connection_id: ''
  }

  componentDidMount() {
    this.props.getMyReviewsAction();
    this.setState({
      depth: this.props.depth
    })
  }

  componentDidUpdate() {
    autosize(this.textarea);
  }

  static getDerivedStateFromProps(props, state) {
    return {
      depth: props.depth
    }
  }

  getInitial = (name) => {
    if (!name) return 'AL';
    const splited = name.split(' ');
    return splited[0][0] + (splited[1].length > 0 ? splited[1][0] : splited[0][1]);
  }

  selectTutor = (item) => {
    let temp = item || {};
    // temp.review = item.review_content || { text: '', date: '', knowledge: 0, presentation: 0}
    this.setState({
      selectedTutor: temp,
      reviewText: temp.review_content,
      editReview: false,

      knowledge: temp.rating1,
      presentation: temp.rating2,
      connection_id: item.id
    });
    this.props.updateTitle('push', item.tutor.name, 2);
  }

  sendReview = () => {
    let { Tutors, selectedTutor, reviewText, knowledge, presentation } = this.state;
    if (reviewText.length === 0) {
      toast("Please type your review");
    } else {
      showSpinner();
      const formData = new FormData()
      formData.append('rating1', this.state.knowledge);
      formData.append('rating2', this.state.presentation);
      formData.append('description', this.state.reviewText);
      formData.append('_method', 'PUT');
      const payload = {
        rating1: this.state.knowledge,
        rating2: this.state.knowledge,
        description: this.state.reviewText,
      }
      this.props.reviewTutorAction(this.state.connection_id, formData).then(() => {
        if (this.props.reviewTutorStatus) {
          this.props.getMyReviewsAction().then(() => {
            if (this.props.myReviewsInfo) {
              selectedTutor = {
                id: selectedTutor.id,
                rating1: knowledge,
                rating2: presentation,
                review_content: reviewText,
                student: {
                  name: selectedTutor.student.name
                },
                tutor: {
                  name: selectedTutor.tutor.name
                },
                created_at: {
                  date: moment().format('YYYY-MM-DD HH:mm:ss')
                }
              }
              this.setState({
                selectedTutor: selectedTutor,
                editReview: false
              })
              hideSpinner();
            }
          })
        }
      })
    }

    // selectedTutor.review = {
    //     text: reviewText,
    //     knowledge: knowledge,
    //     presentation: presentation,
    //     date: moment().format('YYYY-MM-DD HH:mm:ss')
    // }
    // let index = Tutors.findIndex(item => item.id === selectedTutor.id);
    // Tutors[index] = selectedTutor;
    // this.setState({
    //     Tutors: Tutors,
    //     selectedTutor: selectedTutor,
    //     editReview: false
    // })
  }

  deleteReview = () => {
    let { Tutors, selectedTutor } = this.state;
    selectedTutor.review = {
      text: '',
      knowledge: 0,
      presentation: 0,
      date: ''
    }
    let index = Tutors.findIndex(item => item.id === selectedTutor.id);
    Tutors[index] = selectedTutor;
    this.setState({
      Tutors: Tutors,
      selectedTutor: selectedTutor,
      editReview: false,
      reviewText: '',
      knowledge: 0,
      presentation: 0
    })
  }

  render() {
    const { myReviewsInfo } = this.props
    const { searchText, depth, selectedTutor, reviewText, editReview, knowledge, presentation, Tutors } = this.state;
    if (myReviewsInfo) {
      var list = myReviewsInfo.filter(item => item.tutor.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1);
    }
    return (
      <div className="reviewtutors-page">
        <div className={`tutor-list scroll-area v-r ${depth === 0 ? 'activate' : ''}`}>
          <InputBox className="search-contact" value={searchText} icon={search} placeholder="Search..." onChange={val => this.setState({ searchText: val })} />
          <div className="tutors v-r scroll-area">
            {list && list.map((item, index) => <div className={`tutor-container ${selectedTutor.id === item.id ? 'selected' : ''}`} key={index} onClick={e => this.selectTutor(item)}>
              <div className="tutor">
                <Avatar info={item.tutor} className="avatar" />
                <div className="info v-r">
                  <div className="name-area v-c">
                    <h4>{item.tutor.name}</h4> {item.review_content && <p>{moment(item.created_at.date).format('DD MMM')}</p>}
                  </div>
                  <p>{item.review_content ? item.review_content : 'No Review'}</p>
                </div>
              </div>
            </div>)}
          </div>
        </div>
        <div className={`review-area scroll-area v-r ${depth === 1 ? 'activate' : ''}`}>
          {depth === 1 && <div className="review-content v-r">
            <p className="tutor-name show-web">{selectedTutor.tutor.name}</p>
            {(!selectedTutor.review_content || editReview) && <div className="compose-review v-r">
              <div className="star-area v-c h-c v-r">
                <h4>{editReview ? 'Publish a new' : 'Edit'} review about <span>{selectedTutor.tutor.name}</span></h4>
                <p>Knowledge of content</p>
                <StarRatings
                  rating={knowledge}
                  starDimension="32px"
                  starSpacing="4.5px"
                  starRatedColor="#23A4EF"
                  starHoverColor="#23A4EF"
                  starEmptyColor="#D3EDFC"
                  svgIconViewBox={StarSVGViewBox}
                  svgIconPath={StarSVGIconPath}
                  changeRating={(val, name) => this.setState({ [name]: val })}
                  name="knowledge"
                />
                <p>Presentation of content</p>
                <StarRatings
                  rating={presentation}
                  starDimension="32px"
                  starSpacing="4.5px"
                  starRatedColor="#23A4EF"
                  starHoverColor="#23A4EF"
                  starEmptyColor="#D3EDFC"
                  svgIconViewBox={StarSVGViewBox}
                  svgIconPath={StarSVGIconPath}
                  changeRating={(val, name) => this.setState({ [name]: val })}
                  name="presentation"
                />
              </div>
              <div className="review-input v-c">
                <textarea className="review-text" rows={1} ref={c => (this.textarea = c)} placeholder={`Type your review about ${selectedTutor.tutor.name}`} value={reviewText} onChange={e => this.setState({ reviewText: e.target.value })} />
                <ReactSVG src={send} className="svg-icon" onClick={e => this.sendReview()} />
              </div>
            </div>}
            {(selectedTutor.review_content && !editReview) && <div className="view-review v-r">
              <div className="review-message">
                <div className="v-c h-c initial">{this.getInitial(selectedTutor.student.name)}</div>
                <div className="review-info v-r">
                  <div className="explain">
                    You submitted a review about <span>{selectedTutor.tutor.name}</span> {moment(selectedTutor.created_at.date).format("hh:mm A")}
                  </div>
                  <div className="my-review">
                    <div className="feedback-area">
                      <div className="feedback v-c">
                        <label>Knowledge: </label>
                        <StarRatings
                          rating={selectedTutor.rating1}
                          starDimension="15px"
                          starSpacing="2px"
                          starRatedColor="#23A4EF"
                          starEmptyColor="#D3EDFC"
                          svgIconViewBox={StarSVGViewBox}
                          svgIconPath={StarSVGIconPath}
                        />
                      </div>
                      <div className="feedback v-c">
                        <label>Presentation: </label>
                        <StarRatings
                          rating={selectedTutor.rating2}
                          starDimension="15px"
                          starSpacing="2px"
                          starRatedColor="#23A4EF"
                          starEmptyColor="#D3EDFC"
                          svgIconViewBox={StarSVGViewBox}
                          svgIconPath={StarSVGIconPath}
                        />
                      </div>
                    </div>
                    <p>{selectedTutor.review_content}</p>
                  </div>
                  <div className="v-c btn-area">
                    <button className="btn-2 btn-2-outline edit-btn" onClick={e => this.setState({ editReview: true })}>Edit</button>
                    {/* <button className="btn-2 btn-2-outline del-btn" onClick={e => this.deleteReview()}>Delete</button> */}
                  </div>
                </div>
              </div>
              {((selectedTutor.rating1 + selectedTutor.rating2) / 2 >= 4) && <div className="review-message">
                <div className="v-c h-c initial"><Avatar info={selectedTutor.tutor} className="avatar" /></div>
                <div className="review-info v-r">
                  <div className="explain">
                    {selectedTutor.tutor.name}, {moment(selectedTutor.created_at.date).format("hh:mm A")}
                  </div>
                  {/* <p dangerouslySetInnerHTML={innerFunc(tutorResponse)} className="message"/> */}
                  <p>Hi {selectedTutor.student.name},</p>
                  <br />
                  <p>Thanks for taking the time to leave me a review. I know you have choices when it comes to your tutoring needs and thanks again for choosing to work with me.</p>
                  <br />
                  <p>Most families outside the GradeGetter community have yet to know the amazing work me and other GradeGetter Tutors do. Can you please take a minute of your time to copy and paste your recent review below or a new one on any of our review pages listed below; I will get a bonus of up to  $10 for any positive reviews I get on yelp.</p>
                  <br />
                  <p>Your recent review:</p>
                  <p>{selectedTutor.review_content}</p>
                  <br />
                  <p>Please click links below to leave your reviews:</p>
                  <a href="https://www.facebook.com/">Facebook</a> <br />
                  <br /><br />
                  <p>Thank you for sharing.</p>
                  <br /><br />
                  <p>Sincerely,</p>
                  <p>{selectedTutor.tutor.name}</p>
                </div>
              </div>}
            </div>}
          </div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  myReviewsInfo: state.Core.myReviewsInfo,
  reviewTutorStatus: state.Auth.reviewTutorStatus,
});

export default connect(mapStateToProps, {
  getMyReviewsAction,
  reviewTutorAction,
})(withRouter(ReviewTutorsPage));