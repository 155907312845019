import React from 'react';
import './style.scss';
import facebook from 'src/Assets/Icon/Social/facebook-logo.png';
import linkedin from 'src/Assets/Icon/Social/linkedin-logo.png';
import twitter from 'src/Assets/Icon/Social/twitter-logo.png';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

export default function SocialSharing({
  className='',
  ShareURL
}) {

  return (
    <div className={`sharing-area ${className}`}>
      <FacebookShareButton url={ShareURL} className="share-btn" >
        <div className='share-logo'>
          <img src={facebook} alt="facebook" />
        </div>
      </FacebookShareButton>
      <LinkedinShareButton url={ShareURL} className="share-btn">
        <div className='share-logo'>
          <img src={linkedin} alt="linkedin" />
        </div>
      </LinkedinShareButton>
      <TwitterShareButton url={ShareURL} className="share-btn" >
        <div className='share-logo'>
          <img src={twitter} alt="twitter" />
        </div>
      </TwitterShareButton>      
    </div>
  );
}
