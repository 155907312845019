import React from 'react';
import './CompletedLessonPage.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { InputBox, RoundButton, SelectBox } from '../../Components';
import search from '../../Assets/Icon/search.svg';
import left from '../../Assets/Icon/left.svg';
import right from '../../Assets/Icon/right.svg';
// import video from '../../Assets/Video/video.mp4';
import play_button from '../../Assets/Icon/play-button.svg';
import moment from 'moment';
import { ReactSVG } from 'react-svg';
import { toggleVideoModal } from '../../Redux/Actions';
import _ from 'lodash'
import {
  getCompletedLessonAction
} from "../../Redux/Actions";

class CompletedLessonPage extends React.Component {
  state = {
    id: '',
    data: [],
    filteredList: [],

    searchText: '',
    currentPage: 1,
    totalPage: 1,
    displayCount: 10,

    depth: 0,
    selectedLesson: '',
    current_timezone: moment.tz.guess(true)
  }

  static getDerivedStateFromProps(props, state) {
    const id = (props.match.params.id && props.match.params.id.length > 0) ? props.match.params.id : '';
    return {
      id: id,
      depth: props.depth
    };
  }

  componentDidMount() {
    const { current_timezone } = this.state;
    const timezone = moment.tz(moment.utc(), current_timezone).utcOffset() / 60;

    this.props.getCompletedLessonAction(timezone).then(() => {
      this.filterData('', 10);
      if (this.props.match.params.id) {
        const { completedLesson } = this.props
        var selectedLesson = _.find(completedLesson, { id: Number(this.props.match.params.id) })
        this.setState({ selectedLesson })
      }
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const prevID = (prevProps.match.params.id && prevProps.match.params.id.length > 0) ? prevProps.match.params.id : '';
    const newID = (this.props.match.params.id && this.props.match.params.id.length > 0) ? this.props.match.params.id : '';
    if (prevID === newID && prevID !== '' && newID !== '') {
      if (this.state.depth !== 1 && prevState.depth === 0) {
        // let index = this.state.data.findIndex(item => item.id === parseInt(newID, 10));
        // this.setState({selectedLesson: this.state.data[index]});
        this.props.updateTitle('push', 'Completed Lessons', 2);
      }
    }
  }

  changeDisplayCount = (val) => {
    const { searchText } = this.state;
    this.filterData(searchText, val.name);
  }

  changeSearchText = (val) => {
    const { displayCount } = this.state;
    this.filterData(val, displayCount);
  }

  changeCurrentPage = (direction) => {
    const { currentPage, totalPage } = this.state;
    if ((direction === 'previous' && currentPage === 1) || (direction === 'next' && currentPage === totalPage)) return;
    this.setState({
      currentPage: direction === 'next' ? currentPage + 1 : currentPage - 1
    })
  }

  filterData = (searchText, displayCount) => {
    // const { data } = this.state;
    const { completedLesson } = this.props
    const filteredList = completedLesson.filter(item => item.tutor.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1);
    const totalPage = Math.ceil(filteredList.length / displayCount);

    this.setState({
      searchText: searchText,
      filteredList: filteredList,
      totalPage: totalPage,
      displayCount: displayCount,
      currentPage: 1
    })
  }

  viewItem = (item) => {
    this.props.history.push(`/completed-lesson/${item.id}`);
  }

  openVideoModal = (item) => {
    this.props.toggleVideoModal(true, item.actions);
  }

  navigateToTutor = (tutor_id) => {
    this.props.history.push(`/tutors/${tutor_id}`);
  }

  seeRecording = (item) => {
    if (item.recorded_online_link) {
      window.open(item.recorded_online_link);
    }
  }

  render() {
    // const {completedLesson} = this.props;
    const { searchText, displayCount, filteredList, currentPage, totalPage, depth, selectedLesson } = this.state;
    const startIndex = displayCount * (currentPage - 1);
    const displayList = filteredList.slice(startIndex, startIndex + displayCount);
    return (
      <div className="completedlesson-page v-r">
        {depth === 0 && <div className="options-area">
          <InputBox className="search-input" value={searchText} icon={search} placeholder="Search..." onChange={val => this.changeSearchText(val)} />
          <div className="count-area v-c">
            <RoundButton icon={left} className="show-mobile-flex" onClick={e => this.changeCurrentPage('previous')} />
            <p className="page-count">Page {currentPage} of {totalPage}</p>
            <RoundButton icon={left} className="arrow-btn show-web-flex" onClick={e => this.changeCurrentPage('previous')} />
            <RoundButton icon={right} className="arrow-btn" onClick={e => this.changeCurrentPage('next')} />
            <div className="combo-area v-c">
              <p>Items</p>
              <SelectBox value={displayCount} onChange={val => this.changeDisplayCount(val)} />
            </div>
          </div>
        </div>}
        {depth === 0 && <div className="data-area v-r">
          <div className="data-header show-web-flex v-c">
            <div className="cell cell-1">TUTOR</div>
            <div className="cell cell-2">SCHEDULE</div>
            <div className="cell cell-3">HOURS</div>
            <div className="cell cell-5">CANCELLED</div>
            <div className="cell cell-6">ACTIONS</div>
          </div>
          <div className="data-list show-web-flex v-r">
            {displayList.map((item, index) => <div className="data-row" key={index}>
              <div className="cell cell-1"><p style={{ cursor: 'pointer' }} onClick={e => this.navigateToTutor(item.tutor.id)}>{item.tutor.name}</p></div>
              <div className="cell cell-2"><span style={{ cursor: 'pointer' }} onClick={e => this.viewItem(item)}>{moment(item.sched).local().format('MMM DD, YYYY hh:mm a')}</span></div>
              <div className="cell cell-3">{item.hours}</div>
              <div className="cell cell-5">{item.cancel_time ? item.cancel_time : 'NO'}</div>
              <div className="cell cell-6">
                <div className={`v-c ${item.cancel_time ? '' : 'active'}`}>
                  {!item.cancel_time ? <React.Fragment>
                    <div className="v-c" onClick={e => this.seeRecording(item)}>
                      <ReactSVG src={play_button} className="svg-icon" /> {item.recorded_online_link ? "See Recording" : "No link to online session"}
                    </div>
                  </React.Fragment> : 'No link to online session'}
                </div>
              </div>
            </div>)}
          </div>
          <div className="show-mobile-flex v-r">
            {displayList.map((item, index) => <div className="mobile-row v-r" key={index}>
              <div className="data-row">
                <div className="left-col v-r"> <div className="col-header">TUTOR</div> <p className="active" onClick={e => this.navigateToTutor(item.tutor.id)}>{item.tutor.name}</p> </div>
                <div className="right-col v-r"> <div className="col-header">SCHEDULE</div> <p className="active" onClick={e => this.viewItem(item)}>{moment(item.sched).local().format('MMM DD, YYYY hh:mm a')}</p> </div>
              </div>
              <div className="data-row">
                <div className="left-col v-r"> <div className="col-header">HOURS</div> <p>{item.hours}</p> </div>
              </div>
              <div className="data-row">
                <div className="left-col v-r"> <div className="col-header">CANCELLED</div> <p>{item.cancelled ? 'YES' : 'NO'}</p> </div>
                <div className="right-col v-r">
                  <div className={`v-c ${item.cancel_time ? '' : 'active'}`}>
                    {!item.cancel_time ? <div className="v-c" onClick={e => this.seeRecording(item)}><ReactSVG src={play_button} className="svg-icon" /> {item.recorded_online_link ? "See Recording" : "No link to online session"}</div> : 'No link to online session'}
                  </div>
                </div>
              </div>
            </div>)}
          </div>
        </div>}
        {depth === 1 && <div className="completed-detail v-r">
          <h1 className="show-web">Tutoring Session</h1>
          <div className="detail-list v-r">
            <div className="detail-row v-c">
              <div className="detail-title">Tutor</div>
              <div className="detail-content name" style={{ cursor: 'pointer' }} onClick={e => this.navigateToTutor(selectedLesson.tutor.id)}>{selectedLesson ? selectedLesson.tutor.name : ''}</div>
            </div>
            <div className="detail-row v-c">
              <div className="detail-title">Start Time</div>
              <div className="detail-content">{moment(selectedLesson.sched).local().format('MMM DD, YYYY hh:mm a')}</div>
            </div>
            <div className="detail-row v-c">
              <div className="detail-title">Length</div>
              <div className="detail-content">{selectedLesson.hours}</div>
            </div>
            {/* <div className="detail-row v-c">
                <div className="detail-title">Money paid</div>
                <div className="detail-content">$50.00</div>
            </div> */}
            <div className="detail-row v-c">
              <div className="detail-title">Booked on</div>
              <div className="detail-content">{moment(selectedLesson.sched).local().format('MMM DD, YYYY hh:mm a')}</div>
            </div>
          </div>
          <h1> Description </h1>
          <div className="group">
            <h2>Client</h2>
            <p>{selectedLesson.client_note}</p>
          </div>
          <div className="group">
            <h2>Tutor</h2>
            <p>{selectedLesson.tutor_note}</p>
          </div>
        </div>}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  completedLesson: state.Core.completedLesson,
});

export default connect(mapStateToProps, {
  toggleVideoModal,
  getCompletedLessonAction,
})(withRouter(CompletedLessonPage));