import React, { useState } from "react";
import './style.scss';
import PlannerProgressIndicator from "../../PlannerProgress";
import close from 'src/Assets/MIMS/Planner/Close.png';

 export default function WeekDaysComponent({onWeekDays}) {
    const [days, setDays] = useState([])

    const WeekDays = [
        { id: 1, value: "Mon"}, { id: 2, value: "Tus"}, 
        { id: 3, value:"Wed"}, { id: 4, value: "Thu"}, 
        { id: 5, value: "Fri"}, { id: 6, value: "Sat"}, 
        { id: 7, value: "Sun"}];

    const onDaySelected = (day) => {
        var dayList = [...days]
        const selectedIndex = dayList.findIndex(item => {
            return item === day.id
        })
        if (selectedIndex >= 0) {
            dayList.splice(selectedIndex, 1)
            setDays([...dayList])
        } else setDays([...dayList, day.id])
    }

    const isSelected = (day) => {
        const selectedIndex = days.findIndex(item => {
            return day.id === item
        })
        return selectedIndex >= 0
    }

    return (
        <div className="week-days card">
            <div className="info-content">
                <div className="step-info">step 4 of 5</div>
                <h3 className="plan-title">Days Dedicated To Studying:</h3>
                <p className="plan-content">Choose the days you plan to study for your upcoming test. Our intelligent study planner will then suggest topics for each selected day.</p>
                <div className="weekdays-wrapper">
                    {
                        WeekDays.map((item, index) => (
                            <div className={`week-card ${isSelected(item) ? 'active' : '' }`} key={index} onClick={() => onDaySelected(item)}>
                                <div className="head"></div>
                                <div className="content">{item.value}</div>
                            </div>
                        ))
                    }
                </div>
            </div>
            {/* <img className="dismiss" src={close} /> */}
            <div className={`primary-btn ${days.length === 0 ? 'disabled': ''}`} onClick={() => onWeekDays(days.join(','))}>NEXT</div>
            <PlannerProgressIndicator stage={4} />
        </div>
    )
 }