import React from 'react';
import './RequestTutorPage.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import $ from 'jquery';
import Media from 'react-media';
import Loadable from '@loadable/component';
import list from '../../Assets/Icon/list.svg';
import shoppingList from '../../Assets/Icon/shopping-list.svg';
import success from '../../Assets/Icon/success.png';
import { SelectBox, SearchBox } from '../../Components';
import {
    CarouselLeftButton,
    CarouselRightButton
} from '../../Constant';
import { getDetailsDays } from "../../Util/util";
import {
    getLevelsAction,
    getSubjectsAction,
    requestTutorAction
} from "../../Redux/Actions";
import { showSpinner, hideSpinner } from 'src/Util/Helper';


const OwlCarousel = Loadable(() => import('react-owl-carousel'));

const Dates = getDetailsDays(60);

const TutorTimes = [
    { part: 'Morning', duration: '1 am - 12 noon' },
    { part: 'Afternoon', duration: '12 noon - 6 pm' },
    { part: 'Evening', duration: '6 pm - 12 am' },
];

class RequestTutorPage extends React.Component {
    state = {
        tutor_date: '',
        tutor_date_index: 0,
        tutor_time: {},
        subject: '',
        level: '',
        tutor_note: '',
        depth: 0,
        level_id: '',
        subject_id: ''
    }

    componentDidMount() {
        showSpinner();
        this.props.getLevelsAction().then(() => {
            hideSpinner();
        });
    }

    static getDerivedStateFromProps(props, state) {
        return {
            depth: props.depth
        }
    }

    setStateAsync = (state) => {
        return new Promise((resolve) => {
            this.setState(state, resolve)
        });
    }

    selectDate = async (item, index) => {
        const itemWidth = $(`#carousel${index}`)[0].offsetWidth;
        const transformValue = -parseInt((($('.owl-stage')[0].style.transform.split("(")[1]).split(',')[0]).slice(0, -2), 10);
        const passedCount = parseInt(transformValue / itemWidth, 10);
        const itemCount = parseInt($(`#carousel${index}`)[0].offsetParent.offsetLeft / itemWidth, 10);
        const finalIndex = index - itemCount + passedCount;
        await this.setState({ tutor_date: item, tutor_date_index: finalIndex });
        await this.setState({ tutor_date: item, tutor_date_index: finalIndex });
    }

    changeLevel = (val) => {
        this.setState({ level: val.name });
        this.setState({ level_id: val.id });
        this.props.getSubjectsAction(val.id);
    }

    changeSubject = (val) => {
        this.setState({ subject: val.name });
        this.setState({ subject_id: val.id });
    }

    requestTutor = () => {
        showSpinner();
        const formData = new FormData()
        formData.append('level_id', this.state.level_id);
        formData.append('subject_id', this.state.subject_id);
        formData.append('start_when', `${this.state.tutor_date}/${this.state.tutor_time.part}/${this.state.tutor_time.duration}`);
        formData.append('tutoring_need', this.state.tutor_note);
        this.props.requestTutorAction(formData).then(() => {
            if (this.props.requestTutorStatus) {
                hideSpinner();
                this.props.updateTitle('push', 'Request new tutor')
            }
        })
    }

    render() {
        const { tutor_time, level, subject, tutor_note, tutor_date, tutor_date_index, depth } = this.state;
        const { subjects, levels } = this.props;
        return (
            <div className="request-tutor-page">
                {depth === 0 && <div className="container request-container v-r">
                    <h1>Request a New Tutor</h1>
                    <div className="option-group v-r">
                        <h2 className="option-title">When would you like to start tutoring?</h2>
                        <Media queries={{
                            lg: "(min-width: 992px)",
                            sm: "(max-width: 991px)",
                        }}>
                            {matches => (
                                <OwlCarousel
                                    className="owl-theme"
                                    rewind
                                    nav
                                    stagePadding={0}
                                    margin={15}
                                    items={matches.lg ? 4 : 3}
                                    dots={false}
                                    navText={[CarouselLeftButton, CarouselRightButton]}
                                    startPosition={tutor_date_index}
                                >
                                    {Dates.map((item, index) => <div key={index} className="date-container" id={`carousel${index}`}>
                                        <div className={`tutor-date v-r v-c h-c ${tutor_date === item.tutor_date ? 'active' : ''}`} onClick={e => this.selectDate(item.tutor_date, index)}>
                                            <div className="month">{item.month}</div>
                                            <div className="day">{item.day}</div>
                                            <div className="weekday">{item.weekday}</div>
                                        </div>
                                    </div>)}
                                </OwlCarousel>
                            )}
                        </Media>
                    </div>
                    <div className="option-group v-r">
                        <h2 className="option-title">Select Time</h2>
                        <div className="option-list">
                            {TutorTimes.map((item, index) => <div className={`type-item v-c h-c v-r ${item.part === tutor_time.part ? 'active' : ''}`} key={index} onClick={e => this.setState({ tutor_time: item })}>
                                <p>{item.part}</p>
                                <p>{item.duration}</p>
                            </div>)}
                        </div>
                    </div>
                    <div className="option-group v-r">
                        <h2 className="option-title">Level</h2>
                        <SelectBox className="option-select" icon={list} placeholder="Select Level" value={level} options={levels} onChange={val => this.changeLevel(val)} />
                    </div>
                    <div className="option-group v-r">
                        <h2 className="option-title">Subject</h2>
                        <SearchBox icon={shoppingList} value={subject} placeholder="Type Subject" options={subjects} onChange={val => this.changeSubject(val)} />
                    </div>
                    <div className="option-group v-r">
                        <h2 className="option-title">What else would you like the tutor to know about your tutoring needs</h2>
                        <textarea className="option-note" value={tutor_note} onChange={e => this.setState({ tutor_note: e.target.value })} resize="none" placeholder="( optional )" />
                    </div>
                    {tutor_date && tutor_time.part && subject && level ?
                        <button
                            className="btn-2 primary-btn submit-btn"
                            onClick={e => this.requestTutor()}>
                            Save Changes
                        </button>
                        :
                        <button
                            className="btn-2 submit-btn">
                            Save Changes
                        </button>
                    }
                </div>}
                {depth === 1 && <div className="success-area v-c h-c">
                    <div className="success-content v-r h-c v-c">
                        <h2>Success!</h2>
                        <img src={success} alt="success" />
                        <p>Thanks for your request. We’re working on matching you with the best possible tutor and you should hear back from us shortly.</p>
                    </div>
                </div>}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    levels: state.Core.levels,
    subjects: state.Core.subjects,
    requestTutorStatus: state.Auth.requestTutorStatus,
    requestTutorErr: state.Auth.requestTutorErr,
});

export default connect(mapStateToProps, {
    getLevelsAction,
    getSubjectsAction,
    requestTutorAction,
})(withRouter(RequestTutorPage));