import React from 'react';
import './style.scss';

export default function Score({
  className = '', barColor = '', title, max = 0, value
}) {

  const getWidth = () => {
    if (max !== 0)
      return `${(value / max) * 100}%`
    return '0%'
  }

  return (
    <div className={`score-component ${className}`}>
      <div className='v-c space-between'>
        <div className='title'>{title}</div>
        <span>{value}</span>
      </div>
      <div className='out-container'>
        <div
          className={`inner-container`}
          style={{ width: getWidth(), backgroundColor: barColor }}
        />
      </div>
    </div>
  );
}
