import React, { useEffect, useState } from "react";
import './style.scss';
import { Modal } from "react-bootstrap";
import HeadLogo from 'src/Assets/MIMS/MIM-Logo.png';
import _ from 'lodash'
import StartComponent from "./StartComponent";
import PlannerProcessComponent from "./PlannerProcess";
import ProcessCompleteComponent from "./ProcessComplete";
import PlannerActivity from "./PlannerActivity";
import PracticeQuestionsComponent from "./Questions";
import { useDispatch, useSelector } from "react-redux";
import { hideSpinner, showSpinner } from "src/Util/Helper";
import { createPlannerAction, deletePlannerAction, getPlannerAction, toggleConfirmNewPlannerModal, togglePlannerExitModal, togglePlannerModalAction } from "src/Redux/Actions";
import { SET_PLANNING_STAGE } from "src/Redux/Type";
import ExitPlannerModal from "./ExitPlannerModal";
import ConfirmNewPlanModal from "./ConfirmNewPlanModal";

export default function PlannerComponent({showModal, onHide}) {
    const dispatch = useDispatch()
    const [examDate, setExamDate] = useState(undefined)
    const [topicRanges, setTopicRanges] = useState(undefined)
    const [weekdays, setWeekdays] = useState(undefined)

    const {
        showing_plan,
        planner,
        active_exam,
        loading_planner,
        isPlannerModalOpen,
        isExitPlannerOpen,
        isConfirmNewPlannerOpen,
        plan_stage,
        show_collecting_plan,
        createPlanner_loading,
        createPlanner_success,
        deletePlanner_loading
    } = useSelector(state => state.Mims)

    const exitMessage = `Exit Your <br>${active_exam ? active_exam.name : ''} Planner ?</br>` 

    const onEditPlanner = () => {
        dispatch(togglePlannerExitModal(false))
        dispatch(togglePlannerModalAction(false))
    }

    useEffect(() => {
        if(isPlannerModalOpen && active_exam) {
            showSpinner()
            dispatch(getPlannerAction(active_exam.id))
        }
    }, [dispatch, isPlannerModalOpen])

    useEffect(() => {
        if (!loading_planner) {
            hideSpinner()
            console.log('=== planner ===', planner)
        }
    }, [loading_planner])

    useEffect(() => {
        if(!deletePlanner_loading) {
            hideSpinner()
        }
    }, [deletePlanner_loading])

    const onCreateNewPlanner = () => {
        dispatch(toggleConfirmNewPlannerModal(false))
        showSpinner()
        dispatch(deletePlannerAction(active_exam.id))
    }

    const onDateSet = (date) => {
        setExamDate(date)
        setPlanningStage(2)
    }

    const onTopicRangeSet = (ranges) => {
        setTopicRanges(ranges)
        setPlanningStage(4)
    }
    
    const setPlanningStage = (stage) => dispatch({type: SET_PLANNING_STAGE, data: { stage } })

    const onWeekDays = (value) => {
        setWeekdays(value)
        setPlanningStage(5)
    }

    const onWorkTime = (value) => {
        if (isValid(value)) {
            const formdata = new FormData()
            formdata.append('exam_id', active_exam.id)
            formdata.append('name', `${active_exam.name} Planner`)
            formdata.append('exam_date', examDate)
            formdata.append('weekdays', weekdays)
            formdata.append('work_time', value)
            formdata.append('percentages', JSON.stringify(topicRanges))
            showSpinner()
            dispatch(createPlannerAction(formdata))
        }
    }

    useEffect(() => {
        if(!createPlanner_loading) {
            hideSpinner()
            if (createPlanner_success) {
                setTimeout(() => dispatch(getPlannerAction(active_exam.id)), 10000)
            }
        }
    }, [createPlanner_loading, createPlanner_success])

    const isValid = (workTime) => !_.isEmpty(examDate) && !_.isEmpty(topicRanges) && !_.isEmpty(weekdays) && workTime > 0

    const showStart = () => !showing_plan && plan_stage === 0

    const showProcess = () => !showing_plan && plan_stage > 0

    const showCompleted = () => !showing_plan && show_collecting_plan

    return  (
        <>
            <Modal className="planner-modal" show={showModal} onHide={onHide}>
                <Modal.Header className="pc-header">
                    <div className="logo-wrapper">
                        <img src={HeadLogo}/>
                    </div>
                    <div className="header-content">
                        <h4>{active_exam && active_exam.name} Planner</h4>
                        <div className="action-wrapper">
                            { showing_plan && <div className="action-btn" onClick={() => dispatch(toggleConfirmNewPlannerModal(true))}>
                                Make a new Plan
                            </div> }
                            <div className="action-btn" onClick={onHide}>
                                <div>Exit</div>
                            </div>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Header className="mobile-header">
                    <h4>{active_exam && active_exam.name} Planner</h4>
                    <div className="action-wrapper">
                        { showing_plan && <div className="action-btn" onClick={() => dispatch(toggleConfirmNewPlannerModal(true))}>
                                    Make a new Plan
                                </div> }
                        <div className="action-btn" onClick={onHide}>
                            <div>Exit</div>
                        </div>
                    </div>
                </Modal.Header>
                {
                    !loading_planner && (
                        <>
                            { showStart() && <StartComponent onStart={() => setPlanningStage(1)}/> }
                            { showProcess() && <PlannerProcessComponent 
                                onDate={onDateSet}
                                onSubject={() => setPlanningStage(3)}
                                onTopicRange={(ranges) => onTopicRangeSet(ranges)}
                                onWeekDays={onWeekDays}
                                onWorkTime={onWorkTime}
                            /> }
                            { showCompleted() && <ProcessCompleteComponent /> }
                            { planner && showing_plan && <PlannerActivity planner={planner}/> }
                            {/* <PracticeQuestionsComponent /> */}
                        </>
                    )
                }
            </Modal>
            <ExitPlannerModal 
                show={isExitPlannerOpen}
                message={exitMessage}
                onResume={() => dispatch(togglePlannerExitModal(false))}
                onExit={() => onEditPlanner()}
            />
            <ConfirmNewPlanModal 
                show={isConfirmNewPlannerOpen}
                onCancel={() => dispatch(toggleConfirmNewPlannerModal(false))}
                onContinue={() => onCreateNewPlanner()}
            />
    </>
    )
}