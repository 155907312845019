import React from 'react';
import './style.scss';
import AnswerStatus from './AnswerStatus'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import GraphLogo from "src/Assets/MIMS/graph-icon.svg";
import questionBankImg from 'src/Assets/MIMS/QuestionBank.png';

import {
  toggleQuestionModal,
  getQuestionsAction,
  getTestIdAction,
  toggleGraphModal,
} from 'src/Redux/Actions';

export default function Topic({
  className = '',
  info,
  assessments,
  onAssessmentClicked,
  onFlashcardClicked,
  onExamPlannerClicked,
  flashcards,
  exams
}) {
  let history = useHistory();
  const dispatch = useDispatch();

  const {
    id,
    logo,
    tag,
    question_answered,
    questions_count,
    have_access,
    pending_question,
    concept_pending,
    correct,
    concept_correct,
    wrong,
    concept_wrong,
  } = info;

  const onStartQuestions = (concept) => {
    if (Number(questions_count) > 0) {
      dispatch(toggleQuestionModal(true, { topic_id: id, have_access, concept, question_answered }));
      dispatch(getQuestionsAction(id));
      dispatch(getTestIdAction({ topic_id: id }))
    };
  }

  const onUpgrade = () => {
    history.push({
      pathname: "/make-it-make-sense/plans",
      topicInfo: info
    });
  };

  const onGraph = () => {
    dispatch(toggleGraphModal(true, { topic_id: id }));
    dispatch(getQuestionsAction(id));
  };

  return (
    <div className={`topic-component shadow-object ${className}`}>
      <div className='topic-header'>
        <div className='left-logo' >
          <img src={`${process.env.REACT_APP_IMAGE_DOMAIN}${logo}`} alt="logo" className='topic-logo' />
        </div>
        <div className='topic-icons'>
            <div className='tooltip-wrapper'>
              <img src={GraphLogo} alt='graph-logo' className='graph-logo' onClick={onGraph} />
              <div className='info'>Report</div>
            </div>
            { assessments && assessments.map(item => <div className='tooltip-wrapper' key={item.id}>
                <img src={`${process.env.REACT_APP_IMAGE_DOMAIN}${item.icon}`} onClick={() => onAssessmentClicked(item)} className='topic-action-logo'/>
                <div className='info'>{item.name}</div>
              </div>)}
            { flashcards && flashcards.map(item => <div className='tooltip-wrapper' key={item.id}>
              <img src={`${process.env.REACT_APP_IMAGE_DOMAIN}${item.icon}`} onClick={() => onFlashcardClicked(item)} className='topic-action-logo'/>
              <div className='info'>{item.name}</div>
            </div>)}
            <div className='tooltip-wrapper'>
              <img src={questionBankImg} onClick={() => onStartQuestions('')} className='topic-action-logo'/>
              <div className='info'>Question Bank</div>
            </div>
        </div>
        {
          (exams && exams.length > 0) && (
            <div className='btn-2 center start-btn' onClick={() => onExamPlannerClicked(exams[0])} >
              {`${tag.replaceAll('Math', '').replaceAll('Verbal', '').trim()} Study Planner`}
            </div>
          )
        }
      </div>
      <div className='topic-body'>
        <AnswerStatus
          title="STATUS"
          status={have_access}
        />
        <AnswerStatus
          title="PENDING QUESTIONS"
          value={pending_question}
          concept={concept_pending}
          onConcept={onStartQuestions}
        />
        <AnswerStatus
          title="CORRECT"
          value={correct}
          concept={concept_correct}
          onConcept={onStartQuestions}
        />
        <AnswerStatus
          title="WRONG"
          value={wrong}
          concept={concept_wrong}
          onConcept={onStartQuestions}
        />
        {/* <AnswerStatus
          title="SKIP"
          value={1}
        />
        <AnswerStatus
          title="SAVED"
          value={1}
        /> */}
        <div className='upgrade-btn-container' />
        <div className='upgrade-btn-container' />
        <div className='upgrade-btn-container' />
        <div className='upgrade-btn-container'>
          <div
            className='btn-2 upgrade-btn center'
            onClick={onUpgrade}
          >
            Upgrade
          </div>
        </div>
      </div>
    </div >
  );
}
