import React from 'react';
import './SettingsPage.scss';
import { CheckBox,  Accordion } from '../../Components';
import { toast } from "react-toastify";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    getMypofileInfoAction,
    // getSchoolsAction,
    profileUpdateAction
} from "../../Redux/Actions";
import _ from 'lodash'
import { showSpinner, hideSpinner } from 'src/Util/Helper';

const Menus = [
    'PROFILE', 'STUDENT INFO', 'PASSWORD', 'FAQ', 'SUSPEND SERVICES'
]

const questions = [
    { title: 'How can I allow a tutor to book a session for me?', content: 'Your tutor can only book for you if you have tutoring minutes loaded onto your account.'},
    { title: 'What is my current tutoring rate?', content: 'Click on Your Plan” on the left menu to see the subscription plan you’re currently signed up for.'},
    { title: 'What happens if I miss a tutoring session?', content: 'You will be charged 100% for that lesson the student fails to show up for the lesson. However, If you cancel within 24 hours before the start of the lesson, you will only be charged 50% for that lesson. Cancellations more than 24 hours before the start of a lesson will not be charged. If there are extenuating circumstances or a situation out of your control, please contact us and we can cancel lessons for you at no charge.'},
    { title: 'What happens if my tutor cancels a tutoring session?', content: 'In the event a tutor has to cancel a lesson, your tutoring credit will remain the same and you will have the option to reschedule the lesson at a later date.'},
    { title: 'What if I cancel a session before the 24-hour time frame?', content: 'You won’t get charged for the lesson and you will be able to reschedule.'},
    { title: 'What if I cancel a session within the 24-hour time frame?', content: 'You will be charged 50% of the scheduled session rate'},
    { title: 'What is your refund policy?', content: 'On the GradeGetter platform, your subscription includes a set number of tutoring minutes for a specified period of time. The tutoring minutes do not roll over from one period to another, and we do not issue refunds without reason. Cancel at any time. You are billed either weekly, biweekly, monthly, or one time depending on your plan. Additional tutoring sessions not covered by your plan will be billed individually. Your membership will be renewed automatically at the beginning of each cycle until you cancel. Tutor satisfaction guaranteed: If you do not like your tutor that you are assigned, we assign you a new tutor at no additional cost '},
    { title: 'Can I switch my tutor?', content: 'Yes!'},
    { title: 'Can I have more than one tutor?', content: 'Yes!'},
    { title: 'Can I change my subscription plan?', content: 'Yes!'},
    { title: 'Who do I content if I have a question?', content: 'Yes!'},
    { title: 'Can I change my tutoring schedule?', content: 'Yes!'},
];

class SettingsPage extends React.Component {
    state = {
        menu: this.props.location.state.menu,
        id: '',
        email: '',
        photo: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        phone_verify: false,

        about_me: '', 
        school: '',
        school_id: '',

        last_password: '',
        new_password: '',
        loading: true,
        flagMenu: '',
        upload_picture: ''
    }

    componentDidMount() {
        if(_.isEmpty(this.props.myProfileInfo)){
            this.props.getMypofileInfoAction().then(()=> {
                this.updateProfileState();
            })
        } else {
            this.updateProfileState();
        };
        // this.props.getSchoolsAction()
    }
    
    updateProfileState = () => {
        const {myProfileInfo} = this.props;
        this.setState({
            id: myProfileInfo.id,
            email: myProfileInfo.email,
            photo: myProfileInfo.picture? process.env.REACT_APP_IMAGE_DOMAIN + myProfileInfo.picture : '',
            first_name: myProfileInfo.first_name,
            last_name: myProfileInfo.last_name,
            email: myProfileInfo.email,
            phone: myProfileInfo.phone,
            about_me: myProfileInfo.note,
        });
    }

    componentDidUpdate() {
        if(this.state.flagMenu !== this.props.location.state.menu){
            this.setState({menu: this.props.location.state.menu, flagMenu: this.props.location.state.menu})
        }
    }

    pickFileForPhoto = (e) => {
        e.preventDefault();
        if (!e.target.files[0]) return;
        this.setState({photo: URL.createObjectURL(e.target.files[0])});
        this.setState({upload_picture: e.target.files[0]});
    }

    updateProfile = (updating_category) => {
        showSpinner();
        const { email, first_name, last_name, upload_picture, phone, about_me, last_password, new_password } = this.state;

        const formData = new FormData();       
        if(updating_category === 'profile'){     
            if(upload_picture){
                formData.append('picture', upload_picture);
            };
            formData.append('first_name', first_name);
            formData.append('last_name', last_name);
            formData.append('email', email);
            formData.append('phone', phone);
        };

        if(updating_category === 'studentInfo'){
            formData.append('note', about_me);
        };

        if(updating_category === 'password'){
            formData.append('old_password', last_password);
            formData.append('password', new_password);
        };

        this.props.profileUpdateAction(formData).then(()=> {
            hideSpinner();
            const { profileUpdateStatus } = this.props;
            if(profileUpdateStatus){
                this.props.headerProfileInfo(profileUpdateStatus);
                toast("Successfully updating!");
            } else {
                toast(this.props.profileUpdateErr);
            };
        });
    };

    render() {
        
        const { menu, photo, first_name, last_name, email, phone, phone_verify, about_me, school, last_password, new_password, loading } = this.state;
        const {myProfileInfo, schools} = this.props;
        // const {menu} = this.props.location.state
        return (
            <div className="settings-page v-r">
                <div className="settings-header">
                    <div className="container settings-container header-container v-c">
                        {Menus.map((item, index) => <p className={`head-menu ${menu === item ? 'active' : ''}`} key={index} onClick={e => this.setState({menu: item})}>
                            {item}
                        </p>)}
                    </div>
                </div>
                <div className="settings-body">
                    <div className="container settings-container">
                        {menu === 'PROFILE' && <div id="section-profile" className="v-r">
                            <div className="option-group v-r picture-option">
                                <h2 className="option-title">Profile Picture</h2>
                                <div className="photo-area v-c">
                                    <div className="initial-name h-c v-c">
                                        {photo ? <img src={photo} style={{width:'100%',height:'100%', borderRadius:'50%'}} alt="avatar" /> 
                                                : <span>{first_name[0] || 'A'}{last_name[0] || 'L'}</span>}
                                    </div>
                                    <input type="file" accept="image/*" id="image_picker" style={{display:'none'}} onChange={e => this.pickFileForPhoto(e)}></input>
                                    <label htmlFor="image_picker"><div className="btn-2 btn-2-outline upload_btn v-c h-c">Upload Photo</div></label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="option-group v-r">
                                        <h2 className="option-title">First Name</h2>
                                        <input 
                                            value={first_name} 
                                            placeholder="Type First Name"
                                            id="firstName" 
                                            type="text"
                                            className="profile-info-input"
                                            onChange={(event) => this.setState({first_name: event.target.value})}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="option-group v-r">
                                        <h2 className="option-title">Last Name</h2>
                                        <input 
                                            value={last_name} 
                                            placeholder="Type Last Name"
                                            id="lastName" 
                                            type="text"
                                            className="profile-info-input"
                                            onChange={(event) => this.setState({last_name: event.target.value})}
                                        />
                                    </div>
                                </div>
                            </div>                            
                            <div className="option-group v-r">
                                <h2 className="option-title">Email</h2>
                                <input 
                                    value={email} 
                                    placeholder="Type Email"
                                    id="email" 
                                    type="text"
                                    className="profile-info-input"
                                    onChange={(event) => this.setState({email: event.target.value})}
                                />
                            </div>                            
                            <div className="option-group v-r">
                                <h2 className="option-title">Phone</h2>
                                <input 
                                    value={phone} 
                                    placeholder="Type Phone Number"
                                    id="phone" 
                                    type="text"
                                    className="profile-info-input"
                                    onChange={(event) => this.setState({phone: event.target.value})}
                                />
                            </div>
                            <div className="option-group v-c check-option">
                                <CheckBox className="check-box" checked={phone_verify} onChange={val => this.setState({phone_verify: val})} />
                                <p>Set phone notification </p>
                            </div>
                            {first_name && last_name && email && phone? 
                                <button 
                                    className="btn-2 primary-btn"
                                    onClick={(e) => this.updateProfile("profile")}>
                                    Save Changes
                                </button>
                                :
                                <button 
                                    className="btn-2">
                                    Save Changes
                                </button>
                            }
                        </div>}
                        {menu === 'STUDENT INFO' && <div id="section-info" className="v-r">
                            <p>Here, you can enter your academic strengths and weaknesses, interests outside of school, tutoring needs, teachers name, or any other information you would like your tutor to know. Any information you provide will be made available to your tutor to allow them to understand your needs better.</p>
                            <div className="option-group v-r">
                                <h2 className="option-title">About Me</h2>
                                <textarea className="option-note" value={about_me} onChange={e => this.setState({about_me: e.target.value})} resize="none" placeholder=""/>
                            </div>
                            {/* <div className="option-group v-r">
                                <h2 className="option-title">School</h2>
                                <SearchBox icon={search} value={school} placeholder="Search School" options={schools} onChange={val => [this.setState({school: val.name}), this.setState({school_id: val.id})]}/>
                            </div> */}
                            {about_me?
                                <button 
                                    className="btn-2 primary-btn"
                                    onClick={(e) => this.updateProfile("studentInfo")}>
                                    Save Changes
                                </button>
                            :
                                <button 
                                    className="btn-2">
                                    Save Changes
                                </button>
                            }   
                        </div>}
                        {menu === 'PASSWORD' && <div id="section-password" className="v-r">
                            <div className="option-group v-r">
                                <h2 className="option-title">Old Password</h2>
                                <input 
                                    type="password" 
                                    value={last_password} 
                                    placeholder="Type Last Password" 
                                    className="profile-info-input"
                                    onChange={(event) => this.setState({last_password: event.target.value})}
                                />
                            </div>
                            <div className="option-group v-r">
                                <h2 className="option-title">New Password</h2>
                                <input 
                                    type="password" 
                                    value={new_password} 
                                    placeholder="Type New Password"
                                    className="profile-info-input"
                                    onChange={(event) => this.setState({new_password: event.target.value})}
                                />
                            </div>
                            {last_password && new_password?
                                <button 
                                    className="btn-2 primary-btn"
                                    onClick={(e) => this.updateProfile("password")}>
                                    Save Changes
                                </button>
                            :
                                <button 
                                    className="btn-2">
                                    Save Changes
                                </button>
                            }
                        </div>}
                        {menu === 'FAQ' && <div id="section-how-it" className="v-r">
                            {questions.map((item, index) => <Accordion className="accordion" title={item.title} content={item.content} key={index} />)}
                        </div>}
                        {menu === 'SUSPEND SERVICES' && <div id="section-suspend" className="v-r">
                            <p>By deactivating your account, GradeGetter will cancel your subscription plan and prevent any future payment from going through. You will also stop receiving transactional emails from us. Thank you for trusting us with your tutoring needs.</p>
                            <button className="btn-2 primary-btn">Deactivate My Account</button>
                        </div>}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    myProfileInfo: state.Core.myProfileInfo,
    // schools: state.Core.schools,
    profileUpdateStatus: state.Core.profileUpdateStatus,
    profileUpdateErr: state.Core.profileUpdateErr,
});

export default connect(mapStateToProps, {
    getMypofileInfoAction,
    // getSchoolsAction,
    profileUpdateAction
})(withRouter(SettingsPage));