import React from 'react';
import './style.scss';

export default function CircleCheckBox({
  className = '', isChecked = false, onClick, title
}) {
  
  return (
    <div
      className={
        `circle-checkbox-component
        ${isChecked ? 'active' : ''}
        ${className}
      `}
      onClick={onClick}>
      <div className='circle-content v-c h-c'>
        {isChecked ? <div className='fill' /> : null}
      </div>
    </div >
  );
}
