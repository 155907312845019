import React, { useEffect, useRef } from 'react';
import './style.scss';
import _ from 'lodash'
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import add from 'src/Assets/Icon/add.svg';
import { Scores, QuestionAnswerFlow, ConceptsCharts } from 'src/Components';
import HeadLogo from 'src/Assets/MIMS/MIM-Logo.png';
import {
  toggleQuestionModal,
  getTestIdAction,
  getScoresAction,
  getAverageTimeAction,
} from 'src/Redux/Actions';
import { showSpinner, hideSpinner } from 'src/Util/Helper';
import { useState } from 'react';

export default function QuestionAnswerModal() {
  const dispatch = useDispatch();
  const getTestIdRef = useRef(false);
  const getLoadingRef = useRef(false);

  const {
    isQuestionModalOpen, topic_id, have_access, concept, question_answered
  } = useSelector((state) => state.Modal);

  //Get Test ID
  useEffect(() => {
    if (!_.isEmpty(topic_id)) {
      showSpinner();
      dispatch(getTestIdAction({ topic_id }))
    }
  }, [dispatch, isQuestionModalOpen, topic_id]);

  const {
    questions, getQuestions_success, getQuestions_loading,
    testId, get_testId_success, get_testId_loading,
    scores,
    averageTime, get_scores_success, get_scores_loading
  } = useSelector(
    state => state.QA,
  );

  const [filterQuestions, setFilterQuestions] = useState([]);

  //Get Test id res
  useEffect(() => {
    if (getTestIdRef.current && !get_testId_loading) {
      // hideSpinner();
      if (get_testId_success) {
        onGetAnswerScours();
      }
    }
    getTestIdRef.current = get_testId_loading;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_testId_loading, get_testId_success, testId]);

  const onGetAnswerScours = () => {
    dispatch(getScoresAction(testId));
    dispatch(getAverageTimeAction(testId));
  }

  //Get Questions res
  useEffect(() => {
    if (getLoadingRef.current && !getQuestions_loading) {
      // hideSpinner();
      if (getQuestions_success) {
        // console.log("===questions===", questions)
      }
    }
    getLoadingRef.current = getQuestions_loading;
  }, [getQuestions_loading, getQuestions_success, questions]);

  useEffect(() => {
    if (!get_scores_loading && get_scores_success) {
      if(!concept) {
        var questionList = questions.filter(item => {
          return !scores.question_answered.includes(item.id)
        })
        setFilterQuestions(questionList.length > 0 ? questionList : questions)
        hideSpinner()
      }
    }
  }, [get_scores_loading, get_scores_success])

  //filter questions
  useEffect(() => {
    if (!_.isEmpty(questions)) {
      if (concept) {
        var _filterQuestions = []
        // eslint-disable-next-line array-callback-return
        concept.question_ids.map((c) => {
          // eslint-disable-next-line array-callback-return
          questions.map((q) => {
            if (c === q.id) {
              _filterQuestions.push(q)
            }
          })
        })
        // const _filterConcept = questions.filter(item => item.concept === concept);
        setFilterQuestions(_filterQuestions)
      } else {
        setFilterQuestions(questions)
      }
    }
  }, [questions, concept]);

  const closeModal = () => {
    dispatch(toggleQuestionModal(false));
  };

  return (
    <Modal show={isQuestionModalOpen} className="question-answer-modal-component">
      <div className="question-answer-modal-content v-r">
        <Modal.Header className="pc-header">
            <div className="logo-wrapper">
                <img src={HeadLogo}/>
            </div>
            <div className="header-content">
                <h4></h4>
                <div className="exit-btn" onClick={() => closeModal()}>
                    <div>Exit</div>
                </div>
            </div>
        </Modal.Header>
        <Modal.Header className="mobile-header">
            <h4></h4>
            <div className="exit-btn" onClick={() => closeModal()}>
                <div>Exit</div>
            </div>
        </Modal.Header>
        <div className='question-answer-main-content'>
          {!_.isEmpty(filterQuestions) && (
            <div className="row">
              <div className="col-lg-12 right-content">
                <QuestionAnswerFlow
                  testId={testId}
                  topic_id={topic_id}
                  questions={filterQuestions}
                  have_access={have_access}
                  // answeredQuestionsCount={question_answered ? question_answered.length : 0}
                  answeredQuestionsCount={0}
                  onGetAnswerScours={onGetAnswerScours}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
