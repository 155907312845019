import React, { useEffect, useState } from "react";
import './style.scss'
import ConceptActiveIcon from 'src/Assets/MIMS/Quiz/ConceptActive.png';
import ConceptLockedIcon from 'src/Assets/MIMS/Quiz/ConceptLocked.png';
import RadioGreenCheck from 'src/Assets/MIMS/Concept/RadioGreenCheck.png';
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash';
import { FLASHCARD_NUM, RightMessages } from "../Info";
import { 
    SWITCH_TO_CONCEPT,
} from "src/Redux/Type";

export default function ConceptComponent({onRetake}) {
    const dispatch = useDispatch()
    const PROGRESS_TRESHOLD = 70

    const {
        flashcard_concept,
        active_flashcard
    } = useSelector(state => state.Mims)

    const concepts = active_flashcard.concepts

    const conceptProgress = (item) => {
       if(!isNaN(item.stage)) {
            const completed = item.stage * FLASHCARD_NUM
            if (completed > item.total) return 100
            return percentage(completed, item.total).toFixed(0)
       }
       return 0
    }

    const getIcon = (item, index) => {
        if(conceptProgress(item) > PROGRESS_TRESHOLD) return RadioGreenCheck
        return isActive(item, index) ? ConceptActiveIcon : ConceptLockedIcon
    }

    const getStatus = (item, index) => {
        if(isActive(item, index)) return conceptProgress(item) > PROGRESS_TRESHOLD ? 'passed' : 'active';
        return ''
    } 

    const percentage = (value, total) => (value / total) * 100

    const isActive = (item, index) => {
        if (_.isEmpty(flashcard_concept)) return index === 0
        return concepts[flashcard_concept] && concepts[flashcard_concept].id === item.id
    }

    const isRetake = (item) => concepts[item].stage > 0 && item === flashcard_concept

    // const isEnabled = (index) => {
    //     if(index === 0) return true
    //     let conceptkeys = Object.keys(active_flashcard.concepts)
    //     const prevKey = conceptkeys[index - 1] 
    //     let prevConcept = active_flashcard.concepts[prevKey]
    //     if(prevConcept && prevConcept.stage * FLASHCARD_NUM >= prevConcept.total) return true
    //     return false
    // }

    const onConceptStart = (concept) => {
        if(flashcard_concept === concept) {
            let item = concepts[concept]
            if(item.stage * FLASHCARD_NUM >= item.total) {
                onRetake()
            }
        } else {
            dispatch({type: SWITCH_TO_CONCEPT, data: { concept }})
        }
    }

    const getAffirmation = () => RightMessages[Math.floor(Math.random() * RightMessages.length)]

    return (
        <div className="concept-component-wrapper card">
            <h4>Concepts</h4>
            <div className="concept-items">
                {
                    Object.keys(concepts).map((item, index) => (
                        <div className={`concept-wrapper ${getStatus(concepts[item], index)}`}  key={index}>
                            <div className="concept">
                                <img src={getIcon(concepts[item], index)} />
                                <div className="info">
                                    <div className="title">{item}</div>
                                    <div className="details">
                                        <div><span>{concepts[item].total}</span> Flashcards</div>
                                        <div className="seperate">|</div>
                                        <div><span>{concepts[item].question_count}</span> Questions</div>
                                    </div>
                                </div>
                                {/* Note on button text message */}
                                <div className={`start ${isActive(concepts[item], index) ? 'disabled' : ''}`} onClick={() => onConceptStart(item)}>{isRetake(item) ? 'RETAKE' : 'START'}</div>
                            </div>
                           {
                            conceptProgress(concepts[item]) > PROGRESS_TRESHOLD && (
                                <div className="stat-info">
                                    <div className="progress-stat">
                                        <div className="motiv-message">{getAffirmation()}</div>
                                        <div className="score">{conceptProgress(concepts[item])}%</div>
                                    </div>
                                    <div className="progress-visual">
                                        <div className="bar">
                                            <div className={`overlay-progress passed`} style={{ width: `${conceptProgress(concepts[item])}%` }}></div>
                                        </div>
                                    </div>
                                </div>
                            )
                           }
                        </div>
                    ))
                }
                {/* <div className="d-flex justify-content-center">
                    <div className="loadmore">LOAD MORE</div>
                </div> */}
            </div>
        </div>
    )
}