import React from 'react';
import './ReceiptToPrint.scss';
import logo from '../../Assets/Header/logo.png';
import moment from 'moment';

export default class ReceiptToPrint extends React.Component {

    render() {
        const { selectedReceipt, myProfileInfo } = this.props;

        return (
            <>
                {selectedReceipt && myProfileInfo && <div className="receiptToPrint-component">
                    <div className="receipt-detail v-r" >
                        <div className="detail-row">
                            <div className="detail-block">
                                <h1>Sale Receipt</h1>
                                <div className="paragraph">
                                    <p>Date: {moment(selectedReceipt.payment_date).local().format('MMM DD, YYYY')}</p>
                                    <p>Receipt #: {selectedReceipt.id}</p>
                                </div>
                            </div>
                        </div>
                        <div className="detail-row" style={{borderBottom: 'none'}}>
                            <div className="detail-block left-block">
                                <h1>GradeGetter</h1>
                                <div className="paragraph">
                                    <p>1751 River Run #200</p>
                                    <p>Fort Worth, Tx 76107</p>
                                </div>
                                <div className="paragraph">
                                    {/* <p>Phone: 817 717 1286</p> */}
                                </div>
                                <div className="paragraph">
                                    <p>Email: info@gradegetter.com</p>
                                </div>
                            </div>
                            <div className="detail-block">
                                <h1>{myProfileInfo.name}</h1>
                                <div className="paragraph">
                                    <p>{myProfileInfo.id}</p>
                                </div>
                                <div className="paragraph">
                                    <p>Phone: {myProfileInfo.phone}</p>
                                </div>
                                <div className="paragraph">
                                    <p>Email: {myProfileInfo.email}</p>
                                </div>
                            </div>
                        </div>
                        <div className="pay-table v-r">
                            <div className="pay-header v-c">
                                <h2 className="first-col">PAID</h2>
                                <h2 className="last-col">STATUS</h2>
                            </div>
                            <div className="pay-data v-c">
                                <p className="first-col">{selectedReceipt.amount}</p>
                                <p className="last-col completed">Completed</p>
                            </div>
                        </div>
                        <div className="receipt-footer">
                            <img src={logo} alt="logo" />
                            <p>Thank you for your business!</p>
                        </div>
                    </div>
                </div>}
            </>
        )
    };
};