import React from 'react';
import './Sidebar.scss';
import { ReactSVG } from 'react-svg';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import collapse from '../../Assets/Icon/collapse.svg';
import open from '../../Assets/Icon/open.svg';
import arrowup from '../../Assets/Icon/arrow-up.svg';
import arrowdown from '../../Assets/Icon/arrow-down.svg';
import { NavLink, withRouter } from 'react-router-dom';
import { toggleSidebar, toggleMims } from '../../Redux/Actions';
import { Menus, MIMSMenus } from './Menus';
import { DropDownLogo } from 'src/Components'

class Sidebar extends React.Component {
  state = {
    isOpen: true,
    isPrevOpen: true,
    menu: Menus,
    isGG: true
  }


  static getDerivedStateFromProps(props, state) {
    return {
      isOpen: props.isOpen,
      isPrevOpen: state.isOpen,
      menu: props.isMims ? MIMSMenus : Menus
    }
  }

  setStateAsync = (state) => {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    });
  }

  closeMenuHeaders = async () => {
    let temp = this.state.menu;
    for (let item of temp) {
      item.opened = false
    }
    await this.setState({ menu: temp })
  }

  toggleSidebar = async () => {
    await this.closeMenuHeaders();
    setTimeout(() => {
      this.props.toggleSidebar(!this.state.isOpen);
    }, 100);
  }

  toggleMenu = async (index) => {
    if (!this.state.isOpen) {
      await this.closeMenuHeaders();
    }
    let temp = this.state.menu;
    temp[index].opened = !temp[index].opened;
    this.setState({
      menu: temp
    })
  }

  subMenuBlur = (index) => {
    if (!this.state.isOpen) {
      let temp = this.state.menu;
      temp[index].opened = false;
      this.setState({
        menu: temp
      })
    }
  }

  subMenuItemClick = (index) => {
    let temp = this.state.menu;
    for (let item of temp) {
      item.subItemActive = false
    }
    temp[index].subItemActive = true;
    this.setState({ menu: temp })
  }

  onToggleMIMS = (isGradeGetter = true) => {
    this.props.toggleMims(!isGradeGetter);
    this.setState({
      menu: isGradeGetter ? Menus : MIMSMenus
    })
  }

  renderMenu = () => {
    return this.state.menu.map((item, index) => (
      item.children.length === 0 ?
        // item.id === 1
        //   ? (
        //     <NavLink
        //       exact
        //       isActive={(match, location) => {
        //         return (
        //           location.pathname === '/' || location.pathname.substring(0, 7) === '/tutors')
        //       }}
        //       to={item.link}
        //       className="menu-item header-menu-item v-c"
        //       key={index}
        //       onClick={e => this.subMenuItemClick(index)}>
        //       <ReactSVG src={item.icon} className="svg-icon start-icon" />
        //       <p>{item.name}</p>
        //     </NavLink>
        //   ) : 
          <NavLink exact to={item.link} className="menu-item header-menu-item v-c" key={index} onClick={e => this.subMenuItemClick(index)}><ReactSVG src={item.icon} className="svg-icon start-icon" /><p>{item.name}</p></NavLink>
        : <div key={index} style={{ position: 'relative' }}>
          <div className={`menu-header v-c ${item.subItemActive ? 'sub-active' : ''}`} key={index} onClick={e => this.toggleMenu(index)} tabIndex={0} onBlur={e => this.subMenuBlur(index)}>
            <ReactSVG src={item.icon} className="svg-icon start-icon" />
            <p>{item.name}</p>
            <ReactSVG src={item.opened ? arrowup : arrowdown} className="svg-icon end-icon" />
          </div>
          <CSSTransition in={item.opened} timeout={200} classNames="sub-menu">
            <div className={`sub-menu v-r`}>
              {item.children.map((subitem, subindex) => <NavLink to={subitem.link} className="menu-item sub-menu-item" key={subindex} onClick={e => this.subMenuItemClick(index)}>
                {subitem.name}
              </NavLink>)}
            </div>
          </CSSTransition>
        </div>
    ));
  }

  render() {
    const { isOpen, isPrevOpen } = this.state;

    return (
      <div className={`sidebar-component v-r ${isOpen ? isPrevOpen ? '' : 'open' : 'closed'}`}>
        <DropDownLogo
          isGG={!this.props.isMims}
          onClick={this.onToggleMIMS}
        />
        <div className="sidebar-content v-r">
          <div className="sidebar-menu v-r">
            {this.renderMenu()}
          </div>
          <div className="sidebar-resize v-c" onClick={e => this.toggleSidebar()}>
            <ReactSVG src={isOpen ? collapse : open} className="svg-icon" />
            {isOpen && <p>Collapse Sidebar</p>}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isOpen: state.Sidebar.isOpen,
    isMims: state.Mims.isMims,
  }
}

export default connect(mapStateToProps, {
  toggleMims,
  toggleSidebar
})(withRouter(Sidebar));
