import {
  FROG_UP_HUBSPOT, FROG_UP_HUBSPOT_SUCCESS, FROG_UP_HUBSPOT_FAIL,
} from '../Type';

import {
  frogUpHubSpot,
} from "src/Api";

export const frogUpHubSpotAction = (payload) => async (dispatch) => {
  dispatch({ type: FROG_UP_HUBSPOT });
  const res = await frogUpHubSpot(payload);
  if (res && res.data) {
    dispatch({ type: FROG_UP_HUBSPOT_SUCCESS, data: res });
  } else {
    dispatch({ type: FROG_UP_HUBSPOT_FAIL, data: res.data });
  }
};
