import React from 'react';
import './PendingLessonPage.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { InputBox, RoundButton, SelectBox, ConfirmModal } from '../../Components';
import search from '../../Assets/Icon/search.svg';
import left from '../../Assets/Icon/left.svg';
import right from '../../Assets/Icon/right.svg';
import play_button from '../../Assets/Icon/play-button.svg';
import { ReactSVG } from 'react-svg';
import moment from 'moment';
import { toggleVideoModal, toggleJoinLesson } from '../../Redux/Actions';
import { toast } from 'react-toastify';
import _ from 'lodash'
import {
    getPendingLessonAction,
    cancelLessonActin
} from "../../Redux/Actions";

class PendingLessonPage extends React.Component {
    timer = null
    
    state = {
        id: '',
        data: [],
        filteredList: [],
        
        searchText: '',
        currentPage: 1,
        totalPage: 1,
        displayCount: 10,

        depth: 0,
        selectedLesson: '',

        selected_id: 0,
        incomingLessonInfo: {},

        seconds: 10,

        isOpenModal: false,
        current_timezone: moment.tz.guess(true)

    }

    static getDerivedStateFromProps(props, state) {
        const id = (props.match.params.id && props.match.params.id.length > 0) ? props.match.params.id : '';
        return {
            id: id,
            depth: props.depth,
            incomingLessonInfo: props.incomingLessonInfo
        };
    }

    componentDidMount() {
        const { current_timezone } = this.state;
        const timezone = moment.tz(moment.utc(), current_timezone).utcOffset()/60;
        
        this.props.getPendingLessonAction(timezone).then(()=>{
            this.filterData('', 10);
            if(this.props.match.params.id){
                const {pendingLesson} = this.props
                var selectedLesson = _.find(pendingLesson, {id: Number(this.props.match.params.id)})
                this.setState({selectedLesson})
            }
        })
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        this.timer = null;
    }

    componentDidUpdate(prevProps, prevState) {
        const prevID = (prevProps.match.params.id && prevProps.match.params.id.length > 0) ? prevProps.match.params.id : '';
        const newID = (this.props.match.params.id && this.props.match.params.id.length > 0) ? this.props.match.params.id : '';
        if (prevID === newID && prevID !== '' && newID !== '') {
            if (this.state.depth !== 1 && prevState.depth === 0) {
                let index = this.state.data.findIndex(item => item.id === parseInt(newID, 10));
                let lesson = this.state.data[index];
                this.props.updateTitle('push', 'Pending Lessons', 2);
            }
        }
    }

    changeDisplayCount = (val) => {
        const { searchText } = this.state;
        this.filterData(searchText, val.name);
    }

    changeSearchText = (val) => {
        const { displayCount } = this.state;
        this.filterData(val, displayCount);
    }

    changeCurrentPage = (direction) => {
        const { currentPage, totalPage } = this.state;
        if ((direction === 'previous' && currentPage === 1) || (direction === 'next' && currentPage === totalPage)) return;
        this.setState({
            currentPage: direction === 'next' ? currentPage + 1 : currentPage - 1
        })
    }

    filterData = (searchText, displayCount) => {
        const { data } = this.state;
        const {pendingLesson} = this.props;
        const filteredList = pendingLesson.filter(item => item.tutor.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1);
        const totalPage = Math.ceil(filteredList.length / displayCount);

        this.setState({
            searchText: searchText,
            filteredList: filteredList,
            totalPage: totalPage,
            displayCount: displayCount,
            currentPage: 1
        })
    }

    viewItem = (e, item) => {
        e.stopPropagation();
        this.props.history.push(`/pending-lesson/${item.id}`);
    }

    openVideoModal = (item) => {
        this.props.toggleVideoModal(true, item.actions);
    }

    cancelLesson = (item) => {
        this.setState({cancelItem: item})
        this.setState({isOpenModal: true})
    }

    deleteConfirm =(deleteStatus, reason) => {
        this.setState({isOpenModal: false});
        if(deleteStatus){
            const formData = new FormData();
            formData.append('action', 'cancel');
            formData.append('reason', reason);
            formData.append('_method', 'PUT');
            this.props.cancelLessonActin(formData, this.state.cancelItem.id).then(()=>{
                if(this.props.cancelLessonStatus){
                    this.props.getPendingLessonAction();
                    toast("Cancel Successfully !");
                }
            })
        }
    }

    selectLesson = (e, item) => {

    }

    navigateToTutor = (e, tutor_id) => {
        e.stopPropagation();
        this.props.history.push(`/tutors/${tutor_id}`);
    }

    formatTime = (sec) => {
        let second = sec;
        const hour = Math.floor(second / 3600);
        second = second - hour * 3600;
        const minute = Math.floor(second / 60);
        second = second - minute * 60;
        return `${hour}:${minute < 10 ? '0'+minute : minute}:${second < 10 ? '0'+second : second}`;
    }

    joinLesson = (item) => {
        window.open(item.student_online_link);
    }

    seeRecording = (item) => {
        if(item.recorded_online_link){
            window.open(item.recorded_online_link);
        }
    }

    render() {
        const {pendingLesson} = this.props;
        const { searchText, displayCount, filteredList, currentPage, totalPage, depth, selectedLesson, selected_id, seconds } = this.state;
        const startIndex = displayCount * (currentPage - 1);
        const displayList = filteredList.slice(startIndex, startIndex + displayCount);
        return (
            <div className="pendinglesson-page v-r">
                {depth === 0 && <div className="options-area">
                    <InputBox className="search-input" value={searchText} icon={search} placeholder="Search..." onChange={val=>this.changeSearchText(val)} />
                    <div className="count-area v-c">
                        <RoundButton icon={left} className="show-mobile-flex" onClick={e => this.changeCurrentPage('previous')}/>
                        <p className="page-count">Page {currentPage} of {totalPage  }</p>
                        <RoundButton icon={left} className="arrow-btn show-web-flex" onClick={e => this.changeCurrentPage('previous')}/>
                        <RoundButton icon={right} className="arrow-btn" onClick={e => this.changeCurrentPage('next')}/>
                        <div className="combo-area v-c">
                            <p>Items</p>
                            <SelectBox value={displayCount} onChange={val => this.changeDisplayCount(val)}/>
                        </div>
                    </div>
                </div>}
                {depth === 0 && <div className="data-area v-r">
                    <div className="data-header show-web-flex v-c">
                        <div className="cell cell-1">TUTOR</div>
                        <div className="cell cell-2">SCHEDULE</div>
                        <div className="cell cell-3">HOURS</div>
                        <div className="cell cell-5">CANCELLED</div>
                        <div className="cell cell-6">ACTIONS</div>
                    </div>
                    <div className="data-list show-web-flex v-r">
                        {displayList.map((item, index) => <div className={`data-row ${Math.floor((new Date(item.sched) - new Date()) / 1000) > 0 && Math.floor((new Date(item.sched) - new Date()) / 1000) < 86400 ? 'selected' : ''}`} key={index} onClick={e => this.selectLesson(e, item)} onBlur={e=>this.setState({selected_id:0})} tabIndex={index}>
                            <div className="cell cell-1"><p style={{cursor:'pointer'}} onClick={e => this.navigateToTutor(e, item.tutor.id)}>{item.tutor.name}</p></div>
                            <div className="cell cell-2"><span style={{cursor:'pointer'}} onClick={e => this.viewItem(e, item)}>{moment(item.sched).local().format('MMM DD, YYYY hh:mm a')}</span></div>
                            <div className="cell cell-3">{item.hours}</div>
                            <div className="cell cell-5">{item.cancel_time ? item.cancel_time : 'NO'}</div>
                            <div className="cell ">
                                <div className={`v-c ${item.cancel_time ? '' : 'active'}`}>
                                    {/* {!item.cancel_time &&<p>{Math.floor((new Date() - new Date(item.sched)) / 1000) > 0 ? 'Error: No link to online session' : `Online tutoring starts in ${moment(item.sched).local().format('MMM DD, YYYY hh:mm a')}`}</p>} */}
                                    {!item.cancel_time ? <React.Fragment>
                                        {Math.floor((new Date() - new Date(item.sched)) / 1000) > 0 ?
                                            <div className="v-c" onClick={e => this.seeRecording(item)}>
                                                <ReactSVG src={play_button} className="svg-icon"/> {item.recorded_online_link? "See Recording" : "No link to online session"}
                                            </div>
                                            :
                                            <p>{`Online tutoring starts in ${moment(item.sched).local().format('MMM DD, YYYY hh:mm a')}`}</p>
                                        }
                                    </React.Fragment> : 'No link to online session'}
                                </div>
                            </div>
                            <div className="cell cell-6 v-c">
                            {( Math.floor((new Date() - new Date(item.sched)) / 1000) > -1800 && Math.floor((new Date() - new Date(item.sched)) / 1000) < (3600 * Number(item.hours)) ) && <button className="btn-2 btn-2-outline cancel-btn outline-btn note-btn" onClick={e => this.joinLesson(item)}>Join</button>}
                            {(!item.cancel_time && Math.floor((new Date() - new Date(item.sched)) / 1000) < 0) && <button className="btn-2 btn-2-outline cancel-btn outline-btn" onClick={e => this.cancelLesson(item)}>Cancel</button>}
                            </div>
                        </div>)}
                    </div>
                    <div className="show-mobile-flex v-r">
                        {displayList.map((item, index) => <div className="mobile-row v-r" key={index}>
                            <div className="data-row">
                                <div className="left-col v-r"> <div className="col-header">TUTOR</div> <p className="active" onClick={e => this.navigateToTutor(e, item.tutor.id)}>{item.tutor.name}</p> </div>
                                <div className="right-col v-r"> <div className="col-header">SCHEDULE</div> <p className="active" onClick={e => this.viewItem(e, item)}>{moment(item.sched).local().format('MMM DD, YYYY hh:mm a')}</p> </div>
                            </div>
                            <div className="data-row">
                                <div className="left-col v-r"> <div className="col-header">HOURS</div> <p>{item.hours}</p> </div>
                            </div>
                            <div className="data-row">
                                <div className="left-col v-r"> <div className="col-header">CANCELLED</div> <p>{item.cancel_time ? item.cancel_time : 'NO'}</p> </div>
                                <div className="right-col v-r"> 
                                    {/* {!item.cancel_time &&<p>{Math.floor((new Date() - new Date(item.sched)) / 1000) > 0 ? 'Error: No link to online session' : `Online tutoring starts in ${moment(item.sched).local().format('MMM DD, YYYY hh:mm a')}`}</p>} */}
                                    <div className={`v-c ${item.cancel_time ? '' : 'active'}`}>
                                        {!item.cancel_time ? 
                                            <React.Fragment>
                                                {Math.floor((new Date() - new Date(item.sched)) / 1000) > 0 ?
                                                    <div className="v-c" onClick={e => this.seeRecording(item)}>
                                                        <ReactSVG src={play_button} className="svg-icon"/> {item.recorded_online_link? "See Recording" : "No link to online session"}
                                                    </div>
                                                    :
                                                    <p>{`Online tutoring starts in ${moment(item.sched).local().format('MMM DD, YYYY hh:mm a')}`}</p>
                                                }
                                            </React.Fragment>
                                            : 
                                            'No link to online session'
                                        }
                                    </div>
                                 </div>
                            </div>
                            {/* <div className="cell cell-6 v-c"> */}
                            {( Math.floor((new Date() - new Date(item.sched)) / 1000) > -1800 && Math.floor((new Date() - new Date(item.sched)) / 1000) < (3600 * Number(item.hours)) ) && <button className="btn-2 btn-2-outline cancel-btn outline-btn note-btn" onClick={e => this.joinLesson(item)}>Join</button>}
                            {(!item.cancel_time && Math.floor((new Date() - new Date(item.sched)) / 1000) < 0) && <button className="btn-2 btn-2-outline cancel-btn outline-btn" onClick={e => this.cancelLesson(item)}>Cancel</button>}
                            {/* </div> */}
                        </div>)}
                    </div>
                </div>}
                {depth === 1 && <div className="pending-detail v-r">
                    <h1 className="show-web">Tutoring Session</h1>
                    {/* {Math.floor((new Date(selectedLesson.sched) - new Date()) / 1000) > 0 && Math.floor((new Date(selectedLesson.sched) - new Date()) / 1000) < 1800 && <div className="notification show-mobile-flex v-c">
                        <p>Online tutoring starts in {this.formatTime(seconds)}</p>
                        <button className="join-btn" onClick={e => this.joinLesson(selectedLesson)}>Join</button>
                    </div>} */}
                    <div className="detail-list v-r">
                        <div className="detail-row v-c">
                            <div className="detail-title">Tutor</div>
                            <div className="detail-content name"><p style={{cursor:'pointer'}} onClick={e => this.navigateToTutor(e, selectedLesson.tutor.id)}>{selectedLesson? selectedLesson.tutor.name : ''}</p></div>
                        </div>
                        <div className="detail-row v-c">
                            <div className="detail-title">Start Time</div>
                            <div className="detail-content">{moment(selectedLesson.sched).local().format('MMM DD, YYYY hh:mm a')}</div>
                        </div>
                        <div className="detail-row v-c">
                            <div className="detail-title">Length</div>
                            <div className="detail-content">{selectedLesson.hours}</div>
                        </div>
                        {/* <div className="detail-row v-c">
                            <div className="detail-title">Money paid</div>
                            <div className="detail-content">$50.00</div>
                        </div> */}
                        <div className="detail-row v-c">
                            <div className="detail-title">Booked on</div>
                            <div className="detail-content">{moment(selectedLesson.sched).local().format('MMM DD, YYYY hh:mm a')}</div>
                        </div>

                        {(!selectedLesson.cancel_time && Math.floor((new Date() - new Date(selectedLesson.sched)) / 1000) < 0) && (
                            <div className="detail-row v-c">
                                <div className="detail-title">Cancellation</div>
                                <div className="detail-content"> <button className="btn-2 btn-2-outline cancel-btn outline-btn" onClick={e => this.cancelLesson(selectedLesson)}>Cancel</button></div>
                            </div>
                        )}
                    </div>
                    <h1> Description </h1>
                    <div className="group">
                        <h2>Client:</h2>
                        <span>{selectedLesson.client_note}</span>
                    </div>
                    <div className="group">
                        <h2>Tutor:</h2>
                        <span>{selectedLesson.tutor_note}</span>
                    </div>
                </div>}
                <ConfirmModal
                    isOpenModal={this.state.isOpenModal}
                    deleteConfirm={(e, r) => this.deleteConfirm(e, r)}
                    cancelTitle = "Delete lesson?"
                    cancelLesson = {true}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    incomingLessonInfo: state.Lesson.info || {},
    pendingLesson: state.Core.pendingLesson,
    cancelLessonStatus: state.Auth.cancelLessonStatus
});

export default connect(mapStateToProps, { 
    toggleVideoModal, 
    toggleJoinLesson,
    getPendingLessonAction,
    cancelLessonActin
})(withRouter(PendingLessonPage));