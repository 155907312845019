import React, {useEffect, useMemo, useState} from "react";
import './style.scss'
import CustomCircleTimer from "../../CustomCircleTimer";
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash'
import {
    NEXT_ASSESSMENT_QUESTION,
    SET_CURRENT_ASSESSMENT_QUESTION,
    SKIP_ASSESSMENT_QUESTION
} from 'src/Redux/Type'

export default function QuestionAction({question, onIndexChanged }) {
    const dispatch = useDispatch()
    const [selectedAnwer, setSelectedAnswer] = useState(undefined)
    const [isCountDownTime, setIsCountDownTime] = useState(true);
    const [answerTime, setAnswerTime] = useState(0);
    const [allTime, setAllTime] = useState(0);
    const [activeCountUp, setActiveCountUp] = useState(false);
    const [countUpTime, setCountUpTime] = useState(0);

    const getColorsTime = (time) => {
        return [time, time * 2/3, time/3, 0]
    }
    const {
        active_assessment,
        assessment_questions,
        answered_questions
    } = useSelector(state => state.Mims)

    const currentIndex = useMemo(() => {
        if (_.isEmpty(assessment_questions)) return -1;
        const index = _.findIndex(assessment_questions, function (item) {
            return item.id === question.id
        })
        console.log(`current question index: ${index}`)
        return index;
    }, [question.id])

    const onNext = () => {
        const next_question = isLastQuestion() ? null : assessment_questions[currentIndex + 1];
        if (selectedAnwer === undefined) {
            dispatch({ 
                type: SET_CURRENT_ASSESSMENT_QUESTION, 
                data: { question: next_question, is_last: isLastQuestion(), total_time: allTime }
            })
        } else {
            const answer = { question_id: question.id, option: selectedAnwer, timer_second: answerTime + countUpTime }
            const payload = {
                next_question: next_question,
                current_answer: answer,
                total_time: allTime,
                is_last: isLastQuestion()
            }
            dispatch({type: NEXT_ASSESSMENT_QUESTION, data: payload})
        }
        setSelectedAnswer(undefined)
        resetQuestionTimer()
    }

    useEffect(() => {
        onIndexChanged(currentIndex)
    }, [currentIndex])

    const onSkip = () => {
        if (isLastQuestion()) return;
        dispatch({ 
            type: SKIP_ASSESSMENT_QUESTION, 
            data: { question: assessment_questions[currentIndex + 1], total_time: allTime}
        })
        setSelectedAnswer(undefined)
        resetQuestionTimer()
    }

    const onBack = () => {
        if (currentIndex === 0) return;
        dispatch({ 
            type: SET_CURRENT_ASSESSMENT_QUESTION, 
            data: { question: assessment_questions[currentIndex - 1], total_time: allTime }
        })
        setSelectedAnswer(undefined)
        resetQuestionTimer()
    }

    const resetQuestionTimer = () => {
        setCountUpTime(0)
        setActiveCountUp(false)
        setAnswerTime(0)
        setIsCountDownTime(true)
    }

    const getAnswerTime = (time) => {
        setAnswerTime(time)
        if (time === question.timer) {
          setActiveCountUp(true)
        }
    }

    const getAllTime = (time) => {
        setAllTime(time)
        if(time === active_assessment.test_time) {
            dispatch({ 
                type: SET_CURRENT_ASSESSMENT_QUESTION, 
                data: { question: null, is_last: true, total_time: allTime }
            })
        }
    }

    const onTimeUpUpdate = (time) => {
        setCountUpTime(time / 1000)
    }

    const isLastQuestion = () => currentIndex === assessment_questions.length - 1

    const optionIsSelected = (item) => {
        const index = _.findIndex(answered_questions, function(answer) {
            return (answer.question_id === question.id) && item === answer.option
        })
        if (index === -1) {
            return selectedAnwer === item
        } else return _.isEmpty(selectedAnwer) ? true : false;
    }

    return (
        <div className="question-action card">
            <div className="timer-wrapper">
                <div className="item">
                    <CustomCircleTimer 
                        keyId={question.id} 
                        duration={question.timer} 
                        isCountDownTime={isCountDownTime}
                        colorsTime={getColorsTime(question.timer)} 
                        initialRemainingTime={question.timer}
                        getAnswerTime={getAnswerTime}
                        activeCountUp={activeCountUp}
                        size={115}
                        onTimeUpUpdate={onTimeUpUpdate} />
                    <div className="tag">Question Time</div>
                </div>
                <div className="item">
                    <CustomCircleTimer 
                        keyId={active_assessment.id} 
                        duration={active_assessment.test_time} 
                        isCountDownTime={true}
                        colorsTime={getColorsTime(active_assessment.test_time)}
                        initialRemainingTime={active_assessment.test_time}
                        getAnswerTime={getAllTime}
                        activeCountUp={false}
                        size={115}
                        onTimeUpUpdate={() => null}
                        />
                    <div className="tag">All Time</div>
                </div>
            </div>
            <div className="answer-section">
                <h4 className="label">Your Answer</h4>
                {
                   question.options.map((item, index) => (
                        <div className={`answer-item ${optionIsSelected(item) ? 'selected': ''}`} key={index} 
                        onClick={() => setSelectedAnswer(item)}>
                            <div className="answer-check">
                                <div className="inner-circle"></div>
                            </div>
                            <div className="text">{item}</div>
                        </div>
                    ))
                }
            </div>
            <div className="btn-section">
                <div className={`btn-custom btn-light ${currentIndex === 0 ? 'disabled' : ''}`} onClick={() => onBack()}>BACK</div>
                <div className="pagination-show"><span>{currentIndex + 1}</span> of {assessment_questions.length}</div>
                <div className="btn-custom skip-btn" onClick={() => onSkip()}>SKIP</div>
                {/* Note: Next button color varies based on question index and time */}
                <div className={`btn-custom primary-btn ${ isLastQuestion() ? 'last': ''}`} onClick={() => onNext()}>
                    { isLastQuestion() ? 'FINISH' : 'NEXT'}
                    </div>
            </div>
        </div>
    )
}