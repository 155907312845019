import React from 'react';
// import Modal from 'react-modal';
import { Modal } from 'react-bootstrap';
import add from '../../Assets/Icon/add.svg';
import { ReactSVG } from 'react-svg'

import './ConfirmModal.scss';

export default class ConfirmModal extends React.Component {
    state = {
        reason: ''
    }
    confirm = (status) => {
        const {reason} = this.state
        this.props.deleteConfirm(status, reason)
    }

    render() {
        const {isOpenModal, cancelTitle, cancelLesson} = this.props
        const {reason} = this.state
    return (
        <Modal show={isOpenModal} className="confirm-modal-component">
            <div className="confirm-content">
                <div className="close-btn"> <ReactSVG src={ add } className='icon-close' onClick={ () => this.confirm(false) }/> </div>
                <div className="content-area v-r">
                    <h1>{cancelTitle}</h1>
                    {cancelLesson && <div>
                        <h2>reason:</h2>
                        <textarea className="textarea-note" value={reason} onChange={e => this.setState({reason: e.target.value})} />
                    </div>}
                    <div className="confirm-btn-area">
                        <div className="confirm-btn" onClick={()=>this.confirm(true)}>
                            YES
                        </div>
                        <div className="confirm-btn" onClick={()=>this.confirm(false)}>
                            NO
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
  };
};