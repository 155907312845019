import React, { useState, useEffect } from 'react';
import './style.scss';
import { useSelector, useDispatch } from 'react-redux';
import { PlannerPromptModal, Topic } from 'src/Components';
import {
  getCurrentPlanAction,
  setActiveAssessmentAction,
  toggleAssessmentModal,
  toggleAssessmentExitModal,
  toggleQuizModal,
  togglePlannerModalAction,
  setActiveFlashcard,
  toggleQuizExitModal,
  setActiveExam,
  togglePlannerExitModal,
  togglePlannerPrompt
} from 'src/Redux/Actions';
import AsssessmentComponent from 'src/Components/MIMS/Assessment';
import QuizComponent from 'src/Components/MIMS/Quiz';
import _ from 'lodash';
import PlannerComponent from 'src/Components/MIMS/Planner';

export default function MDashboardPage() {
  const dispatch = useDispatch();

  const {
    isPlannerPromptOpen,
    isAssessmentModalOpen,
    isQuizModalOpen,
    isPlannerModalOpen,
    assessment_is_active,
    showing_assessment_solution,
    practice_flashcards,
    taking_quiz,
    showing_flashcards,
    plan_stage
  } = useSelector(state => state.Mims)
  const {
    topicList
  } = useSelector(
    state => state.Topic,
  );

  const {
    myProfileInfo
  } = useSelector(state => state.Core)

  const { 
    assessments,
    exams
  } = useSelector(state => state.Mims)
  // const topicList = JSON.parse(sessionStorage.getItem('topicList'))

  const getAssessments = (topicId) => {
    if(_.isEmpty(assessments)) return [];
    return assessments.filter(item => item.mims_topic_id === topicId)
  }

  const getFlashcards = (topic_id) => {
    if(_.isEmpty(practice_flashcards)) return [];
    return practice_flashcards.filter(item => item.mims_topic_id === topic_id)
  }

  const getExams =  (topicId) => {
    if(_.isEmpty(exams)) return [];
    return exams.filter(exam => exam.topics.map(item => item.id).includes(topicId))
  }

  const onAssessmentStart = (assessment) => {
    if(isQuizModalOpen) dispatch(toggleQuizModal(false))
    dispatch(setActiveAssessmentAction(assessment))
    dispatch(toggleAssessmentModal(true))
  }

  const onFlashcardOpen = (flashcard) => {
     dispatch(setActiveFlashcard(flashcard))
     dispatch(toggleQuizModal(true))
  }

  useEffect(() => {
    dispatch(getCurrentPlanAction());
  }, [dispatch]);

  useEffect(() => {
    if (myProfileInfo && myProfileInfo.has_planner === false) {
      if (_.isEmpty(sessionStorage.getItem("planner_prompt_shown"))) {
        dispatch(togglePlannerPrompt(true))
        sessionStorage.setItem("planner_prompt_shown", "shown")
      }
    }
  }, [dispatch, myProfileInfo])

  // useEffect(() => {
  //   sessionStorage.setItem("topicList", JSON.stringify(topicList))
  // })

  const onHideAssessment = () => {
    if(assessment_is_active || showing_assessment_solution) {
        dispatch(toggleAssessmentExitModal(true))
    } else {
      dispatch(toggleAssessmentModal(false))
    }
  }

  const onHideQuiz = () => {
    if(showing_flashcards || taking_quiz) {
      dispatch(toggleQuizExitModal(true))
    } else {
      dispatch(toggleQuizModal(false))
    }
  } 

  const onPlannerStart = (exam) => {
    dispatch(setActiveExam(exam))
    dispatch(togglePlannerModalAction(true))
  }

  const onPlannerExit = () => {
    if (plan_stage > 0) {
      dispatch(togglePlannerExitModal(true))
    } else {
      dispatch(togglePlannerModalAction(false))
    }
  }

  return (
    <>
      <div className='m-dashboard-page'>
        <div className='topic-list-content'>
          {topicList.map((item, index) => (
            <Topic
              key={index}
              info={item}
              assessments={getAssessments(item.id)}
              exams={getExams(item.id)}
              onExamPlannerClicked={(exam) => onPlannerStart(exam)}
              onAssessmentClicked={(asmt) => onAssessmentStart(asmt)}
              flashcards={getFlashcards(item.id)}
              onFlashcardClicked={(item) => onFlashcardOpen(item)}
            />
          ))}
        </div>
      </div>
      <AsssessmentComponent showModal={isAssessmentModalOpen} onHide={() => onHideAssessment()}/>
      <QuizComponent showModal={isQuizModalOpen} onHide={() => onHideQuiz()}/>
      <PlannerComponent showModal={isPlannerModalOpen} onHide={() => onPlannerExit()}/>
      <PlannerPromptModal 
        show={isPlannerPromptOpen} 
        onExit={() => dispatch(togglePlannerPrompt(false))}
        onCreate={() => {
          dispatch(togglePlannerPrompt(false))
          if (exams && exams.length > 0) onPlannerStart(exams[0])
        }}
        />
    </>
  );
}
