import {
  VIDEO_MODAL_TOGGLE,
  MUSE_VIDEO_MODAL_TOGGLE,
  CREDIT_MODAL_TOGGLE,
  REVIEW_MODAL_TOGGLE,
  TOGGLE_QUESTION_MODAL,
  TOGGLE_NOTE_MODAL,
  TOGGLE_UPGRADE_PLAN_MODAL,
  TOGGLE_GRAPH_MODAL,
  TOGGLE_FINISH_TRIAL_MODAL,
} from '../Type';

const INITIAL = {
  isVideoModalOpen: false,
  isMuseVideoModalOpen: false,
  isCreditModalOpen: false,
  isReviewModalOpen: false,
  creditModalInfo: '',

  isQuestionModalOpen: false,
  question_answered: [],

  questionInfo: null,
  isNoteModal: false,
  note: "",

  isUpgradePlanModal: false,
  upgradeModalInfo: {},

  isGraphModalOpen: false,

  isFinishTrialModal: false,
}

export default (state = INITIAL, action) => {
  switch (action.type) {
    case VIDEO_MODAL_TOGGLE: {
      const { isOpen, info } = action.data;
      return { ...state, isVideoModalOpen: isOpen, info };
    }

    case MUSE_VIDEO_MODAL_TOGGLE: {
      const { isOpen, info } = action.data;
      return { ...state, isMuseVideoModalOpen: isOpen, videoInfo: info };
    }

    case CREDIT_MODAL_TOGGLE: {
      const { isOpen, info } = action.data;
      return { ...state, isCreditModalOpen: isOpen, creditModalInfo: info };
    }

    case REVIEW_MODAL_TOGGLE: {
      const { isOpen, info } = action.data;
      return { ...state, isReviewModalOpen: isOpen, info };
    }

    case TOGGLE_QUESTION_MODAL: {
      const { isOpen, info } = action.data;
      return {
        ...state,
        isQuestionModalOpen: isOpen,
        topic_id: info.topic_id,
        have_access: info.have_access,
        concept: info.concept,
        question_answered: info.question_answered
      };
    }

    /**
     * Note Modal
     */
    case TOGGLE_NOTE_MODAL: {
      const { isNoteModal, note } = action.data;
      return {
        ...state,
        isNoteModal,
        note,
      };
    }

    /**
     * Upgrade Plan Modal
     */
    case TOGGLE_UPGRADE_PLAN_MODAL: {
      const { isUpgradePlanModal, upgradeModalInfo } = action.data;
      return {
        ...state,
        isUpgradePlanModal,
        upgradeModalInfo,
      };
    }

    /**
     * Graph Modal
     */
    case TOGGLE_GRAPH_MODAL: {
      const { isGraphModalOpen, info } = action.data;
      return {
        ...state,
        isGraphModalOpen,
        topic_id: info.topic_id,
      };
    }

    /**
     * Finish Trial Modal
     */
    case TOGGLE_FINISH_TRIAL_MODAL: {
      const { isFinishTrialModal } = action.data;
      return {
        ...state,
        isFinishTrialModal,
      };
    }

    default:
      return state;
  }
}
