import { get, post, remove } from "./Api";

export const getMPlan = (topic_id) => {
  return get(`/mimsrateplans/${topic_id}`);
}

export const getCurrentPlan = () => {
  return get(`/mimsrateplans`);
}

export const cancelPlan = (data) => {
  return post(`/mimsrateplans`, data);
}

export const finishTrial = () => {
  return post(`/mimsrateplans`, {perform: "finish_trial"});
}
