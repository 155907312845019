import React from 'react';
import './ClientFeedback.scss';
import StarRatings from 'react-star-ratings';
import { StarSVGViewBox, StarSVGIconPath } from '../../Constant';
import { Avatar } from '../../Components';
import moment from 'moment-timezone';

export default class ClientFeedback extends React.Component {
    render() {
        const { item, className } = this.props;
        return (
            <div className={`clientfeedback-component ${className || ''}`}>
                <Avatar className="initial avatar" info={item.student} />
                <div className="user-info">
                    <div className="basic"> 
                        <div><span className="name">{item.student.name}</span> from {item.student.location}</div>
                        <div>{moment(item.reviewed_on).format('MMM DD, YYYY hh:mm a')}</div>
                    </div>
                    <div className="sub-info">
                        <div className="subject">Knowledge: </div>
                        <StarRatings
                            rating={item.rating1}
                            starDimension="16px"
                            starSpacing="2.14px"
                            starRatedColor="#23A4EF"
                            starEmptyColor="#D3EDFC"
                            svgIconViewBox={StarSVGViewBox}
                            svgIconPath={StarSVGIconPath}
                        />
                    </div>
                    <div className="sub-info">
                        <div className="subject">Presentation: </div>
                        <StarRatings
                            rating={item.rating2}
                            starDimension="16px"
                            starSpacing="2.14px"
                            starRatedColor="#23A4EF"
                            starEmptyColor="#D3EDFC"
                            svgIconViewBox={StarSVGViewBox}
                            svgIconPath={StarSVGIconPath}
                        />
                    </div>
                    <div className="description">{item.review_content}</div>
                </div>
            </div>
        );
    }
}
