import React, { useEffect, useState } from "react";
import './style.scss';
import ScoreGreen from 'src/Assets/MIMS/Quiz/ScoreGreen.png';
import { useSelector } from "react-redux";

export default function ScoreComponent({onBoost, onContinue}) {
    const [showPassed, setShowPassed] = useState(false)
    const [score, setScore] = useState(0)
    const [color, setColor] = useState('')

    const {
        active_flashcard,
        quiz_completed,
        quiz_answers,
        flashcard_concept
    } = useSelector(state => state.Mims)

    useEffect(() => {
        const correct = (quiz_answers.filter(item => item.correct)).length
        const passingScore = isNaN(active_flashcard.passing_score) ? 90 : active_flashcard.passing_score
        const percentage = calculatePercentage(correct, quiz_answers.length)
        setScore(percentage.toFixed(0))
        if (percentage >= passingScore) {
            setShowPassed(true)
        } else {
            setShowPassed(false)
            setColor(percentage < 30 ? 'failed' : 'orange')
        }
    }, [quiz_completed])

    const calculatePercentage = (value, max) => value / max * 100

    function QuizPassedMessage() {

        return (
            <div className="score-message">
                <div className="score-title"> Impressive! 🎉</div>
                <p>Scored {score}% on the quiz! 🌟 Keep going with flashcard learning! 📚 Unlock more knowledge, stay on the path to success! <br></br>💪 Happy learning! 😊</p>
                <div className="primary-btn" onClick={onContinue}>CONTINUE</div>
                <div className="hint-message">Continue with the next set<br></br> of 10 flashcards</div>
            </div>
        )
    }

    function QuizFailedMessage() {

        return (
            <div className="score-message">
                <div className="score-title">Great effort on the quiz! 🌟</div>
                <p>To reinforce your learning, we have a second quiz lined up just for you! 📚 Click the button below to boost your understanding and score even higher this time! 💪 You've got this! 👏🏼 Good luck! 🍀BOOST</p>
                <div className="primary-btn" onClick={onBoost}>BOOST</div>
            </div>
        )
    }

    return (
        <div className="card quiz-score">
            <div className="title"><span>{flashcard_concept}</span> Score</div>
            <div className={`score-info ${color}`}>
                { showPassed && <img src={ScoreGreen}/>}
                <div>{score}%</div>
            </div>
            <div className="divider"></div>
            <div className="score-message-wrapper">
                { showPassed && <QuizPassedMessage />}
                { !showPassed && <QuizFailedMessage />}
            </div>
        </div>
    )
} 