import React from 'react';
import './InputBox.scss';
import { ReactSVG } from 'react-svg'

export default class InputBox extends React.Component {
  state = {
    value: ''
  }

  componentDidMount() {
    this.setState({
      value: this.props.value || ''
    })
  }

  changeText = (e) => {
    this.setState({
      value: e.currentTarget.value
    })
    this.props.onChange(e.currentTarget.value);
  }

  render() {
    const { className, icon, placeholder, type } = this.props;
    const { value } = this.state;
    return (
      <div className={`inputbox-container v-c ${className || ''}`}>
        {icon && <ReactSVG src={icon} className="svg-icon" />}
        <input type={type || 'text'} className={`input-text ${!icon ? 'no-icon' : ''}`} placeholder={placeholder} value={value} onChange={e => this.changeText(e)} />
      </div>
    );
  }
}
