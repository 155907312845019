import React from 'react';
import './style.scss';
import search from 'src/Assets/Icon/search.svg';
import { InputBox, ReviewUser, } from 'src/Components';

export default function ReviewUserList({
  className = '',
  searchText,
  selectedId,
  onChangeSearchText,
  listInfo,
  onClick
}) {

  return (
    <div className={`user-list-component ${className}`} >
      <div className='search-container'>
        <InputBox
          className="search-contact"
          icon={search}
          placeholder="Search..."
          value={searchText}
          onChange={onChangeSearchText}
        />
      </div>

      <div className='main-user-list'>
        {listInfo.map((item, index) => (
          <ReviewUser
            key={index}
            info={item}
            selectedId={selectedId}
            onClick={onClick}
          />
        ))}
      </div>
    </div >
  );
}