import React from "react";
import './style.scss';
import { Modal } from "react-bootstrap";

export default function PlannerPromptModal({show, onExit, onCreate}) {

    return (
       <Modal show={show} className="planner-prompt-modal">
            <Modal.Body>
                <h4 className="text-center">Create Your Personalized Study Plan</h4>
                <p className="message">You currently don't have a study plan. We advice that you create one to facilitate your studies.</p>
                <div className="action-content">
                    <div className="outline-btn red" onClick={onExit}>
                        <div>REMIND ME LATER</div>
                    </div>
                    <div onClick={onCreate} className="outline-btn primary">CREATE NOW</div>
                </div>
            </Modal.Body>
       </Modal>
    )
}