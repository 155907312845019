import {
  VIDEO_MODAL_TOGGLE,
  MUSE_VIDEO_MODAL_TOGGLE,
  CREDIT_MODAL_TOGGLE,
  REVIEW_MODAL_TOGGLE,
  TOGGLE_QUESTION_MODAL,
  TOGGLE_NOTE_MODAL,
  TOGGLE_UPGRADE_PLAN_MODAL,
  TOGGLE_GRAPH_MODAL,
  TOGGLE_FINISH_TRIAL_MODAL,
} from '../Type';

export const toggleVideoModal = (isOpen, info = null) => {
  return {
    type: VIDEO_MODAL_TOGGLE,
    data: { isOpen, info }
  }
}

export const toggleMuseVideoModal = (isOpen, info = {}) => {
  return {
    type: MUSE_VIDEO_MODAL_TOGGLE,
    data: { isOpen, info }
  }
}

export const toggleCreditModal = (isOpen, info) => {
  return {
    type: CREDIT_MODAL_TOGGLE,
    data: { isOpen, info }
  }
}

export const toggleReviewModal = (isOpen, info = {}) => {
  return {
    type: REVIEW_MODAL_TOGGLE,
    data: { isOpen, info }
  }
}

export const toggleQuestionModal = (isOpen, info = {}) => {
  return {
    type: TOGGLE_QUESTION_MODAL,
    data: { isOpen, info }
  }
}

export const toggleNoteModal = (isNoteModal, note = "") => {
  return {
    type: TOGGLE_NOTE_MODAL,
    data: { isNoteModal, note }
  }
}

export const toggleUpgradePlanModal = (isUpgradePlanModal, upgradeModalInfo = "") => {
  return {
    type: TOGGLE_UPGRADE_PLAN_MODAL,
    data: { isUpgradePlanModal, upgradeModalInfo }
  }
}

export const toggleGraphModal = (isGraphModalOpen, info = {}) => {
  return {
    type: TOGGLE_GRAPH_MODAL,
    data: { isGraphModalOpen, info }
  }
}

export const toggleFinishTrialModal = (isFinishTrialModal) => {
  return {
    type: TOGGLE_FINISH_TRIAL_MODAL,
    data: { isFinishTrialModal }
  }
}
