import React from "react";

export default function PlannerProgressIndicator({stage}) {

    return (
        <div className="progress-indicators">
            {
                Array(5)
                .fill(true)
                .map((item, index) => (
                    <div className={`item ${stage === index + 1 ? 'active' : ''}`} key={index}></div>
                ))
            }
        </div>
    )
}