import React from "react";
import './style.scss';
import { Modal } from "react-bootstrap";

export default function ConfirmNewPlanModal({show, onContinue, onCancel}) {
    
    return (
       <Modal show={show} className="confirm-planner-modal">
            <Modal.Body>
                <h4 className="text-center">Make New Plan ?</h4>
                <p className="text-center">Are you sure you want to delete your current plan ?</p>
                <div className="action-content">
                    <div className="outline-btn" onClick={onContinue}>
                        {/* <img className="" src={IconExit}/> */}
                        <div>YES, CONTINUE</div>
                    </div>
                    <div onClick={onCancel}>CANCEL</div>
                </div>
            </Modal.Body>
       </Modal>
    )
}