import React from 'react';
import './CancelSubscriptionPage.scss';
import { CheckBox, SelectDateTime, ConfirmModal } from '../../Components';
import { ReactSVG } from 'react-svg';
import left from '../../Assets/Icon/arrow-left.png';
import _ from 'lodash'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { toast } from "react-toastify";
import {
    getMypofileInfoAction,
    cancelSubscriptionAction
} from "../../Redux/Actions";
import { showSpinner, hideSpinner } from 'src/Util/Helper';

class CancelSubscriptionPage extends React.Component {
    state = {
        checked: false,
        reason_id: 1,
        selectedDate: '',
        suspendTutoring_reason_list: [
            { reason: 'Summer break', checked: false, selectTime: false },
            { reason: 'Christmas break', checked: false, selectTime: false },
            { reason: 'Don’t need tutoring this semester', checked: false, selectTime: false },
            { reason: 'Don’t need tutoring right now', checked: false, selectTime: false },
        ],
        select_date: null,
        suspend_submit: null,
        cancelSubscription_reason_list: [
            {
                reason: 'Tutor not a good fit',
                checked: false,
                detail: 'We are sorry to hear that your tutoring experience has not been a good fit. Would you like to try one hour of free tutoring with a different instructor?',
                no_description: 'We understand! Don’t hesitate to contact us if we can help in any area',
                yesNo: null,
                submit: false,
                yes_result: 'Thank you so much! Please allow 24-48 hours for our team to schedule a free lesson with a different tutor',
                no_result: 'We have successfully canceled your subscription',
                selectTime: false

            },
            {
                reason: 'Not happy with services',
                checked: false,
                detail: 'We are sorry to hear that the services did not meet our high standards and your expectations. As a family-owned business, we pride ourselves on a high standard of excellence. In an attempt to make things right, we can offer you one free hour of tutoring with a different instructor and a new account manager. Is this something you would be interested in? ',
                no_description: 'We understand! Don’t hesitate to contact us if we can help in any area',
                yesNo: null,
                submit: false,
                yes_result: 'Thank you so much! Please allow 24-48 hours for our team to schedule a free lesson with a different tutor and get you set up with a new account manager. ',
                no_result: 'We have successfully canceled your subscription',
                selectTime: false

            },
            {
                reason: 'Too expensive',
                checked: false,
                detail: 'We understand your concern and we don’t want cost to be the reason anyone can’t access high-quality tutoring services.  If we’re able to lower your rate, would you maintain your subscription? ',
                no_description: 'We understand! Don’t hesitate to contact us if we can help in any area ',
                yesNo: null,
                submit: false,
                yes_result: 'Thank you so much for trusting us with your tutoring needs. A member of our team will call you to see which plan works best for you. ',
                no_result: 'We have successfully canceled your subscription',
                selectTime: false,
                reason_id: 2

            },
            {
                reason: 'Don’t need tutoring right now',
                checked: false,
                detail: 'Do you want to suspend your subscription and reactivate it at a later date?',
                no_description: 'We understand! Don’t hesitate to contact us if we can help in any area ',
                yesNo: null,
                submit: false,
                yes_result: 'When would you like to restart your subscription?',
                no_result: 'We have successfully canceled your subscription',
                selectTime: true

            },
            {
                reason: 'Happy with services but no longer need tutoring',
                checked: false,
                detail: 'Do you want to suspend your subscription and reactivate it at a later date?',
                no_description: 'We understand! Don’t hesitate to contact us if we can help in any area',
                yesNo: null,
                submit: false,
                yes_result: 'When would you like to restart your subscription?',
                no_result: 'We have successfully canceled your subscription',
                selectTime: true

            },
        ],
        cancelIndex: '',
        suspendIndex: '',
        isOpenModal: false
    }

    componentDidMount() {
        this.props.getMypofileInfoAction().then(() => {
            if (this.props.myProfileInfo) {
                const { myProfileInfo } = this.props;
                if (myProfileInfo.plan_status !== 'active') {
                    this.setState({ isOpenModal: true })
                }
            }
        })
    }

    deleteConfirm = (deleteStatus) => {
        if (deleteStatus) {
            this.props.history.push("/your-plan");
        } else {
            this.setState({ isOpenModal: false })
        }
    }

    suspendTutoring = () => {
        this.setState({ reason_id: 2 });
    }

    suspendReasonCheck = (index, val) => {
        const { suspendTutoring_reason_list, suspendIndex } = this.state
        this.state.suspendTutoring_reason_list[index].checked = val
        this.setState({ suspendTutoring_reason_list });
        this.setState({ suspendIndex: index });
    }

    cancelSubscription = () => {
        this.setState({ reason_id: 3 });
    }

    cancelReasonCheck = (index, val) => {
        const { cancelSubscription_reason_list } = this.state
        this.state.cancelSubscription_reason_list[index].checked = val
        this.setState({ cancelSubscription_reason_list });
        this.setState({ cancelIndex: index })
    }

    yesNoCheck = (val) => {
        const { cancelSubscription_reason_list, cancelIndex } = this.state
        this.state.cancelSubscription_reason_list[cancelIndex].yesNo = val
        this.setState({ cancelSubscription_reason_list });
    }

    cancelSubmit = (status) => {
        const { cancelSubscription_reason_list, cancelIndex, selectedDate, reason_id } = this.state
        if (status && cancelIndex > 2) {
            this.state.cancelSubscription_reason_list[cancelIndex].submit = true
            this.state.cancelSubscription_reason_list[cancelIndex].selectTime = status
            this.setState({ cancelSubscription_reason_list });
        } else {

            showSpinner();

            const formData = new FormData()
            formData.append('reason_id', (status && cancelSubscription_reason_list[cancelIndex].reason_id) ? cancelSubscription_reason_list[cancelIndex].reason_id : status ? 1 : 3);
            formData.append('reason', cancelSubscription_reason_list[cancelIndex].reason);
            formData.append('future_date', selectedDate);
            this.props.cancelSubscriptionAction(formData).then(() => {
                hideSpinner();
                const { cancelSubscriptionStatus, cancelSubscriptionErr } = this.props
                if (cancelSubscriptionStatus) {
                    this.state.cancelSubscription_reason_list[cancelIndex].submit = true
                    if (cancelIndex > 2) {
                        this.state.cancelSubscription_reason_list[cancelIndex].selectTime = status
                    }
                    this.setState({ cancelSubscription_reason_list });
                    toast(cancelSubscriptionStatus);
                } else {
                    toast(cancelSubscriptionErr);
                }
            })
        }
    }

    backCancelDate = () => {
        const { cancelSubscription_reason_list, cancelIndex } = this.state
        this.state.cancelSubscription_reason_list[cancelIndex].submit = false
        this.setState({ cancelSubscription_reason_list });
    }

    timeSubmit = () => {
        showSpinner();
        const { cancelSubscription_reason_list, cancelIndex, selectedDate, reason_id } = this.state
        const formData = new FormData()
        formData.append('reason_id', 3);
        formData.append('reason', cancelSubscription_reason_list[cancelIndex].reason);
        formData.append('future_date', selectedDate);
        this.props.cancelSubscriptionAction(formData).then(() => {
            hideSpinner();
            const { cancelSubscriptionStatus, cancelSubscriptionErr } = this.props
            if (cancelSubscriptionStatus) {
                this.state.cancelSubscription_reason_list[cancelIndex].selectTime = null
                this.setState({ cancelSubscription_reason_list });
                toast(cancelSubscriptionStatus);
            } else {
                toast(cancelSubscriptionErr);
            }
        })
    }

    restartSubmit = (status) => {
        showSpinner();
        const { suspendTutoring_reason_list, suspendIndex, selectedDate, reason_id } = this.state
        const formData = new FormData()
        formData.append('reason_id', 3);
        formData.append('reason', suspendTutoring_reason_list[suspendIndex].reason);
        formData.append('future_date', selectedDate);
        this.props.cancelSubscriptionAction(formData).then(() => {
            hideSpinner();
            const { cancelSubscriptionStatus, cancelSubscriptionErr } = this.props
            if (cancelSubscriptionStatus) {
                this.state.suspendTutoring_reason_list[suspendIndex].selectTime = null
                this.setState({ suspend_submit: status })
                toast(cancelSubscriptionStatus);
            } else {
                toast(cancelSubscriptionErr);
            }
        })
    }

    endSubmit = (status) => {
        showSpinner();
        const { suspendTutoring_reason_list, suspendIndex, selectedDate, reason_id } = this.state
        const formData = new FormData()
        formData.append('reason_id', 3);
        formData.append('reason', suspendTutoring_reason_list[suspendIndex].reason);
        formData.append('future_date', selectedDate);
        this.props.cancelSubscriptionAction(formData).then(() => {
            hideSpinner();
            const { cancelSubscriptionStatus, cancelSubscriptionErr } = this.props
            if (cancelSubscriptionStatus) {
                this.state.suspendTutoring_reason_list[suspendIndex].selectTime = null
                this.setState({ suspend_submit: status })
                toast(cancelSubscriptionStatus);
            } else {
                toast(cancelSubscriptionErr);
            }
        })
    }

    selectDate = (selectedDate) => {
        this.setState({ selectedDate })
    }

    render() {
        const { myProfileInfo } = this.props
        const { checked, reason_id, suspendTutoring_reason_list, cancelSubscription_reason_list, select_date, suspend_submit, selectedDate } = this.state;
        return (
            <div className="cancelSubscription-page v-r v-c h-c">
                {myProfileInfo.plan_status === 'active' ?
                    <div>
                        {reason_id === 1 && <div>
                            <h2>What would you like to do?</h2>
                            {/* <div className="check-area">
                                <CheckBox className="check-box" checked={checked} onChange={val => this.setState({checked: val})} />
                                <div className="explain">Yes, I want GradeGetter to be able to auto charge my credit card.</div>
                            </div> */}
                            <div>
                                <button className="btn-2 btn-2-outline save-btn" onClick={e => this.suspendTutoring()}>Suspend Tutoring</button>
                                <button className="btn-2 btn-2-outline save-btn" onClick={e => this.cancelSubscription()}>Cancel Subscription</button>
                            </div>
                        </div>}
                        {reason_id === 2 && (
                            suspend_submit === null ?
                                (_.find(suspendTutoring_reason_list, { checked: true }) ?
                                    (select_date === null ?
                                        <div>
                                            <div className="center-btn">
                                                <img src={left} className="svg-icon back-icon" onClick={e => this.suspendReasonCheck(this.state.suspendIndex, false)} />
                                                <h2>Got it! Do you want to resume tutoring at a different date and time?</h2>
                                            </div>
                                            <div className="center-btn">
                                                <button className="btn-2 btn-2-outline save-btn" onClick={e => this.setState({ select_date: true })}>YES</button>
                                                <button className="btn-2 btn-2-outline save-btn" onClick={e => this.setState({ select_date: false })}>NO</button>
                                            </div>
                                        </div>
                                        :
                                        (select_date ?
                                            (
                                                <div>
                                                    <div className="center-btn">
                                                        <img src={left} className="svg-icon back-icon" onClick={e => this.setState({ select_date: null })} />
                                                        <h2 >When would you like to restart your subscription?</h2>
                                                    </div>
                                                    <SelectDateTime selectDate={this.selectDate} />
                                                    {selectedDate && <div>
                                                        <div className="center-btn">{moment(selectedDate).format('MMM DD, YYYY hh:mm a')}</div>
                                                        <div className="center-btn">
                                                            <button className="btn-2 btn-2-outline save-btn" onClick={e => this.restartSubmit(true)}>Submit</button>
                                                        </div>
                                                    </div>}
                                                </div>
                                            )
                                            :
                                            <div>
                                                <div className="center-btn">
                                                    <img src={left} className="svg-icon back-icon" onClick={e => this.setState({ select_date: null })} />
                                                    <h2>We understand! Don’t hesitate to contact us if we can help in any area</h2>
                                                </div>
                                                <div className="center-btn">
                                                    <button className="btn-2 btn-2-outline save-btn" onClick={e => this.endSubmit(false)}>Cancel subscription</button>
                                                </div>
                                            </div>
                                        )
                                    )
                                    :
                                    <div>
                                        <div className="center-btn">
                                            <img src={left} className="svg-icon back-icon" onClick={e => this.setState({ reason_id: 1 })} />
                                            <h2>Hi {myProfileInfo.first_name}! Why do you want to suspend tutoring services?</h2>
                                        </div>
                                        {suspendTutoring_reason_list.map((item, index) => (
                                            <div className="check-area" key={index}>
                                                <CheckBox className="check-box" checked={item.checked} onChange={val => this.suspendReasonCheck(index, val)} />
                                                <div className="explain">{item.reason}</div>
                                            </div>
                                        ))}
                                    </div>
                                )
                                :
                                <div>
                                    <h2>{suspend_submit ? 'Thanks! You’re subscription has been suspended until date' : 'Subscription is cancelled'}</h2>
                                </div>
                        )}
                        {reason_id === 3 && (
                            _.find(cancelSubscription_reason_list, { checked: true }) ?
                                (
                                    <div>
                                        {_.find(cancelSubscription_reason_list, { checked: true, yesNo: null, submit: false }) &&
                                            <div>
                                                <div className="center-btn">
                                                    <img src={left} className="svg-icon back-icon" onClick={e => this.cancelReasonCheck(this.state.cancelIndex, false)} />
                                                    <h2>{_.find(cancelSubscription_reason_list, { checked: true, submit: false }).detail}</h2>
                                                </div>
                                                <div className="center-btn">
                                                    <button className="btn-2 btn-2-outline save-btn" onClick={e => this.cancelSubmit(true)}>YES</button>
                                                    <button className="btn-2 btn-2-outline save-btn" onClick={e => this.yesNoCheck(false)}>NO</button>
                                                </div>
                                            </div>}
                                        {_.find(cancelSubscription_reason_list, { submit: true }) &&
                                            <div>
                                                <div className="center-btn">
                                                    {_.find(cancelSubscription_reason_list, { submit: true }).selectTime && <img src={left} className="svg-icon back-icon" onClick={e => this.backCancelDate()} />}
                                                    {_.find(cancelSubscription_reason_list, { submit: true }).selectTime !== null &&
                                                        <h2>{_.find(cancelSubscription_reason_list, { submit: true }).yesNo === false ? _.find(cancelSubscription_reason_list, { submit: true }).no_result : _.find(cancelSubscription_reason_list, { submit: true }).yes_result}</h2>
                                                    }
                                                </div>
                                                {_.find(cancelSubscription_reason_list, { submit: true }).selectTime && <div>
                                                    <SelectDateTime selectDate={this.selectDate} />
                                                    {selectedDate && <div>
                                                        <div className="center-btn">{moment(selectedDate).format('MMM DD, YYYY hh:mm a')}</div>
                                                        <div className="center-btn">
                                                            <button className="btn-2 btn-2-outline save-btn" onClick={e => this.timeSubmit()}>Submit</button>
                                                        </div>
                                                    </div>}
                                                </div>}
                                                {_.find(cancelSubscription_reason_list, { submit: true }).selectTime === null && <div>
                                                    <h2>Thanks! You’re subscription has been suspended until date</h2>
                                                </div>}
                                            </div>
                                        }
                                        {_.find(cancelSubscription_reason_list, { checked: true, yesNo: false, submit: false }) &&
                                            <div>
                                                <div className="center-btn">
                                                    <img src={left} className="svg-icon back-icon" onClick={e => this.yesNoCheck(null)} />
                                                    <h2>{_.find(cancelSubscription_reason_list, { checked: true, submit: false }).no_description}</h2>
                                                </div>
                                                <div className="center-btn">
                                                    <button className="btn-2 btn-2-outline save-btn" onClick={e => this.cancelSubmit(false)}>Cancel subscription</button>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                )
                                :
                                <div>
                                    <div className="center-btn">
                                        <img src={left} className="svg-icon back-icon" onClick={e => this.setState({ reason_id: 1 })} />
                                        {/* <h2>Hi {myProfileInfo.first_name}! Why do you want to suspend tutoring services?</h2> */}
                                        <h2>We’re sorry to hear you’d like to leave us. What is your primary reason for leaving?</h2>
                                    </div>
                                    {cancelSubscription_reason_list.map((item, index) => (
                                        <div className="check-area" key={index}>
                                            <CheckBox className="check-box" checked={item.checked} onChange={val => this.cancelReasonCheck(index, val)} />
                                            <div className="explain">{item.reason}</div>
                                        </div>
                                    ))}
                                </div>
                        )}
                    </div>
                    : myProfileInfo.plan_status === 'inactive' ?
                        <div>
                            <h2>Already Canceled</h2>
                        </div>
                        :
                        <div>
                            <h2>No Subscription</h2>
                        </div>
                }
                <ConfirmModal
                    isOpenModal={this.state.isOpenModal}
                    deleteConfirm={(e) => this.deleteConfirm(e)}
                    cancelTitle="Do you want to activate your subscription?"
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    myProfileInfo: state.Core.myProfileInfo,
    cancelSubscriptionStatus: state.Auth.cancelSubscriptionStatus,
    cancelSubscriptionErr: state.Auth.cancelSubscriptionErr,
});

export default connect(mapStateToProps, {
    getMypofileInfoAction,
    cancelSubscriptionAction
})(withRouter(CancelSubscriptionPage));
