import {
  UPDATE_M_PURCHASE, UPDATE_M_PURCHASE_SUCCESS, UPDATE_M_PURCHASE_FAIL,


} from '../Type';

const INITIAL = {
  mPurchaseStatus: '',
  mPurchase_loading: false,
  mPurchase_success: false,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL, action) => {
  switch (action.type) {
    /**
     * M Purchase
     */
    // MY_PROFILE_INFO
    case UPDATE_M_PURCHASE:
      return {
        ...state,
        mPurchaseStatus: "",
        mPurchase_loading: true,
        mPurchase_success: false,
      };
    case UPDATE_M_PURCHASE_SUCCESS:
      return {
        ...state,
        mPurchaseStatus: action.data.message,
        mPurchase_loading: false,
        mPurchase_success: true,
      };
    case UPDATE_M_PURCHASE_FAIL:
      return {
        ...state,
        mPurchaseStatus: action.data.error,
        mPurchase_loading: false,
        mPurchase_success: false,
      };

    default:
      return state;
  }
}
