import React from 'react';
import './style.scss';
import CardIcon from 'src/Assets/Icon/credit-card.svg';
import {
  CardInputBox,
  InputBox,
} from 'src/Components';

export default function AddPayment({
  className = '',
  isCardUpdate,
  cardInfo,
  onChangeCardInfo
}) {

  return (
    <div className={`add-payment-component ${className}`} >
      {isCardUpdate && (
        <>
          <div className="row">
            <div className="col-lg-12 form-group">
              <label>{`Card Number(${isCardUpdate})`}</label>
              <CardInputBox
                icon={CardIcon}
                type="number"
                value={cardInfo.cardNumber}
                onChange={val => onChangeCardInfo('cardNumber', val)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 form-group">
              <label>Month</label>
              <InputBox
                value={cardInfo.month}
                placeholder="Month"
                onChange={val => onChangeCardInfo('month', val)}
              />
            </div>
            <div className="col-lg-4 form-group">
              <label>Year</label>
              <InputBox
                value={cardInfo.year}
                placeholder="Year"
                onChange={val => onChangeCardInfo('year', val)}
              />
            </div>
            <div className="col-lg-4 form-group">
              <label>CVV2</label>
              <InputBox
                value={cardInfo.cvv2}
                placeholder="CVV2"
                onChange={val => onChangeCardInfo('cvv2', val)}
              />
            </div>
          </div>
          {/* <div className="row">
            <div className="col-lg-8 form-group">
              <label>{`Coupon Code`}</label>
              <InputBox
                value={cardInfo.couponCode}
                placeholder="Coupon code"
                onChange={val => onChangeCardInfo('couponCode', val)}
              />
            </div>
            <div className="col-lg-4 form-group check-code-content">
              <div
                className='check-code-btn center'
                onClick={onCheckCouponCode}
              >
                Check Code
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 form-group">
              <label>{`Referral Code`}</label>
              <InputBox
                value={cardInfo.referralCode}
                placeholder="Referral code"
                onChange={val => onChangeCardInfo('referralCode', val)}
              />
            </div>
          </div> */}
        </>
      )}
    </div >
  );
}