import {
  UPDATE_M_PURCHASE, UPDATE_M_PURCHASE_SUCCESS, UPDATE_M_PURCHASE_FAIL,
} from '../Type';

import {
  updateMPurchase,
} from "src/Api";

export const updateMPurchaseAction = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_M_PURCHASE });
  const res = await updateMPurchase(payload);
  if (res && res.data.data.error === false) {
    dispatch({ type: UPDATE_M_PURCHASE_SUCCESS, data: res.data.data });
  } else {
    dispatch({ type: UPDATE_M_PURCHASE_FAIL, data: res.data.data });
  }
};
