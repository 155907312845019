import {
  GET_M_REVIEWS, GET_M_REVIEWS_SUCCESS, GET_M_REVIEWS_FAIL,
  SUBMIT_M_REVIEW, SUBMIT_M_REVIEW_SUCCESS, SUBMIT_M_REVIEW_FAIL,
} from '../Type';

import {
  getMReviews,
  submitMReview,
} from "src/Api";

export const getMReviewsAction = () => async (dispatch) => {
  dispatch({ type: GET_M_REVIEWS });
  const res = await getMReviews();
  if (res && res.data && res.data.data) {
    dispatch({ type: GET_M_REVIEWS_SUCCESS, data: res.data.data });
  } else {
    dispatch({ type: GET_M_REVIEWS_FAIL, data: res.data });
  }
};

export const submitMReviewAction = (payload) => async (dispatch) => {
  dispatch({ type: SUBMIT_M_REVIEW });
  const res = await submitMReview(payload);
  if (res && res.success) {
    dispatch({ type: SUBMIT_M_REVIEW_SUCCESS, data: res.data.data });
  } else {
    dispatch({ type: SUBMIT_M_REVIEW_FAIL, data: res.data.data });
  }
};
