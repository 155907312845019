import {
  GET_M_REVIEWS, GET_M_REVIEWS_FAIL, GET_M_REVIEWS_SUCCESS,
  SUBMIT_M_REVIEW, SUBMIT_M_REVIEW_FAIL, SUBMIT_M_REVIEW_SUCCESS,
} from '../Type';

const INITIAL = {
  mReviews: [],
  getMReviews_success: false,
  getMReviews_loading: false,

  submitMReviewStatus: null,
  submitMReview_success: false,
  submitMReviews_loading: false,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL, action) => {
  switch (action.type) {
    /**
     * Get Reviews
     */
    case GET_M_REVIEWS: {
      return {
        ...state,
        mReviews: [],
        getMReviews_success: false,
        getMReviews_loading: true,

      }
    }
    case GET_M_REVIEWS_FAIL: {
      return {
        ...state,
        mReviews: [],
        getMReviews_success: false,
        getMReviews_loading: false,
      }
    }
    case GET_M_REVIEWS_SUCCESS: {
      return {
        ...state,
        mReviews: action.data,
        getMReviews_success: true,
        getMReviews_loading: false,
      }
    }

    /**
     * Submit Review
     */
    case SUBMIT_M_REVIEW: {
      return {
        ...state,
        submitMReviewStatus: "",
        submitMReview_success: false,
        submitMReviews_loading: true,

      }
    }
    case SUBMIT_M_REVIEW_FAIL: {
      return {
        ...state,
        submitMReviewStatus: action.data.error,
        submitMReview_success: false,
        submitMReviews_loading: false,
      }
    }
    case SUBMIT_M_REVIEW_SUCCESS: {
      return {
        ...state,
        submitMReviewStatus: action.data.message,
        submitMReview_success: true,
        submitMReviews_loading: false,
      }
    }

    default:
      return state;
  }
}