/* @flow */

import { combineReducers } from 'redux';

import Sidebar from './Sidebar';
import Modal from './Modal';
import Lesson from './Lesson';
import Core from "./Core";
import Auth from "./Auth";
import Payment from "./Payment";


import MPlan from "./MPlan";
import MPayment from "./MPayment";
import Topic from "./Topic";
import QA from "./QA";
import MReviews from "./MReviews";
import Mims from "./Mims";

export default combineReducers({
    Sidebar,
    Modal,
    Lesson,
    Core,
    Auth,
    Payment,


    MPlan,
    MPayment,
    Topic,
    QA,
    MReviews,
    Mims,
})