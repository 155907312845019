import React from 'react';
// import Modal from 'react-modal';
import { Modal } from 'react-bootstrap';
import add from '../../Assets/Icon/add.svg';
import { ReactSVG } from 'react-svg'

import './LessonConfirmModal.scss';

export default class LessonConfirmModal extends React.Component {
    
    confirm = (status) => {
        this.props.lessonConfirm(status)
    }

    render() {
        const {isLessonConfirmModal, lessonConfirmDescription} = this.props
    return (
        <Modal show={isLessonConfirmModal} className="lesson-confirm-modal-component">
            <div className="lesson-confirm-content">
                <div className="close-btn"> <ReactSVG src={ add } className='icon-close' onClick={ () => this.confirm(false) }/> </div>
                <div className="content-area v-r">
                    <div>
                        <h2>{lessonConfirmDescription}</h2>
                    </div>
                    <div className="lesson-confirm-btn-area">
                        <div className="lesson-confirm-btn" onClick={()=>this.confirm(true)}>
                            Confirm
                        </div>
                        <div className="lesson-confirm-btn" onClick={()=>this.confirm(false)}>
                            Cancel
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
  };
};