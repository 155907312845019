import React, { useEffect, useState } from "react";
import './style.scss';
import Person from 'src/Assets/MIMS/Person.png';
import InfoIcon from 'src/Assets/MIMS/info-circle.png';
import QuizCircleProgress from "../../QuizCircleProgress";
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash';
import { convertSecondsToMS } from "src/Constant";

export default function UserStatComponent() {
    const failColor = '#F92672';
    const passColor = '#24C1A5';
    const dispatch = useDispatch()
    const [time, setTime] = useState('00:00')
    const [text, setText] = useState(`0/0`)
    const [fail, setFail] = useState(0)
    const [passRatio, setPassRatio] = useState(0)
    const [percentage, setPercentage] = useState(0)

    const {
        active_flashcard,
        quiz_completed,
        quiz_answers,
        quiz_time,
        taking_quiz
    } = useSelector(state => state.Mims)
    
    const {
        myProfileInfo
    } = useSelector(state => state.Core)
    
    useEffect(() => { 
        if (quiz_completed && !_.isEmpty(quiz_answers)) {
            // is user just completed a quiz
            const correctCount = (quiz_answers.filter(item => item.correct)).length
            const wrongCount = quiz_answers.length - correctCount
            setFail(valueOn10(correctCount + wrongCount, quiz_answers.length))
            setPassRatio((correctCount / quiz_answers.length))
            setText(`${correctCount}/${quiz_answers.length}`)
            setPercentage(calculatePercentage(correctCount, quiz_answers.length))
            setTime(convertSecondsToMS(quiz_time))
        } else if (active_flashcard && !_.isEmpty(active_flashcard.summary)) {
            // if user is just opening flashcard modal, show average performance
            const summary = active_flashcard.summary
            const wrong = summary.total - summary.correct 
            setFail(valueOn10(summary.correct + wrong, summary.total))
            setPassRatio((summary.correct / summary.total))
            setText(`${summary.correct}/${summary.total}`)
            setPercentage(calculatePercentage(summary.correct, summary.total))
            setTime(convertSecondsToMS(summary.avg_time))
        }
    }, [quiz_completed, taking_quiz, quiz_answers, active_flashcard,dispatch])

    const valueOn10 = (value, max) => (value / max) * 10

    const calculatePercentage = (value, total) => {
        let percentage = value / total * 100
        return percentage.toFixed(1)
    }

    return (
        <div className="person-section card">
            <div className="leading-sec">
                {myProfileInfo.picture ? <div className="avatar"><img src={`${process.env.REACT_APP_IMAGE_DOMAIN}${myProfileInfo.picture}`} alt="avatar" /></div>
                    : <div className="avatar-alt"><span>{myProfileInfo.first_name ? myProfileInfo.first_name.charAt(0) : 'A'}{myProfileInfo.last_name ? myProfileInfo.last_name.charAt(0) : 'L'}</span></div>}
                    <h6>{myProfileInfo.first_name} {myProfileInfo.last_name}</h6>
                    {/* <div>Graduate CS Student at UTA</div> */}
            </div>
            <div className="trailing-sec">
                <div className="top-section">
                    <div className="score stat">
                        <div className="stat-label">SCORE</div>
                        <div className="stat-text-large">{percentage}%</div>
                        <div className="tooltip-wrapper">
                            <img src={InfoIcon} />
                            <div className="info">Percentage score for all quizes attempted</div>
                        </div>
                    </div>
                    <div className="time stat">
                        <div className="stat-label">TIME</div>
                        <div className="stat-text-large">{time}</div>
                        <div className="tooltip-wrapper">
                            <img src={InfoIcon} />
                            <div className="info">Average time spent on each question during quiz</div>
                        </div>
                    </div>
                </div>
                <div className="progress-show">
                    <div className="progress-stack">
                        <QuizCircleProgress progress={fail} strokeColor={failColor} ratio={1} text={text}/>
                        <QuizCircleProgress progress={10} strokeColor={passColor} ratio={passRatio} text={text}/>
                    </div>
                    <div className="stat-desc">
                        <div className="info-item passed">
                            <div className="dot"></div>
                            <div className="text">Correct</div>
                        </div>
                        <div className="info-item failed">
                            <div className="dot"></div>
                            <div className="text">InCorrect</div>
                        </div>
                        <div className="info-item">
                            <div className="dot"></div>
                            <div className="text">Skipped</div>
                        </div>
                    </div>
                    <div className="tooltip-wrapper">
                        <img src={InfoIcon} />
                        <div className="info">Performance for all quizes attempted. It shows number of questions had correct and wrong</div>
                    </div>
                </div>
            </div>
        </div>
    )
}