import React, { useEffect, useState } from "react";
import './style.scss';
import QuestionAction from "./QuestionAction";
import { QuestionSolution } from "./QuestionSolution";
import { useDispatch, useSelector } from "react-redux";
import { hideSpinner, showSpinner } from "src/Util/Helper";
import IconRule from 'src/Assets/MIMS/IconRule.png';
import IconNotes from 'src/Assets/MIMS/IconNotes.png';
import _ from 'lodash';
import {
    submitAssessmentAction,
    getAssessmentHistoryAction
} from 'src/Redux/Actions'
import {
    TOGGLE_NOTE_MODAL,
    SET_SOLUTION_QUESTION
} from 'src/Redux/Type';

export default function QuestionsComponent() {
    const dispatch = useDispatch()
    const [currentIndex, setCurrentIndex] = useState(0)
    const {
        current_question,
        assessment_questions,
        assessment_completed,
        assessment,
        total_time,
        answered_questions,
        showing_assessment_solution,
        getQuestions_success,
        loading_questions,
        assessment_result
    } = useSelector(state => state.Mims)

    const indexChanged = (index) => {
        setCurrentIndex(index)
    }

    useEffect(() => {
        if(assessment_completed) {
            const formdata = new FormData()
            formdata.append('at_assessment_id', assessment.id)
            formdata.append('answers', JSON.stringify(answered_questions))
            formdata.append('time_taken', total_time)
            showSpinner()
            dispatch(submitAssessmentAction(formdata))
            dispatch(getAssessmentHistoryAction(assessment.id))
        }
    }, [assessment_completed, dispatch])

    const onSeeNote = (questionNote) => {
        dispatch({ 
            type: TOGGLE_NOTE_MODAL,
            data: { isNoteModal: true, note: questionNote }
        })
    }

    function QuestionProgressHeader() {
        const boxStatus = (index) => {
            if (index <= currentIndex) {
                return 'active';
            } else if (index === currentIndex + 1) {
                return 'next';
            } else {
                return 'pending';
            }
        }

        return (
            <div className="progress-header card">
                <div className="row">
                    <div className="col-lg-3">
                        <div className="title"><span>Questions {currentIndex + 1}</span> of {assessment_questions.length}</div>
                    </div>
                    <div className="col-lg-9">
                        <div className="indicators">
                            {
                                assessment_questions.map((item, index) => (
                                    <div key={item.id} className={`square ${boxStatus(index)} ${index}`}></div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function SolutionProgressHeader() {
        const boxStatus = (index) => {
            if (index <= currentIndex) {
                if(_.isEmpty(assessment_result)) {
                    return assessment_questions[index].user_answer === assessment_questions[index].solution ? 'active' : 'fail'
                } else {
                    const searchIndex = _.findIndex(answered_questions, function (item) {
                        return (item.question_id === assessment_questions[index].id) 
                        && (item.option === assessment_questions[index].solution)
                    })
                    return searchIndex === -1 ? 'fail' : 'active';
                }
            } else {
                return 'pending';
            }
        }

        const onMoveToSolution = (item, index) => {
            if (index < 0) return; 
            dispatch({ 
                type: SET_SOLUTION_QUESTION, 
                data: { question: item, last: (index === assessment_questions.length - 1) }
            })
        }

        return (
            <div className="progress-header card">
                <div className="row">
                    <div className="col-lg-3">
                        <div className="title"><span>Questions {currentIndex + 1}</span> of {assessment_questions.length}</div>
                    </div>
                    <div className="col-lg-9">
                        <div className="indicators">
                            {
                                assessment_questions.map((item, index) => (
                                    <div key={item.id} className={`circle ${boxStatus(index)} ${index}`} onClick={() => onMoveToSolution(item, index)}>
                                        <span>{index + 1}</span>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function QuestionDetails() {

        return (
            <div className="question-details card">
                { !_.isEmpty(current_question.question_date) &&  <div className="item q-date" dangerouslySetInnerHTML={{ __html: current_question.question_date }}></div> }
                { !_.isEmpty(current_question.concept) && <div className="item q-concept" dangerouslySetInnerHTML={{ __html: current_question.concept }}></div> }
                <div className="q-rules">
                    <div className="content" dangerouslySetInnerHTML={{ __html: current_question.rules }}></div>
                    <div className="append">
                        <img src={IconRule}/>
                        <div>Test Rules</div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="questions-page">
           {
            (!loading_questions && getQuestions_success) && (
               <>
                { showing_assessment_solution ? <SolutionProgressHeader /> :  <QuestionProgressHeader /> }
                { current_question && <QuestionDetails />}
                <div className="row">
                    <div className="col-lg-6 left-section">
                        {
                            current_question && (
                                <div className="question-content card">
                                    <h4 className="label mb-4">Question</h4>
                                    { !_.isEmpty(current_question.picture) && <div className="img-wrapper">
                                            <img src={current_question.picture} className="q-img"/>
                                        </div>}
                                    {/* <h4 dangerouslySetInnerHTML={{ __html: current_question.name }}></h4> */}
                                    <p dangerouslySetInnerHTML={{ __html: current_question.description}} className="mt-3"></p>
                                    { !_.isEmpty(current_question.notes) && <img className="q-note-toggle" src={IconNotes} onClick={() => onSeeNote(current_question.notes)}/> }
                                </div>
                            )
                        }
                    </div>
                    <div className="col-lg-6 right-section">
                        { (!showing_assessment_solution && current_question) && <QuestionAction question={current_question} onIndexChanged={indexChanged}/>}
                        { showing_assessment_solution && <QuestionSolution onIndexChanged={indexChanged} />}
                    </div>
                </div>
               </>
            )
           }
        </div>
    )
}