import {
    GET_MYTUTORS,
    GET_MYTUTORS_SUCCESS,
    GET_MYTUTORS_FAIL,
    GET_PENDING_LESSON,
    GET_PENDING_LESSON_SUCCESS,
    GET_PENDING_LESSON_FAIL,
    GET_COMPLETED_LESSON,
    GET_COMPLETED_LESSON_SUCCESS,
    GET_COMPLETED_LESSON_FAIL,
    GET_MY_PROFILE_INFO,
    GET_MY_PROFILE_INFO_SUCCESS,
    GET_MY_PROFILE_INFO_FAIL,
    GET_PROFILE_UPDATE,
    GET_PROFILE_UPDATE_SUCCESS,
    GET_PROFILE_UPDATE_FAIL,
    GET_SCHOOLS,
    GET_SCHOOLS_SUCCESS,
    GET_SCHOOLS_FAIL,
    GET_MY_TUTOR_DETAIL,
    GET_MY_TUTOR_DETAIL_SUCCESS,
    GET_MY_TUTOR_DETAIL_FAIL,
    GET_RECEIPT,
    GET_RECEIPT_SUCCESS,
    GET_RECEIPT_FAIL,
    GET_LEVELS,
    GET_LEVELS_SUCCESS,
    GET_LEVELS_FAIL,
    GET_SUBJECTS,
    GET_SUBJECTS_FAIL,
    GET_SUBJECTS_SUCCESS,
    GET_MY_REVIEWS,
    GET_MY_REVIEWS_FAIL,
    GET_MY_REVIEWS_SUCCESS,
    GET_PLANS,
    GET_PLANS_SUCCESS,
    GET_PLANS_FAIL,
    GET_BRAINTREEKEY,
    GET_BRAINTREEKEY_FAIL,
    GET_BRAINTREEKEY_SUCCESS,
    GET_MESSAGE,
    GET_MESSAGE_FAIL,
    GET_MESSAGE_SUCCESS,
    GET_MESSAGE_USER_LIST,
    GET_MESSAGE_USER_LIST_FAIL,
    GET_MESSAGE_USER_LIST_SUCCESS,
    GET_CARD_INFO,
    GET_CARD_INFO_FAIL,
    GET_CARD_INFO_SUCCESS,
    INIT_APP,
    GET_SAL_SUBSCRIPTION,
    GET_SAL_SUBSCRIPTION_SUCCESS,
    GET_SAL_SUBSCRIPTION_FAIL,
    TOAST_MESSAGE,
    GET_RECOMMENDATION,
    GET_RECOMMENDATION_SUCCESS,
    GET_RECOMMENDATION_FAIL,
} from "../Type";

import {
    getMytutors,
    getPendingLesson,
    getCompletedLesson,
    getMypofileInfo,
    profileUpdate,
    getSchools,
    getMytutorDetail,
    getReceipt,
    getLevels,
    getSubjects,
    getMyReviews,
    getPlans,
    getBraintreekey,
    getMessage,
    getMessageUserList,
    getCardInfo,
    getSmileandlearnSubscription,
    startSmileandlearnSubscription,
    cancelSmileandlearnSubscription,
    getRecommendation,
} from "../../Api/Core";

export const initAppAction = () => (dispatch) => {
    dispatch({ type: INIT_APP });
};
  
export const getMytutorsAction = () => async (dispatch) => {
  dispatch({ type: GET_MYTUTORS });
  const res = await getMytutors();
  if (res && res.data && res.data.data) {
    dispatch({ type: GET_MYTUTORS_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_MYTUTORS_FAIL, payload: res.data });
  }
};

export const getPendingLessonAction = (timezone) => async (dispatch) => {
  dispatch({ type: GET_PENDING_LESSON });
  const res = await getPendingLesson(timezone);
  if (res && res.data && res.data.data) {
    dispatch({ type: GET_PENDING_LESSON_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_PENDING_LESSON_FAIL, payload: res.data });
  }
};

export const getCompletedLessonAction = (timezone) => async (dispatch) => {
  dispatch({ type: GET_COMPLETED_LESSON });
  const res = await getCompletedLesson(timezone);
  if (res && res.data && res.data.data) {
    dispatch({ type: GET_COMPLETED_LESSON_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_COMPLETED_LESSON_FAIL, payload: res.data });
  }
};

export const getMypofileInfoAction = () => async (dispatch) => {
  dispatch({ type: GET_MY_PROFILE_INFO });
  const res = await getMypofileInfo();
  if (res && res.data) {
    dispatch({ type: GET_MY_PROFILE_INFO_SUCCESS, payload: res.data });
  } else {
    dispatch({ type: GET_MY_PROFILE_INFO_FAIL, payload: res.data });
  }
};

export const profileUpdateAction = (payload) => async (dispatch) => {
  dispatch({ type: GET_PROFILE_UPDATE });
  const res = await profileUpdate(payload);
  if (res && res.data.id) {
    dispatch({ type: GET_PROFILE_UPDATE_SUCCESS, payload: res.data });
  } else {
      dispatch({ type: GET_PROFILE_UPDATE_FAIL, payload: res.data });
  }
};

export const getSchoolsAction = () => async (dispatch) => {
  dispatch({ type: GET_SCHOOLS });
  const res = await getSchools();
  if (res && res.data && res.data.data) {
    dispatch({ type: GET_SCHOOLS_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_SCHOOLS_FAIL, payload: res.data.data });
  }
};

export const getMytutorDetailAction = (payload) => async (dispatch) => {
  dispatch({ type: GET_MY_TUTOR_DETAIL });
  const res = await getMytutorDetail(payload);
  if (res && res.data && res.data.data) {
    dispatch({ type: GET_MY_TUTOR_DETAIL_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_MY_TUTOR_DETAIL_FAIL, payload: res.data.data });
  }
};

export const getReceiptAction = () => async (dispatch) => {
  dispatch({ type: GET_RECEIPT });
  const res = await getReceipt();
  if (res && res.data && res.data.data) {
    dispatch({ type: GET_RECEIPT_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_RECEIPT_FAIL, payload: res.data.data });
  }
};

export const getLevelsAction = () => async (dispatch) => {
  dispatch({ type: GET_LEVELS });
  const res = await getLevels();
  if (res && res.data && res.data.data) {
    dispatch({ type: GET_LEVELS_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_LEVELS_FAIL, payload: res.data });
  }
};

export const getSubjectsAction = (level_id) => async (dispatch) => {
  dispatch({ type: GET_SUBJECTS });
  const res = await getSubjects(level_id);
  if (res && res.data && res.data.data) {
    dispatch({ type: GET_SUBJECTS_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_SUBJECTS_FAIL, payload: res.data });
  }
};

export const getMyReviewsAction = () => async (dispatch) => {
  dispatch({ type: GET_MY_REVIEWS });
  const res = await getMyReviews();
  if (res && res.data && res.data.data) {
    dispatch({ type: GET_MY_REVIEWS_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_MY_REVIEWS_FAIL, payload: res.data });
  }
};

export const getPlanAction = () => async (dispatch) => {
  dispatch({ type: GET_PLANS });
  const res = await getPlans();
  if (res && res.data && res.data.data) {
    dispatch({ type: GET_PLANS_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_PLANS_FAIL, payload: res.data });
  }
};

export const getBraintreekeyAction = () => async (dispatch) => {
  dispatch({ type: GET_BRAINTREEKEY });
  const res = await getBraintreekey();
  if (res && res.data && !res.data.error) {
    dispatch({ type: GET_BRAINTREEKEY_SUCCESS, payload: res.data});
  } else {
    dispatch({ type: GET_BRAINTREEKEY_FAIL, payload: res.data });
  }
};

export const getMessageAction = (id) => async (dispatch) => {
  dispatch({ type: GET_MESSAGE });
  const res = await getMessage(id);
  if (res && res.data && res.data.data) {
    dispatch({ type: GET_MESSAGE_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_MESSAGE_FAIL, payload: res.data });
  }
};

export const getMessageUserListAction = () => async (dispatch) => {
  dispatch({ type: GET_MESSAGE_USER_LIST });
  const res = await getMessageUserList();
  if (res && res.data && res.data.data) {
    dispatch({ type: GET_MESSAGE_USER_LIST_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_MESSAGE_USER_LIST_FAIL, payload: res.data });
  }
};

export const getCardInfoAction = () => async (dispatch) => {
  dispatch({ type: GET_CARD_INFO });
  const res = await getCardInfo();
  if (res && res.data && !res.data.error) {
    dispatch({ type: GET_CARD_INFO_SUCCESS, payload: res.data.card });
  } else {
    dispatch({ type: GET_CARD_INFO_FAIL, payload: res.data });
  }
};

export const getSmileandlearnSubscriptionAction = () => async (dispatch) => {
  dispatch({ type: GET_SAL_SUBSCRIPTION });
  const res = await getSmileandlearnSubscription();
  if (res && res.data) {
    dispatch({ type: GET_SAL_SUBSCRIPTION_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_SAL_SUBSCRIPTION_FAIL, payload: [] });
  }
};

export const startSmileandlearnSubscriptionAction = (payload) => async (dispatch) => {
  const res = await startSmileandlearnSubscription(payload);
  if (res && res.data) {
    dispatch({ type: TOAST_MESSAGE, payload: res.data.message });
  }
};

export const cancelSmileandlearnSubscriptionAction = () => async (dispatch) => {
  const res = await cancelSmileandlearnSubscription();
  if (res && res.data) {
    dispatch({ type: TOAST_MESSAGE, payload: res.data.message });
  }
};

export const getRecommendationAction = () => async (dispatch) => {
  dispatch({ type: GET_RECOMMENDATION });
  const res = await getRecommendation();
  if (res && res.data) {
    dispatch({ type: GET_RECOMMENDATION_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_RECOMMENDATION_FAIL, payload: [] });
  }
};

  