import React, { useState, useEffect, useRef } from 'react';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from "react-svg";
import FrameIcon from "src/Assets/MIMS/frame.svg";
import { AnswerItem, QuestionTags, CircleTimer } from 'src/Components';
import {
  toggleMuseVideoModal,
  submitAnswerAction,
  toggleNoteModal,
  toggleUpgradePlanModal,
  toggleFinishTrialModal,
} from 'src/Redux/Actions';
import { showSpinner, hideSpinner, } from 'src/Util/Helper';

export default function QuestionAnswerFlow({
  className = '',
  testId,
  topic_id,
  questions,
  onGetAnswerScours,
  have_access,
  answeredQuestionsCount,
}) {
  const dispatch = useDispatch();
  const getLoadingRef = useRef(false);

  const {
    submitAnswerStatus, get_submitAnswer_success, get_submitAnswer_loading,
  } = useSelector(
    state => state.QA,
  );

  const {
    mCurrentPlan,
  } = useSelector(
    state => state.MPlan
  );

  const [answerStep, setAnswerStep] = useState(answeredQuestionsCount);
  const [currentQuestion, setCurrentQuestion] = useState(questions[0]);
  const [isAnswerTrue, setIsAnswerTrue] = useState(undefined);
  const [selectedIndex, setSelectedIndex] = useState(undefined);
  const [preSelectedIndex, setPreSelectedIndex] = useState(undefined);
  const [isCountDownTime, setIsCountDownTime] = useState(true);
  const [answerTime, setAnswerTime] = useState(0);
  const [activeCountUp, setActiveCountUp] = useState(false);
  const [countUpTime, setCountUpTime] = useState(0);

  useEffect(() => {
    setCurrentQuestion(questions[answerStep])
  }, [answerStep, questions]);

  //Get Submit Answer res
  useEffect(() => {
    if (getLoadingRef.current && !get_submitAnswer_loading) {
      hideSpinner();
      if (get_submitAnswer_success) {
        setIsAnswerTrue(submitAnswerStatus);
        setSelectedIndex(preSelectedIndex);
      }
    }
    getLoadingRef.current = get_submitAnswer_loading;
  }, [get_submitAnswer_loading, get_submitAnswer_success, onGetAnswerScours, preSelectedIndex, submitAnswerStatus]);

  const onSelectAnswer = (answer, index) => {
    setIsCountDownTime(false);
    setActiveCountUp(false);
    setPreSelectedIndex(index);
    const payload = {
      test_id: testId,
      question_id: currentQuestion.id,
      option: answer,
      timer_second: answerTime + countUpTime
    }
    showSpinner();
    dispatch(submitAnswerAction(payload));
  };

  const onBack = () => {
    if (answerStep !== 0) {
      setAnswerStep(answerStep - 1);
    }
  };

  const onSkip = () => {
    onNext();
  };

  const onNext = () => {
    onShowNextQuestion();
  };

  const onShowNextQuestion = () => {
    if (questions.length - 1 > answerStep) {
      if (!have_access && answerStep > 8) {
        //Modal to ask the upgrade plan
        dispatch(toggleUpgradePlanModal(true, { id: topic_id }));
      } else {
        if (mCurrentPlan.is_trial && answerStep > 98) {
          //Modal to finish trial period easily
          dispatch(toggleFinishTrialModal(true));
        } else {
          setAnswerStep(answerStep + 1);
          setIsAnswerTrue(undefined);
          setSelectedIndex(undefined);
        }
      }
    }
  }

  const onSeeVideo = (answer) => {
    dispatch(toggleMuseVideoModal(true, { videoId: currentQuestion.muse_video }));
  };

  const onNote = () => {
    dispatch(toggleNoteModal(true, currentQuestion.notes));
  }

  const getAnswerTime = (time) => {
    setAnswerTime(time)
    if (time === currentQuestion.timer) {
      setActiveCountUp(true)
    }
  }

  const onTimeUpUpdate = (time) => {
    setCountUpTime(time / 1000)
  }

  return (
    <div className={`question-answer-flow-component ${className}`}>
      <div className='sub-header-content card'>
        <QuestionTags
          questionDate={currentQuestion.question_date}
          concept={currentQuestion.concept}
          name={currentQuestion.name}
          rules={currentQuestion.rules}
        />

      </div>
      <div className='questions-answer-content'>
        <div className='row'>
          <div className='col-lg-6 left-section'>
            <div className='question-content v-c h-c card'>
            <div className='w-100'>
              <h4 className="label">Question</h4>
            </div>
              {currentQuestion.picture && (
                <img
                  src={currentQuestion.picture}
                  alt='question-img'
                  className='question-img'
                />
              )}
               <div className='mt-3'
                dangerouslySetInnerHTML={{ __html: currentQuestion.description }}
              />
            </div>
          </div>
          <div className='col-lg-6 right-section card'>
              <div className='circle-timer'>
                  <CircleTimer
                    isCountDownTime={isCountDownTime}
                    duration={currentQuestion.timer}
                    key={currentQuestion.id}
                    getAnswerTime={getAnswerTime}
                    activeCountUp={activeCountUp}
                    onTimeUpUpdate={onTimeUpUpdate}
                  />
            </div>
            <ReactSVG src={FrameIcon} className='frame-icon' onClick={onNote} />
            <div className='answer-container'>
              {/* <h3>{currentQuestion.name}</h3> */}
              <h4 className="label mt-4">Your Answer</h4>
              <div className='answer-content'>
                {currentQuestion.options.map((item, index) => (
                  <AnswerItem
                    key={index}
                    index={index}
                    item={item}
                    explanation={currentQuestion.explanation_text}
                    thumbImg={currentQuestion.muse_video_preview}
                    selectedIndex={selectedIndex}
                    isAnswerTrue={isAnswerTrue}
                    onSelectAnswer={onSelectAnswer}
                    onSeeVideo={onSeeVideo}
                  />
                ))}
              </div>
            </div>
            <div className='btn-content v-c space-between'>
              <div className='btn-2 back-btn center' onClick={onBack} >BACK</div>
              <div className='step-num'><strong>{answerStep + 1}</strong>{` of ${questions.length}`}</div>
              <div className='v-c'>
                {/* <div className='btn-2 skip-btn center' onClick={onSkip} >Skip</div> */}
                <div className='btn-2 next-btn center' onClick={onNext} >NEXT</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
