import React from 'react';
import './style.scss';

export default class ThemeToggle extends React.Component {
  state = {
    value: this.props.value,
  }

  onChange = () => {
    if (this.props.disable) return;

    this.props.onChange(!this.state.value);
    this.setState({
      value: !this.state.value,
    })
  }

  render() {
    const {
      className,
      disable
    } = this.props;

    const { value } = this.state;

    return (
      <div className={`theme-toggle-component ${className || ''} ${disable? 'disable': ''} ${value? '': 'off'}`} onClick={this.onChange}>
        <div className='handle'></div>
        <div className='theme'>{ value ? 'Whiteboard' : 'Blackboard'}</div>
      </div>
    )
  };
};