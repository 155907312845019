import {
  AUTH_UPDATE_TOKEN,
  GET_AUTH_SIGN_OUT,
  GET_AUTH_SIGN_OUT_SUCCESS,
  GET_AUTH_SIGN_OUT_FAIL,
  GET_REQUEST_TUTOR,
  GET_REQUEST_TUTOR_SUCCESS,
  GET_REQUEST_TUTOR_FAIL,
  GET_SCHEDULE_LESSON,
  GET_SCHEDULE_LESSON_SUCCESS,
  GET_SCHEDULE_LESSON_FAIL,
  GET_DELETE_TUTOR,
  GET_DELETE_TUTOR_SUCCESS,
  GET_DELETE_TUTOR_FAIL,
  GET_REVIEW_TUTOR,
  GET_REVIEW_TUTOR_SUCCESS,
  GET_REVIEW_TUTOR_FAIL,
  GET_CANCEL_LESSON,
  GET_CANCEL_LESSON_SUCCESS,
  GET_CANCEL_LESSON_FAIL,
  GET_CANCEL_SUBSCRIPTION,
  GET_CANCEL_SUBSCRIPTION_SUCCESS,
  GET_CANCEL_SUBSCRIPTION_FAIL,
  GET_AVAILABILITY,
  GET_AVAILABILITY_FAIL,
  GET_AVAILABILITY_SUCCESS,
  GET_CREATE_MESSAGE,
  GET_CREATE_MESSAGE_FAIL,
  GET_CREATE_MESSAGE_SUCCESS,
 
  INIT_APP,
  GET_SEND_NONCE_TO_SERVER,
  GET_SEND_NONCE_TO_SERVER_SUCCESS,
  GET_SEND_NONCE_TO_SERVER_FAIL
} from "../Type";

const INITIAL = {
  isLoggedIn: false,

  profileUpdate: null,
  scheduleLessonStatus: null,
  scheduleLessonErr: '',
  deleteTutorStatus: null,
  deleteTutorErr: '',
  reviewTutorStatus: null,
  reviewTutorErr: '',
  cancelLessonStatus: null,
  cancelLessonErr: '',
  signOutStatus: null,
  cancelSubscriptionStatus: null,
  cancelSubscriptionErr: '',
  availabilityTime: [],
  sendMessageStatus: null,
  removeCardStatus: null,
  removeCardError: '',
 

  nonceUpdate: null,
  nonceUpdateErr: null,
  nonceUpdateStatus_loading: false,
  nonceUpdateStatus_success: false,
};

export default (state = INITIAL, action) => {
  switch (action.type) {
    case AUTH_UPDATE_TOKEN: {
      const { token } = action.data;
      sessionStorage.setItem('token', token);

      return {
        ...state,
        token,
        isLoggedIn: token && token.length > 0 ? true : false,
      }
    }

    case INIT_APP: {
      const token = sessionStorage.getItem("token");
      return {
        ...state,
        token: token,
        isLoggedIn: token && token.length > 0 ? true : false,
      };
    }

    // SIGNOUT
    case GET_AUTH_SIGN_OUT:
      sessionStorage.clear();
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      return { ...state, signOutStatus: null };
    case GET_AUTH_SIGN_OUT_SUCCESS:
      return { ...state, signOutStatus: action.payload, isLoggedIn: false, };
    case GET_AUTH_SIGN_OUT_FAIL:
      return { ...state, signOutStatus: null };

    // REQUEST TUTOR
    case GET_REQUEST_TUTOR:
      return { ...state, requestTutorStatus: null };
    case GET_REQUEST_TUTOR_SUCCESS:
      return { ...state, requestTutorStatus: action.payload.message };
    case GET_REQUEST_TUTOR_FAIL:
      return { ...state, requestTutorErr: action.payload };

    // REQUEST TUTOR
    case GET_SCHEDULE_LESSON:
      return { ...state, scheduleLessonStatus: null };
    case GET_SCHEDULE_LESSON_SUCCESS:
      return { ...state, scheduleLessonStatus: action.payload.message };
    case GET_SCHEDULE_LESSON_FAIL:
      return { ...state, scheduleLessonStatus: null, scheduleLessonErr: action.payload };

    // DELETE TUTOR
    case GET_DELETE_TUTOR:
      return { ...state, deleteTutorStatus: null };
    case GET_DELETE_TUTOR_SUCCESS:
      return { ...state, deleteTutorStatus: action.payload.message };
    case GET_DELETE_TUTOR_FAIL:
      return { ...state, deleteTutorStatus: null, deleteTutorErr: action.payload.message };

    // REVIEW TUTOR
    case GET_REVIEW_TUTOR:
      return { ...state, reviewTutorStatus: null };
    case GET_REVIEW_TUTOR_SUCCESS:
      return { ...state, reviewTutorStatus: action.payload.message };
    case GET_REVIEW_TUTOR_FAIL:
      return { ...state, reviewTutorErr: action.payload };

    // CANCEL LESSON
    case GET_CANCEL_LESSON:
      return { ...state, cancelLessonStatus: null };
    case GET_CANCEL_LESSON_SUCCESS:
      return { ...state, cancelLessonStatus: action.payload.message };
    case GET_CANCEL_LESSON_FAIL:
      return { ...state, cancelLessonStatus: null, cancelLessonErr: action.payload };

    // CANCEL SUBSCRIPTION
    case GET_CANCEL_SUBSCRIPTION:
      return { ...state, cancelSubscriptionStatus: null };
    case GET_CANCEL_SUBSCRIPTION_SUCCESS:
      return { ...state, cancelSubscriptionStatus: action.payload.message };
    case GET_CANCEL_SUBSCRIPTION_FAIL:
      return { ...state, cancelSubscriptionStatus: null, cancelSubscriptionErr: action.payload };

    // SCHEDULE AVAILABILITY Time
    case GET_AVAILABILITY:
      return { ...state, availabilityTime: [] };
    case GET_AVAILABILITY_SUCCESS:
      return { ...state, availabilityTime: action.payload };
    case GET_AVAILABILITY_FAIL:
      return { ...state, availabilityTime: action.payload };

    //CREATE MESSAGE
    case GET_CREATE_MESSAGE:
      return { ...state, sendMessageStatus: null };
    case GET_CREATE_MESSAGE_SUCCESS:
      return { ...state, sendMessageStatus: action.payload };
    case GET_CREATE_MESSAGE_FAIL:
      return { ...state, sendMessageStatus: null };


    // SEND NONCE TO SERVER
    case GET_SEND_NONCE_TO_SERVER:
      return {
        ...state,
        nonceUpdate: null,
        nonceUpdateStatus_loading: true,
        nonceUpdateStatus_success: false,
      };
    case GET_SEND_NONCE_TO_SERVER_SUCCESS: {
      return {
        ...state,
        nonceUpdate: action.payload.message,
        nonceUpdateStatus_loading: false,
        nonceUpdateStatus_success: true,
      };
    }
    case GET_SEND_NONCE_TO_SERVER_FAIL:
      return {
        ...state,
        nonceUpdate: null,
        nonceUpdateErr: action.payload.message,
        nonceUpdateStatus_loading: false,
        nonceUpdateStatus_success: false,
      };

    default:
      return state;
  }
};
