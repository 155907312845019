import React, { useEffect, useRef } from "react";
import './style.scss';
import { Modal } from "react-bootstrap";
import HeadLogo from 'src/Assets/MIMS/MIM-Logo.png';
import IntroComponent from "./IntroComponent";
import QuestionsComponent from "./Questions";
import { useDispatch, useSelector } from "react-redux";
import {
    getAssessmentAction,
    toggleAssessmentExitModal,
    toggleAssessmentModal,
    getAssessmentQuestionsAction
} from 'src/Redux/Actions';
import { hideSpinner, showSpinner } from "src/Util/Helper";
import _ from 'lodash'
import ExitAssessmentModal from "./ExitAssessmentModal";

export default function AsssessmentComponent({showModal, onHide}) {
    const dispatch = useDispatch()
    const loadingRef = useRef(false)
    const {
        
    } = useSelector(state => state.Mims)

    const { 
        assessment,
        active_assessment,
        assessment_is_active,
        getAssessment_loading,
        getAssessment_success,
        isAssessmentModalOpen,
        showing_assessment_solution,
        isExitAssessmentModalOpen
    } = useSelector(state => state.Mims)

    useEffect(() => {
        if(!_.isEmpty(active_assessment) && active_assessment.id && isAssessmentModalOpen) {
            showSpinner()
            dispatch(getAssessmentAction(active_assessment.id))
        }
    },[dispatch, isAssessmentModalOpen])

    useEffect(() => {
        if (!getAssessment_loading) {
            if (getAssessment_success && !_.isEmpty(assessment)) {
                console.log('assessment', assessment)
                dispatch(getAssessmentQuestionsAction(assessment.id))
            }
        }
    }, [getAssessment_loading, getAssessment_success])

    const onExit = () => {
        dispatch(toggleAssessmentExitModal(false));
        dispatch(toggleAssessmentModal(false))
    }

    return (
        <>
            <Modal className="assessment-modal" show={showModal} onHide={onHide}>
                <Modal.Header className="pc-header">
                    <div className="logo-wrapper">
                        <img src={HeadLogo}/>
                    </div>
                    <div className="header-content">
                        { assessment && (<h4>{assessment.name} </h4>)}
                        <div className="exit-btn" onClick={onHide}>
                            <div>Exit</div>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Header className="mobile-header">
                    { assessment && (<h4>{assessment.name} </h4>)}
                    <div className="exit-btn" onClick={onHide}>
                        <div>Exit</div>
                    </div>
                </Modal.Header>
            {
                assessment && (
                    <Modal.Body>
                        { (!assessment_is_active && !showing_assessment_solution) && <IntroComponent assessment={assessment}/>}
                        { (assessment_is_active) && <QuestionsComponent />}
                    </Modal.Body>
                )
            }
            </Modal>
           {
            assessment &&  
            <ExitAssessmentModal 
                show={isExitAssessmentModalOpen} 
                name={assessment.name}
                onExit={() => onExit()}
                onResume={() => dispatch(toggleAssessmentExitModal(false))}
            />
           }
        </>
    )
}