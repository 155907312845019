import {
  GET_M_PLAN, GET_M_PLAN_SUCCESS, GET_M_PLAN_FAIL,
  GET_M_CURRENT_PLAN, GET_M_CURRENT_PLAN_SUCCESS, GET_M_CURRENT_PLAN_FAIL,
  CANCEL_PLAN, CANCEL_PLAN_SUCCESS, CANCEL_PLAN_FAIL,
  FINISH_TRIAL, FINISH_TRIAL_SUCCESS, FINISH_TRIAL_FAIL,


} from '../Type';

const INITIAL = {
  mPlans: [],
  mCurrentPlan: null,
  mCurrentPlanRes: '',
  getMCurrentPlanLoading: false,
  getMCurrentPlanSuccess: false,

  cancelPlanStatus: '',
  cancelPlanLoading: false,
  cancelPlanSuccess: false,

  finishTrialStatus: '',
  finishTrialLoading: false,
  finishTrialSuccess: false,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL, action) => {
  switch (action.type) {
    /**
     * Get M Plan
     */
    case GET_M_PLAN:
      return { ...state, mPlans: [] };
    case GET_M_PLAN_SUCCESS:
      return { ...state, mPlans: action.payload };
    case GET_M_PLAN_FAIL:
      return { ...state, mPlans: [] };

    /**
     * Get M Current Plan
     */
    case GET_M_CURRENT_PLAN:
      return {
        ...state,
        mCurrentPlan: null,
        getMCurrentPlanLoading: true,
        getMCurrentPlanSuccess: false,
      };
    case GET_M_CURRENT_PLAN_SUCCESS:
      return {
        ...state,
        mCurrentPlan: action.payload,
        mCurrentPlanRes: '',
        getMCurrentPlanLoading: false,
        getMCurrentPlanSuccess: true,
      };
    case GET_M_CURRENT_PLAN_FAIL:
      return {
        ...state,
        mCurrentPlan: null,
        mCurrentPlanRes: action.payload.message,
        getMCurrentPlanLoading: false,
        getMCurrentPlanSuccess: false,
      };

    /**
   * Cancel Plan
   */
    case CANCEL_PLAN:
      return {
        ...state,
        cancelPlanStatus: '',
        cancelPlanLoading: true,
        cancelPlanSuccess: false,
      };
    case CANCEL_PLAN_SUCCESS:
      return {
        ...state,
        cancelPlanStatus: action.payload.message,
        cancelPlanLoading: false,
        cancelPlanSuccess: true,
      };
    case CANCEL_PLAN_FAIL:
      return {
        ...state,
        cancelPlanStatus: action.payload.error,
        cancelPlanLoading: false,
        cancelPlanSuccess: false,
      };

    /**
    * Finish Trial Plan
    */
    case FINISH_TRIAL:
      return {
        ...state,
        finishTrialStatus: '',
        finishTrialLoading: true,
        finishTrialSuccess: false,
      };
    case FINISH_TRIAL_SUCCESS:
      return {
        ...state,
        finishTrialStatus: action.payload.message,
        finishTrialLoading: false,
        finishTrialSuccess: true,
      };
    case FINISH_TRIAL_FAIL:
      return {
        ...state,
        finishTrialStatus: action.payload.error,
        finishTrialLoading: false,
        finishTrialSuccess: false,
      };

    default:
      return state;
  }
}
