/**
 * Sidebar
 */
export const MOBILE_SIDEBAR_TOGGLE = 'MOBILE_SIDEBAR_TOGGLE';
export const SIDEBAR_TOGGLE = 'SIDEBAR_TOGGLE';

/**
 * Modal
 */
export const VIDEO_MODAL_TOGGLE = 'VIDEO_MODAL_TOGGLE';
export const MUSE_VIDEO_MODAL_TOGGLE = 'MUSE_VIDEO_MODAL_TOGGLE';
export const CREDIT_MODAL_TOGGLE = 'CREDIT_MODAL_TOGGLE';
export const REVIEW_MODAL_TOGGLE = 'REVIEW_MODAL_TOGGLE';
export const TOGGLE_QUESTION_MODAL = 'TOGGLE_QUESTION_MODAL';
export const TOGGLE_NOTE_MODAL = 'TOGGLE_NOTE_MODAL';
export const TOGGLE_UPGRADE_PLAN_MODAL = 'TOGGLE_UPGRADE_PLAN_MODAL';
export const TOGGLE_GRAPH_MODAL = 'TOGGLE_GRAPH_MODAL';
export const TOGGLE_FINISH_TRIAL_MODAL = 'TOGGLE_FINISH_TRIAL_MODAL';

/**
 * Join Lesson
 */
export const JOIN_LESSON_TOGGLE = 'JOIN_LESSON_TOGGLE';

/**
 * INIT_APP
 */
export const INIT_APP = "INIT_APP";


export const AUTH_UPDATE_TOKEN = "AUTH_UPDATE_TOKEN";
export const TOGGLE_MIMS = "TOGGLE_MIMS";

/**
 * SIGN OUT
 */
export const GET_AUTH_SIGN_OUT = "GET_AUTH_SIGN_OUT";
export const GET_AUTH_SIGN_OUT_SUCCESS = "GET_AUTH_SIGN_OUT_SUCCESS";
export const GET_AUTH_SIGN_OUT_FAIL = "GET_AUTH_SIGN_OUT_FAIL";

/**
 * MYTUTORS
 */
export const GET_MYTUTORS = "GET_MYTUTORS";
export const GET_MYTUTORS_SUCCESS = "GET_MYTUTORS_SUCCESS";
export const GET_MYTUTORS_FAIL = "GET_MYTUTORS_FAIL";

/**
 * PENDING_LESSON
 */
export const GET_PENDING_LESSON = "GET_PENDING_LESSON";
export const GET_PENDING_LESSON_SUCCESS = "GET_PENDING_LESSON_SUCCESS";
export const GET_PENDING_LESSON_FAIL = "GET_PENDING_LESSON_FAIL";

/**
 * COMPLETED_LESSON
 */
export const GET_COMPLETED_LESSON = "GET_COMPLETED_LESSON";
export const GET_COMPLETED_LESSON_SUCCESS = "GET_COMPLETED_LESSON_SUCCESS";
export const GET_COMPLETED_LESSON_FAIL = "GET_COMPLETED_LESSON_FAIL";

/**
 * MY PROFILE INFO
 */
export const GET_MY_PROFILE_INFO = "GET_MY_PROFILE_INFO";
export const GET_MY_PROFILE_INFO_SUCCESS = "GET_MY_PROFILE_INFO_SUCCESS";
export const GET_MY_PROFILE_INFO_FAIL = "GET_MY_PROFILE_INFO_FAIL";



/**
 * SCHOOLS
 */
export const GET_SCHOOLS = "GET_SCHOOLS";
export const GET_SCHOOLS_SUCCESS = "GET_SCHOOLS_SUCCESS";
export const GET_SCHOOLS_FAIL = "GET_SCHOOLS_FAIL";

/**
 * MY_TUTOR_DETAIL
 */
export const GET_MY_TUTOR_DETAIL = "GET_MY_TUTOR_DETAIL";
export const GET_MY_TUTOR_DETAIL_SUCCESS = "GET_MY_TUTOR_DETAIL_SUCCESS";
export const GET_MY_TUTOR_DETAIL_FAIL = "GET_MY_TUTOR_DETAIL_FAIL";

/**
 RECEIPT
 */
export const GET_RECEIPT = "GET_RECEIPT";
export const GET_RECEIPT_SUCCESS = "GET_RECEIPT_SUCCESS";
export const GET_RECEIPT_FAIL = "GET_RECEIPT_FAIL";

/**
 * Levels
 */
export const GET_LEVELS = "GET_LEVELS";
export const GET_LEVELS_SUCCESS = "GET_LEVELS_SUCCESS";
export const GET_LEVELS_FAIL = "GET_LEVELS_FAIL";

/**
 * SUBJECTS
 */
export const GET_SUBJECTS = "GET_SUBJECTS";
export const GET_SUBJECTS_SUCCESS = "GET_SUBJECTS_SUCCESS";
export const GET_SUBJECTS_FAIL = "GET_SUBJECTS_FAIL";

/**
 * MY REVIEWS
 */
export const GET_MY_REVIEWS = "GET_MY_REVIEWS";
export const GET_MY_REVIEWS_SUCCESS = "GET_MY_REVIEWS_SUCCESS";
export const GET_MY_REVIEWS_FAIL = "GET_MY_REVIEWS_FAIL";

/**
 * Plans
 */
export const GET_PLANS = "GET_PLANS";
export const GET_PLANS_SUCCESS = "GET_PLANS_SUCCESS";
export const GET_PLANS_FAIL = "GET_PLANS_FAIL";

/**
 * BRAINTREEKEY
 */
export const GET_BRAINTREEKEY = "GET_BRAINTREEKEY";
export const GET_BRAINTREEKEY_SUCCESS = "GET_BRAINTREEKEY_SUCCESS";
export const GET_BRAINTREEKEY_FAIL = "GET_BRAINTREEKEY_FAIL";

/**
 * SEND_NONCE_TO_SERVER
 */
export const GET_SEND_NONCE_TO_SERVER = "GET_SEND_NONCE_TO_SERVER";
export const GET_SEND_NONCE_TO_SERVER_SUCCESS = "GET_SEND_NONCE_TO_SERVER_SUCCESS";
export const GET_SEND_NONCE_TO_SERVER_FAIL = "GET_SEND_NONCE_TO_SERVER_FAIL";

/**
 * SCHEDULE AVAILABILITY TIME
 */
export const GET_AVAILABILITY = "GET_AVAILABILITY";
export const GET_AVAILABILITY_SUCCESS = "GET_AVAILABILITY_SUCCESS";
export const GET_AVAILABILITY_FAIL = "GET_AVAILABILITY_FAIL";


/**
 * MESSAGE DATA
 */
export const GET_MESSAGE = "GET_MESSAGE";
export const GET_MESSAGE_SUCCESS = "GET_MESSAGE_SUCCESS";
export const GET_MESSAGE_FAIL = "GET_MESSAGE_FAIL";

/**
 * MESSAGE USER LIST
 */
export const GET_MESSAGE_USER_LIST = "GET_MESSAGE_USER_LIST";
export const GET_MESSAGE_USER_LIST_SUCCESS = "GET_MESSAGE_USER_LIST_SUCCESS";
export const GET_MESSAGE_USER_LIST_FAIL = "GET_MESSAGE_USER_LIST_FAIL";

/**
 * GET CARD INFO
 */
export const GET_CARD_INFO = "GET_CARD_INFO";
export const GET_CARD_INFO_SUCCESS = "GET_CARD_INFO_SUCCESS";
export const GET_CARD_INFO_FAIL = "GET_CARD_INFO_FAIL";







/**
 * PROFILE UPDATE
 */
export const GET_PROFILE_UPDATE = "GET_PROFILE_UPDATE";
export const GET_PROFILE_UPDATE_SUCCESS = "GET_PROFILE_UPDATE_SUCCESS";
export const GET_PROFILE_UPDATE_FAIL = "GET_PROFILE_UPDATE_FAIL";

/**
 * REQUEST TUTOR
 */
export const GET_REQUEST_TUTOR = "GET_REQUEST_TUTOR";
export const GET_REQUEST_TUTOR_SUCCESS = "GET_REQUEST_TUTOR_SUCCESS";
export const GET_REQUEST_TUTOR_FAIL = "GET_REQUEST_TUTOR_FAIL";

/**
 * SCHEDULE LESSON
 */
export const GET_SCHEDULE_LESSON = "GET_SCHEDULE_LESSON";
export const GET_SCHEDULE_LESSON_SUCCESS = "GET_SCHEDULE_LESSON_SUCCESS";
export const GET_SCHEDULE_LESSON_FAIL = "GET_SCHEDULE_LESSON_FAIL";

/**
 * DELETE TUTOR
 */
export const GET_DELETE_TUTOR = "GET_DELETE_TUTOR";
export const GET_DELETE_TUTOR_SUCCESS = "GET_DELETE_TUTOR_SUCCESS";
export const GET_DELETE_TUTOR_FAIL = "GET_DELETE_TUTOR_FAIL";

/**
 * REVIEW TUTOR
 */
export const GET_REVIEW_TUTOR = "GET_REVIEW_TUTOR";
export const GET_REVIEW_TUTOR_SUCCESS = "GET_REVIEW_TUTOR_SUCCESS";
export const GET_REVIEW_TUTOR_FAIL = "GET_REVIEW_TUTOR_FAIL";

/**
 * CANCEL LESSON
 */
export const GET_CANCEL_LESSON = "GET_CANCEL_LESSON";
export const GET_CANCEL_LESSON_SUCCESS = "GET_CANCEL_LESSON_SUCCESS";
export const GET_CANCEL_LESSON_FAIL = "GET_CANCEL_LESSON_FAIL";

/**
 * CANCEL SUBSCRIPTION
 */
export const GET_CANCEL_SUBSCRIPTION = "GET_CANCEL_SUBSCRIPTION";
export const GET_CANCEL_SUBSCRIPTION_SUCCESS = "GET_CANCEL_SUBSCRIPTION_SUCCESS";
export const GET_CANCEL_SUBSCRIPTION_FAIL = "GET_CANCEL_SUBSCRIPTION_FAIL";

/**
 * CREATE MESSAGE
 */
export const GET_CREATE_MESSAGE = "GET_CREATE_MESSAGE";
export const GET_CREATE_MESSAGE_SUCCESS = "GET_CREATE_MESSAGE_SUCCESS";
export const GET_CREATE_MESSAGE_FAIL = "GET_CREATE_MESSAGE_FAIL";

/**
 * REMOVE CARD
 */
export const GET_REMOVE_CARD = "GET_REMOVE_CARD";
export const GET_REMOVE_CARD_SUCCESS = "GET_REMOVE_CARD_SUCCESS";
export const GET_REMOVE_CARD_FAIL = "GET_REMOVE_CARD_FAIL";

/**
 * SET DEFAULT CARD
 */
export const GET_SET_DEFAULT_CARD = "GET_SET_DEFAULT_CARD";
export const GET_SET_DEFAULT_CARD_SUCCESS = "GET_SET_DEFAULT_CARD_SUCCESS";
export const GET_SET_DEFAULT_CARD_FAIL = "GET_SET_DEFAULT_CARD_FAIL";

/**
 * Smile and Learn Subscription
 */
export const GET_SAL_SUBSCRIPTION = "GET_SAL_SUBSCRIPTION";
export const GET_SAL_SUBSCRIPTION_SUCCESS = "GET_SAL_SUBSCRIPTION_SUCCESS";
export const GET_SAL_SUBSCRIPTION_FAIL = "GET_SAL_SUBSCRIPTION_FAIL";

// TOAST message
export const TOAST_MESSAGE = "TOAST_MESSAGE";

/**
 * Recommendation - Growth engine
 */
export const GET_RECOMMENDATION = "GET_RECOMMENDATION";
export const GET_RECOMMENDATION_SUCCESS = "GET_RECOMMENDATION_SUCCESS";
export const GET_RECOMMENDATION_FAIL = "GET_RECOMMENDATION_FAIL";


/**
* MMS Plan
*/
export const GET_M_PLAN = 'GET_M_PLAN';
export const GET_M_PLAN_SUCCESS = 'GET_M_PLAN_SUCCESS';
export const GET_M_PLAN_FAIL = 'GET_M_PLAN_FAIL';

export const GET_M_CURRENT_PLAN = 'GET_M_CURRENT_PLAN';
export const GET_M_CURRENT_PLAN_SUCCESS = 'GET_M_CURRENT_PLAN_SUCCESS';
export const GET_M_CURRENT_PLAN_FAIL = 'GET_M_CURRENT_PLAN_FAIL';

export const CANCEL_PLAN = 'CANCEL_PLAN';
export const CANCEL_PLAN_SUCCESS = 'CANCEL_PLAN_SUCCESS';
export const CANCEL_PLAN_FAIL = 'CANCEL_PLAN_FAIL';

export const FINISH_TRIAL = 'FINISH_TRIAL';
export const FINISH_TRIAL_SUCCESS = 'FINISH_TRIAL_SUCCESS';
export const FINISH_TRIAL_FAIL = 'FINISH_TRIAL_FAIL';

/**
* MIMS
*/

export const GET_TOPIC_LIST = 'GET_TOPIC_LIST';
export const GET_TOPIC_LIST_SUCCESS = 'GET_TOPIC_LIST_SUCCESS';
export const GET_TOPIC_LIST_FAIL = 'GET_TOPIC_LIST_FAIL';

export const UPDATE_M_PURCHASE = 'UPDATE_M_PURCHASE';
export const UPDATE_M_PURCHASE_SUCCESS = 'UPDATE_M_PURCHASE_SUCCESS';
export const UPDATE_M_PURCHASE_FAIL = 'UPDATE_M_PURCHASE_FAIL';

export const SET_CURRENT_TOPIC = 'SET_CURRENT_TOPIC';

/**
* Get Questions
*/
export const GET_QUESTIONS = 'GET_QUESTIONS';
export const GET_QUESTIONS_SUCCESS = 'GET_QUESTIONS_SUCCESS';
export const GET_QUESTIONS_FAIL = 'GET_QUESTIONS_FAIL';

export const GET_TEST_ID = 'GET_TEST_ID';
export const GET_TEST_ID_SUCCESS = 'GET_TEST_ID_SUCCESS';
export const GET_TEST_ID_FAIL = 'GET_TEST_ID_FAIL';

export const GET_SCORES = 'GET_SCORES';
export const GET_SCORES_SUCCESS = 'GET_SCORES_SUCCESS';
export const GET_SCORES_FAIL = 'GET_SCORES_FAIL';

export const GET_AVERAGE_TIME = 'GET_AVERAGE_TIME';
export const GET_AVERAGE_TIME_SUCCESS = 'GET_AVERAGE_TIME_SUCCESS';
export const GET_AVERAGE_TIME_FAIL = 'GET_AVERAGE_TIME_FAIL';

export const SUBMIT_ANSWER = 'SUBMIT_ANSWER';
export const SUBMIT_ANSWER_SUCCESS = 'SUBMIT_ANSWER_SUCCESS';
export const SUBMIT_ANSWER_FAIL = 'SUBMIT_ANSWER_FAIL';

/**
* Get Questions
*/
export const GET_M_REVIEWS = 'GET_M_REVIEWS';
export const GET_M_REVIEWS_SUCCESS = 'GET_M_REVIEWS_SUCCESS';
export const GET_M_REVIEWS_FAIL = 'GET_M_REVIEWS_FAIL';

export const SUBMIT_M_REVIEW = 'SUBMIT_M_REVIEW';
export const SUBMIT_M_REVIEW_SUCCESS = 'SUBMIT_M_REVIEW_SUCCESS';
export const SUBMIT_M_REVIEW_FAIL = 'SUBMIT_M_REVIEW_FAIL';

/**
* HubSpot
*/
export const FROG_UP_HUBSPOT = 'FROG_UP_HUBSPOT';
export const FROG_UP_HUBSPOT_SUCCESS = 'FROG_UP_HUBSPOT_SUCCESS';
export const FROG_UP_HUBSPOT_FAIL = 'FROG_UP_HUBSPOT_FAIL';

/* Assessment */
export const GET_ASSESSMENT_LIST = 'GET_ASSESSMENT_LIST';
export const GET_ASSESSMENT_LIST_SUCCESS = 'GET_ASSESSMENT_LIST_SUCCESS';
export const GET_ASSESSMENT_LIST_FAIL = 'GET_ASSESSMENT_LIST_FAIL';

export const GET_ASSESSMENT = 'GET_ASSESSMENT';
export const GET_ASSESSMENT_SUCCESS = 'GET_ASSESSMENT_SUCCESS';
export const GET_ASSESSMENT_FAIL = 'GET_ASSESSMENT_FAIL';

export const GET_ASSESSMENT_QUESTIONS = 'GET_ASSESSMENT_QUESTIONS';
export const GET_ASSESSMENT_QUESTIONS_SUCCESS = 'GET_ASSESSMENT_QUESTIONS_SUCCESS';
export const GET_ASSESSMENT_QUESTIONS_FAIL = 'GET_ASSESSMENT_QUESTIONS_FAIL';

export const TOGGLE_ASSESSMENT_MODAL = 'TOGGLE_ASSESSMENT_MODAL';
export const TOGGLE_QUIZ_MODAL = 'TOGGLE_QUIZ_MODAL';
export const TOGGLE_PLANNER_MODAL = 'TOGGLE_PLANNER_MODAL';

export const SET_ACTIVE_ASSESSMENT = 'SET_ACTIVE_ASSESSMENT';
export const NEXT_ASSESSMENT_QUESTION = 'NEXT_ASSESSMENT_QUESTION';
export const SET_CURRENT_ASSESSMENT_QUESTION = 'SET_CURRENT_ASSESSMENT_QUESTION';
export const SKIP_ASSESSMENT_QUESTION = 'SKIP_ASSESSMENT_QUESTION';
export const RETAKE_ASSESSMENT = 'RETAKE_ASSESSMENT';
export const SHOW_ASSESSMENT_SOLUTION = 'SHOW_ASSESSMENT_SOLUTION';
export const START_ASSESSMENT = 'START_ASSESSMENT';

export const SUBMIT_ASSESSMENT = 'SUBMIT_ASSESSMENT';
export const SUBMIT_ASSESSMENT_SUCCESS = 'SUBMIT_ASSESSMENT_SUCCESS';
export const SUBMIT_ASSESSMENT_FAIL = 'SUBMIT_ASSESSMENT_FAIL';

export const SET_SOLUTION_QUESTION = 'SET_SOLUTION_QUESTION';
export const TOGGLE_ASMT_EXIT_MODAL = 'TOGGLE_ASMT_EXIT_MODAL';

export const GET_ASSESSMENT_HISTORY = 'GET_ASSESSMENT_HISTORY';
export const GET_ASSESSMENT_HISTORY_SUCCESS = 'GET_ASSESSMENT_HISTORY_SUCCESS';
export const GET_ASSESSMENT_HISTORY_FAIL = 'GET_ASSESSMENT_HISTORY_FAIL';

/* GET PRACTICE FLASHCARDS */
export const GET_PRACTICE_FLASHCARDS = 'GET_PRACTICE_FLASHCARDS';
export const GET_PRACTICE_FLASHCARDS_SUCCESS = 'GET_PRACTICE_FLASHCARDS_SUCCESS';
export const GET_PRACTICE_FLASHCARDS_FAIL = 'GET_PRACTICE_FLASHCARDS_ERROR';

export const GET_PRACTICE_FLASHCARD = 'GET_PRACTICE_FLASHCARD';
export const GET_PRACTICE_FLASHCARD_SUCCESS = 'GET_PRACTICE_FLASHCARD_SUCCESS';
export const GET_PRACTICE_FLASHCARD_FAIL = 'GET_PRACTICE_FLASHCARD_FAIL';

export const SET_ACTIVE_FLASHCARD = 'SET_ACTIVE_FLASHCARD';
export const SET_ACTIVE_CONCEPT = 'SET_ACTIVE_CONCEPT';
export const FLASHCARD_ROUND_COMPLETED = 'FLASHCARD_ROUND_COMPLETED';
export const NEXT_FLASHCARD_QUESTION = 'NEXT_FLASHCARD_QUESTION';
export const SKIP_FLASHCARD_QUESTION = 'SKIP_FLASHCARD_QUESTION';
export const SET_FLASHCARD_QUESTION = 'SET_FLASHCARD_QUESTION';
export const START_FLASHCARD_QUIZ = 'START_FLASHCARD_QUIZ';
export const FLASHCARDS_NEXT_STAGE = 'FLASHCARDS_NEXT_STAGE';
export const SWITCH_TO_CONCEPT = 'SWITCH_TO_CONCEPT';
export const RETAKE_ACTIVE_CONCEPT = 'RETAKE_ACTIVE_CONCEPT';
export const TOGGLE_QUIZ_EXIT_MODAL = 'TOGGLE_QUIZ_EXIT_MODAL';

export const GET_FLASHCARDS = 'GET_FLASHCARDS';
export const GET_FLASHCARDS_SUCCESS = 'GET_FLASHCARDS_SUCCESS';
export const GET_FLASHCARDS_FAIL = 'GET_FLASHCARDS_FAIL';

export const GET_FLASHCARD_QUESTIONS = 'GET_FLASHCARD_QUESTIONS';
export const GET_FLASHCARD_QUESTIONS_SUCCESS = 'GET_FLASHCARD_QUESTIONS_SUCCESS';
export const GET_FLASHCARD_QUESTIONS_FAIL = 'GET_FLASHCARD_QUESTIONS_FAIL';

export const SUBMIT_QUIZ_ANSWERS = 'SUBMIT_QUIZ_ANSWERS';
export const SUBMIT_QUIZ_ANSWERS_SUCCESS = 'SUBMIT_QUIZ_ANSWERS_SUCCESS';
export const SUBMIT_QUIZ_ANSWERS_FAIL = 'SUBMIT_QUIZ_ANSWERS_FAIL';

export const SAVE_FLASHCARD_STAGE = 'SAVE_FLASHCARD_STAGE';
export const SAVE_FLASHCARD_STAGE_SUCCESS = 'SAVE_FLASHCARD_STAGE_SUCCESS';
export const SAVE_FLASHCARD_STAGE_FAIL = 'SAVE_FLASHCARD_STAGE_FAIL';

export const GET_EXAMS = 'GET_EXAMS';
export const GET_EXAMS_SUCCESS = 'GET_EXAMS_SUCCESS';
export const GET_EXAMS_FAIL = 'GET_EXAMS_FAIL';

export const GET_PLANNER = 'GET_PLANNER';
export const GET_PLANNER_SUCCESS = 'GET_PLANNER_SUCCESS';
export const GET_PLANNER_FAIL = 'GET_PLANNER_FAIL';

export const SUBMIT_PLANNER = 'SUBMIT_PLANNER';
export const SUBMIT_PLANNER_SUCCESS = 'SUBMIT_PLANNER_SUCCESS';
export const SUBMIT_PLANNER_ERROR = 'SUBMIT_PLANNER_ERROR';

export const SET_ACTIVE_EXAM = 'SET_ACTIVE_EXAM';
export const SET_PLANNING_STAGE = 'SET_PLANNING_STAGE'
export const TOGGLE_PLANNER_EXIT_MODAL = 'TOGGLE_PLANNER_EXIT_MODAL';
export const SET_PLANNER_ACTIVE_DAY = 'SET_PLANNER_ACTIVE_DAY';
export const TOGGLE_CONFIRM_NEW_PLANNER = 'TOGGLE_CONFIRM_NEW_PLANNER';

export const DELETE_PLANNER = 'DELETE_PLANNER';
export const DELETE_PLANNER_SUCCESS = 'DELETE_PLANNER_SUCCESS';
export const DELETE_PLANNER_FAIL = 'DELETE_PLANNER_FAIL';

export const TOGGLE_PLANNER_PROMPT = 'TOGGLE_PLANNER_PROMPT';