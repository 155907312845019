import React, {forwardRef, useEffect, useState} from "react";
import './style.scss';
import TimeImage from 'src/Assets/MIMS/Planner/time management 2.png';
import Calendar from 'src/Assets/MIMS/Planner/fe_calendar.png';
import DatePicker from "react-datepicker";
import PlannerProgressIndicator from "../PlannerProgress";
import "react-datepicker/dist/react-datepicker.css";
import _ from 'lodash';
import TimeSplitterComponent from "./TimeSplitter";
import WeekDaysComponent from "./WeekDays";
import WorkTimeComponent from "./WorkTime";
import DoubleTimeSplitterComponent from "./DoubleTimeSplitter";
import TrippleTimeSplitterComponent from "./TrippleTimeSplitter";
import { useSelector } from "react-redux";

export default function PlannerProcessComponent({
    onDate,
    onSubject,
    onTopicRange,
    onWeekDays,
    onWorkTime
}) {
    const [startDate, setStartDate] = useState(undefined)
    const [days, setDays] = useState(0)

    const {
        plan_stage,
        active_exam
    } = useSelector(state => state.Mims)

    const onDateChanged = (date) => {
        setStartDate(date)
        let timeDifference = date.getTime() - (new Date()).getTime();
        const daysDifference = timeDifference / (1000 * 3600 * 24);
        setDays(daysDifference.toFixed(0))
    }

    const formatDate = (date) => `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`

    const trimTopic = (text) => {
        let edited = text.toUpperCase().replaceAll('MAKE', '')
        return (edited.replaceAll('SENSE', '').trim())
    }

    const CustomDateInput = forwardRef(({ value, onClick }, ref) => (
        <div className={`date-input ${days > 0 ? 'active' : ''}`} onClick={onClick} ref={ref}>
            <div className="label-content">
                <img src={Calendar}/>
                <div>{ _.isEmpty(value) ? 'Choose Exam Date' : value }</div>
            </div>
           { days > 0 && <div className="info-tag">Brilliant!</div> }
        </div>
      ));

    function DateComponent() {
        return (
            <div className="date-component card">
                <div className="info-content">
                    <div className="step-info">step 1 of 5</div>
                    <h3 className="plan-title">When is your exam date for the {active_exam.name}?</h3>
                    <p className="plan-content">Use the calendar to set your exam date. If unsure, pick a tentative date to create a study planner.</p>
                    <DatePicker 
                        dateFormat="M/dd/yyyy"
                        selected={startDate}
                        customInput={<CustomDateInput />}
                        onChange={(date) => onDateChanged(date)} />
                    { days > 0 && <div className="stat">You have <span>{days} days</span> before exam</div>}
                </div>
                <div className={`primary-btn ${days > 0 ? '' : 'disabled'}`} onClick={() => onDate(formatDate(startDate))}>NEXT</div>
                <PlannerProgressIndicator stage={1} />
            </div>
        )
    }

    function SubjectsComponent() {
        return (
            <div className="select-subjects card">
                <div className="info-content">
                    <div className="step-info">step 2 of 5</div>
                    <h3 className="plan-title">Choose Your Test Prep Focus:</h3>
                    <p className="plan-content">Choose the test prep section for which you'd like to create an optimal study planner.</p>
                    <div className="subjects">
                        <div className="row subject-row">
                            <div className="col-lg-4">
                                <div className="sub-label">{active_exam.name}</div>
                            </div>
                            <div className="col-lg-8">
                                <div className="sub-wrapper">
                                    {
                                        active_exam.topics.map(item => (
                                            <div className="subject selected" key={item.id}>{trimTopic(item.name)}</div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`primary-btn`} onClick={() => onSubject()}>NEXT</div>
                <PlannerProgressIndicator stage={2} />
            </div>
        )
    }

    function TopicTimeSplitComponent() {
        const topicCount = active_exam.topics.length

        if (topicCount === 2) {
            return <TimeSplitterComponent exam={active_exam} onValue={(value) => onTopicRange(value)}/>
        } else if (topicCount === 3) {
            return <DoubleTimeSplitterComponent exam={active_exam} onValue={(value) => onTopicRange(value)}/>
        } else if (topicCount > 3) {
            return <TrippleTimeSplitterComponent exam={active_exam} onValue={(value) => onTopicRange(value)}/>
        } else return <div></div>
    }

    return (
        <div className="planner-process">
            <div className="planner-content">
                <div className="row">
                    <div className="col-lg-7 left-section">
                        { plan_stage === 1 && <DateComponent />}
                        { plan_stage === 2 && <SubjectsComponent />}
                        { plan_stage === 3 && <TopicTimeSplitComponent />}
                        { plan_stage === 4 && <WeekDaysComponent onWeekDays={(value) => onWeekDays(value)}/>}
                        { plan_stage === 5 && <WorkTimeComponent onWorkTime={(time) => onWorkTime(time)}/>}
                    </div>
                    <div className="col-lg-5 right-section">
                        <div className="title">Perfect Score <br></br>Planner</div>
                        <img src={TimeImage} />
                    </div>
                </div>
            </div>
        </div>
    )
}