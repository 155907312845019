import React from 'react';
import './style.scss';
import { ReactSVG } from "react-svg";
import TickCircle1 from "src/Assets/MIMS/tick-circle-1.svg";
import TickCircle from "src/Assets/MIMS/tick-circle.svg";
import HitBg from "src/Assets/MIMS/hit-bg.svg";
import TrialHitBg from "src/Assets/MIMS/trial-hit-bg.svg";

export default function Plan({
  className = '', info, selectedPlanId, onChosePlan,
}) {

  return (
    <div
      className={`
        plan-component shadow-object 
        border-top-${info.id} 
        ${className}
        ${selectedPlanId === info.id ? 'active' : ''}
      `
      }>
      <div className='plan-main-content'>
        <span>{info.name}</span>
        <div className='price'>{info.price}</div>
        {selectedPlanId &&
          <div className={`btn-2 center chose-btn`} onClick={() => onChosePlan(info)}>Try It For Free</div>
        }
        <div className='split-border' />
        {info.description.map((item, index) => (
          <div className='h-r mb-2' key={index}>
            <ReactSVG src={index === 0 ? TickCircle1 : TickCircle} />
            <div
              className='access-permission'
              dangerouslySetInnerHTML={{ __html: item }}
            />
          </div>
        ))}
      </div>
      {info.id === 2 && <div className='hit-bg'>
        <ReactSVG src={HitBg} />
        <div className='hit-text'>HIT</div>
      </div>}
      <div className='hit-bg trial-bg'>
        <ReactSVG src={TrialHitBg} />
        <div className='hit-text'>{'FREE 7 days'}</div>
      </div>
    </div >
  );
}
