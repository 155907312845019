import _ from 'lodash';

import {
  TOGGLE_MIMS,
  GET_ASSESSMENT_LIST,
  GET_ASSESSMENT_LIST_FAIL,
  GET_ASSESSMENT_LIST_SUCCESS,
  NEXT_ASSESSMENT_QUESTION,
  SET_CURRENT_ASSESSMENT_QUESTION,
  GET_ASSESSMENT,
  GET_ASSESSMENT_FAIL,
  GET_ASSESSMENT_SUCCESS,
  SET_ACTIVE_ASSESSMENT,
  TOGGLE_ASSESSMENT_MODAL,
  TOGGLE_QUIZ_MODAL,
  GET_ASSESSMENT_QUESTIONS,
  GET_ASSESSMENT_QUESTIONS_FAIL,
  GET_ASSESSMENT_QUESTIONS_SUCCESS,
  SUBMIT_ASSESSMENT,
  SUBMIT_ASSESSMENT_FAIL,
  SUBMIT_ASSESSMENT_SUCCESS,
  RETAKE_ASSESSMENT,
  SHOW_ASSESSMENT_SOLUTION,
  SET_SOLUTION_QUESTION,
  TOGGLE_ASMT_EXIT_MODAL,
  GET_ASSESSMENT_HISTORY,
  GET_ASSESSMENT_HISTORY_FAIL,
  GET_ASSESSMENT_HISTORY_SUCCESS,
  SKIP_ASSESSMENT_QUESTION,
  START_ASSESSMENT,
  TOGGLE_PLANNER_MODAL,
  GET_PRACTICE_FLASHCARDS,
  GET_PRACTICE_FLASHCARDS_SUCCESS,
  GET_PRACTICE_FLASHCARDS_FAIL,
  SET_ACTIVE_FLASHCARD,
  SET_ACTIVE_CONCEPT,
  GET_FLASHCARDS,
  GET_FLASHCARDS_SUCCESS,
  GET_FLASHCARDS_FAIL,
  GET_FLASHCARD_QUESTIONS,
  GET_FLASHCARD_QUESTIONS_SUCCESS,
  GET_FLASHCARD_QUESTIONS_FAIL,
  FLASHCARD_ROUND_COMPLETED,
  SET_FLASHCARD_QUESTION,
  NEXT_FLASHCARD_QUESTION,
  SKIP_FLASHCARD_QUESTION,
  START_FLASHCARD_QUIZ,
  SUBMIT_QUIZ_ANSWERS,
  SUBMIT_QUIZ_ANSWERS_FAIL,
  SUBMIT_QUIZ_ANSWERS_SUCCESS,
  FLASHCARDS_NEXT_STAGE,
  SWITCH_TO_CONCEPT,
  RETAKE_ACTIVE_CONCEPT,
  TOGGLE_QUIZ_EXIT_MODAL,
  GET_PRACTICE_FLASHCARD,
  GET_PRACTICE_FLASHCARD_SUCCESS,
  GET_PRACTICE_FLASHCARD_FAIL,
  GET_EXAMS,
  GET_EXAMS_FAIL,
  GET_EXAMS_SUCCESS,
  GET_PLANNER,
  GET_PLANNER_SUCCESS,
  GET_PLANNER_FAIL,
  SET_ACTIVE_EXAM,
  SET_PLANNING_STAGE,
  TOGGLE_PLANNER_EXIT_MODAL,
  SUBMIT_PLANNER,
  SUBMIT_PLANNER_ERROR,
  SUBMIT_PLANNER_SUCCESS,
  SET_PLANNER_ACTIVE_DAY,
  TOGGLE_CONFIRM_NEW_PLANNER,
  DELETE_PLANNER,
  DELETE_PLANNER_FAIL,
  DELETE_PLANNER_SUCCESS,
  TOGGLE_PLANNER_PROMPT
} from "../Type";
import { FLASHCARD_NUM } from 'src/Components/MIMS/Quiz/Info';
import { WeekDays } from 'src/Constant';

const INITIAL = {
  isMims: false,
  assessments: null,
  assessment: null,
  assessment_questions: null,
  loading_questions: false,
  getQuestions_success: false,
  active_assessment: null,
  getAssessment_loading: false,
  getAssessment_success: false,
  assessment_is_active: false,
  assessment_completed: false,
  total_time: 0,
  current_question: null,
  answered_questions: [],
  assessment_result: null,
  submit_assmt_loading: false,
  showing_assessment_solution: false,
  assessment_history: null,
  assessment_on_hold: false,
  isAssessmentModalOpen: false,
  isExitAssessmentModalOpen: false,

  /* Flashcards and Quiz */
  isQuizModalOpen: false,
  practice_flashcards: null,
  active_flashcard: null,
  flashcard_concept: null,
  flashcards: null,
  flashcard_questions: null,
  quiz_questions: [],
  quiz_flashcards: [],
  current_quiz_question: null,
  loading_flashcard_questions: false,
  getFlashcards_success: false,
  getFcQuestions_success: false,
  showing_flashcards: false,
  showing_score: false,
  completed_flashcards: false,
  taking_quiz: false,
  quiz_completed: false,
  quiz_answers: [],
  quiz_time: 0,
  retake_stage: 0,
  submit_quiz_loading: false,
  submit_quiz_success: false,
  isQuizExitModalOpen: false,
  loadingPracticeFlashcard: false,

/*   Planner */
  isPlannerModalOpen: false,
  exams: null,
  planner: null,
  active_day: null,
  loading_planner: false,
  showing_plan: true,
  active_exam: null,
  plan_stage: 0,
  isExitPlannerOpen: false,
  createPlanner_loading: false,
  createPlanner_success: false,
  show_collecting_plan: false,
  isConfirmNewPlannerOpen: false,
  deletePlanner_loading: false,
  isPlannerPromptOpen: false
};

export default (state = INITIAL, action) => {
  switch (action.type) {
    case TOGGLE_MIMS: {
      const { isMims } = action.data;

      return {
        ...state,
        isMims,
      }
    }

    /* GET ASSESSMENTWS */
    case GET_ASSESSMENT_LIST: {
      return {
        ...state,
        assessments: null
      }
    }
    case GET_ASSESSMENT_LIST_SUCCESS: {
      return {
        ...state,
        assessments: action.payload
      }
    }
    case GET_ASSESSMENT_LIST_FAIL: {
      return {
        ...state,
        assessments: null
      }
    }

    /* GET ASSESSMENT DATA */
    case GET_ASSESSMENT: {
      return {
        ...state,
        assessment: null,
        getAssessment_loading: true,
        getAssessment_success: false
      }
    }
    case GET_ASSESSMENT_SUCCESS: {
      return { 
        ...state, 
        assessment: action.payload,
        getAssessment_loading: false,
        getAssessment_success: true,
        assessment_on_hold: action.payload && action.payload.wait_time !== 0
       }
    }
    case GET_ASSESSMENT_FAIL: {
      return {
        ...state, 
        assessment: null,
        getAssessment_loading: false,
        getAssessment_success: false
      }
    }

    /* GET ASSESSMENT QUESTIONS */
    case GET_ASSESSMENT_QUESTIONS: {
      return {
        ...state, 
        assessment_questions: null,
        loading_questions: true,
        getQuestions_success: false
      }
    }
    case GET_ASSESSMENT_QUESTIONS_SUCCESS: {
      return {
        ...state, 
        assessment_questions: action.payload,
        loading_questions: false,
        getQuestions_success: true,
      }
    }
    case GET_ASSESSMENT_QUESTIONS_FAIL: {
      return {
        ...state, 
        assessment_questions: null,
        loading_questions: false,
        getQuestions_success: false
      }
    }
    case START_ASSESSMENT: {
      const first_question = !_.isEmpty(state.assessment_questions) ? (state.assessment_questions)[0] : null;
      return {
        ...state,
        current_question: first_question,
        assessment_is_active: true,
        assessment_completed: false,
        assessment_on_hold: false
      }
    }

    /* SUBMIT ASSESSMENT */
    case SUBMIT_ASSESSMENT: {
      return {
        ...state,
        submit_assmt_loading: true,
        assessment_result: null,
        assessment_is_active: true
      }
    }
    case SUBMIT_ASSESSMENT_SUCCESS: {
      return {
        ...state,
        submit_assmt_loading: false,
        assessment_result: action.payload,
        assessment_is_active: false
      }
    }
    case SUBMIT_ASSESSMENT_FAIL: {
      return {
        ...state,
        submit_assmt_loading: false,
        assessment_result: null,
        assessment_is_active: true
      }
    }

   /* ASSESSMENT HISTORY */ 
    case GET_ASSESSMENT_HISTORY: {
      return {
        ...state,
        assessment_history: null
      }
    }
    case GET_ASSESSMENT_HISTORY_SUCCESS: {
      return {
        ...state,
        assessment_history: action.payload
      }
    }
    case GET_ASSESSMENT_HISTORY_FAIL: {
      return {
        ...state,
        assessment_history: null
      }
    }

    /* ASSESSMENT ACTIONS */
    case SET_ACTIVE_ASSESSMENT: {
      const { assessment } = action.data
      return {
        ...state,
        active_assessment: assessment
      }
    }
    case TOGGLE_ASSESSMENT_MODAL: {
      const { show } = action.data 
      if(show) {
        return {
          ...state,
          isAssessmentModalOpen: show,
          assessment_on_hold: false
        }
      } else {
        return {
          ...state,
          isAssessmentModalOpen: show,
          assessment_completed: false,
          assessment_is_active: false,
          assessment_result: null,
          answered_questions: [],
          total_time: 0,
          showing_assessment_solution: false,
          assessment_questions: null,
          assessment_history: null,
          assessment_on_hold: false
        }
      }
    }
    case NEXT_ASSESSMENT_QUESTION: {
      const { next_question, current_answer, is_last , total_time } = action.data
      const answered = _.remove(state.answered_questions, function(item) {
        return item.question_id !== current_answer.question_id
      })
      return {
        ...state,
        current_question: is_last ? null : next_question,
        assessment_completed: is_last ? true : false,
        total_time: total_time,
        answered_questions: [...answered, current_answer]
      }
    }
    case SKIP_ASSESSMENT_QUESTION: {
      const { question, total_time } = action.data
      // if question was already answered (you're coming from ahead) don't send it forward
      const answerIndex = _.findIndex(state.answered_questions, function(item) {
        return item.question_id === state.current_question.id
      })

      if(answerIndex >= 0) {
        return {
          ...state,
          current_question: question,
          total_time: total_time
        }
      } else {
        // if question has not been answered, move it forward to be answered later
        const currentIndex = _.findIndex(state.assessment_questions, function (item) {
          return item.id === state.current_question.id
        })
        if (currentIndex >= 0) {
          const new_questions = [...state.assessment_questions]
          new_questions.splice(currentIndex, 1)
          new_questions.push((state.assessment_questions)[currentIndex])
  
          return {
            ...state,
            current_question: question,
            total_time: total_time,
            assessment_questions: new_questions
          }
        } else {
          return {
            ...state,
            current_question: question,
            total_time: total_time
          }
        }
      }
    }
    case SET_CURRENT_ASSESSMENT_QUESTION: {
      const { question, is_last, total_time } = action.data
      return {
        ...state,
        current_question: is_last ? null : question,
        total_time: total_time,
        assessment_completed: is_last ? true : false,
      }
    }
    case RETAKE_ASSESSMENT: {
      return {
        ...state,
        current_question: state.assessment_questions[0],
        answered_questions: [],
        assessment_is_active: true,
        assessment_completed: false,
        total_time: 0,
        assessment_result: null,
        showing_assessment_solution: false,
        assessment_on_hold: false
      }
    }
    case SHOW_ASSESSMENT_SOLUTION: {
      return {
        ...state,
        showing_assessment_solution: true,
        assessment_is_active: true,
        assessment_completed: false,
        current_question: state.assessment_questions[0]
      }
    }
    case SET_SOLUTION_QUESTION: {
      const { question, last } = action.data
      return {
        ...state,
        current_question: question,
        showing_assessment_solution: last ? false : true,
        assessment_is_active: last ? false : true,
        assessment_completed: last ? true : false,
      }
    }
    case TOGGLE_ASMT_EXIT_MODAL: {
      const { isOpen } = action.data
      return {
        ...state,
        isExitAssessmentModalOpen: isOpen
      }
    }

    /* FLASHCARDS & QUIZ */
    case TOGGLE_QUIZ_MODAL: {
      const { show } = action.data
      if(show) {
        return {
          ...state,
          isQuizModalOpen: show
        }
      } else {
        return {
          ...state,
          isQuizModalOpen: show,
          taking_quiz: false,
          showing_flashcards: false,
          showing_score: false,
          submit_quiz_success: false,
          quiz_completed: false,
          flashcards: null,
          flashcard_questions: null,
          flashcard_concept: null,
          retake_stage: 0
        }
      }
    }

    case TOGGLE_QUIZ_EXIT_MODAL: {
      const { isOpen } = action.data
      return {
        ...state,
        isQuizExitModalOpen: isOpen
      }
    }
    /* GET PRACTICE FLASHCARDS */
    case GET_PRACTICE_FLASHCARDS: {
      return {
        ...state,
        practice_flashcards: null
      }
    }

    case GET_PRACTICE_FLASHCARDS_SUCCESS: {
      return {
        ...state,
        practice_flashcards: action.payload
      }
    }

    case GET_PRACTICE_FLASHCARDS_FAIL: {
      return {
        ...state,
        practice_flashcards: null
      }
    }

    /* GET PRACTICE FLASHCARD DATA */
    case GET_PRACTICE_FLASHCARD: {
      return {
        ...state,
        loadingPracticeFlashcard: true
      }
    }

    case GET_PRACTICE_FLASHCARD_SUCCESS: {
      return {
        ...state,
        active_flashcard: action.payload,
        loadingPracticeFlashcard: false
      }
    }

    case GET_PRACTICE_FLASHCARD_FAIL: {
      return {
        ...state,
        loadingPracticeFlashcard: false
      }
    } 

    /* GET FLASHCARDS */
    case GET_FLASHCARDS: {
      return {
        ...state,
        flashcards: null,
        getFlashcards_success: false,
        getFcQuestions_success: false,
        retake_stage: 0
      }
    }

    case GET_FLASHCARDS_SUCCESS: {
      const concept = (state.active_flashcard.concepts)[state.flashcard_concept]
      const offset = concept.stage * FLASHCARD_NUM >= concept.total ? 0 : concept.stage * FLASHCARD_NUM
      const quizFlashcards = action.payload.slice(offset, offset + FLASHCARD_NUM)
      return {
        ...state,
        flashcards: action.payload,
        quiz_flashcards: quizFlashcards,
        getFlashcards_success: true,
      }
    }

    case GET_FLASHCARDS_FAIL: {
      return {
        ...state,
        flashcards: null,
        getFlashcards_success: false
      }
    }

    /* GET FLASHCARD QUESTIONS */
    case GET_FLASHCARD_QUESTIONS: {
      return {
        ...state,
        flashcard_questions: null,
        loading_flashcard_questions: true,
        getFcQuestions_success: false
      }
    }

    case GET_FLASHCARD_QUESTIONS_SUCCESS: {
      return {
        ...state,
        flashcard_questions: action.payload,
        loading_flashcard_questions: false,
        getFcQuestions_success: true,
        showing_flashcards: true,
        taking_quiz: false,
        showing_score: false,
      }
    }

    case GET_FLASHCARD_QUESTIONS_FAIL: {
      return {
        ...state,
        flashcard_questions: null,
        loading_flashcard_questions: false,
        getFcQuestions_success: false
      }
    }

    case SET_ACTIVE_FLASHCARD: {
      const { flashcard } = action.data
      return {
        ...state,
        active_flashcard: flashcard
      }
    }

    case SET_ACTIVE_CONCEPT: {
      const { concept } = action.data
      return {
        ...state,
        flashcard_concept: concept
      }
    }

    case FLASHCARD_ROUND_COMPLETED: {
      const concept = (state.active_flashcard.concepts)[state.flashcard_concept]
      var retakeStage = state.retake_stage

      if((concept.stage * FLASHCARD_NUM) < concept.total) {
        Object.keys(state.active_flashcard.concepts).forEach(key => {
          if (key === state.flashcard_concept) state.active_flashcard.concepts[key].stage += 1
        })
      } else if (retakeStage * FLASHCARD_NUM < concept.total) { retakeStage += 1 }

      return {
        ...state,
        showing_score: false,
        taking_quiz: false,
        showing_flashcards: false,
        retake_stage: retakeStage
      }
    }

    case START_FLASHCARD_QUIZ: {
      const flashcardIds = state.quiz_flashcards.map(item => item.id)
      const flashcardQuestions = state.flashcard_questions.filter(item => flashcardIds.includes(item.ac_flashcard_id))
      console.log(flashcardIds, flashcardQuestions)
      return {
        ...state,
        quiz_questions: flashcardQuestions.length > 0 ? flashcardQuestions : state.flashcard_questions.slice(0, 10),
        current_quiz_question: flashcardQuestions.length > 0 ? flashcardQuestions[0] : state.flashcard_questions[0],
        taking_quiz: true,
        showing_flashcards: false,
        showing_score: false,
        quiz_completed: false,
        quiz_answers: [],
      }
    }

    case NEXT_FLASHCARD_QUESTION: {
      const { next_question, current_answer, is_last , total_time } = action.data
      const answered = _.remove(state.quiz_answers, function(item) {
        return item.question_id !== current_answer.question_id
      })
      return {
        ...state,
        taking_quiz: is_last ? false : true,
        quiz_time: total_time,
        showing_score: is_last ? true : false,
        quiz_answers: [...answered, current_answer],
        quiz_completed: is_last ? true : false,
        current_quiz_question: is_last ? null : next_question,
      }
    }
    case SKIP_FLASHCARD_QUESTION: {
      const { question, total_time } = action.data
      // if question was already answered (you're coming from ahead) don't send it forward
      const answerIndex = _.findIndex(state.quiz_answers, function(item) {
        return item.question_id === state.current_quiz_question.id
      })

      if(answerIndex >= 0) {
        return {
          ...state,
          current_quiz_question: question,
          quiz_time: total_time
        }
      } else {
        // if question has not been answered, move it forward to be answered later
        const currentIndex = _.findIndex(state.quiz_questions, function (item) {
          return item.id === state.current_quiz_question.id
        })
        if (currentIndex >= 0) {
          const new_questions = [...state.quiz_questions]
          new_questions.splice(currentIndex, 1)
          new_questions.push((state.quiz_questions)[currentIndex])
  
          return {
            ...state,
            current_quiz_question: question,
            quiz_time: total_time,
            quiz_questions: new_questions
          }
        } else {
          return {
            ...state,
            current_quiz_question: question,
            quiz_time: total_time
          }
        }
      }
    }
    case SET_FLASHCARD_QUESTION: {
      const { question, is_last, total_time } = action.data
      return {
        ...state,
        current_quiz_question: is_last ? null : question,
        quiz_time: total_time,
        taking_quiz: is_last ? false : true,
      }
    }

    case SUBMIT_QUIZ_ANSWERS: {
      return {
        ...state,
        submit_quiz_loading: true,
        submit_quiz_success: false,
      }
    }

    case SUBMIT_QUIZ_ANSWERS_SUCCESS: {
      return {
        ...state,
        submit_quiz_loading: false,
        submit_quiz_success: action.payload.status === 200,
        taking_quiz: false,
        showing_score: true,
      }
    }

    case SUBMIT_QUIZ_ANSWERS: {
      return {
        ...state,
        submit_quiz_loading: false,
        submit_quiz_success: false
      }
    }

    case FLASHCARDS_NEXT_STAGE: {
      const { isContinue } = action.data
      const concept = (state.active_flashcard.concepts)[state.flashcard_concept]
      if(isContinue) {
        const startOffset = concept.stage * FLASHCARD_NUM  
        const retakeOffset = state.retake_stage * FLASHCARD_NUM
        const nextFlashcards = startOffset >= state.flashcards.length ? state.flashcards.slice(retakeOffset, retakeOffset + FLASHCARD_NUM) : state.flashcards.slice(startOffset, startOffset + FLASHCARD_NUM)
        return {
          ...state, 
          quiz_flashcards: nextFlashcards.length > 0 ? nextFlashcards : state.flashcards.slice(0, FLASHCARD_NUM),
          quiz_completed: false, 
          showing_flashcards: true,
          showing_score: false,
          submit_quiz_success: false
        }
      } else {
        return {
          ...state,
          showing_score: false,
          showing_flashcards: false,
          quiz_completed: false,
          quiz_answers: [],
          current_quiz_question: state.quiz_questions[0],
          taking_quiz: true,
          submit_quiz_success: false
        }
      }
    }

    case SWITCH_TO_CONCEPT: {
      const { concept } = action.data
      return {
        ...state,
        flashcard_concept: concept,
        showing_score: false,
        showing_flashcards: false,
        quiz_completed: false,
        quiz_answers: [],
        taking_quiz: false,
        flashcards: null,
        flashcard_questions: null,
        quiz_flashcards: [],
        quiz_questions: [],
        submit_quiz_success: false,
        retake_stage: 0
      }
    } 

    case RETAKE_ACTIVE_CONCEPT: {
      return {
        ...state,
      }
    }

    /* Planner */
    case TOGGLE_PLANNER_MODAL: {
      const { show } = action.data
      if (show) {
        return {
          ...state,
          isPlannerModalOpen: show,
          flashcard_concept: null
        }
      } else {
        return {
          ...state,
          isPlannerModalOpen: show,
          plan_stage: 0,
          planner: null,
          showing_plan: false,
          flashcard_concept: null
        }
      }
    }
    case SET_ACTIVE_EXAM: {
      const { exam } = action.data
      return {
        ...state,
        active_exam: exam
      }
    }
    case GET_EXAMS: {
      return {
        ...state,
        exams: null
      }
    }
    case GET_EXAMS_SUCCESS: {
      return {
        ...state,
        exams: action.payload
      }
    }
    case GET_EXAMS_FAIL: {
      return {
        ...state,
        exams: null
      }
    }
    case GET_PLANNER: {
      return {
        ...state,
        planner: null,
        loading_planner: true,
        showing_plan: false
      }
    }
    case GET_PLANNER_SUCCESS: {
      const noPlanner = action.payload && action.payload.status === 404
      // sessionStorage.setItem('planner', JSON.stringify(action.payload))
      return {
        ...state,
        planner: noPlanner ? null : action.payload,
        active_day: noPlanner ? null : WeekDays[action.payload.active_day - 1],
        show_collecting_plan: false,
        showing_plan: noPlanner ? false : true,
        loading_planner: false
      }
    }
    case GET_PLANNER_FAIL: {
      return {
        ...state,
        planner: null,
        showing_plan: false,
        loading_planner: false
      }
    }
    case SET_PLANNING_STAGE: {
      const { stage } = action.data
      return {
        ...state,
        plan_stage: stage
      }
    }
    case SUBMIT_PLANNER: {
      return {
        ...state,
        createPlanner_loading: true,
        createPlanner_success: false,
        show_collecting_plan: false
      }
    }
    case SUBMIT_PLANNER_SUCCESS: {
      const success = !_.isEmpty(action.payload.success)
      return {
        ...state,
        createPlanner_loading: false,
        createPlanner_success: success,
        show_collecting_plan: true,
        plan_stage: -1
      }
    }
    case SUBMIT_PLANNER_ERROR: {
      return {
        ...state,
        createPlanner_loading: false,
        createPlanner_success: false,
        show_collecting_plan: false
      }
    }
    case TOGGLE_PLANNER_EXIT_MODAL: {
      const { show } = action.data
      return {
        ...state,
        isExitPlannerOpen: show
      }
    }
    case SET_PLANNER_ACTIVE_DAY: {
      const { day } = action.data
      return {
        ...state,
        active_day: day
      }
    }
    case TOGGLE_CONFIRM_NEW_PLANNER: {
      const { show } = action.data
      return {
        ...state,
        isConfirmNewPlannerOpen: show
      }
    }
    case DELETE_PLANNER: {
      return {
        ...state,
        deletePlanner_loading: true
      }
    }
    case DELETE_PLANNER_SUCCESS: {
      return {
        ...state,
        deletePlanner_loading: false,
        showing_plan: false,
        plan_stage: 0,
        planner: null,
        active_day: null
      }
    }
    case DELETE_PLANNER_FAIL: {
      return {
        ...state,
        deletePlanner_loading: false
      }
    }
    case TOGGLE_PLANNER_PROMPT: {
      const { isOpen } =  action.data
      return {
        ...state,
        isPlannerPromptOpen: isOpen
      }
    }
    default:
      return state;
  }
};
