import React from 'react';
// import Modal from 'react-modal';
import { Modal } from 'react-bootstrap';
import add from '../../Assets/Icon/add.svg';
import { ReactSVG } from 'react-svg'

import './FreeTrialModal.scss';

export default class FreeTrialModal extends React.Component {
    
    confirm = (status) => {
        this.props.freeTrialLessonConfirm(status)
    }

    render() {
        const {isFreeTrialConfirmModal} = this.props
    return (
        <Modal show={isFreeTrialConfirmModal} className="free-trial-confirm-modal-component">
            <div className="free-trial-confirm-content">
                <div className="close-btn"> <ReactSVG src={ add } className='icon-close' onClick={ () => this.confirm(false) }/> </div>
                <div className="content-area v-r">
                    <div>
                        <h2>
                            If you need to schedule more than one hour of tutoring, you need to select a tutoring plan and activate your subscription. 
                            Only the first 30 minutes of tutoring are free.
                        </h2>
                    </div>
                    <div className="lesson-confirm-btn-area">
                        <div className="lesson-confirm-btn" onClick={()=>this.confirm(true)}>
                            SetUp
                        </div>
                        <div className="lesson-confirm-btn" onClick={()=>this.confirm(false)}>
                            Cancel
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
  };
};