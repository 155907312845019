import React, { useState } from 'react';
import './style.scss';
import { ReactSVG } from 'react-svg';
import Logo_GG from 'src/Assets/Header/logo.png';
import Logo_MIMS from 'src/Assets/MIMS/Logo-MIMS.png';
import Logo_MIMS_dark from 'src/Assets/MIMS/Logo-MIMS-dark.png';
import arrowup from 'src/Assets/Icon/arrow-up.svg';
import arrowdown from 'src/Assets/Icon/arrow-down.svg';
import { CircleCheckBox } from 'src/Components'

export default function DropDownLogo({
  className = '', isGG = false, onClick
}) {

  const [isDropDown, setIsDropDown] = useState(false);

  const onDropDown = () => {
    setIsDropDown(!isDropDown);
  }

  const onSelectLogo = (isGradeGetter) => {
    setIsDropDown(false);
    onClick(isGradeGetter);
  };

  return (
    <div className={`drop-down-logo-component ${className}`}>
      <div className="logo-header-container v-c" onClick={onDropDown}>
        <div className='logo-area'>
          <img src={isGG ? Logo_GG : Logo_MIMS} className='logo' alt='logo' />
        </div>
        <ReactSVG src={isDropDown ? arrowup : arrowdown} className="drop-icon" />
      </div>
      {isDropDown && (
        <div className='drop-down-list'>          
          <div className='select-logo v-c' onClick={() => onSelectLogo(true)}>
            <img src={Logo_GG} className='gg-logo' alt='logo' />
            <CircleCheckBox 
              isChecked={isGG}
              onClick={() => onSelectLogo(true)}
            />
          </div>
          <div className='select-logo v-c' onClick={() => onSelectLogo(false)}>
            <img src={Logo_MIMS_dark} className='mims-logo' alt='logo' />
            <CircleCheckBox 
              isChecked={!isGG}
              onClick={() => onSelectLogo(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
}
