import React from 'react';
import './CreditCardPage.scss';
import master from '../../Assets/CreditCard/master.png';
import visa from '../../Assets/CreditCard/visa.png';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from "react-toastify";
import { braintainService } from '../../services/paymentServices';
import {
  getBraintreekeyAction,
  sendNoneToServerAction
} from "../../Redux/Actions";
import { showSpinner, hideSpinner } from 'src/Util/Helper';

class CreditCardPage extends React.Component {
  state = {
    card_number_1: '',
    card_number_2: '',
    card_number_3: '',
    card_number_4: '',
    month: '',
    year: '',
    cvv2: '',
    saveText: 'Save Changes',
    isPayClick: false,
  }

  componentDidMount() {
    this.props.getBraintreekeyAction()
  }

  saveChange = () => {
    showSpinner();
    const { card_number_1, card_number_2, card_number_3, card_number_4, month, year, cvv2 } = this.state;
    var cardDetail = {
      card_number: `${card_number_1}${card_number_2}${card_number_3}${card_number_4}`,
      month,
      year,
      cvv2,
      zip_code: '12345'
    };
    const { braintreeKey } = this.props;
    braintainService(braintreeKey, cardDetail).then((res) => {
      this.sendNonce(res);
    })
      .catch(err => {
        hideSpinner();
        toast("Invalid Card Info")
      });
  }

  sendNonce = (nonce) => {
    const payload = {
      nonce
    }
    const formData = new FormData()
    formData.append('todo', 'new');
    formData.append('nonce', nonce);

    this.props.sendNoneToServerAction(formData).then(() => {
      hideSpinner();
      const { nonceUpdate, nonceUpdateErr } = this.props;
      if (nonceUpdate) {
        toast(nonceUpdate)
      } else {
        toast(nonceUpdateErr);
      };
    });
  };

  paymentToken = (token) => {
    this.setState({ isPayClick: false });
    hideSpinner();
    toast("Successfully")
  }

  paymentTokenError = (error) => {
    this.setState({ isPayClick: false });
    this.props.loader(false)
    toast(error)
  }

  render() {
    const { saveText } = this.state;
    return (
      <div className="creditcard-page v-c h-c v-r">
        <p>Change Your Credit Card</p>
        <div className="box-container v-r">
          <div className="card-box v-r">
            <p>Card Number</p>
            <div className="row">
              <div className="col-3 sub-col"> <input className="sub-input" maxLength="4" onChange={e => this.setState({ card_number_1: e.currentTarget.value })} /> </div>
              <div className="col-3 sub-col"> <input className="sub-input" maxLength="4" onChange={e => this.setState({ card_number_2: e.currentTarget.value })} /> </div>
              <div className="col-3 sub-col"> <input className="sub-input" maxLength="4" onChange={e => this.setState({ card_number_3: e.currentTarget.value })} /> </div>
              <div className="col-3 sub-col"> <input className="sub-input" maxLength="4" onChange={e => this.setState({ card_number_4: e.currentTarget.value })} /> </div>
            </div>
            <p className="second-line">Date validity</p>
            <div className="row">
              <div className="col-3 sub-col"> <input className="sub-input" maxLength="2" onChange={e => this.setState({ month: e.currentTarget.value })} /> <div className="slash">/</div> </div>
              <div className="col-3 sub-col"> <input className="sub-input" maxLength="2" onChange={e => this.setState({ year: e.currentTarget.value })} /> </div>
              <div className="col-3 sub-col v-c h-c"> <img src={master} alt="master" /> </div>
              <div className="col-3 sub-col v-c h-c"> <img src={visa} alt="visa" /> </div>
            </div>
          </div>
          <div className="card-box v-r">
            <div className="break-line" />
            <div className="row second-line">
              <div className="col-3 sub-col" /><div className="col-3 sub-col" /><div className="col-3 sub-col" />
              <div className="col-3 sub-col h-c v-r"> <p>CVV2</p> <input className="sub-input" maxLength="4" onChange={e => this.setState({ cvv2: e.currentTarget.value })} /> </div>
            </div>
          </div>
        </div>
        <button className="btn-2 btn-2-outline save-btn" onClick={e => this.saveChange()}>{saveText}</button>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  braintreeKey: state.Core.braintreeKey,
  nonceUpdate: state.Auth.nonceUpdate,
  nonceUpdateErr: state.Auth.nonceUpdateErr
});

export default connect(mapStateToProps, {
  getBraintreekeyAction,
  sendNoneToServerAction,
})(withRouter(CreditCardPage));