import React from 'react';
import './style.scss';
import { Modal } from 'react-bootstrap';
import { ReactSVG } from 'react-svg'
import { useDispatch, useSelector } from 'react-redux';
import { toggleMuseVideoModal } from 'src/Redux/Actions';
import add from 'src/Assets/Icon/add.svg';

// const videoId = "g4n8PXP"

export default function MuseVideoModal() {
  const dispatch = useDispatch();

  const {
    isMuseVideoModalOpen, videoInfo
  } = useSelector((state) => state.Modal);

  const closeModal = () => {
    dispatch(toggleMuseVideoModal(false));
  };

  return (
    <Modal show={isMuseVideoModalOpen} className="muse-video-modal-component">
      <div className="video-content v-r">
        <div className="close-btn" onClick={closeModal}>
          <ReactSVG src={add} className='icon-close' />
        </div>
        <iframe
          src={`https://muse.ai/embed/${videoInfo?.videoId}?start=0&volume=75&links=0&logo=https://i.ibb.co/m4WQHy2/MIMS-by.png&cover_play_position=center&style=minimal`}
          height="600"
          frameBorder="0"
          title='tutor video'
          allowFullScreen>
        </iframe>
      </div>
    </Modal>
  );
}