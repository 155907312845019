import React, { useEffect, useState } from "react";
import './style.scss';
import QuizCircleProgress from "../../QuizCircleProgress";
import ConceptTestTracking from "../ConceptTestTracking";
import FeedbackAnalysis from "../FeedbackAnalysis";
import { convertSecondsToMS } from "src/Constant";
import _ from 'lodash';


export default function PDFComponent({compRef, result, time}) {
    const failColor = '#F92672';
    const passColor = '#24C1A5';
    const [text, setText] = useState(`0/0`)
    const [fail, setFail] = useState(0)
    const [passRatio, setPassRatio] = useState(0)
    const [percentage, setPercentage] = useState(0)

    useEffect(() => {
        if (!_.isEmpty(result)) {
            const summary = result.question_summary
            setFail(valueOn10(summary.correct + summary.wrong, summary.total))
            setPassRatio((summary.correct / summary.total))
            setText(`${summary.correct}/${summary.total}`)
            setPercentage(calculatePercentage(summary))
        }
    })

    const valueOn10 = (value, max) => (value / max) * 10

    const calculatePercentage = (summary) => {
        return (summary.correct / summary.total) * 100
    }

    return (
        <div className="pdf-component-wrapper" ref={compRef}>
            <div className="section-wrapper">
                <div className="top-section row">
                    <div className="col-lg-5">
                        <h1 className="mb-0">Congratulations 🎉</h1>
                        <h1>{result.user.first_name}</h1>
                    </div>
                    <div className="col-lg-6">
                        <div className="message-content" style={{ paddingRight: '3rem'}}>
                            <p>You've successfully completed the assessment! 🌟 Your hard work has paid off, and we're proud of your efforts. 🏆 Take a moment to acknowledge your achievements, and know that you're one step closer to reaching your academic goals! 🚀</p>
                        </div>
                    </div>
                </div>
                <div className="stats">
                    <div className="row row-sm">
                        <div className="col-lg-4 left-section">
                           <div className="stat-wrapper">
                                <div className="stat-row">
                                    <div className="item">
                                        <div className="stat-label">SCORE</div>
                                        <div className="stat-text-large">{percentage}%</div>
                                    </div>
                                    <div className="item">
                                        <div className="stat-label">TIME</div>
                                        <div className="stat-text-large">{convertSecondsToMS(time)}</div>
                                    </div>
                                </div>
                                <div className="progress-show">
                                    <div className="progress-stack">
                                        <QuizCircleProgress progress={fail} strokeColor={failColor} ratio={1} text={text} width={240} height={240}/>
                                        <QuizCircleProgress progress={10} strokeColor={passColor} ratio={passRatio} text={text} width={240} height={240}/>
                                    </div>
                                </div>
                           </div>
                        </div>
                        <div className="col-lg-8 right-section">
                            <div className="analysis">
                               <div className="row">
                                    <div className="col-lg-6 concepts-track">
                                        <ConceptTestTracking assessment={result}/>
                                    </div>
                                    <div className="col-lg-6 feedback">
                                        <FeedbackAnalysis assessment={result}/>
                                    </div>
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-text">
                    <p>You've successfully completed the assessment! 🌟 Your hard work has paid off, and we're proud of your efforts. 🏆 Take a moment to acknowledge your achievements, and know that you're one step closer to reaching your academic goals! 🚀</p>
                </div>
            </div>
        </div>
    )
}