import React from 'react';
import './SmileAndLearnPage.scss';
import logo from './smileandlearnlogo.jpeg';
import videoimage from './video.png';
import offer1 from './offer1.png';
import offer2 from './offer2.png';
import { CheckBox, Accordion } from '../../Components';
import { toast } from "react-toastify";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    getCardInfoAction,
    getSmileandlearnSubscriptionAction,
    startSmileandlearnSubscriptionAction,
    cancelSmileandlearnSubscriptionAction,
} from "../../Redux/Actions";
import _ from 'lodash'
import { showSpinner, hideSpinner } from 'src/Util/Helper';

class SmileAndLearnPage extends React.Component {
    state = {
        password: '',
        subscription_type: 'monthly'
    }

    componentDidMount() {
        this.props.getCardInfoAction();
        this.props.getSmileandlearnSubscriptionAction();
    }

    componentDidUpdate() {

    }

    startSubscription() {
        showSpinner();
        const { password, subscription_type } = this.state;
        const formData = new FormData();
        formData.append('password', password);
        formData.append('subscription_type', subscription_type);
        this.props.startSmileandlearnSubscriptionAction(formData).then(() => {
            hideSpinner();
            toast(this.props.toast_message);
            this.props.getSmileandlearnSubscriptionAction();
        });
    }

    cancelSubscription() {
        showSpinner();
        this.props.cancelSmileandlearnSubscriptionAction().then(() => {
            hideSpinner();
            toast(this.props.toast_message);
            this.props.getSmileandlearnSubscriptionAction();
        });

    }

    subscriptionstatus() {
        const { smileandlearn } = this.props;
        if (smileandlearn.length == 0) return <div className="bold">You don't have subscription with Smile and Learn yet</div>;
        if (smileandlearn.is_active) return <div className="bold">
            Your Smile and Learn subscription is active.
            Starting from {smileandlearn.start_date} to {smileandlearn.renew_date}.
            {smileandlearn.is_trial ? 'You are on trial month.' : ''}
        </div>;
        return <div className="bold">
            Your Smile and Learn subscription is inactive.
        </div>;
    }
    userform() {
        const { card_numbers, smileandlearn } = this.props;
        if (card_numbers.length == 0) return <div className="bold">Please add your credit card in "Your Plan" menu before subscribe to Smile and Learn service</div>;
        if (smileandlearn.is_active) return <div>
            <button className="btn-2 primary-btn" onClick={(e) => this.cancelSubscription()}>
                Cancel my Subscription
            </button>
        </div>
        return <div className="col-lg-6">
            <div className="option-group v-r">
                <h2 className="option-title">Password for your Smile and Learn login</h2>
                <input placeholder="Password" id="password" type="password" onChange={(event) => this.setState({ password: event.target.value })} />
                * Your username for login will be your email registered in GradeGetter
            </div>
            <div className="option-group v-r">
                <h2 className="option-title">Subscription length</h2>
                <select onChange={(event) => this.setState({ subscription_type: event.target.value })}>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                </select>
            </div>
            <div className="option-group v-r">
                <button className="btn-2 primary-btn" onClick={(e) => this.startSubscription()}>
                    Start Subscription
                </button>
            </div>
        </div>

    }

    render() {
        const { card_numbers, smileandlearn } = this.props;

        return (
            <div className="smileandlearn-page v-r">
                <div className="settings-body">
                    <div className="container settings-container">
                        <div id="section-profile">
                            <div className="option-group">
                                <img src={logo} style={{ margin: '0 auto', display: 'block' }} />
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="option-group">
                                        <span className="bold">GradeGetter</span> and <span className="bold">Smile and Learn</span> have partnered
                                        together to provide an exciting opportunity for children to supplement their tutoring classes with interactive
                                        digital content through the Smile and Learn platform.
                                    </div>
                                    <div className="option-group">
                                        You can checkout from over <span className="bold">10,000 different educational activities</span> in stories,
                                        videos, games, and puzzles available in <span className="bold">6 languages</span>!
                                    </div>
                                    <div className="option-group">
                                        <img src={videoimage} style={{ margin: '0 auto', display: 'block' }} />
                                    </div>
                                    <div className="option-group">
                                        Start your <span className="bold">30 day free trial</span> by clicking on either of the subscription options below!
                                        You will be able to:
                                        <ul>
                                            <li>Access a <span className="bold">large collection</span> of <span className="bold">cross-curricular</span> content perfect for supplementing
                                                the tutoring sessions.</li>
                                            <li>Engage your children in <span className="bold">fun</span> and <span className="bold">guided learning</span> alongside their tutors by
                                                sharing their profiles</li>
                                            <li>Manage your child's profile and <span className="bold">track their progress</span> through the Parent Portal.</li>
                                            <li>Ensure a <span className="bold">secure</span> and <span className="bold">safe learning environment</span> for your children.</li>
                                            <li>Add up to 5 different children profiles at <span className="bold">no additional cost</span>.</li>
                                        </ul>
                                    </div>
                                    <div className="option-group">
                                        <div style={{ margin: '0 auto', display: 'block', width: '800px' }}>
                                            <img src={offer1} style={{ width: '400px' }} />
                                            <img src={offer2} style={{ width: '400px' }} />
                                        </div>
                                    </div>
                                    <div className="option-group">
                                        <span className="bold">Terms and Conditions.</span> You will not be charged
                                        during your free trial month and
                                        you can cancel the subscription whenever you want. GradeGetter will send you a
                                        reminder when your first month is about to end, so you can cancel your account
                                        if you wish to end your subscription.
                                        <br />If you choose to continue after your Smile and Learn trial ends, you will
                                        be automatically charged based on your subscription option, and continue enjoying full
                                        access to the Smile and Learn platform.
                                    </div>

                                    <div className="option-group">
                                        <span className="bold" style={{ color: 'blue' }}>Smile and Learn</span> is a 360
                                        adaptive learning platform, <span className="bold">internationally awarded by
                                            the European Union, BETT, and MIT, and used by more than 20 million kids worldwide!</span>
                                    </div>
                                    <div className="option-group">
                                        Specialized in videos, games, puzzles, and stories beautifully designed by educators
                                        in 6 languages, the platform reinforces cross-curricular learning in Math, Literacy,
                                        Foreign Languages, and Sciences along with SEL, Global Awareness, and cognitive skills
                                        development.
                                    </div>

                                    <hr />
                                    {this.subscriptionstatus()}
                                    {this.userform()}
                                </div>
                            </div>

                        </div>



                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    card_numbers: state.Core.card_numbers,
    smileandlearn: state.Core.smileandlearn,
    toast_message: state.Core.toast_message,
});

export default connect(mapStateToProps, {
    getCardInfoAction,
    getSmileandlearnSubscriptionAction,
    startSmileandlearnSubscriptionAction,
    cancelSmileandlearnSubscriptionAction,
})(withRouter(SmileAndLearnPage));
