import React, { useState, useMemo, useEffect } from "react";
import './style.scss';
import { useDispatch, useSelector } from "react-redux";
import { RightMessages, WrongMessages  } from "../Info";
import {
    SET_FLASHCARD_QUESTION,
    NEXT_FLASHCARD_QUESTION,
    SKIP_FLASHCARD_QUESTION
} from 'src/Redux/Type';
import _ from 'lodash';

export default function FlashcardQuiz({concept}) {
    const dispatch = useDispatch()
    const [selectdAnswer, setSelectedAnswer] = useState(undefined)
    
    const {
        flashcard_concept,
        current_quiz_question,
        quiz_questions,
        quiz_answers,
    } = useSelector(state => state.Mims)

    // Stopwatch timer
    const [time, setTime] = useState(0);
    const [lastTime, setLastTime] = useState(0);
    const [isRunning, setIsRunning] = useState(true);
    const [message, setMessage] = useState("");
    
    useEffect(() => {
        let intervalId;
        if (isRunning) {
            intervalId = setInterval(() => setTime(time + 1), 10);
        }
        return () => clearInterval(intervalId);
    }, [isRunning, time]);

    const seconds = Math.floor((time % 6000) / 100);

    const currentIndex = useMemo(() => {
        if (_.isEmpty(quiz_questions)) return -1;
        const index = _.findIndex(quiz_questions, function (item) {
            return item.id === current_quiz_question.id
        })
        console.log(`current question index: ${index}`)
        return index;
    }, [current_quiz_question.id])

    const isAnswered = () => {
        const answerIndex = _.findIndex(quiz_answers, function(answer) {
            return answer.question_id === current_quiz_question.id
        })
        return answerIndex >= 0
    }

    const onItemSelect = (item) => {
        if(selectdAnswer !== undefined) return;
        setSelectedAnswer(item)
        setMessage(getAffirmation(item))
    }

    const getAffirmation = (item) => {
        if (isCorrect(item)) return RightMessages[Math.floor(Math.random() * RightMessages.length)]
        return WrongMessages[Math.floor(Math.random() * WrongMessages.length)]
    }

    const isLastQuestion = () => currentIndex === quiz_questions.length - 1

    const onBack = () => {
        if (currentIndex === 0) return;
        dispatch({ 
            type: SET_FLASHCARD_QUESTION, 
            data: { question: quiz_questions[currentIndex - 1], total_time: seconds, is_last: false }
        })
        setSelectedAnswer(undefined)
    }

    const onNext = () => {
        if (selectdAnswer === undefined) return;
        const next_question = isLastQuestion() ? null : quiz_questions[currentIndex + 1];
        if(isAnswered()) {
            dispatch({ 
                type: SET_FLASHCARD_QUESTION, 
                data: { question: next_question, total_time: seconds, is_last: isLastQuestion() }
            })
        } else {
            const answer = { 
                question_id: current_quiz_question.id, 
                option: selectdAnswer, 
                timer_second: seconds - lastTime,
                correct: selectdAnswer === current_quiz_question.solution
            }
            const payload = {
                next_question: next_question,
                current_answer: answer,
                total_time: seconds,
                is_last: isLastQuestion()
            }
            dispatch({type: NEXT_FLASHCARD_QUESTION, data: payload})
            setLastTime(seconds)
            console.log(lastTime, seconds)
        }
        setSelectedAnswer(undefined)
    }

    const onSkip = () => {
        if (isLastQuestion()) return;
        dispatch({ 
            type: SKIP_FLASHCARD_QUESTION, 
            data: { question: quiz_questions[currentIndex + 1], total_time: seconds}
        })
        setSelectedAnswer(undefined)
    }

    const getAnswerStatus = (item) => {
        if (selectdAnswer !== item) return ''
        return isCorrect(item) ? 'correct' : 'wrong'
    }

    const isCorrect = (item) => item === current_quiz_question.solution

    const getIndicatorStatus = (index) => {
        const answerIndex = _.findIndex(quiz_answers, function (item) {
            return item.question_id === quiz_questions[index].id
        })
        if (answerIndex >= 0) {
            return quiz_answers[answerIndex].correct ? 'passed' : 'failed'
        } else return 'pending';
    }

    return (
        <div className="flashcard-quiz card">
            <h1>{flashcard_concept} <span>Flashcard Test</span></h1>
            <h4>Question #{currentIndex + 1}</h4>
            <p dangerouslySetInnerHTML={{ __html: current_quiz_question.description }}></p>
            {
                current_quiz_question && (
                    <>
                        <div className="answer-section">
                            <h4>Your Answer</h4> 
                            {
                                current_quiz_question.options.map((item, index) => (
                                    <div className={`answer-item ${getAnswerStatus(item)}`} key={index} onClick={() => onItemSelect(item)}>
                                        <div className="answer-info">
                                            <div className="answer-check">
                                                <div className="inner-circle"></div>
                                            </div>
                                            <div className="answer-content">
                                                <div className="text">{item}</div>
                                                { (selectdAnswer === item) && <div className="message">{message}</div>}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="btn-section">
                            <div className={`btn-custom btn-light ${currentIndex === 0 ? 'disabled' : ''}`} onClick={() => onBack()}>BACK</div>
                            <div className="pagination-show"><span>{currentIndex + 1}</span> of {quiz_questions.length}</div>
                            <div className="btn-custom skip-btn" onClick={() => onSkip()}>SKIP</div>
                            {/* Note: Next button color varies based on question index and time */}
                            <div className={`btn-custom primary-btn ${ isLastQuestion() ? 'last': ''} ${selectdAnswer === undefined ? 'disabled': ''}`} onClick={() => onNext()}>
                                { isLastQuestion() ? 'FINISH' : 'NEXT'}
                                </div>
                        </div>
                    </>
                )
            }
            <div className="indicators">
                {
                    quiz_questions.map((item, index) => (
                        <div className={`square ${getIndicatorStatus(index)}`} key={index} style={{
                            width: `${currentIndex === index ? 40 : 35}px`,
                            height: `${currentIndex === index ? 40 : 35}px`
                        }}></div>
                    ))
                }
            </div>
        </div>
    )
}