import React from 'react';
import './ScheduleLessonPage.scss';
import search from '../../Assets/Icon/search.svg';
import cancel from '../../Assets/Icon/cancel.svg';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { InputBox, SelectBox, Avatar, TimeZone, LessonConfirmModal, FreeTrialModal } from '../../Components';
import { TutorList } from './TutorList';
import { ReactSVG } from 'react-svg';
import left from '../../Assets/Icon/left.svg';
import right from '../../Assets/Icon/right.svg';
import success from '../../Assets/Icon/success.png';
import Calendar from 'react-calendar';
import moment from 'moment-timezone';
import _ from 'lodash'
import {
    getMytutorsAction,
    scheduleLessonAction,
    deleteTutorAction,
    getAvailabilityAction,
    getMypofileInfoAction
} from "../../Redux/Actions";
import { toast } from 'react-toastify';
import { showSpinner, hideSpinner } from 'src/Util/Helper';

const TimeList = [
    {availability: true, time24: '00:00:00', value: 0},
    {availability: true, time24: '00:30:00', value: 0},
    {availability: true, time24: '01:00:00', value: 1},
    {availability: true, time24: '01:30:00', value: 1},
    {availability: true, time24: '02:00:00', value: 2},
    {availability: true, time24: '02:30:00', value: 2},
    {availability: true, time24: '03:00:00', value: 3},
    {availability: true, time24: '03:30:00', value: 3},
    {availability: true, time24: '04:00:00', value: 4},
    {availability: true, time24: '04:30:00', value: 4},
    {availability: true, time24: '05:00:00', value: 5},
    {availability: true, time24: '05:30:00', value: 5},
    {availability: true, time24: '06:00:00', value: 6},
    {availability: true, time24: '06:30:00', value: 6},
    {availability: true, time24: '07:00:00', value: 7},
    {availability: true, time24: '07:30:00', value: 7},
    {availability: true, time24: '08:00:00', value: 8},
    {availability: true, time24: '08:30:00', value: 8},
    {availability: true, time24: '09:00:00', value: 9},
    {availability: true, time24: '09:30:00', value: 9},
    {availability: true, time24: '10:00:00', value: 10},
    {availability: true, time24: '10:30:00', value: 10},
    {availability: true, time24: '11:00:00', value: 11},
    {availability: true, time24: '11:30:00', value: 11},
    {availability: true, time24: '12:00:00', value: 12},
    {availability: true, time24: '12:30:00', value: 12},
    {availability: true, time24: '13:00:00', value: 13},
    {availability: true, time24: '13:30:00', value: 13},
    {availability: true, time24: '14:00:00', value: 14},
    {availability: true, time24: '14:30:00', value: 14},
    {availability: true, time24: '15:00:00', value: 15},
    {availability: true, time24: '15:30:00', value: 15},
    {availability: true, time24: '16:00:00', value: 16},
    {availability: true, time24: '16:30:00', value: 16},
    {availability: true, time24: '17:00:00', value: 17},
    {availability: true, time24: '17:30:00', value: 17},
    {availability: true, time24: '18:00:00', value: 18},
    {availability: true, time24: '18:30:00', value: 18},
    {availability: true, time24: '19:00:00', value: 19},
    {availability: true, time24: '19:30:00', value: 19},
    {availability: true, time24: '20:00:00', value: 20},
    {availability: true, time24: '20:30:00', value: 20},
    {availability: true, time24: '21:00:00', value: 21},
    {availability: true, time24: '21:30:00', value: 21},
    {availability: true, time24: '22:00:00', value: 22},
    {availability: true, time24: '22:30:00', value: 22},
    {availability: true, time24: '23:00:00', value: 23},
    {availability: true, time24: '23:30:00', value: 23},
];

const TutorLengths = [
    {id: "1", name: "0.5 hr ( Free trial )"},
    {id: "2", name: "1 hr"},
    {id: "3", name: "1.5 hrs"},
    {id: "4", name: "2 hrs"},
    {id: "5", name: "2.5 hrs"},
    {id: "6", name: "3 hrs"},
    {id: "7", name: "3.5 hrs"},
    {id: "8", name: "4 hrs"},
    {id: "9", name: "4.5 hrs"},
    {id: "10", name: "5 hrs"},
    {id: "11", name: "5.5 hrs"},
    {id: "12", name: "6 hrs"},
    {id: "13", name: "6.5 hrs"},
    {id: "14", name: "7 hrs"},
    {id: "15", name: "7.5 hrs"},
    {id: "16", name: "8 hrs"},

];

const tutorTypes = [
    'Online Tutoring', 'In-Person Tutoring'
];

const TutorRepeats = [
    {id: "1", name: "One time only", value: "0"},
    {id: "2", name: "2 Weeks", value: "2"},
    {id: "3", name: "4 Weeks", value: "4"},
    {id: "4", name: "6 Weeks", value: "6"},
    {id: "5", name: "8 Weeks", value: "8"},
    {id: "6", name: "10 Weeks", value: "10"},
    {id: "7", name: "12 Weeks", value: "12"},
    {id: "8", name: "4 Months", value: "16"},
    {id: "9", name: "6 Months", value: "24"},
];

class ScheduleLessonPage extends React.Component {
    state = {
        timeList: TimeList,
        Tutors: TutorList,
        tutor_id: '',
        searchText: '',
        depth: 0,
        selectedTutor: {},
        tutorLengths: TutorLengths,

        tutor_date: '',
        selected_time: '',

        tutor_length: '0.5 hr ( Free trial )',
        tutor_type: '',
        tutor_repeat: 'One time only',
        tutor_note: '',
        loading: false,
        isLessonConfirmModal: false,
        lessonConfirmDescription: '',
        isFreeTrialConfirmModal: false,

        current_timezone: moment.tz.guess(true)
    }

    componentDidMount() {
        this.props.getMypofileInfoAction().then(()=> {
            const {myProfileInfo} = this.props;
            if(!myProfileInfo.can_book_trial){
                this.setState({tutor_length: '1 hr'});
                this.state.tutorLengths.splice(0,1);
            }
        });
        this.props.getMytutorsAction().then(()=> {
            if(this.props.mytutors){
                if(this.props.match.params.tutor_id){
                    let index = this.props.mytutors.findIndex(temp => temp.id === parseInt(this.props.match.params.tutor_id, 10));
                    this.selectTutor(this.props.mytutors[index]);
                } else {
                    if(this.props.mytutors.length > 0){
                        this.selectTutor(this.props.mytutors[0]);
                    }
                }
            }
        })
    }

    formatInitialData = () => {
        const {myProfileInfo} = this.props

        this.setState({
            tutor_date: '',
            selected_time: '',
            tutor_length: myProfileInfo.can_book_trial? '0.5 hr ( Free trial )' : '1 hr',
            tutor_type: '',
            tutor_repeat: 'One time only',
            tutor_note: ''
        })
    }

    static getDerivedStateFromProps(props, state) {
        return {
            depth: props.depth
        }
    }

    selectTutor = async (tutor) => {
        let temp = tutor || {};
        this.setState({ 
            selectedTutor: temp,
        });
        this.formatInitialData();
        await this.props.formatTitle();
        this.props.updateTitle('push', 'Schedule a lesson', 2);
        this.setState({tutor_id: tutor.id})
    }

    deleteTutor = (e, item, index) => {
        this.setState({loading: true});
        e.stopPropagation();

        this.props.deleteTutorAction(item.id).then(() => {
            if(this.props.deleteTutorStatus){
                this.props.mytutors.splice(index, 1);
                this.setState({loading: false})
                if (item.id === this.state.selectedTutor.id) {
                    this.setState({ 
                        selectedTutor: {},
                    });
                    this.formatInitialData();
                    this.props.formatTitle();
                }
            }
        });
    }

    selectDate = (date) => {
        if(Math.floor((new Date() - new Date(date))) / 1000 < 0 ){
            this.setState({ 
                tutor_date : date,
                selected_time: '',
            })
            this.getAvailabilityTime(moment(date).format('YYYY-MM-DD'), this.state.current_timezone);
        } else {
            this.setState({ 
                tutor_date : '',
                selected_time: '',
            })
        }
    }

    selectTimezone = (val) => {
        this.setState({current_timezone: val})
        this.getAvailabilityTime(moment(this.state.tutor_date).format('YYYY-MM-DD'), val);
    }

    getAvailabilityTime = (day, timezone) => {
        showSpinner();
        const formData = new FormData()
        formData.append('tutor_id', this.state.tutor_id);
        formData.append('day', day);
        formData.append('timezone', moment.tz(moment.utc(), timezone).utcOffset()/60);

        this.props.getAvailabilityAction(formData).then(()=>{
            hideSpinner();
            const {availabilityTime} = this.props
            const { timeList } = this.state
            if(availabilityTime){
                timeList.map((data, index)=>{
                    timeList[index].availability = true
                    availabilityTime.availability.map((availability_data, availability_index)=>{
                        if(data.value === availability_index && !availability_data){
                            timeList[index].availability = false
                        }
                    })
                })

                this.setState({timeList})
            }
        })
    }

    submit = (status) => {
        const { myProfileInfo } = this.props;
        const {tutor_id, tutor_length, tutor_repeat, tutor_date, selected_time, current_timezone, tutor_note, isFreeTrialConfirmModal} = this.state;
        
        if(myProfileInfo.can_book_trial && tutor_length !== '0.5 hr ( Free trial )'){
            this.setState({isFreeTrialConfirmModal: true});
        } else {
            showSpinner();
            const formData = new FormData()
            formData.append('tutor_id', tutor_id);
            formData.append('hours', Number(tutor_length.split(' ')[0]));
            formData.append('sched', `${moment(tutor_date).format('YYYY-MM-DD')} ${selected_time}`);
            formData.append('timezone', moment.tz(moment.utc(), current_timezone).utcOffset()/60);
            formData.append('client_note', tutor_note);
            formData.append('confirm_schedule', status? 'true' : 'false');
            if(myProfileInfo.id === 5){
                formData.append('repeat_until', _.find(TutorRepeats, {name: tutor_repeat}).value);     
            }

            this.props.scheduleLessonAction(formData).then(() => {
                const {scheduleLessonStatus, scheduleLessonErr} = this.props;
                if(scheduleLessonStatus){                
                    this.props.updateTitle('push', 'Schedule a lesson', 4)
                    
                    this.props.getMypofileInfoAction().then(()=> {
                        hideSpinner();
                        const {myProfileInfo} = this.props;                    
                        if(!myProfileInfo.can_book_trial){
                            this.setState({tutor_length: '1 hr'});
                            this.state.tutorLengths.splice(0,1);
                        };
                    });                
                } else {
                    if(scheduleLessonErr.nomal_error){
                        hideSpinner();
                        toast(scheduleLessonErr.message);
                    } else {                    
                        this.setState({
                            isLessonConfirmModal: true,
                            lessonConfirmDescription: scheduleLessonErr.message
                        });
                    };
                };
            });
        };
    };

    lessonConfirm =(status) => {
        this.setState({isLessonConfirmModal: false});
        if(status){
            this.submit(true);
        };
    };

    freeTrialLessonConfirm =(status) => {
        this.setState({isFreeTrialConfirmModal: false});
        if(status){
            this.props.history.push("/your-plan");
        };
    };

    render() {
        const {mytutors, myProfileInfo} = this.props;
        const { timeList, tutorLengths, searchText, depth, selectedTutor, Tutors, tutor_date, selected_time, tutor_length, tutor_repeat, tutor_note, current_timezone } = this.state;
        if(mytutors){
            var list = mytutors.filter(tutor => tutor.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1);
        }
        return (
            <div className="schedulelesson-page">
                <div className={`tutor-list v-r ${depth === 0 ? 'activate' : ''}`}>
                    <InputBox className="search-contact" value={searchText} icon={search} placeholder="Search..." onChange={val=>this.setState({searchText:val})} />
                    <div className="tutors v-r scroll-area">
                        {list && list.map((item, index) => <div className={`tutor-container ${selectedTutor.id === item.id ? 'selected' : ''}`} key={index} onClick={e => this.selectTutor(item)}>
                            <div className="tutor v-c">
                                <Avatar className="avatar" info={item} />
                                <div className="info v-r">
                                    <h4>{item.name}</h4>
                                    <p>{item.school}</p>
                                </div>
                                {/* <ReactSVG src={cancel} className="svg-icon" onClick={e => this.deleteTutor(e, item, index)} /> */}
                            </div>
                        </div>)}
                    </div>
                </div>
                <div className={`content-area scroll-area v-r ${depth >= 1 ? 'activate' : ''}`}>
                    {depth === 1 && <div className="date-content">
                        <div className="date-select-area v-r">
                            <Calendar
                                className="calendar"
                                onChange={this.selectDate}
                                value={tutor_date || new Date()}
                                calendarType="US"
                                prevLabel={<ReactSVG src={left} className="svg-icon" />}
                                nextLabel={<ReactSVG src={right} className="svg-icon" />}
                            />
                            <TimeZone value={current_timezone} onChange={val => this.selectTimezone(val)}/>
                        </div>
                        {tutor_date.toString().length > 0 && <div className="time-select v-r">
                            <div className="time-header v-c v-r">
                                <h1>{moment(tutor_date).format('dddd, MMMM DD, YYYY')}</h1>
                                <p>Please select start time</p>
                            </div>
                            <div className="time-list v-r">
                                {timeList.map((item, index) =>                                     
                                    !item.availability?
                                        null
                                        :
                                        <div className={`time-container v-c ${selected_time === item.time24 ? 'active' : ''}`} key={index}>
                                            <div className="time-item sub-item v-c h-c" onClick={e => this.setState({selected_time: item.time24})}>{moment(item.time24, ["HH:mm"]).format("hh:mm A")}</div>
                                            <div className="confirm-item sub-item v-c h-c" onClick={e => this.props.updateTitle('push', 'Schedule a lesson', 3)}>Confirm</div>
                                        </div>
                                )}
                            </div>
                        </div>}
                    </div>}
                    {depth >= 2 && <div className="schedule-options v-r">
                        <div className="selected-date v-c">
                            <ReactSVG src={left} className="svg-icon show-web-flex" onClick={e => this.props.updateTitle('pop')} />
                            <h2>{moment(tutor_date).format('dddd, MMMM D')}, Start Time: {moment(selected_time, ["HH:mm"]).format("hh:mm A")}, {current_timezone}</h2>
                        </div>
                        {depth === 2 && <div className="option-list v-r">
                            <div className="option-group v-r">
                                <h2 className="option-title">Length of tutoring session</h2>
                                <SelectBox className="option-select" value={tutor_length} options={tutorLengths} onChange={val => this.setState({tutor_length: val.name})}/>
                            </div>
                            {myProfileInfo.id === 5 && <div className="option-group v-r">
                                <h2 className="option-title">Repeat this schedule to the next</h2>
                                <SelectBox className="option-select" value={tutor_repeat} options={TutorRepeats} onChange={val => this.setState({tutor_repeat: val.name})}/>
                            </div>}
                            <div className="option-group v-r">
                                <h2 className="option-title">Note for tutor (optional)</h2>
                                <textarea className="option-note" value={tutor_note} onChange={e => this.setState({tutor_note: e.target.value})} resize="none" placeholder=""/>
                            </div>
                            <button 
                                className="btn-2 primary-btn submit-btn" 
                                onClick={e => this.submit(false)}>
                                Submit
                            </button>
                        </div>}
                        {depth === 3 && <div className="success-area v-c h-c">
                            <div className="success-content v-r h-c v-c">
                                <h2>Success!</h2>
                                <img src={success} alt="success" />
                                <p>Thanks for scheduling a lesson. Your  tutor will be notify by email and SMS.</p>
                            </div>
                        </div>}
                    </div>}
                </div>
                <LessonConfirmModal
                    isLessonConfirmModal={this.state.isLessonConfirmModal}
                    lessonConfirm={(e) => this.lessonConfirm(e)}
                    lessonConfirmDescription = {this.state.lessonConfirmDescription}
                />
                <FreeTrialModal
                    isFreeTrialConfirmModal={this.state.isFreeTrialConfirmModal}
                    freeTrialLessonConfirm={(e) => this.freeTrialLessonConfirm(e)}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    mytutors: state.Core.mytutors,
    scheduleLessonStatus: state.Auth.scheduleLessonStatus,
    scheduleLessonErr: state.Auth.scheduleLessonErr,    
    deleteTutorStatus: state.Auth.deleteTutorStatus,
    availabilityTime: state.Auth.availabilityTime,
    myProfileInfo: state.Core.myProfileInfo,
});

export default connect(mapStateToProps, {
    getMytutorsAction,
    scheduleLessonAction,
    deleteTutorAction,
    getAvailabilityAction,
    getMypofileInfoAction
})(withRouter(ScheduleLessonPage));