import React from "react";
import './style.scss';


export default function ConceptIntroComponent({concept, onStart}) {

    return (
        <div className="concept-intro-component card">
            <h1>🎉 Flashcard Mastery! 🌟</h1>
            <h1 className="concept-title">{concept}</h1>
            <p className="intro-message"><span>Step 1:</span> 🚀 Let's embark on a journey of learning and mastery with our powerful flashcard tool. We'll start by giving you a set of 10 carefully curated flashcards. Take your time to absorb the knowledge they offer and understand each concept thoroughly.📚</p>
            <p className="intro-message"><span>Step 2:</span> 💡 Once you feel confident in your understanding, it's time to put your knowledge to the test with a quiz. Score above 90% on the quiz, and you'll seamlessly progress to the next set of 10 flashcards, diving deeper into the subject. 📝</p>
            <p className="intro-message"><span>Step 3:</span> 🎯 If you score below 90% on the quiz, don't worry! We believe in the power of practice and reinforcement. You'll get a chance to take a second quiz to solidify your understanding before moving on to the next set of flashcards. 🔄</p>
            <p className="intro-message">At Flashcard Mastery, we're here to ensure your success and foster a true sense of mastery in your subject. Let's get started on this exciting journey! Begin your path to becoming a master with our engaging and effective flashcard tool. Happy learning! 🌈📚
            </p>
            <div className="d-flex justify-content-center">
                <div className="btn-c primary-btn" onClick={onStart}>START LEARNING</div>
            </div>

            <div className="intro-tags">
                {/* <div className="tag date">April 2017</div> */}
                <div className="tag topic">{concept}</div>
            </div>
        </div>
    )
}