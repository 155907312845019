import React from "react";
import './style.scss'
import IconComplete from 'src/Assets/MIMS/Quiz/IconComplete.png';

export default function FlashcardComplete({onStart, concept, flashcardNum }) {

    return (
        <div className="flashcard-complete card">
           <h1>🎉 Flashcard Mastery! 🌟</h1>
            <h1>{concept}</h1>
            <img src={IconComplete} className="icon"/>
            <p> Congratulations on learning {flashcardNum} flashcards! 🎉 </p>
            <p>Now, let's put your understanding to the test with a quick quiz. Click the button below to continue and showcase your knowledge!
Good luck! 👍🏼</p>
            <div className="d-flex justify-content-center">
                <div className="btn-c primary-btn" onClick={onStart}>START QUIZ NOW</div>
            </div>
        </div>
    )
}