import { get, post } from "./Api";

export const getQuestions = (topic_id) => {
  return get(`/mimstopics/${topic_id}/questions`);
};

export const getTestId = (data) => {
  return post(`/mimstests`, data);
}

export const getScores = (test_id) => {
  return get(`/mimstests/${test_id}`);
}

export const getAverageTime = (test_id) => {
  return get(`/mimsanswers/${test_id}`);
}

export const submitAnswer = (data) => {
  return post(`/mimsanswers`, data);
}
