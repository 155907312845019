import React, { useEffect } from "react";
import './style.scss';

export default function FeedbackAnalysis({assessment}) {

    const feedbackAnalysis = [
        { grade: 'A', title: "Excellent mastery of concepts", color: '24C1A5' },
        { grade: 'B', title: "Good mastery of concepts", color: '8ed37d' },
        { grade: 'C', title: "Satisfactory mastery of concepts", color: 'ffdf6e' },
        { grade: 'D', title: "Less than satisfactory mastery of concepts", color: 'f08c18' },
        { grade: 'F', title: "Unsatisfactory mastery of concepts", color: 'F92672' },
    ]

    return (
        <div className="feed-back-analysis card">
            <h4>Feedback Analysis %</h4>
            <div className="content">
                {
                    feedbackAnalysis
                        .map(item => (
                            <div className="item" key={item.grade}>
                                {/* <div className="label">{item.description}</div>
                                <div className="visual" style={{width: `${item.max}%`}}>
                                    <div className="progress" style={{
                                        backgroundColor: `#${item.color_code}`
                                    }}></div>
                                    <div className="range">{item.min} - {item.max}</div>
                                </div> */}
                                <div className="box" style={{
                                        backgroundColor: `#${item.color}`
                                    }}>{item.grade}</div>
                                <div className="label">{item.title}</div>
                            </div>
                        ))
                }
            </div>
        </div>
    )
}