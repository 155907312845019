import {
    GET_MYTUTORS,
    GET_MYTUTORS_SUCCESS,
    GET_MYTUTORS_FAIL,
    GET_PENDING_LESSON,
    GET_PENDING_LESSON_SUCCESS,
    GET_PENDING_LESSON_FAIL,
    GET_COMPLETED_LESSON,
    GET_COMPLETED_LESSON_SUCCESS,
    GET_COMPLETED_LESSON_FAIL,
    GET_MY_PROFILE_INFO,
    GET_MY_PROFILE_INFO_SUCCESS,
    GET_MY_PROFILE_INFO_FAIL,
    GET_PROFILE_UPDATE,
    GET_PROFILE_UPDATE_SUCCESS,
    GET_PROFILE_UPDATE_FAIL,
    GET_SCHOOLS,
    GET_SCHOOLS_SUCCESS,
    GET_SCHOOLS_FAIL,
    GET_MY_TUTOR_DETAIL,
    GET_MY_TUTOR_DETAIL_SUCCESS,
    GET_MY_TUTOR_DETAIL_FAIL,
    GET_RECEIPT,
    GET_RECEIPT_SUCCESS,
    GET_RECEIPT_FAIL,
    GET_LEVELS,
    GET_LEVELS_SUCCESS,
    GET_LEVELS_FAIL,
    GET_SUBJECTS,
    GET_SUBJECTS_SUCCESS,
    GET_SUBJECTS_FAIL,
    GET_MY_REVIEWS,
    GET_MY_REVIEWS_SUCCESS,
    GET_MY_REVIEWS_FAIL,
    GET_PLANS,
    GET_PLANS_FAIL,
    GET_PLANS_SUCCESS,
    GET_BRAINTREEKEY,
    GET_BRAINTREEKEY_FAIL,
    GET_BRAINTREEKEY_SUCCESS,
    GET_MESSAGE,
    GET_MESSAGE_FAIL,
    GET_MESSAGE_SUCCESS,
    GET_MESSAGE_USER_LIST,
    GET_MESSAGE_USER_LIST_FAIL,
    GET_MESSAGE_USER_LIST_SUCCESS,
    GET_CARD_INFO,
    GET_CARD_INFO_FAIL,
    GET_CARD_INFO_SUCCESS,
    GET_SAL_SUBSCRIPTION,
    GET_SAL_SUBSCRIPTION_SUCCESS,
    GET_SAL_SUBSCRIPTION_FAIL,
    GET_RECOMMENDATION,
    GET_RECOMMENDATION_SUCCESS,
    GET_RECOMMENDATION_FAIL,
    TOAST_MESSAGE,
  } from "../Type";
  
  const INITIAL = {
    mytutors: [],
    mytutorsErr: '',
    pendingLesson: [],
    completedLesson: [],
    myProfileInfo: [],
    profileUpdateStatus: null,
    profileUpdateErr: null,
    schools: [],
    myTutorDetail: null,
    receiptData: [],
    levels: [],
    subjects: [],
    myReviewsInfo: null,
    myReviewsInfoErr: '',    
    braintreeKey: null,
    messageData: [],
    messageUserList: [],
    card_numbers: '',
    smileandlearn: [],
    recommendation: [],
    toast_message: null,
  };
  
  export default (state = INITIAL, action) => {
    switch (action.type) {
        // MYTUTORS
        case GET_MYTUTORS:
            return { ...state, mytutors: [] };
        case GET_MYTUTORS_SUCCESS:
            return { ...state, mytutors: action.payload};
        case GET_MYTUTORS_FAIL:
            return { ...state, mytutorsErr: action.payload};

        // PENDING_LESSON
        case GET_PENDING_LESSON:
            return { ...state, pendingLesson: [] };
        case GET_PENDING_LESSON_SUCCESS:
            return { ...state, pendingLesson: action.payload };
        case GET_PENDING_LESSON_FAIL:
            return { ...state, pendingLesson: [] };

        // COMPLETED_LESSON
        case GET_COMPLETED_LESSON:
            return { ...state, completedLesson: [] };
        case GET_COMPLETED_LESSON_SUCCESS:
            return { ...state, completedLesson: action.payload };
        case GET_COMPLETED_LESSON_FAIL:
            return { ...state, completedLesson: [] };

        // MY_PROFILE_INFO
        case GET_MY_PROFILE_INFO:
            return { ...state, myProfileInfo: [] };
        case GET_MY_PROFILE_INFO_SUCCESS:
            return { ...state, myProfileInfo: action.payload };
        case GET_MY_PROFILE_INFO_FAIL:
            return { ...state, myProfileInfo: [] };

        // PROFILE UPDATE
        case GET_PROFILE_UPDATE:
            return { ...state, profileUpdateStatus: null };
        case GET_PROFILE_UPDATE_SUCCESS:
            return { ...state, profileUpdateStatus: action.payload, myProfileInfo: action.payload };
        case GET_PROFILE_UPDATE_FAIL:
            return { ...state, profileUpdateStatus: null, profileUpdateErr: action.payload.error };

        // SCHOOLS
        case GET_SCHOOLS:
            return { ...state, schools: [] };
        case GET_SCHOOLS_SUCCESS:
            return { ...state, schools: action.payload };
        case GET_SCHOOLS_FAIL:
            return { ...state, schools: [] };

        // MY_TUTOR_DETAIL
        case GET_MY_TUTOR_DETAIL:
            return { ...state, myTutorDetail: null };
        case GET_MY_TUTOR_DETAIL_SUCCESS:
            return { ...state, myTutorDetail: action.payload };
        case GET_MY_TUTOR_DETAIL_FAIL:
            return { ...state, myTutorDetail: null };

        // RECEIPT
        case GET_RECEIPT:
            return { ...state, receiptData: [] };
        case GET_RECEIPT_SUCCESS:
            return { ...state, receiptData: action.payload };
        case GET_RECEIPT_FAIL:
            return { ...state, receiptData: [] };

        // LEVELS
        case GET_LEVELS:
            return { ...state, levels: [] };
        case GET_LEVELS_SUCCESS:
            return { ...state, levels: action.payload };
        case GET_LEVELS_FAIL:
            return { ...state, levels: [] };

        // SUBJECTS
        case GET_SUBJECTS:
            return { ...state, subjects: [] };
        case GET_SUBJECTS_SUCCESS:
            return { ...state, subjects: action.payload };
        case GET_SUBJECTS_FAIL:
            return { ...state, subjects: [] };

        // MY REVIEWS 
        case GET_MY_REVIEWS:
            return { ...state, myReviewsInfo: null };
        case GET_MY_REVIEWS_SUCCESS:
            return { ...state, myReviewsInfo: action.payload };
        case GET_MY_REVIEWS_FAIL:
            return { ...state, myReviewsInfoErr: action.payload.message};

        // PLANS
        case GET_PLANS:
            return { ...state, plans: null };
        case GET_PLANS_SUCCESS:
            return { ...state, plans: action.payload };
        case GET_PLANS_FAIL:
            return { ...state, plans: null };

        // BRAINTREEKEY
        case GET_BRAINTREEKEY:
            return { ...state, stripeKey: null };
        case GET_BRAINTREEKEY_SUCCESS:
            const { token } = action.payload;
            return { ...state, braintreeKey: token };
        case GET_BRAINTREEKEY_FAIL:
            return { ...state, stripeKey: null };

        // GET MESSAGE
        case GET_MESSAGE:
            return { ...state, messageData: [] };
        case GET_MESSAGE_SUCCESS:
            return { ...state, messageData: action.payload };
        case GET_MESSAGE_FAIL:
            return { ...state, messageData: [] };

        // GET MESSAGE USER LIST
        case GET_MESSAGE_USER_LIST:
            return { ...state, messageUserList: [] };
        case GET_MESSAGE_USER_LIST_SUCCESS:
            return { ...state, messageUserList: action.payload };
        case GET_MESSAGE_USER_LIST_FAIL:
            return { ...state, messageUserList: [] };

        // GET CARD INFO
        case GET_CARD_INFO:
            return { ...state, card_numbers: '' };
        case GET_CARD_INFO_SUCCESS:
            return { ...state, card_numbers: action.payload };
        case GET_CARD_INFO_FAIL:
            return { ...state, card_numbers: '' };

        // Smile and learn subscription
        case GET_SAL_SUBSCRIPTION:
            return { ...state, smileandlearn: [] };
        case GET_SAL_SUBSCRIPTION_SUCCESS:
            return { ...state, smileandlearn: action.payload };
        case GET_SAL_SUBSCRIPTION_FAIL:
            return { ...state, smileandlearn: [] };

        case TOAST_MESSAGE:
            return { ...state, toast_message: action.payload };

        // Recommendation
        case GET_RECOMMENDATION:
            return { ...state, recommendation: [] };
        case GET_RECOMMENDATION_SUCCESS:
            return { ...state, recommendation: action.payload };
        case GET_RECOMMENDATION_FAIL:
            return { ...state, recommendation: [] };
        default:
            return state;
    }
  };
  