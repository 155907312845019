import React, { useState } from 'react';
import './style.scss';
import _ from 'lodash';
import { Plan } from 'src/Components';

export default function SelectPlan({
  mPlans,
  mCurrentPlan,
  onCancelSubscription,
  onNext,
}) {

  const [selectedPlanId, setSelectedPlanId] = useState(2);

  const onChosePlan = (item) => {
    setSelectedPlanId(item.id)
    onNext(item.id);
  };

  return (
    <div className="select-plan-component">
      <div className="container">
        <h2 className='text-center'>Current Plan</h2>
        {!_.isEmpty(mCurrentPlan) ? (
          <div>
            <Plan
              info={mCurrentPlan}
            />
            {mCurrentPlan?.status === 'active' && (
              <button className='btn-2 cancel-btn' onClick={onCancelSubscription}>
                Cancel subscription
              </button>
            )}
          </div>
        ) : (
          <div className='no-plan text-center' >You do not have a plan yet</div>
        )}
        {!_.isEmpty(mPlans) && (
          <>
            <h2 className='text-center mt-5'>Select Your Rate Plan</h2>
            <div className="plan-content">
              {mPlans.map((item, index) =>
                <Plan
                  key={index}
                  info={item}
                  selectedPlanId={selectedPlanId}
                  onChosePlan={onChosePlan}
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
